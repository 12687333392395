import { Outlet } from "react-router-dom";
import { AppHeader } from "../../components/app-header/app-header";
import Menu from "../../components/menu/menu";
import { AppPopups } from "../../pages/user/app-popups/app-popups";
import "./admin-layout.scss";

export default function AdminLayout() {
  return (
    <>
      <Menu />
      <AppHeader />
      <AppPopups />
      <div className="admin-layout-container">
        <Outlet />
      </div>
    </>
  );
}
