import { ReactComponent as VCircle } from "../../../../../../assets/icons/v-circle.svg";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { ReactComponent as BoostersExampleIcon } from "../../../../../../assets/icons/boosters-example.svg";
import "./share-step.scss";
import { useTranslation } from "react-i18next";
import BosstersExampleHe from "../../../../../../assets/images/boosters-example-he.jpeg";

export const ShareStep = ({ setShareBoosterPopupOpen, navigateToNextStep }) => {
  const { t, i18n } = useTranslation("PopUps");

  return (
    <>
      <div className="share-step-container flex">
        <VCircle width={70} height={70} className="v-circle-icon" />
        <div className="text">
          <div>{t("ShareStep.wonderful")}</div>
          <div>{t("ShareStep.every-action")}</div>
          <div>{t("ShareStep.is-significant")}</div>
          <div>{t("ShareStep.find-and-add")}</div>
          {i18n.language === "he" ? (
            <img className="boosters-example-img" src={BosstersExampleHe} />
          ) : (
            <BoostersExampleIcon className="boosters-example-icon" />
          )}{" "}
        </div>
        <PopupButton
          className="share-button"
          onClick={() => setShareBoosterPopupOpen(true)}
        >
          {t("Common:share")}
        </PopupButton>
        <PopupButton className="share-button" onClick={navigateToNextStep}>
          {t("Common:continue")}
        </PopupButton>
      </div>
    </>
  );
};
