import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import {
  changeMapInstructionsPopupOpen,
  changeViewMainGoalPopup,
} from "../../../../../../store/map/map-slice";
import "./top-header-details.scss";
import { useTranslation } from "react-i18next";

export const TopHeaderDetails = ({
  firstName,
  mainGoal,
  shareMode,
  mapFinished,
  mapArchived,
  ...props
}) => {
  const { t, i18n } = useTranslation("Maps");

  const isIOS = /iPad|iPhone|iPod|/.test(navigator.userAgent);

  const dispatch = useDispatch();

  const openViewMainGoalPopup = () => {
    if (mainGoal.length > 40)
      dispatch(
        changeViewMainGoalPopup({
          open: true,
          goal: mainGoal,
        })
      );
  };

  return (
    <div className="top-header-container">
      {mapFinished ? (
        <div className="completed-ribbon">
          <span>&nbsp;&nbsp;{t("ViewMap.AnimatedMap.completed")}</span>
        </div>
      ) : (
        <FontAwesomeIcon
          icon={faCircleInfo}
          className="help-icon"
          onClick={() => {
            dispatch(changeMapInstructionsPopupOpen(true));
          }}
          style={isIOS ? { transform: "translateY(20px)" } : {}}
        />
      )}
      {mapArchived && (
        <div className="archived-container flex">
          <p className="archived">{t("ViewMap.AnimatedMap.archive")}</p>
        </div>
      )}
      <div
        className={
          "top-header-details-container flex" +
          (props.className ? ` ${props.className}` : "")
        }
      >
        <div className="name flex">
          {!shareMode ? (
            <p className="text">
              {t("ViewMap.AnimatedMap.hello")} {firstName}
            </p>
          ) : (
            <p className="text">
              {firstName}
              {t("ViewMap.AnimatedMap.owner")}
            </p>
          )}
        </div>
        <div className="main-goal-details flex">
          <p className="text" onClick={openViewMainGoalPopup}>
            {t(`Maps:CreateMap.MainGoal.${mainGoal}`) !==
            `CreateMap.MainGoal.${mainGoal}`
              ? t(`Maps:CreateMap.MainGoal.${mainGoal}`)
              : mainGoal}
          </p>
        </div>
      </div>
    </div>
  );
};
