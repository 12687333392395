import {
  InitStepSegmentsValues,
  StepSegmentsValues,
} from "../pages/user/maps/view-map/enums/map-lotties-enums";
import colors from "./colors.scss";

export const updateInitialSegment = (steps, setInitialSegment) => {
  if (steps[2].Completed)
    // step 3 completed
    setInitialSegment({
      updated: true,
      value: InitStepSegmentsValues.Step3.End,
    });
  else if (steps[1].Completed)
    // step 2 completed
    setInitialSegment({
      updated: true,
      value: InitStepSegmentsValues.Step3.Start,
    });
  else if (steps[0].Completed)
    // step 1 completed
    setInitialSegment({
      updated: true,
      value: InitStepSegmentsValues.Step2.Start,
    });
  else
    setInitialSegment({
      updated: true,
      value: InitStepSegmentsValues.Step1.Start,
    });
};

export const setCurrentCategoryColor = (categoryName) => {
  //   console.log({ categoryName });
  let categoryColor = "";
  switch (categoryName) {
    case "Family":
      categoryColor = colors.familyColor;
      break;
    case "Health":
      categoryColor = colors.healthColor;
      break;
    case "Self & Wellbeing":
      categoryColor = colors.selfAndWellbeingColor;
      break;
    case "Leisure":
      categoryColor = colors.leisureColor;
      break;
    case "Spiritual":
      categoryColor = colors.spiritualColor;
      break;
    case "Social & Community":
      categoryColor = colors.socialAndCommunityColor;
      break;
    case "Professional/Career":
      categoryColor = colors.professionalOrCareerColor;
      break;
    case "Financial":
      categoryColor = colors.financialColor;
      break;
    case "Education":
      categoryColor = colors.educationColor;
      break;
    case "Legacy":
      categoryColor = colors.educationColor;
      break;
    default:
      categoryColor = colors.otherColor;
      break;
  }
  document.documentElement.style.setProperty(
    "--category-background-color",
    categoryColor
  );
};

export const getCategoryColor = (categoryName) => {
  //   console.log({ categoryName });
  switch (categoryName) {
    case "Family":
      return colors.familyColor;
    case "Health":
      return colors.healthColor;
    case "Self & Wellbeing":
      return colors.selfAndWellbeingColor;
    case "Leisure":
      return colors.leisureColor;
    case "Spiritual":
      return colors.spiritualColor;
    case "Social & Community":
      return colors.socialAndCommunityColor;
    case "Professional/Career":
      return colors.professionalOrCareerColor;
    case "Financial":
      return colors.financialColor;
    case "Education":
      return colors.educationColor;
    case "Legacy":
      return colors.educationColor;
    default:
      return colors.otherColor;
  }
};
