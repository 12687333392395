import React from "react";
import { Button } from "../../../../../../components/button/button";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import "./read-more-popup.scss";
import { useTranslation } from "react-i18next";

export const ReadMorePopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  return (
    <Popup
      height={"504px"}
      close={close}
      buttons={<PopupButton onClick={close}>Back</PopupButton>}
    >
      <div className="read-more-popup-container">
        <h2>{t("CreateMap.SmartGoal.ReadMorePopUp.title")}</h2>

        <h3>{t("CreateMap.SmartGoal.ReadMorePopUp.S-Specific")}</h3>
        <p>{t("CreateMap.SmartGoal.ReadMorePopUp.S-Specific-info")}</p>

        <h3>{t("CreateMap.SmartGoal.ReadMorePopUp.M-Measurable")}</h3>
        <p>{t("CreateMap.SmartGoal.ReadMorePopUp.M-Measurable-info")}</p>

        <h3>{t("CreateMap.SmartGoal.ReadMorePopUp.A-Attainable")}</h3>
        <p>{t("CreateMap.SmartGoal.ReadMorePopUp.A-Attainable-info")}</p>

        <h3>{t("CreateMap.SmartGoal.ReadMorePopUp.R-Relevant")}</h3>
        <p>{t("CreateMap.SmartGoal.ReadMorePopUp.R-Relevant-info")}</p>

        <h3>{t("CreateMap.SmartGoal.ReadMorePopUp.T-Timely")}</h3>
        <p>{t("CreateMap.SmartGoal.ReadMorePopUp.T-Timely-info")}</p>
      </div>
      <div className="read-more-button-wrapper flex">
        <Button onClick={close}>{t("Common:back")}</Button>
      </div>
    </Popup>
  );
};
