import { faCircleInfo, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { ReactComponent as EducationCategoryIcon } from "../../../../../../assets/icons/education-category.svg";
import { ReactComponent as FamilyCategoryIcon } from "../../../../../../assets/icons/family-category.svg";
import { ReactComponent as FinancialCategoryIcon } from "../../../../../../assets/icons/financial-category.svg";
import { ReactComponent as HealthCategoryIcon } from "../../../../../../assets/icons/health-category.svg";
import { ReactComponent as LegacyCategoryIcon } from "../../../../../../assets/icons/legacy-category.svg";
import { ReactComponent as LeisureCategoryIcon } from "../../../../../../assets/icons/leisure-category.svg";
import { ReactComponent as OtherCategoryIcon } from "../../../../../../assets/icons/other-category.svg";
import { ReactComponent as ProfessionalOrCareerCategoryIcon } from "../../../../../../assets/icons/professional-or-career-category.svg";
import { ReactComponent as SelfAndWellbeingCategoryCategoryIcon } from "../../../../../../assets/icons/self-and-wellbeing-category.svg";
import { ReactComponent as SocialAndCommunityCategoryIcon } from "../../../../../../assets/icons/social-and-community.svg";
import { ReactComponent as SpiritualCategoryIcon } from "../../../../../../assets/icons/spiritual-category.svg";
import { CustomCategoryInstructionsPopup } from "./custom-category-instructions-popup/custom-category-instructions-popup";
import { EditCustomCategoryPopup } from "./edit-custom-category-popup/edit-custom-category-popup";
import "./category-button.scss";
import { useTranslation } from "react-i18next";

export const CategoryButton = ({
  category,
  saveNewCustomCategory,
  removeCustomCategory,
  ...props
}) => {
  const { t, i18n } = useTranslation("Common");
  const [categoryIcon, setCategoryIcon] = useState("");
  const [editCustomCategoryPopupOpen, setEditCustomCategoryPopupOpen] =
    useState(false);
  const [
    customCategoryInstructionPopupOpen,
    setCustomCategoryInstructionPopupOpen,
  ] = useState(false);

  useEffect(() => {
    switch (category.Name) {
      case "Health":
        setCategoryIcon(<HealthCategoryIcon className="icon" />);
        break;
      case "Self & Wellbeing":
        setCategoryIcon(
          <SelfAndWellbeingCategoryCategoryIcon className="icon" />
        );
      case "Family":
        setCategoryIcon(<FamilyCategoryIcon className="icon" />);
        break;
      case "Leisure":
        setCategoryIcon(<LeisureCategoryIcon className="icon" />);
        break;
      case "Spiritual":
        setCategoryIcon(<SpiritualCategoryIcon className="icon" />);
        break;
      case "Social & Community":
        setCategoryIcon(<SocialAndCommunityCategoryIcon className="icon" />);
        break;
      case "Professional/Career":
        setCategoryIcon(<ProfessionalOrCareerCategoryIcon className="icon" />);
        break;
      case "Financial":
        setCategoryIcon(<FinancialCategoryIcon className="icon" />);
        break;
      case "Education":
        setCategoryIcon(<EducationCategoryIcon className="icon" />);
        break;
      case "Legacy":
        setCategoryIcon(<LegacyCategoryIcon className="icon" />);
        break;
      default:
        setCategoryIcon(<OtherCategoryIcon className="icon" />);
        break;
    }
  }, []);

  const openEditCustomCategoryPopup = (e) => {
    setEditCustomCategoryPopupOpen(true);
  };

  return (
    <>
      {editCustomCategoryPopupOpen && (
        <EditCustomCategoryPopup
          category={category}
          saveNewCustomCategory={saveNewCustomCategory}
          removeCustomCategory={removeCustomCategory}
          close={() => setEditCustomCategoryPopupOpen(false)}
        />
      )}

      {customCategoryInstructionPopupOpen && (
        <CustomCategoryInstructionsPopup
          close={() => setCustomCategoryInstructionPopupOpen(false)}
        />
      )}

      {category.IsCustom ? (
        <div className="flex">
          <FontAwesomeIcon
            size="lg"
            icon={faCircleInfo}
            style={{ margin: "0 5px" }}
            onClick={() => {
              setCustomCategoryInstructionPopupOpen(true);
            }}
          />
          <div className="category-button flex">
            {categoryIcon}
            <div className="text" {...props}>
              {category.Name}
            </div>
            <div className="flex edit-icons">
              <FontAwesomeIcon
                icon={faEdit}
                onClick={openEditCustomCategoryPopup}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="category-button flex" {...props}>
          {categoryIcon}
          <div className="text">{t(`Categories.${category.Name}`)}</div>
        </div>
      )}
    </>
  );
};
