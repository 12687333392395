import React from "react";
import "./popup-button.scss";

export const PopupButton = ({ children, className, ...props }) => {
  return (
    <button
      className={
        "popup-button flex" +
        (props.continue ? " continue" : "") +
        (className ? ` ${className}` : "")
      }
      {...props}
    >
      {children}
    </button>
  );
};
