import debounce from "lodash/debounce";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { Button } from "../../../../../components/button/button";
import { ImageBlank } from "../../../../../components/image-blank/image-blank";
import { Input } from "../../../../../components/input/input";
import { Loader } from "../../../../../components/loader/loader";
import { Select } from "../../../../../components/select/select";
import { SmallTitle } from "../../../../../components/small-title/small-title";
import { setTitle } from "../../../../../store/app-settings/app-settings-slice";
import {
  changeProfile,
  updateDraftMap,
  updateUserPoints,
} from "../../../../../store/profile/profile-slice";
import Api from "../../../../../utils/api";
import { FinishedSettingGoalPopup } from "../popups/finished-setting-goal/finished-setting-goal-popup";
import "./create-name-and-image.scss";
import { useTranslation } from "react-i18next";
import { NativeEvents } from "../../../../../utils/enums";
import { ImageUpload } from "../../../../../components/image-upload/image-upload";

export const CreateNameAndImage = ({
  createMapStage,
  setCreateMapStage,
  ...props
}) => {
  const { t, i18n } = useTranslation("Maps");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const profile = useSelector((state) => state.profileReducer.profile);
  const map = useSelector((state) => state.mapReducer.map);
  const { newsletterPopupOpen } = useSelector((state) => ({
    newsletterPopupOpen: state.appSettingsReducer.newsletterPopupOpen,
  }));
  const params = useParams();
  const stepsButtonsRef = useRef();
  const goalRefs = useRef([]);
  const [showError, setShowError] = useState(false);
  const [baseMap, setBaseMap] = useState(null);
  const [timeToComplete, setTimeToComplete] = useState(0);
  const [finishedSettingGoalPopupOpen, setFinishedSettingGoalPopupOpen] =
    useState(false);
  const [
    finishedSettingThreeStepsPopupOpen,
    setFinishedSettingThreeStepsPopupOpen,
  ] = useState(false);
  const [finishedCreatingMapPopupOpen, setFinishedCreatingMapPopupOpen] =
    useState(false);
  const [newMapId, setNewMapId] = useState("");
  const [goalImageSrc, setGoalImageSrc] = useState(null);
  const [goalImagePreviewSrc, setGoalImagePreviewSrc] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [
    cameraAndMediaLibraryPermissionGranted,
    setCameraAndMediaLibraryPermissionGranted,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [missingPathwayMessage, setMissingPathwayMessage] = useState({
    show: false,
    index: 0,
  });
  const [info, setInfo] = useState({
    CategoryId: "",
    CategoryName: "",
    MainGoal: "",
    StrengthWord: "",
    Image: "",
    Steps: [
      {
        Name: "",
        Obstacle: "",
        Overcome: "",
        Phone: "",
        Email: "",
        StartDate: null,
        StartReminder: null,
      },
      {
        Name: "",
        Obstacle: "",
        Overcome: "",
        Phone: "",
        Email: "",
        StartDate: null,
        StartReminder: null,
      },
      {
        Name: "",
        Obstacle: "",
        Overcome: "",
        Phone: "",
        Email: "",
        StartDate: null,
        StartReminder: null,
      },
    ],
  });
  useEffect(() => {
    document.addEventListener(
      "message",
      checkCameraAndMediaLibraryPermissionResponse
    );

    return () => {
      document.removeEventListener(
        "message",
        checkCameraAndMediaLibraryPermissionResponse
      );
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () => setTimeToComplete((prev) => prev + 1),
      1000
    );
    return () => clearInterval(interval);
  }, []);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (profile?.DraftMap?.Map) {
      initMapInfo(profile.DraftMap.Map);
    }
  }, [profile]);

  const checkCameraAndMediaLibraryPermissionResponse = (message) => {
    if (message.data.cameraAndMediaLibraryPermissionGranted) {
      setCameraAndMediaLibraryPermissionGranted(true);
      uploadRef.current.open();
    }
  };

  const initMapInfo = (mapToEdit) => {
    dispatch(setTitle(t("CreateMap.CreateGoal.title")));
    setBaseMap(mapToEdit);
    for (var key of Object.keys(info)) {
      if (mapToEdit[key]) {
        changeInfo(key, mapToEdit[key]);
      }
    }
    getCategoryOptionsForDraftMap(profile.DraftMap.Map.CategoryId);

    if (profile.DraftMap.Map.Image.Path) {
      setGoalImagePreviewSrc(
        `${process.env.REACT_APP_BASE_IMAGE_URL}/${mapToEdit.Image.Path}`
      );
      if (mapToEdit.Image.Path) setGoalImageSrc(`${mapToEdit.Image.Path}`);
    }
  };

  const getCategoryOptionsForDraftMap = async (categoryId) => {
    try {
      const res = await Api.get(`user/categories/${categoryId}`);
      if (res.data.payload) {
        setCategoryOptions(
          [
            { label: t("Common:select-or-type"), value: "*", isDisabled: true },
          ].concat(
            res.data.payload.GoalsOptions.map((option) => ({
              value: option,
              label: t(`CreateMap.MainGoal.${option}`),
            }))
          )
        );
      }
    } catch (error) {}
  };

  const changeInfo = (name, value) => {
    console.log("changeInfo");
    setInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    changeInfo(e.target.name, e.target.value);
    debouncedUpdateDaftMap({
      ...info,
      [e.target.name]: e.target.value,
      TimeToComplete: timeToComplete,
    });
    //setTimeToComplete(0);
    // dispatch(
    //   changeProfile({
    //     ...profile,
    //     DraftMap: {
    //       ...profile.DraftMap,
    //       Map: {
    //         ...profile.DraftMap.Map,
    //         [e.target.name]: e.target.value,
    //       },
    //     },
    //   })
    // );
  };

  const handleChangeSelect = (name, selectedOption) => {
    console.log("handleChangeSelect");
    if (name === "MainGoal") {
      dispatch(updateUserPoints({ points: 100, createStepType: "goal step" }));
    }
    changeInfo(name, selectedOption.value);
    if (
      selectedOption.value.replace(/^\s+/g, "") &&
      selectedOption.value !== baseMap.MainGoal
    ) {
      debouncedUpdateDaftMap({
        ...info,
        [name]: selectedOption.value,
        TimeToComplete: timeToComplete,
      });
      setTimeToComplete(0);
      // dispatch(
      //   changeProfile({
      //     ...profile,
      //     DraftMap: {
      //       ...profile.DraftMap,
      //       Map: {
      //         ...profile.DraftMap.Map,
      //         [name]: selectedOption.value,
      //       },
      //     },
      //   })
      // );
    }
  };

  const resetValue = (name) => {
    changeInfo([name], "");
  };

  const changeDraftMap = async (data) => {
    console.log({ data }, "changeDraftMap");
    dispatch(
      updateDraftMap({
        ...data,
      })
    );
    setTimeToComplete(0);
  };

  const [debouncedUpdateDaftMap] = useState(() =>
    debounce(changeDraftMap, 1000)
  );

  const focusEmptyInput = (name) => {
    const index = Object.keys(info).indexOf(name);
    goalRefs.current[index].select();
    goalRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const focusEmptySelect = (name) => {
    const index = Object.keys(info).indexOf(name);
    goalRefs.current[index].focus();
  };

  const showMissingPathwayMessage = (index) => {
    setMissingPathwayMessage({ show: true, index });
  };

  const changeMapImage = (imagesrc, fromGallery) => {
    if (!imagesrc) {
      setGoalImagePreviewSrc("");
      setGoalImageSrc("");
    }

    dispatch(
      changeProfile({
        ...profile,
        DraftMap: {
          ...profile.DraftMap,
          Map: {
            ...profile.DraftMap.Map,
            Image: {
              Path: imagesrc,
              fromGallery: fromGallery,
            },
          },
        },
      })
    );
  };

  const validate = () => {
    let isValidate = true;
    if (!info.StrengthWord.replace(/^\s+/g, "")) {
      focusEmptyInput("StrengthWord");
      isValidate = false;
    }
    if (!info.MainGoal.replace(/^\s+/g, "")) {
      focusEmptySelect("MainGoal");
      isValidate = false;
    }
    // for (let i = info.Steps.length - 1; i >= 0; i--) {
    //   if (info.Steps.filter((step) => step.Name !== "").length !== 3) {
    //     // not all steps defined
    //     if (!info.Steps[i].Name.replace(/^\s+/g, "")) {
    //       isValidate = false;
    //       showMissingPathwayMessage(i);
    //     }
    //   } else {
    //     // not all steps edited
    //     if (
    //       !info.Steps[i].Name.replace(/^\s+/g, "") ||
    //       !info.Steps[i].Obstacle.replace(/^\s+/g, "") ||
    //       !info.Steps[i].Overcome.replace(/^\s+/g, "")
    //     ) {
    //       isValidate = false;
    //       showMissingPathwayMessage(i);
    //     }
    //   }
    // }
    if (!isValidate) setShowError(true);
    return isValidate;
  };

  const saveMap = async () => {
    if (!validate()) return;
    setFinishedSettingGoalPopupOpen(true);
    // setIsLoading(true);
    // try {
    //   let res = null;
    //   // create map
    //   const data = {
    //     ...info,
    //     Image: profile.DraftMap.Map.Image,
    //   };
    //   res = await Api.post("/user/maps", data);
    //   if (res.data.payload) {
    //     setNewMapId(res.data.payload);
    //     dispatch(resetDraftMap());
    //     if (!profile.FirstMapCreatedDate) {
    //       dispatch(
    //         changeProfile({ ...profile, FirstMapCreatedDate: new Date() })
    //       );
    //       dispatch(openNewsletterPopup());
    //     }
    //     setFinishedCreatingMapPopupOpen(true);
    //   } else {
    //     console.log("error");
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const navigateToViewMap = () => {
    setFinishedCreatingMapPopupOpen(false);
    navigate(`/maps/view/${newMapId}`);
  };

  const navigateBack = () => {
    const newSelectedCategory = {
      Id: baseMap.CategoryId,
      Name: baseMap.CategoryName,
    };
    navigate("../../category", {
      state: {
        selectedCategory: newSelectedCategory,
      },
    });
  };

  return (
    <div className="create-name-and-image-container">
      {finishedSettingGoalPopupOpen && (
        <FinishedSettingGoalPopup
          close={() => {
            debouncedUpdateDaftMap({
              ...info,
              TimeToComplete: timeToComplete,
            });
            setCreateMapStage({
              ...createMapStage,
              createGoal: false,
              createSteps: true,
            });
          }}
        />
      )}

      {profile ? (
        <>
          <SmallTitle>{t("Goal.my-goal-is")}</SmallTitle>
          <Select
            name="MainGoal"
            value={
              categoryOptions.filter((option) => option.value === info.MainGoal)
                .length > 0
                ? categoryOptions.filter(
                    (option) => option.value === info.MainGoal
                  )[0].label
                : info.MainGoal !== ""
                ? info.MainGoal
                : ""
            }
            onChange={(e) => {
              handleChangeSelect("MainGoal", e);
              props.setGoal(e.label);
            }}
            options={categoryOptions}
            isError={showError && !info.MainGoal.replace(/^\s+/g, "")}
            ref={(el) =>
              (goalRefs.current[Object.keys(info).indexOf("MainGoal")] = el)
            }
            placeholder={t("Goal.my-goal-is")}
            maxLength={50}
            editable
          />

          <div className="goal-text">
            {t("CreateMap.CreateGoal.words-to-inpire")}
          </div>
          <SmallTitle>{t("Goal.strengthening-word")}</SmallTitle>
          <Input
            type="text"
            name="StrengthWord"
            value={info.StrengthWord}
            onChange={handleChange}
            reset={resetValue}
            placeholder={t("Goal.strengthening-word")}
            isError={showError}
            ref={(el) =>
              (goalRefs.current[Object.keys(info).indexOf("StrengthWord")] = el)
            }
          />
          <div className="goal-text">
            {t("CreateMap.CreateGoal.visual-images")}
          </div>
          <SmallTitle>{t("Goal.choose-an-image")}</SmallTitle>
          <div className="image-container">
            <ImageUpload
              mapId={null}
              defaultImageSrc={goalImagePreviewSrc}
              changeImage={changeMapImage}
              saveImageUrl={"/user/profile/draftMap/image"}
            />
          </div>

          <div
            className="goal-buttons flex"
            style={{ marginTop: goalImageSrc ? "50px" : "100px" }}
          >
            <Button className="goal-button" onClick={navigateBack}>
              {t("Common:cancel")}
            </Button>
            <Button className="goal-button" onClick={saveMap}>
              {t("Common:save")}
            </Button>
          </div>
        </>
      ) : (
        <Loader className="page" />
      )}
    </div>
  );
};
