export const sharePlatformOptions = [
  { value: "WhatsApp", label: "WhatsApp" },
  { value: "Telegram", label: "Telegram" },
  { value: "Twitter", label: "Twitter" },
  { value: "Email", label: "Email" },
  { value: "Facebook", label: "Facebook" },
  { value: "Copy to clipboard", label: "Copy to clipboard" },
];

export const sharePlatformNames = {
  WhatsApp: "WhatsApp",
  Telegram: "Telegram",
  Twitter: "Twitter",
  Email: "Email",
  Facebook: "Facebook",
  Linkedin: "Linkedin",
  FacebookMessenger: "FacebookMessenger",
  CopyToClipboard: "Copy to clipboard",
};
