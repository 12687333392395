import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../../components/button/button";
import { InputSwitch } from "../../../components/input-switch/input-switch";
import { SelectLanguage } from "../../../components/select-language/select-language";
import { SmallTitle } from "../../../components/small-title/small-title";
import {
  setShowLoader,
  setTitle,
  showToastMessage,
} from "../../../store/app-settings/app-settings-slice";
import {
  changeProfile,
  getProfile,
} from "../../../store/profile/profile-slice";
import Api from "../../../utils/api";
import { NativeEvents } from "../../../utils/enums";
import { setDirection } from "../../../store/app-settings/app-settings-slice";
import "./profile-settings.scss";
import { useTranslation } from "react-i18next";
import { ProfileImageUpload } from "../../../components/profile-image-upload/profile-image-upload";
import countryList from "country-list";
import { Select } from "../../../components/select/select";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popup } from "../../../components/popup/popup";
import { PopupButton } from "../../../components/popup-button/popup-button";
import { PopupTitle } from "../../../components/popup/popup-title/popup-title";

export const ProfileSettings = () => {
  const { t, i18n } = useTranslation("ProfileSettings");

  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const dispatch = useDispatch();
  const uploadRef = useRef();
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const profile = useSelector((state) => state.profileReducer.profile);
  const [userProfileImagePreviewSrc, setUserProfileImagePreviewSrc] =
    useState("");
  const [languageOptions, setLanguageOptions] = useState([
    { label: "English", value: "en" },
    { label: "Hebrew", value: "he" },
  ]);
  const [profileSettings, setProfileSettings] = useState({
    Allow: false,
    Email: false,
    // Mobile: false,
    Newsletter: false,
    LanguageCode: profile?.Settings.LanguageCode,
    // CountryCode: "",
  });
  const [countryOptions, setcountriesOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    label: "",
    value: "",
  });

  const cameraAndMediaLibraryPermissionGranted = useRef(false);

  useLayoutEffect(() => {
    if (profile) {
      if (profile?.Settings.LanguageCode.toLowerCase() === "he") {
        dispatch(setDirection("rtl"));
      } else {
        dispatch(setDirection("ltr"));
      }
      localStorage.setItem("hope-lang", profile?.Settings.LanguageCode);
      i18n.changeLanguage(profile?.Settings.LanguageCode);
      const countriesObj = countryList.getNameList();
      const arr = [];
      for (let key in countriesObj) {
        arr.push({
          value: countriesObj[key],
          label: key.charAt(0).toUpperCase() + key.slice(1),
        });
      }
      setcountriesOptions(arr);
    }
  }, [i18n, profile]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.addEventListener(
      "message",
      checkCameraAndMediaLibraryPermissionResponse
    );
    getProfileSettings();

    return () => {
      document.removeEventListener(
        "message",
        checkCameraAndMediaLibraryPermissionResponse
      );
    };
  }, []);

  const checkCameraAndMediaLibraryPermissionResponse = (message) => {
    if (message.data.cameraAndMediaLibraryPermissionGranted) {
      cameraAndMediaLibraryPermissionGranted.current = true;
      uploadRef.current.changeCameraAndMediaLibraryPermissionGranted(true);
      uploadRef.current.open();
    }
  };

  const getProfileSettings = async () => {
    dispatch(setShowLoader(true));
    dispatch(changeProfile(null));
    await dispatch(getProfile());
    dispatch(setShowLoader(false));
  };

  useEffect(() => {
    if (profile) {
      dispatch(
        setTitle(`${profile.FirstName} ${profile.LastName} ${t("profile")}`)
      );
      setProfileSettings({
        Email: profile.Settings.Notifications.Email,
        // Mobile: profile.Settings.Notifications.Mobile,
        Allow: profile.Settings.Notifications.Allow,
        Newsletter: profile.Settings.Notifications.Newsletter,
        LanguageCode: profile.Settings.LanguageCode,
      });
      if (profile && profile.Settings.ImagePath) {
        setUserProfileImagePreviewSrc(
          `${process.env.REACT_APP_BASE_IMAGE_URL}/${profile.Settings.ImagePath}`
        );
      }
      dispatch(setShowLoader(false));
    }
  }, [profile]);

  const changeProfileSettings = (name, value) => {
    setProfileSettings((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeLanguage = (name, selectedOption) => {
    changeProfileSettings(name, selectedOption);
  };

  const handleChangeCheckbox = (e) => {
    // console.log(e.target.name);
    // console.log(!profileSettings[e.target.name]);
    changeProfileSettings(`${e.target.name}`, !profileSettings[e.target.name]);
  };

  const changeProfileImage = async (imagesrc) => {
    setUserProfileImagePreviewSrc(imagesrc);
    dispatch(
      changeProfile({
        ...profile,
        Settings: {
          ...profile.Settings,
          ImagePath: imagesrc,
        },
      })
    );
  };

  const saveProfileSettings = async () => {
    try {
      //dispatch(showToastMessage({ loading: true }));
      const data = {
        Notifications: {
          Email: profileSettings.Email,
          Mobile: profileSettings.Allow,
          Allow: profileSettings.Allow,
          Newsletter: profileSettings.Newsletter,
        },
        LanguageCode: profileSettings.LanguageCode,
        // CountryCode: profileSettings.CountryCode,
      };

      const res = await Api.put("user/profile/settings", data);
      if (res.data) {
        dispatch(
          showToastMessage({
            text: t("success"),
            type: toast.TYPE.SUCCESS,
          })
        );
        dispatch(
          changeProfile({
            ...profile,
            Settings: {
              ...profile.Settings,
              Notifications: {
                ...profile.Settings.Notifications,
                Email: profileSettings.Email,
                Mobile: profileSettings.Allow,
                Allow: profileSettings.Allow,
                Newsletter: profileSettings.Newsletter,
              },
            },
          })
        );
        if (profileSettings.Allow) {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ event: NativeEvents.AddPushNotificationToken })
            );
          }
        }
        if (profileSettings.LanguageCode.toLowerCase() === "he") {
          dispatch(setDirection("rtl"));
        } else {
          dispatch(setDirection("ltr"));
        }
        localStorage.setItem("hope-lang", profileSettings.LanguageCode);
        i18n.changeLanguage(profileSettings.LanguageCode);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      // dispatch(showToastMessage({ text: "" }));
    }
  };

  return (
    <div className="settings-container">
      {showDeleteUserModal && (
        <div className="delete-user-modal-container">
          <Popup
            close={() => {
              setShowDeleteUserModal(false);
            }}
          >
            <div className="delete-user-item">
              <PopupTitle>{t("delete-user")}</PopupTitle>
            </div>
            <div className="delete-user-item">
              {t("delete-user-are-you-sure")}
            </div>
            <div className="flex delete-user-buttons">
              <PopupButton
                onClick={() => {
                  setShowDeleteUserModal(false);
                }}
              >
                {t("Common:cancel")}
              </PopupButton>
              <PopupButton
                onClick={() => {
                  Api.delete("user/profile").then((res) => {
                    setShowDeleteUserModal(false);
                    window.location.href = "../../login";
                  });
                }}
              >
                {t("Common:confirm")}
              </PopupButton>
            </div>
          </Popup>
        </div>
      )}
      {!showLoader && profile && (
        <>
          <div className="language-select-container">
            <table>
              <tbody>
                <tr>
                  <td className="language-select-info">
                    <SmallTitle>{t("select-language")}</SmallTitle>
                  </td>
                  <td className="language-select">
                    <SelectLanguage
                      handleChangeLanguage={handleChangeLanguage}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="profile-pic-container">
            <table>
              <tbody>
                <tr>
                  <td className="profile-pic-info">
                    <SmallTitle>{t("profile-pic")}</SmallTitle>
                  </td>
                  <td>
                    <ProfileImageUpload
                      defaultImageSrc={userProfileImagePreviewSrc}
                      changeImage={changeProfileImage}
                      saveImageUrl={"/user/profile/image"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          {/* <div className="country-container flex">
            <SmallTitle>{t("OnBoarding:BasicInfo.country")}</SmallTitle>
            <Select
              name="CountryCode"
              value={selectedCountry.label}
              onChange={(e) => {
                setSelectedCountry(e);
                changeProfileSettings("CountryCode", e.value);
              }}
              options={countryOptions}
              style={{ width: "200px" }}
              height="300px"
            />
          </div> */}
          <div className="notifications-container">
            <SmallTitle>{t("notifications")}</SmallTitle>
            <InputSwitch
              style={{ width: "100px" }}
              className="col-3"
              checked={profileSettings.Allow}
              setCheked={handleChangeCheckbox}
              id="Allow"
              name="Allow"
            />
          </div>
          <br />
          <div className="notifications-container">
            <SmallTitle>{t("email")}</SmallTitle>
            <InputSwitch
              style={{ width: "100px" }}
              className="col-3"
              checked={profileSettings.Email}
              setCheked={handleChangeCheckbox}
              id="Email"
              name="Email"
            />
          </div>
          <br />
          <div className="notifications-container">
            <SmallTitle>{t("newsletter")}</SmallTitle>
            <InputSwitch
              style={{ width: "100px" }}
              className="col-3"
              checked={profileSettings.Newsletter}
              setCheked={handleChangeCheckbox}
              name="Newsletter"
              id="Newsletter"
            />
          </div>
          <div className="delete-user-container">
            <SmallTitle>{t("delete-user")}</SmallTitle>
            <FontAwesomeIcon
              icon={faTrashCan}
              className="trash-icon"
              onClick={() => {
                setShowDeleteUserModal(true);
              }}
            />
          </div>
          <br /> <br /> <br />
          <Button onClick={saveProfileSettings}>{t("Common:save")}</Button>
        </>
      )}
    </div>
  );
};
