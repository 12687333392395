import React from "react";
import { ReactComponent as CustomCategoryExampleIcon } from "../../../../../../../assets/icons/custom-category-example.svg";
import { Popup } from "../../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../../components/popup/popup-title/popup-title";
import { PopupButton } from "../../../../../../../components/popup-button/popup-button";
import "./custom-category-instructions-popup.scss";
import { useTranslation } from "react-i18next";

export const CustomCategoryInstructionsPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  return (
    <Popup height={"320px"} close={close}>
      <div className="custom-category-instructions-popup-container flex">
        <PopupTitle>
          {t("SelectCatagory.custom-category-instructions-title")}
        </PopupTitle>
        <p className="custom-category-description">
          {t("SelectCatagory.custom-category-instructions")}
        </p>
        <CustomCategoryExampleIcon />
        <PopupButton onClick={close}>{t("Common:continue")}</PopupButton>
      </div>
    </Popup>
  );
};
