import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../../components/input/input";
import { PopupButton } from "../../../../components/popup-button/popup-button";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import { setBoosterQuestionsPopup } from "../../../../store/app-settings/app-settings-slice";
import { WentWellPopup } from "./went-well/went-well-popup";
import "./good-to-have-you-back-popup.scss";
import { useTranslation } from "react-i18next";

export const GoodToHaveYouBackPopup = ({ close }) => {
  const { t, i18n } = useTranslation("PopUps");

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const [popupHeight, setPopupHeight] = useState("510px");
  const [openWentWellPopup, setOpenWentWellPopup] = useState(false);

  const openBoosterQuestionsPopup = () => {
    dispatch(setBoosterQuestionsPopup({ open: true, editMode: true }));
    close();
  };

  const displayWentWellPopup = () => {
    setPopupHeight("380px");
    setOpenWentWellPopup(true);
  };

  return (
    <Popup height={popupHeight} close={close}>
      {!openWentWellPopup && (
        <div className="good-to-have-you-back-container">
          <div className="header-container flex">
            <PopupTitle small={true}>
              {t("GoodToHaveYouBack.jumpstart")}
            </PopupTitle>
            <PopupTitle>{t("GoodToHaveYouBack.on-your-journey")}</PopupTitle>
          </div>
          <div className="content">
            <h5 className="header">
              {t("GoodToHaveYouBack.last-time-good-idea")}
            </h5>
            <Input
              type="text"
              // onChange={handleChangeInput}
              value={
                profile?.Boosters.Answers[profile.Boosters.Answers.length - 1]
                  .Need
              }
              disabled={true}
            />

            <h4 className="header center">
              {t("GoodToHaveYouBack.good-idea-status")}
            </h4>
            <div className="footer-buttons-container flex">
              <PopupButton onClick={displayWentWellPopup}>
                {t("Common:helpful")}
              </PopupButton>
              <br />
              <PopupButton onClick={openBoosterQuestionsPopup}>
                {t("Common:not-so-helpful")}
              </PopupButton>
            </div>
          </div>
        </div>
      )}
      {openWentWellPopup && <WentWellPopup close={close} />}
    </Popup>
  );
};
