import "./button.scss";

export const Button = ({ isLoading, className, children, small, ...props }) => {
  return (
    <button
      className={
        "button flex" +
        (isLoading && small
          ? " disabled small"
          : isLoading && !small
          ? " disabled"
          : !isLoading && small
          ? " small"
          : "") +
        (className ? " " + className : "")
      }
      {...props}
    >
      {children}
    </button>
  );
};
