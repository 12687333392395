import React from "react";
import "./quote.scss";

const Quote = ({ quote, source }) => {
  return (
    <div className="quote-container">
      <div className="quote">
        <span className="fa fa-quote-left" aria-hidden="true"></span>
        <q>"{quote}"</q>
        <span className="fa fa-quote-right" aria-hidden="true"></span>
      </div>
      <div className="source">&mdash; {source}</div>
    </div>
  );
};

export default Quote;
