import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import "./report-card.scss";

export const ReportCard = ({ smallTitle, type, title }) => {
  const token = useSelector((state) => state.authReducer.accessToken);
  return (
    <a
      className="report-card-container flex"
      href={`${process.env.REACT_APP_BASE_URL}/api/admin/reports/${type}?token=${token}`}
    >
      <h2 className={"title" + (smallTitle ? " small-title" : "")}>{title}</h2>
      <FontAwesomeIcon icon={faDownload} size="2x" />
    </a>
  );
};
