import debounce from "lodash/debounce";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { Button } from "../../../../components/button/button";
import { ImageBlank } from "../../../../components/image-blank/image-blank";
import { Input } from "../../../../components/input/input";
import { Loader } from "../../../../components/loader/loader";
import { Select } from "../../../../components/select/select";
import { SmallTitle } from "../../../../components/small-title/small-title";
import {
  closeNewsletterPopup,
  openNewsletterPopup,
  setTitle,
} from "../../../../store/app-settings/app-settings-slice";
import { changeMap, getMapById } from "../../../../store/map/map-slice";
import {
  changeProfile,
  resetDraftMap,
  updateDraftMap,
} from "../../../../store/profile/profile-slice";
import Api from "../../../../utils/api";
import { StepsButtons } from "./steps-buttons/steps-buttons";
import { NewsletterPopup } from "../create-map/popups/newsletter/newsletter-popup";
import { FinishedCreatingMapPopup } from "../create-map/popups/finshed-creating-map/finished-creating-map-popup";
import { FinishedSettingGoalPopup } from "../create-map/popups/finished-setting-goal/finished-setting-goal-popup";
import { FinishedSettingThreeStepsPopup } from "../create-map/popups/finished-setting-three-steps/finished-setting-three-steps-popup";
import "./goal.scss";
import { useTranslation } from "react-i18next";
import { NativeEvents } from "../../../../utils/enums";
import { ImageUpload } from "../../../../components/image-upload/image-upload";

export const Goal = ({ editMode }) => {
  const { t, i18n } = useTranslation("Maps");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const profile = useSelector((state) => state.profileReducer.profile);
  const map = useSelector((state) => state.mapReducer.map);
  const { newsletterPopupOpen } = useSelector((state) => ({
    newsletterPopupOpen: state.appSettingsReducer.newsletterPopupOpen,
  }));
  const params = useParams();
  const stepsButtonsRef = useRef();
  const goalRefs = useRef([]);
  const [showError, setShowError] = useState(false);
  const [baseMap, setBaseMap] = useState(null);
  const [finishedSettingGoalPopupOpen, setFinishedSettingGoalPopupOpen] =
    useState(false);
  const [
    finishedSettingThreeStepsPopupOpen,
    setFinishedSettingThreeStepsPopupOpen,
  ] = useState(false);
  const [finishedCreatingMapPopupOpen, setFinishedCreatingMapPopupOpen] =
    useState(false);
  const [newMapId, setNewMapId] = useState("");
  const [goalImageSrc, setGoalImageSrc] = useState("");
  const [goalImagePreviewSrc, setGoalImagePreviewSrc] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [
    cameraAndMediaLibraryPermissionGranted,
    setCameraAndMediaLibraryPermissionGranted,
  ] = useState(false);
  const [missingPathwayMessage, setMissingPathwayMessage] = useState({
    show: false,
    index: 0,
  });
  const [info, setInfo] = useState({
    CategoryId: "",
    CategoryName: "",
    MainGoal: "",
    StrengthWord: "",
    Image: "",
    Steps: [
      {
        Name: "",
        Obstacle: "",
        Overcome: "",
        Phone: "",
        Email: "",
        StartDate: null,
        StartReminder: null,
      },
      {
        Name: "",
        Obstacle: "",
        Overcome: "",
        Phone: "",
        Email: "",
        StartDate: null,
        StartReminder: null,
      },
      {
        Name: "",
        Obstacle: "",
        Overcome: "",
        Phone: "",
        Email: "",
        StartDate: null,
        StartReminder: null,
      },
    ],
  });
  const [editedSteps, setEditedSteps] = useState([]);

  useEffect(() => {
    document.addEventListener(
      "message",
      checkCameraAndMediaLibraryPermissionResponse
    );

    if (editMode) {
      const mapId = params.mapId;
      if (!mapId) navigate("goal-by-categories");
      if (!map || map?._id !== mapId) dispatch(getMapById(mapId));
    }

    return () => {
      document.removeEventListener(
        "message",
        checkCameraAndMediaLibraryPermissionResponse
      );
    };
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!editMode && profile?.DraftMap?.Map) {
      initMapInfo(profile.DraftMap.Map);
    }
  }, [profile]);

  useEffect(() => {
    if (editMode && map && profile) {
      initMapInfo(map);
    }
  }, [map, profile]);

  const checkCameraAndMediaLibraryPermissionResponse = (message) => {
    if (message.data.cameraAndMediaLibraryPermissionGranted) {
      setCameraAndMediaLibraryPermissionGranted(true);
      uploadRef.current.open();
    }
  };

  const initMapInfo = (mapToEdit) => {
    dispatch(setTitle(t("Goal.title")));
    stepsButtonsRef.current.initStepsButtons(mapToEdit.Steps);
    setBaseMap(mapToEdit);
    for (var key of Object.keys(info)) {
      if (mapToEdit[key]) {
        changeInfo(key, mapToEdit[key]);
      }
    }
    if (editMode && map) {
      setCategoryOptions(
        [{ label: "select or type...", value: "*", isDisabled: true }].concat(
          map.Category.GoalsOptions.map((option) => ({
            value: option,
            label: option,
          }))
        )
      );
    } else {
      getCategoryOptionsForDraftMap(profile.DraftMap.Map.CategoryId);
    }
    if (mapToEdit.Image.Path) {
      setGoalImagePreviewSrc(
        `${process.env.REACT_APP_BASE_IMAGE_URL}/${mapToEdit.Image.Path}`
      );
      setGoalImageSrc(`${mapToEdit.Image.Path}`);
    }
  };

  const getCategoryOptionsForDraftMap = async (categoryId) => {
    try {
      const res = await Api.get(`user/categories/${categoryId}`);
      if (res.data.payload) {
        setCategoryOptions(
          [{ label: "select or type...", value: "*", isDisabled: true }].concat(
            res.data.payload.GoalsOptions.map((option) => ({
              value: option,
              label: option,
            }))
          )
        );
      }
    } catch (error) {}
  };

  const changeInfo = (name, value) => {
    setInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    changeInfo(e.target.name, e.target.value);
    if (!editMode) {
      debouncedUpdateDaftMap({
        ...info,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeSelect = (name, selectedOption) => {
    changeInfo(name, selectedOption.value);
    if (!editMode) {
      debouncedUpdateDaftMap({
        ...info,
        [name]: selectedOption.value,
      });
    }
    if (
      selectedOption.value.replace(/^\s+/g, "") &&
      selectedOption.value !== baseMap.MainGoal
    ) {
      setFinishedSettingGoalPopupOpen(true);
    }
  };

  const resetValue = (name) => {
    changeInfo([name], "");
    if (!editMode) {
      debouncedUpdateDaftMap({
        ...info,
        [name]: "",
      });
    }
  };

  const changeDraftMap = async (data) => {
    dispatch(updateDraftMap(data));
  };

  const [debouncedUpdateDaftMap] = useState(() =>
    debounce(changeDraftMap, 1000)
  );

  const focusEmptyInput = (name) => {
    const index = Object.keys(info).indexOf(name);
    goalRefs.current[index].select();
    goalRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const focusEmptySelect = (name) => {
    const index = Object.keys(info).indexOf(name);
    goalRefs.current[index].focus();
  };

  const showMissingPathwayMessage = (index) => {
    setMissingPathwayMessage({ show: true, index });
  };

  const changeMapImage = (imagesrc, fromGallery) => {
    if (!imagesrc) {
      setGoalImagePreviewSrc("");
      setGoalImageSrc("");
    }

    dispatch(
      changeMap({
        ...map,
        Image: {
          Path: imagesrc,
          fromGallery: fromGallery,
        },
      })
    );
  };

  const validate = () => {
    let isValidate = true;
    if (!info.StrengthWord.replace(/^\s+/g, "")) {
      focusEmptyInput("StrengthWord");
      isValidate = false;
    }
    if (!info.MainGoal.replace(/^\s+/g, "")) {
      focusEmptySelect("MainGoal");
      isValidate = false;
    }
    for (let i = info.Steps.length - 1; i >= 0; i--) {
      if (info.Steps.filter((step) => step.Name !== "").length !== 3) {
        // not all steps defined
        if (!info.Steps[i].Name.replace(/^\s+/g, "")) {
          isValidate = false;
          showMissingPathwayMessage(i);
        }
      } else {
        // not all steps edited
        if (
          !info.Steps[i].Name.replace(/^\s+/g, "") ||
          !info.Steps[i].Obstacle.replace(/^\s+/g, "") ||
          !info.Steps[i].Overcome.replace(/^\s+/g, "")
        ) {
          isValidate = false;
          showMissingPathwayMessage(i);
        }
      }
    }
    if (!isValidate) setShowError(true);
    return isValidate;
  };

  const saveMap = async () => {
    if (!validate()) return;
    setIsLoading(true);
    try {
      let res = null;
      if (editMode) {
        // update map
        res = await Api.put(`/user/maps/${params.mapId}`, info);
        if (res.data.payload) {
          navigate(`/maps/view/${params.mapId}`);
        } else {
          console.log("error");
        }
      } else {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        var year = currentDate.getUTCFullYear();
        var month = String(currentDate.getUTCMonth() + 1).padStart(2, "0");
        var day = String(currentDate.getUTCDate()).padStart(2, "0");
        var hours = String(currentDate.getUTCHours()).padStart(2, "0");
        var minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
        var seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");
        var milliseconds = String(currentDate.getUTCMilliseconds()).padStart(
          3,
          "0"
        );
        var dateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
        // create map
        const data = {
          ...info,
          Image: profile.DraftMap.Map.Image,
          BoosterQuestionDate: dateString,
        };
        res = await Api.post("/user/maps", data);
        if (res.data.payload) {
          setNewMapId(res.data.payload);
          dispatch(resetDraftMap());
          if (!profile.FirstMapCreatedDate) {
            dispatch(
              changeProfile({ ...profile, FirstMapCreatedDate: new Date() })
            );
            dispatch(openNewsletterPopup());
          }
          setFinishedCreatingMapPopupOpen(true);
        } else {
          console.log("error");
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const navigateToViewMap = () => {
    setFinishedCreatingMapPopupOpen(false);
    navigate(`/maps/view/${newMapId}`);
  };

  const navigateBack = () => {
    if (editMode) {
      navigate("/maps");
    } else {
      const newSelectedCategory = {
        Id: baseMap.CategoryId,
        Name: baseMap.CategoryName,
      };
      navigate("../../category", {
        state: {
          selectedCategory: newSelectedCategory,
        },
      });
    }
  };

  return (
    <div className="goal-container">
      {newsletterPopupOpen && (
        <NewsletterPopup close={() => dispatch(closeNewsletterPopup())} />
      )}
      {finishedCreatingMapPopupOpen && !newsletterPopupOpen && (
        <FinishedCreatingMapPopup close={navigateToViewMap} />
      )}
      {finishedSettingGoalPopupOpen && (
        <FinishedSettingGoalPopup
          close={() => setFinishedSettingGoalPopupOpen(false)}
        />
      )}
      {finishedSettingThreeStepsPopupOpen && (
        <FinishedSettingThreeStepsPopup
          steps={info.Steps}
          close={() => setFinishedSettingThreeStepsPopupOpen(false)}
        />
      )}

      {profile ? (
        <>
          <SmallTitle>{t("Goal.my-goal-is")}</SmallTitle>
          <Select
            name="MainGoal"
            value={info.MainGoal}
            onChange={(e) => handleChangeSelect("MainGoal", e)}
            options={categoryOptions}
            isError={showError && !info.MainGoal.replace(/^\s+/g, "")}
            ref={(el) =>
              (goalRefs.current[Object.keys(info).indexOf("MainGoal")] = el)
            }
            placeholder={t("Goal.my-goal-is") + "..."}
            maxLength={50}
            editable
          />

          <div className="goal-text">{t("Goal.goal-text")}</div>
          <SmallTitle>{t("Goal.strengthening-word")}</SmallTitle>
          <Input
            type="text"
            name="StrengthWord"
            value={info.StrengthWord}
            onChange={handleChange}
            reset={resetValue}
            placeholder={t("Goal.strengthening-word")}
            isError={showError}
            ref={(el) =>
              (goalRefs.current[Object.keys(info).indexOf("StrengthWord")] = el)
            }
          />
          <div className="goal-text">{t("Goal.goal-text2")}</div>
          <SmallTitle>{t("Goal.choose-an-image")} </SmallTitle>
          <ImageUpload
            mapId={params.mapId}
            defaultImageSrc={goalImagePreviewSrc}
            changeImage={changeMapImage}
            saveImageUrl={`/user/maps/${params.mapId}/image`}
          />

          <br />
          <br />
          {!editMode &&
            info.Steps.filter((step) => step.Name !== "").length !== 3 && (
              <>
                <SmallTitle>
                  {t("Goal.pathways-info")}{" "}
                  <span style={{ textDecoration: "underline" }}>
                    {info.Steps.filter((step) => step.Name !== "").length === 0
                      ? "first"
                      : info.Steps.filter((step) => step.Name !== "").length ===
                        1
                      ? "second"
                      : info.Steps.filter((step) => step.Name !== "").length ===
                        2
                      ? "third"
                      : ""}
                  </span>{" "}
                  {t("Goal.pathways-info2")}
                </SmallTitle>
                {missingPathwayMessage.show &&
                  !info.Steps[missingPathwayMessage.index].Name.replace(
                    /^\s+/g,
                    ""
                  ) && (
                    <p className="missing-pathway-message error">
                      {t("Goal.set-pathway")} #{missingPathwayMessage.index + 1}
                    </p>
                  )}
                <hr />
              </>
            )}
          {info.Steps.filter((step) => step.Name !== "").length === 3 && (
            <>
              {missingPathwayMessage.show && (
                <p className="missing-pathway-message error">
                  {t("Goal.edit-pathway")} #{missingPathwayMessage.index + 1}
                </p>
              )}
              <SmallTitle>{t("Goal.edit-pathway-title")}</SmallTitle>
              <hr />
            </>
          )}
          <StepsButtons
            info={info}
            setInfo={setInfo}
            editMode={editMode}
            debouncedUpdateDaftMap={debouncedUpdateDaftMap}
            setFinishedSettingThreeStepsPopupOpen={
              setFinishedSettingThreeStepsPopupOpen
            }
            ref={stepsButtonsRef}
            editedSteps={editedSteps}
            setEditedSteps={setEditedSteps}
          />
          <div className="goal-buttons flex">
            <Button className="goal-button" onClick={navigateBack}>
              {t("Common:cancel")}
            </Button>
            <Button
              className="goal-button"
              onClick={saveMap}
              isLoading={isLoading}
            >
              {t("Common:save")}
            </Button>
          </div>
        </>
      ) : (
        <Loader className="page" />
      )}
    </div>
  );
};
