import React from "react";
import { useState, useEffect } from "react";
import { CreateContentPopup } from "./contents-action-popups/create-content-popup/create-content-popup";
import { Button } from "../../../components/button/button";
import { Checkbox } from "../../../components/checkbox/checkbox";
import { Input } from "../../../components/input/input";
import { PopupTitle } from "../../../components/popup/popup-title/popup-title";
import { useTranslation } from "react-i18next";
import DraggableList from "../../../components/DraggableList/draggable-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faWindowRestore } from "@fortawesome/free-regular-svg-icons";
import searchIcon from "../../../assets/images/search-icon.png";
import { ReactComponent as XIcon } from "../../../assets/icons/x.svg";
import "./media-center.scss";

// redux
import { useDispatch } from "react-redux";
import {
  getCountries,
  getCategories,
  getContents,
  orderContents,
  getDistributionInfo,
} from "../../../store/media-center/media-center-slice";
import { setTitle } from "../../../store/app-settings/app-settings-slice";
import { CreateCountryPopup } from "./countries-action-popups/create-country-popup/create-country-popup";
import { NewContentAlertPopup } from "./countries-action-popups/new-content-alert-popup/new-content-alert-popup";
import { DeleteCountryPopup } from "./countries-action-popups/delete-country-popup/delete-country-popup";
import { RestoreCountryPopup } from "./countries-action-popups/restore-country-popup/restore-country-popup";
import { arrayMove } from "react-sortable-hoc";
import { languagesList } from "../../../components/languages-list/languages-list";
import { Select } from "../../../components/select/select";
import { forEach, includes } from "lodash";
import { SearchModal } from "./search-modal/search-modal";

export default function MediaCenter() {
  const { t, i18n } = useTranslation("MediaCenter");
  const dispatch = useDispatch();

  const [showCreateContentPopup, setShowCreateContentPopup] = useState(false);
  const [showCreateCountryPopup, setShowCreateCountryPopup] = useState(false);
  const [showNewContentAlertPopup, setShowNewContentAlertPopup] =
    useState(false);
  const [showDeleteCountryPopup, setShowDeleteCountryPopup] = useState(false);
  const [showRestoreCountryPopup, setShowRestoreCountryPopup] = useState(false);

  const [countryToBeChangedId, setCountryToBeChangedId] = useState("");

  const [contents, setContents] = useState([]);
  const [filteredContents, setFilteredContents] = useState([]);

  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  const [currentCountryId, setCurrentCountryId] = useState("");
  const [currentCountryCode, setCurrentCountryCode] = useState("");

  const [onlyActiveChecked, setOnlyActiveChecked] = useState(true);

  const [strFilter, setStrFilter] = useState("");
  const [filterByTitle, setFilterByTitle] = useState(false);
  const [filterByDescription, setFilterByDescription] = useState(false);
  const [filterByTags, setFilterByTags] = useState(false);
  const [filterByCategories, setFilterByCategories] = useState(false);

  const [filterByArticle, setFilterByArticle] = useState(false);
  const [filterByVideo, setFilterByVideo] = useState(false);
  const [filterByPodcast, setFilterByPodcast] = useState(false);
  const [filterBySong, setFilterBySong] = useState(false);
  const [filterByTip, setFilterByTip] = useState(false);
  const [filterByOther, setFilterByOther] = useState(false);

  const [langFilter, setLangFilter] = useState({ value: "", label: "" });

  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showAdvancedfilters, setShowAdvancedFilters] = useState(false);

  const [cronJobTime, setCronJobTime] = useState("");

  const [canDrag, setCanDrag] = useState(true);

  useEffect(() => {
    if (
      !filterByArticle &&
      !filterByVideo &&
      !filterByPodcast &&
      !filterBySong &&
      !filterByTip &&
      !filterByOther &&
      langFilter.label === ""
    ) {
      setCanDrag(true);
    }
  }, [
    filterByArticle,
    filterByVideo,
    filterByPodcast,
    filterBySong,
    filterByTip,
    filterByOther,
    langFilter,
  ]);

  useEffect(() => {
    dispatch(setTitle(t("Main.title")));
  }, [i18n]);

  useEffect(() => {
    dispatch(getContents()).then((res) => {
      setTags([
        {
          value: "Professional",
          label: t("OnBoarding:Community.Options.Professional"),
        },
        { value: "Social", label: t("OnBoarding:Community.Options.Social") },
        {
          value: "Literature",
          label: t("OnBoarding:Community.Options.Literature"),
        },
        { value: "Arts", label: t("OnBoarding:Community.Options.Arts") },
        {
          value: "Academic",
          label: t("OnBoarding:Community.Options.Academic"),
        },
        { value: "Games", label: t("OnBoarding:Community.Options.Games") },
        { value: "Travel", label: t("OnBoarding:Community.Options.Travel") },
        {
          value: "Volunteering",
          label: t("OnBoarding:Community.Options.Volunteering"),
        },
        {
          value: "Religious",
          label: t("OnBoarding:Community.Options.Religious"),
        },
        { value: "Sewing", label: t("OnBoarding:Community.Options.Sewing") },
        {
          value: "Gardening",
          label: t("OnBoarding:Community.Options.Gardening"),
        },
        {
          value: "Cooking/baking",
          label: t("OnBoarding:Community.Options.Cooking/baking"),
        },
        { value: "Writing", label: t("OnBoarding:Community.Options.Writing") },
        {
          value: "Creative arts",
          label: t("OnBoarding:Community.Options.Creative arts"),
        },
        { value: "Animals", label: t("OnBoarding:Community.Options.Animals") },
        { value: "Sports", label: t("OnBoarding:Community.Options.Sports") },
        {
          value: "Meditation/yoga",
          label: t("OnBoarding:Community.Options.Meditation/yoga"),
        },
        { value: "Nature", label: t("OnBoarding:Community.Options.Nature") },
        {
          value: "Theater/movies",
          label: t("OnBoarding:Community.Options.Theater/movies"),
        },
        {
          value: "Crafting",
          label: t("OnBoarding:Community.Options.Crafting"),
        },
        { value: "Music", label: t("OnBoarding:Community.Options.Music") },
        {
          value: "Water sports",
          label: t("OnBoarding:Community.Options.Water sports"),
        },
        { value: "Science", label: t("OnBoarding:Community.Options.Science") },
        { value: "Camping", label: t("OnBoarding:Community.Options.Camping") },
        { value: "Dancing", label: t("OnBoarding:Community.Options.Dancing") },
      ]);

      const sortedContents = sortContents(res.payload);
      setContents(sortedContents);
      setFilteredContents(
        sortedContents.filter((content) => !onlyActiveChecked || content.Active)
      );
    });
    dispatch(getCountries()).then((res) => {
      setCountries(res.payload);
    });
    dispatch(getCategories()).then((res) => {
      setCategories(res.payload);
    });
  }, []);

  const sortContents = (tempContents) => {
    if (tempContents && tempContents.length > 0) {
      let resArr = [];
      resArr.push(tempContents[0]);
      for (let i = 1; i < tempContents.length; i++) {
        var onStart = true;
        for (let j = 0; j < resArr.length; j++) {
          if (tempContents[i].DisplayOrder > resArr[j].DisplayOrder) {
            resArr.splice(j + 1, 0, tempContents[i]);
            onStart = false;
            break;
          }
        }
        if (onStart) {
          resArr.splice(0, 0, tempContents[i]);
        }
      }
      return resArr;
    } else {
      return [];
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setContents((prevState) => arrayMove(prevState, oldIndex, newIndex));

    const tempContents = arrayMove(filteredContents, oldIndex, newIndex);
    setFilteredContents(tempContents);
    const data = {
      ContentsOrd: tempContents.map((item, index) => {
        return {
          Id: item._id,
          Order: index + 1,
        };
      }),
    };

    dispatch(orderContents(data));
  };

  const filterByType = (
    filterByArticleChecked = false,
    filterByVideoChecked = false,
    filterByPodcastChecked = false,
    filterBySongChecked = false,
    filterByTipChecked = false,
    filterByOtherChecked = false,
    AlreadyFilteredContents = contents
  ) => {
    return AlreadyFilteredContents.filter(
      (content) =>
        (!filterByArticleChecked &&
          !filterByVideoChecked &&
          !filterByPodcastChecked &&
          !filterBySongChecked &&
          !filterByTipChecked &&
          !filterByOtherChecked) ||
        (filterByArticleChecked && content.Type === "Article") ||
        (filterByVideoChecked && content.Type === "Video") ||
        (filterByPodcastChecked && content.Type === "Podcast") ||
        (filterBySongChecked && content.Type === "Song") ||
        (filterByTipChecked && content.Type === "Tip") ||
        (filterByOtherChecked && content.Type === "Other")
    );
  };

  const filterByActive = (
    onlyActiveChecked = true,
    AlreadyFilteredContents = contents
  ) => {
    return AlreadyFilteredContents.filter(
      (content) => content.Active || !onlyActiveChecked
    );
  };

  const filterByText = (
    str,
    filterByTitleCheked = false,
    filterByDescriptionChecked = false,
    filterByTagsCheked = false,
    filterByCategoriesChecked = false,
    AlreadyFilteredContents = contents
  ) => {
    setStrFilter(str);
    if (str === "") {
      return AlreadyFilteredContents;
    } else {
      if (showAdvancedfilters) {
        return AlreadyFilteredContents.filter(
          (content) =>
            (!filterByTitleCheked || content.Content.Title.includes(str)) &&
            (!filterByDescriptionChecked ||
              content.Content.Description.includes(str)) &&
            (!filterByTagsCheked ||
              content.Content.Tags.Tags.some((tag) => tag.includes(str)) ||
              content.Content.Tags.All) &&
            (!filterByCategoriesChecked ||
              content.Content.Categories.All ||
              content.Content.Categories.Categories.some((category) =>
                category.Name.includes(str)
              ))
        );
      } else {
        return AlreadyFilteredContents.filter(
          (content) =>
            content.Content.Title?.includes(str) ||
            content.Content.Description.includes(str) ||
            content.Content.Tags.All ||
            content.Content.Categories.All ||
            content.Content.Tags.Tags.some((tag) => tag.includes(str)) ||
            content.Content.Categories.Categories.some((category) =>
              category.Name?.includes(str)
            )
        );
      }
    }
  };

  const filterByLang = (
    langFilterTemp = { value: "", label: "" },
    AlreadyFilteredContents = contents
  ) => {
    return AlreadyFilteredContents.filter(
      (content) =>
        content.Languages.Languages.includes(langFilterTemp.value) ||
        langFilterTemp.value == ""
    );
  };

  const filterBase = (
    str = "",
    filterByTitleCheked = false,
    filterByDescriptionChecked = false,
    filterByTagsCheked = false,
    filterByCategoriesChecked = false,
    onlyActiveChecked = false,
    filterByArticleChecked = false,
    filterByVideoChecked = false,
    filterByPodcasedChecked = false,
    filterBySongChecked = false,
    filterByTipChecked = false,
    filterByOtherChecked = false,
    langFilterTemp = { value: "", label: "" }
  ) => {
    let filteredContentsTemp = contents;
    filteredContentsTemp = filterByText(
      str,
      filterByTitleCheked,
      filterByDescriptionChecked,
      filterByTagsCheked,
      filterByCategoriesChecked,
      filteredContentsTemp
    );

    filteredContentsTemp = filterByActive(
      onlyActiveChecked,
      filteredContentsTemp
    );

    filteredContentsTemp = filterByType(
      filterByArticleChecked,
      filterByVideoChecked,
      filterByPodcasedChecked,
      filterBySongChecked,
      filterByTipChecked,
      filterByOtherChecked,
      filteredContentsTemp
    );

    filteredContentsTemp = filterByLang(langFilterTemp, filteredContentsTemp);

    setCanDrag(
      currentCountryId === "" &&
        strFilter === "" &&
        !filterByArticle &&
        !filterByVideo &&
        !filterByPodcast &&
        !filterBySong &&
        !filterByTip &&
        !filterByOther &&
        langFilter.value === ""
    );

    return filteredContentsTemp;
  };

  return (
    <div className="media-center-container">
      {showSearchModal && (
        <SearchModal
          close={() => {
            setShowSearchModal(false);
          }}
          filterBase={filterBase}
          onlyActiveChecked={onlyActiveChecked}
          setFilteredContents={setFilteredContents}
          showAdvancedfilters={showAdvancedfilters}
          setShowAdvancedFilters={setShowAdvancedFilters}
          strFilter={strFilter}
          setStrFilter={setStrFilter}
          filterByTags={filterByTags}
          setFilterByTags={setFilterByTags}
          filterByCategories={filterByCategories}
          setFilterByCategories={setFilterByCategories}
          filterByTitle={filterByTitle}
          setFilterByTitle={setFilterByTitle}
          filterByDescription={filterByDescription}
          setFilterByDescription={setFilterByDescription}
          filterByArticle={filterByArticle}
          setFilterByArticle={setFilterByArticle}
          filterByVideo={filterByVideo}
          setFilterByVideo={setFilterByVideo}
          filterByPodcast={filterByPodcast}
          setFilterByPodcast={setFilterByPodcast}
          filterBySong={filterBySong}
          setFilterBySong={setFilterBySong}
          filterByTip={filterByTip}
          setFilterByTip={setFilterByTip}
          filterByOther={filterByOther}
          setFilterByOther={setFilterByOther}
          langFilter={langFilter}
          setLangFilter={setLangFilter}
        />
      )}
      <div className="filters-container">
        <div className="active-filter">
          <Checkbox
            id={`active-filter`}
            label={t("Main.show-only-active")}
            checked={onlyActiveChecked}
            onChange={(e) => {
              setOnlyActiveChecked(e.target.checked);
              let filteredContentsTemp = filterBase(
                strFilter,
                filterByTitle,
                filterByDescription,
                filterByTags,
                filterByCategories,
                e.target.checked,
                filterByArticle,
                filterByVideo,
                filterByPodcast,
                filterBySong,
                filterByTip,
                filterByOther,
                langFilter
              );
              setFilteredContents(filteredContentsTemp);
            }}
          />
        </div>
      </div>
      <br />
      <div className="search-openner" onClick={() => setShowSearchModal(true)}>
        <img src={searchIcon} />
      </div>
      <div className="quick-remove-search-container">
        {filterByArticle && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterByArticle(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  onlyActiveChecked,
                  false,
                  filterByVideo,
                  filterByPodcast,
                  filterBySong,
                  filterByTip,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            {t("Main.articles")}
          </div>
        )}
        {filterByVideo && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterByVideo(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  onlyActiveChecked,
                  filterByArticle,
                  false,
                  filterByPodcast,
                  filterBySong,
                  filterByTip,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            {t("Main.vidoes")}
          </div>
        )}
        {filterByPodcast && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterByPodcast(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  onlyActiveChecked,
                  filterByArticle,
                  filterByVideo,
                  false,
                  filterBySong,
                  filterByTip,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            {t("Main.podcasts")}
          </div>
        )}
        {filterBySong && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterBySong(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  onlyActiveChecked,
                  filterByArticle,
                  filterByVideo,
                  filterByPodcast,
                  false,
                  filterByTip,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            {t("Main.songs")}
          </div>
        )}
        {filterByTip && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterByTip(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  onlyActiveChecked,
                  filterByArticle,
                  filterByVideo,
                  filterByPodcast,
                  filterBySong,
                  false,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            {t("Main.tips")}
          </div>
        )}
        {filterByOther && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterByOther(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  onlyActiveChecked,
                  filterByArticle,
                  filterByVideo,
                  filterByPodcast,
                  filterBySong,
                  filterByTip,
                  false,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            {t("Main.others")}
          </div>
        )}
        {langFilter.value && (
          <div className="quick-remove-search-item">
            {langFilter.label}{" "}
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setLangFilter({ value: "", label: "" });
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  onlyActiveChecked,
                  filterByArticle,
                  filterByVideo,
                  filterByPodcast,
                  filterBySong,
                  filterByTip,
                  filterByOther,
                  { value: "", label: "" }
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
          </div>
        )}
        {strFilter && (
          <div className="quick-remove-search-str">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setStrFilter("");
                let filteredContentsTemp = filterBase(
                  "",
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  onlyActiveChecked,
                  filterByArticle,
                  filterByVideo,
                  filterByPodcast,
                  filterBySong,
                  filterByTip,
                  filterByOther,
                  { value: "", label: "" }
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            <h4>
              {strFilter.length < 15
                ? strFilter
                : strFilter.substring(0, 12) + "..."}
            </h4>
          </div>
        )}
      </div>
      <br />
      <div className="creates-container">
        {showCreateContentPopup && (
          <CreateContentPopup
            showPopup={showCreateContentPopup}
            closePopup={(contents, doSet = false) => {
              setShowCreateContentPopup(false);
              if (doSet) {
                setContents(sortContents(contents));
                setFilteredContents(
                  sortContents(
                    contents.filter(
                      (content) => !onlyActiveChecked || content.Active
                    )
                  )
                );
              }
            }}
            countries={countries}
            categories={categories}
            tags={tags}
          />
        )}
        {showCreateCountryPopup && (
          <CreateCountryPopup
            showPopup={showCreateCountryPopup}
            closePopup={(countries, doSet = false) => {
              setShowCreateCountryPopup(false);
              if (doSet) {
                setCountries(countries);
                setContents(
                  sortContents(
                    contents.filter(
                      (content) => !onlyActiveChecked || content.Active
                    )
                  )
                );
              }
            }}
            countries={countries.map((country) => country.Name)}
          />
        )}
        {showNewContentAlertPopup && (
          <NewContentAlertPopup
            showPopup={showNewContentAlertPopup}
            cronJobTime={cronJobTime}
            closePopup={() => {
              setShowNewContentAlertPopup(false);
            }}
            countryId={currentCountryId}
          />
        )}

        {showDeleteCountryPopup && (
          <DeleteCountryPopup
            showPopup={showDeleteCountryPopup}
            closePopup={(countriesRes, doSet = false) => {
              if (doSet) {
                setCountries(countriesRes);
              }
              setShowDeleteCountryPopup(false);
            }}
            countryId={countryToBeChangedId}
          />
        )}

        {showRestoreCountryPopup && (
          <RestoreCountryPopup
            showPopup={showRestoreCountryPopup}
            closePopup={(countriesRes, doSet = false) => {
              if (doSet) {
                setCountries(countriesRes);
              }
              setShowRestoreCountryPopup(false);
            }}
            countryId={countryToBeChangedId}
          />
        )}

        <Button
          className="create-content-button edit"
          onClick={() => setShowCreateContentPopup(true)}
        >
          {t("Main.create-content")}
        </Button>
        <Button
          className="create-country-button edit"
          onClick={() => setShowCreateCountryPopup(true)}
        >
          {t("Main.create-country")}
        </Button>
        {currentCountryId && currentCountryId !== "" && (
          <Button
            className="set-new-content-alert edit"
            onClick={() => {
              dispatch(getDistributionInfo(currentCountryCode)).then((res) => {
                setCronJobTime(res.payload);
                setShowNewContentAlertPopup(true);
              });
            }}
          >
            {t("Main.new-content-alert")}
          </Button>
        )}
      </div>
      <div className="countries-buttons-container">
        <Button
          className={
            currentCountryId === ""
              ? "country-button-selected edit"
              : "country-button edit"
          }
          onClick={() => {
            setStrFilter("");
            setFilteredContents(
              contents.filter((content) => !onlyActiveChecked || content.Active)
            );
            setCurrentCountryId("");
            setCurrentCountryCode("");
          }}
        >
          {t("Main.all-countries")}
        </Button>
        {countries.map((country, index) => {
          return (
            <Button
              key={index}
              className={
                currentCountryId === country._id
                  ? "country-button-selected edit"
                  : "country-button edit"
              }
              onClick={() => {
                if (country.Active) {
                  setStrFilter("");
                  setCurrentCountryId(country._id);
                  setCurrentCountryCode(country.Code);
                  setFilteredContents(
                    contents.filter(
                      (content) =>
                        content.Countries.Countries.includes(country._id) &&
                        (!onlyActiveChecked || content.Active)
                    )
                  );
                }
              }}
            >
              {country.Name}
              {country.Active ? (
                <FontAwesomeIcon
                  className="country-delete-restore"
                  icon={faTrashCan}
                  onClick={() => {
                    setShowDeleteCountryPopup(true);
                    setCountryToBeChangedId(country._id);
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  className="country-delete-restore"
                  icon={faWindowRestore}
                  onClick={() => {
                    setShowRestoreCountryPopup(true);
                    setCountryToBeChangedId(country._id);
                  }}
                />
              )}
            </Button>
          );
        })}
      </div>
      <div className="contents-container">
        {filteredContents && filteredContents.length > 0 && (
          <div>
            <DraggableList
              items={filteredContents}
              onSortEnd={onSortEnd}
              countries={countries}
              categories={categories}
              tags={tags}
              t={t}
              closePopup={(contents) => {
                setContents(contents);
                setFilteredContents(
                  sortContents(
                    contents.filter(
                      (content) => !onlyActiveChecked || content.Active
                    )
                  )
                );
                setCurrentCountryId("");
                setCurrentCountryCode("");
              }}
              currentCountryId={currentCountryId}
              canDrag={canDrag}
            />
          </div>
        )}
      </div>
    </div>
  );
}
