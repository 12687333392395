import React from "react";
import "./speech-bubble.scss";
import RightSpeechBubble from "../../assets/images/speech-bubble-right.png";
import LeftSpeechBubble from "../../assets/images/speech-bubble-left.png";
import AlertIcon from "../../assets/icons/alert.svg";

export const SpeechBubble = ({
  direction,
  top,
  left,
  width,
  height,
  children,
  style = {},
}) => {
  return (
    <div
      className="speech-bubble-container"
      style={{
        ...style,
        top,
        left,
        backgroundImage:
          direction === "right"
            ? `url(${AlertIcon}), url(${RightSpeechBubble})`
            : `url(${AlertIcon}), url(${LeftSpeechBubble})`,
        backgroundSize: width && height ? `13px 13px, ${width} ${height}` : "",
      }}
    >
      <div
        className="content flex"
        style={{ width, height: `calc(${height} - 16px)` }}
      >
        {children}
        <br />
      </div>
    </div>
  );
};
