import { ReactComponent as PersonIcon } from "../../../../../../assets/icons/person2.svg";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import "./finished-creating-map-popup.scss";
import { useTranslation } from "react-i18next";

export const FinishedCreatingMapPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  return (
    <Popup close={close}>
      <div className="finshed-creating-map-popup-container flex-popup-container">
        <PopupTitle>
          {t("CreateMap.FinishedCreatingMapPopUp.excellent")}
        </PopupTitle>
        <br />
        <div className="step-container">
          <p>{t("CreateMap.FinishedCreatingMapPopUp.info1")}</p>
          <p>{t("CreateMap.FinishedCreatingMapPopUp.info2")}</p>
          <PersonIcon />
        </div>
        <br />
        <div className="buttons-container flex">
          <PopupButton onClick={close}>
            {t("CreateMap.FinishedCreatingMapPopUp.show-map")}
          </PopupButton>
        </div>
      </div>
    </Popup>
  );
};
