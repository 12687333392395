import { forwardRef } from "react";
import { Input } from "../../../../../../components/input/input";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import "./create-need-step.scss";
import { useTranslation } from "react-i18next";

export const CreateNeedStep = forwardRef(
  ({ need, setBooster, showError, navigateToNextStep }, ref) => {
    const { t, i18n } = useTranslation("PopUps");

    return (
      <div className="create-need-step-container">
        <div className="background-image"></div>
        <div className="content">
          <p className="question">{t("CreateNeedStep.small-action")}</p>

          <Input
            type="text"
            name="need"
            value={need}
            width="265px"
            maxHeight={50}
            onChange={(e) =>
              setBooster((prevState) => {
                return {
                  ...prevState,
                  Need: e.target.value,
                };
              })
            }
            // placeholder="My small action..."
            isError={showError}
            ref={ref}
          />
          <div className="footer-buttons flex">
            <PopupButton
              className="create-need-button"
              onClick={navigateToNextStep}
            >
              {t("Common:OK")}
            </PopupButton>
          </div>
        </div>
      </div>
    );
  }
);
