import { forwardRef, useEffect, useRef, useState } from "react";
import { NativeEvents } from "../../utils/enums";
import { ContactUploadPopup } from "./contacts-uplaod-popup/contacts-uplaod-popup";
import { PopupTitle } from "../popup/popup-title/popup-title";
import "./contacts-upload.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faContactBook } from "@fortawesome/free-solid-svg-icons";
import { Input } from "../input/input";

export const ContactUpload = forwardRef(
  ({ onChange, phone, ...props }, ref) => {
    const { t } = useTranslation("Maps");

    const [data, setData] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [contactFilterText, setContactFilterText] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const contactRef = useRef(null);

    useEffect(() => {
      //change
      document.addEventListener("message", checkContactPermissionResponse);

      return () => {
        document.removeEventListener("message", checkContactPermissionResponse);
      };
    }, [document]);

    const checkContactPermissionResponse = async (message) => {
      if (message.data.contactsPermissionGranted) {
        setShowPopup(true);
        setData(message.data.contacts);
        setFilteredContacts(message.data.contacts);
        contactRef.current.click();
      }
    };

    const choose = async () => {
      if (window.ReactNativeWebView) {
        await window.ReactNativeWebView.postMessage(
          JSON.stringify({
            event: NativeEvents.RequestContactPermissions,
          })
        );
      } else {
        contactRef.current.click();
      }
    };

    const cancel = () => {
      setData([]);
      setShowPopup(false);
    };

    const changeFilteredContacts = (contactName) => {
      setContactFilterText(contactName);
      var tempContacts = [];
      if (contactName !== "") {
        for (let i = 0; i < data.length; i++) {
          if (
            data[i] &&
            ((data[i].firstName && data[i].firstName.includes(contactName)) ||
              (data[i].lastName && data[i].lastName.includes(contactName)))
          ) {
            tempContacts = [...tempContacts, data[i]];
          }
        }
      } else {
        tempContacts = data;
      }
      setFilteredContacts(tempContacts);
    };

    return (
      <div className="contact-upload-container flex">
        {showPopup && (
          <ContactUploadPopup close={cancel}>
            <PopupTitle>{t("ContactUpload.choose")}</PopupTitle>
            <br />
            <br />
            <br />
            <Input
              type="text"
              value={contactFilterText}
              width="265px"
              maxHeight={50}
              onChange={(e) => {
                changeFilteredContacts(e.target.value);
              }}
              placeholder={t("ContactUpload.filter")}
            />
            {filteredContacts.map((contact, index) => {
              if (contact.firstName || contact.lastName) {
                return (
                  <div
                    className="contact"
                    key={index}
                    onClick={() => {
                      if (contact.firstName && contact.lastName) {
                        onChange(
                          contact.firstName + " " + contact.lastName,
                          contact.phoneNumbers
                            ? contact.phoneNumbers[0].number
                            : "",
                          contact.Emails ? contact.Emails[0] : ""
                        );
                      } else if (contact.firstName && !contact.lastName) {
                        onChange(
                          contact.firstName,
                          contact.phoneNumbers
                            ? contact.phoneNumbers[0].number
                            : "",
                          contact.Emails ? contact.Emails[0] : ""
                        );
                      } else if (!contact.firstName && contact.lastName) {
                        onChange(
                          contact.lastName,
                          contact.phoneNumbers
                            ? contact.phoneNumbers[0].number
                            : "",
                          contact.Emails ? contact.Emails[0] : ""
                        );
                      } else {
                        onChange("", "");
                      }
                      cancel();
                    }}
                  >
                    {contact.firstName && contact.lastName
                      ? contact.firstName + " " + contact.lastName
                      : contact.firstName && !contact.lastName
                      ? contact.firstName
                      : !contact.firstName && contact.lastName
                      ? contact.lastName
                      : ""}
                  </div>
                );
              }
            })}
          </ContactUploadPopup>
        )}
        <div className="contact-upload-btn-container">
          <button
            className="calendar-container contact-upload-btn"
            onClick={() => choose()}
          >
            <FontAwesomeIcon icon={faContactBook} className="fa-contact" />
            {t("ContactUpload.choose")}
          </button>
        </div>
      </div>
    );
  }
);
