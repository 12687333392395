import { Popup } from "../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import "./view-tip-modal.scss";
import { useTranslation } from "react-i18next";
import BoosterIcon from "../../../../../assets/images/save-as-booster.png";
import AddToBoostersIcon from "../../../../../assets/images/not-in-boosters-icon.png";
import ShareIcon from "../../../../../assets/images/share-media.jpg";
import { useState } from "react";
import { ShareMedia } from "../../share-media-popup/share-media-popup";

export const ViewTipModal = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");
  return (
    <div className="view-content-tip-container flex-popup-container">
      <Popup
        height={"425px"}
        width={props.width ? props.width : "1000px"}
        close={() => {
          props.closePopup();
        }}
      >
        <div className="info-container">
          <div className="info-container-inner">
            <div className="content-date">
              <h5>
                {props.item ? props.item.CreationDate.replaceAll("/", ".") : ""}
              </h5>
            </div>
            <div className="content-title">
              <h4>{props.item ? props.item.Title : ""}</h4>
            </div>
            <div className="content-desription">
              <h5>{props.item ? props.item.Description : ""}</h5>{" "}
            </div>
            {props.item.Link && (
              <h5
                className="read-more-container"
                onClick={() => {
                  window.open(props.item.Link, "_blank");
                }}
              >
                {t("Common:read-more")}...
              </h5>
            )}
          </div>

          <div className="actions-container">
            {props.bookmarked ? (
              <img
                src={BoosterIcon}
                className="booster-icon"
                onClick={() => {
                  props.bookmarkUserContent(props.item, props.id);
                  props.closePopup();
                }}
              />
            ) : (
              <img
                src={AddToBoostersIcon}
                className="booster-icon"
                onClick={() => {
                  props.bookmarkUserContent(props.item, props.id);
                  props.closePopup();
                }}
              />
            )}
            <img
              src={ShareIcon}
              className="share-icon"
              onClick={() => {
                props.closePopup();
                props.setShowSharePopup(true);
              }}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};
