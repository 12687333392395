import { useEffect, useState } from "react";
import { PhotoAlbum } from "react-photo-album";
import Api from "../../../utils/api";
import { Button } from "../../button/button";
import "./gallery-popup.scss";
const renderPhoto = ({ imageProps, layout }) => {
  const {
    src,
    alt,
    srcSet,
    sizes,
    className,
    selected,
    style,
    ...restImageProps
  } = imageProps;

  return (
    <img
      src={src}
      alt={alt}
      className={
        className +
        (selected == layout.index ? " selected-image-in-gallery" : "")
      }
      style={style}
      {...(srcSet ? { srcSet, sizes } : null)}
      {...restImageProps}
    />
  );
};
export const GalleryPopup = ({
  galleryUrl,
  active,
  onSelect,
  onClose,
  children,
}) => {
  const isIOS = /iPad|iPhone|iPod|Windows/.test(navigator.userAgent);

  const [photos, setPhotos] = useState([]);
  const [chosen, setChosen] = useState(-1);
  useEffect(() => {
    async function getGallery() {
      let res = await Api.get(galleryUrl);
      setPhotos(
        res.data.payload.map((image) => ({
          src: `${process.env.REACT_APP_BASE_IMAGE_URL}/${image.Path}`,
          width: 300,
          height: 200,
          selected: false,
        }))
      );
    }
    getGallery();
  }, []);
  return (
    <div
      className={`galery-popup-container`}
      style={isIOS ? { transform: "translateY(20px)" } : {}}
    >
      <div className="gallery-popup-buttons">
        <Button
          small={true}
          disabled={chosen < 0}
          onClick={() => onSelect(photos[chosen].src)}
        >
          save
        </Button>
        <Button small={true} onClick={() => onClose()}>
          cancel
        </Button>
      </div>
      <PhotoAlbum
        renderPhoto={renderPhoto}
        layout="rows"
        photos={photos}
        componentsProps={{
          imageProps: {
            selected: chosen,
          },
        }}
        onClick={(event, photo, index) => {
          setChosen(index);
        }}
      />
    </div>
  );
};
