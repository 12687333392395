import React, { useState } from "react";
import { CreateNameAndImage } from "./create-name-and-image/create-name-and-image";
import { CreateSteps } from "./create-steps/create-steps";
import "./create-goal.scss";
import { useSelector } from "react-redux";

export const CreateGoal = () => {
  const [createMapStage, setCreateMapStage] = useState({
    createGoal: true,
    createSteps: false,
  });
  const profile = useSelector((state) => state.profileReducer.profile);
  const [goal, setGoal] = useState(profile?.DraftMap?.Map?.MainGoal);
  return (
    <div className="create-goal-container">
      {createMapStage.createGoal && (
        <CreateNameAndImage
          createMapStage={createMapStage}
          setCreateMapStage={setCreateMapStage}
          setGoal={setGoal}
        />
      )}
      {createMapStage.createSteps && (
        <CreateSteps
          createMapStage={createMapStage}
          setCreateMapStage={setCreateMapStage}
          goal={goal}
        />
      )}
    </div>
  );
};
