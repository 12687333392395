import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { Popup } from "../../../../../../components/popup/popup";
import { SpeechBubble } from "../../../../../../components/speech-bubble/speech-bubble";
import "./finished-setting-goal-popup.scss";
import { useTranslation } from "react-i18next";

export const FinishedSettingGoalPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  return (
    <Popup height={"430px"} close={close}>
      <div className="finished-setting-goal-popup-container">
        <PopupTitle
          small={true}
          style={{ textAlign: "center", marginTop: "30px" }}
        >
          {t("CreateMap.FinishSettingGoalPopUp.you-have")}
        </PopupTitle>
        <PopupTitle style={{ marginBottom: "15px" }}>
          {" "}
          {t("CreateMap.FinishSettingGoalPopUp.set-a-goal")}
        </PopupTitle>
        <div className="background-image"></div>
        <SpeechBubble
          direction={"right"}
          top={"90px"}
          left={"34px"}
          width={"215px"}
          height={"67px"}
        >
          <p>{t("CreateMap.FinishSettingGoalPopUp.to-achieve")}</p>
          <p>{t("CreateMap.FinishSettingGoalPopUp.three-pathways")}</p>
        </SpeechBubble>
        <SpeechBubble
          direction={"right"}
          top={"155px"}
          left={"24px"}
          width={"210px"}
          height={"75px"}
        >
          <p>{t("CreateMap.FinishSettingGoalPopUp.describe")}</p>
          <p>{t("CreateMap.FinishSettingGoalPopUp.which-will-help")}</p>
          <p>{t("CreateMap.FinishSettingGoalPopUp.click-next")}</p>
        </SpeechBubble>

        <div className="popup-bottom-bottons-container flex">
          <PopupButton
            continue="true"
            onClick={close}
            style={{ width: "230px" }}
          >
            {t("Common:next")}
          </PopupButton>
        </div>
      </div>
    </Popup>
  );
};
