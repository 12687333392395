import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useTranslation } from "react-i18next";
import Api from "../../utils/api";
import { NativeEvents } from "../../utils/enums";
import { PopupButton } from "../popup-button/popup-button";
import { Popup } from "../popup/popup";
import { PopupTitle } from "../popup/popup-title/popup-title";
import "./profile-image-upload.scss";
import AWS from "aws-sdk";
import { setShowLoader } from "../../store/app-settings/app-settings-slice";
import { useDispatch } from "react-redux";

export const ProfileImageUpload = ({
  defaultImageSrc,
  changeImage,
  saveImageUrl,
}) => {
  const { t } = useTranslation("Common");
  const dispatch = useDispatch();

  const uploadFromDeviceImageRef = useRef();
  const processedImageRef = useRef();

  const [image, setImage] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const [isChosenFromGallery, setIsChosenFromGallery] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [scale, setScale] = useState(1);
  const [
    cameraAndMediaLibraryPermissionGranted,
    setCameraAndMediaLibraryPermissionGranted,
  ] = useState(false);

  useEffect(() => {
    document.addEventListener(
      "message",
      checkCameraAndMediaLibraryPermissionResponse
    );

    return () => {
      document.removeEventListener(
        "message",
        checkCameraAndMediaLibraryPermissionResponse
      );
    };
  }, []);

  useEffect(() => {
    // console.log({ defaultImageSrc });
    if (defaultImageSrc) setImageSrc(defaultImageSrc);
  }, [defaultImageSrc]);

  const checkCameraAndMediaLibraryPermissionResponse = (message) => {
    if (message.data.cameraAndMediaLibraryPermissionGranted) {
      setCameraAndMediaLibraryPermissionGranted(true);
      setPopupIsOpen(true);
    }
  };

  const handleImageChange = (el) => {
    if (!el.target.files.length) return;
    setImage(el.target.files[0]);
    setImageSrc(URL.createObjectURL(el.target.files[0]));
    setIsChosenFromGallery(false);
  };

  const save = async () => {
    try {
      dispatch(setShowLoader(true));
      //   console.log({ imageSrc });
      if (!imageSrc) {
        if (!defaultImageSrc) {
          setPopupIsOpen(false);
        }
        // remove existing profile image
        else await Api.delete(`user/profile/image`);
        await changeImage("");
        setPopupIsOpen(false);
      } else {
        const imageData = {
          FromGallery: isChosenFromGallery,
        };
        let res = await Api.put(saveImageUrl, imageData);
        const data = res.data.payload;
        const formDataValues = new FormData();

        for (const key in data.fields) {
          formDataValues.append(key, data.fields[key]);
        }

        const newImageSrc = data.fields["key"];
        const imagePreview = processedImageRef.current
          .getImageScaledToCanvas()
          .toDataURL();

        const blob = await new Promise((resolve) => {
          fetch(imagePreview)
            .then((res) => res.blob())
            .then((blob) => resolve(blob));
        });

        formDataValues.append("file", blob);
        formDataValues.append("Content-type", "image/png");

        AWS.config.update({
          accessKeyId: "GIXELX3YIRN2WAEMMTIV",
          secretAccessKey: "KulhEtUp3litlrId3zuImv8cD3iWnRohael1zvkdFVk",
          endpoint: new AWS.Endpoint("https://fra1.digitaloceanspaces.com"),
        });

        const s3 = new AWS.S3();

        const bucketName = "tlechaim-static";
        const key = data.fields["key"];

        const params = {
          Bucket: bucketName,
          Key: key,
          Body: image,
          ACL: "public-read",
        };

        await s3.upload(params).promise();
        await changeImage(newImageSrc);
        dispatch(setShowLoader(false));
        setPopupIsOpen(false);
      }
    } catch (err) {
      dispatch(setShowLoader(false));
      console.log(err);
    }
  };

  const cancel = () => {
    setImageSrc(defaultImageSrc);
    setPopupIsOpen(false);
  };

  const removeImage = () => {
    setImageSrc("");
  };

  const chooseFileFromDevice = () => {
    if (window.ReactNativeWebView) {
      if (cameraAndMediaLibraryPermissionGranted) {
        uploadFromDeviceImageRef.current.click();
      } else if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            event: NativeEvents.RequestCameraAndMediaLibraryPermissions,
          })
        );
      }
    } else {
      uploadFromDeviceImageRef.current.click();
    }
  };
  return (
    <div className="profile-image-upload-container flex-popup-container">
      <input
        type="file"
        name="Image"
        accept="image/*"
        onChange={handleImageChange}
        className="hidden-file-input"
        ref={uploadFromDeviceImageRef}
        onClick={(e) => {
          e.target.value = null;
        }}
      />

      <img
        className={"selected-image"}
        style={{
          padding: "4px",
        }}
        src={defaultImageSrc || require("../../assets/icons/user.svg").default}
        onClick={() => setPopupIsOpen(true)}
      />

      {popupIsOpen && (
        <Popup close={cancel}>
          <PopupTitle>{t("Maps:ImageUpload.choose-image")}</PopupTitle>
          <br />
          <button className="font-button" onClick={chooseFileFromDevice}>
            <FontAwesomeIcon icon={faUpload} />
            &nbsp;{t("file")}
          </button>
          {imageSrc && (
            <button className="font-button" onClick={removeImage}>
              <FontAwesomeIcon icon={faTrash} />
              &nbsp;{t("remove")}
            </button>
          )}
          <br />
          <div className="avatar-container flex">
            <AvatarEditor
              crossOrigin="anonymous"
              image={imageSrc}
              ref={processedImageRef}
              width={250}
              height={250}
              borderRadius={150}
              color={[241, 241, 241, 0.6]} // RGBA
              scale={scale}
              rotate={0}
            />
            <input
              type="range"
              step="0.001"
              min="0.5"
              max="3"
              onChange={(el) => setScale(Number(el.target.value))}
              value={scale}
            />
          </div>

          <div className="flex image-upload-buttons">
            <PopupButton onClick={cancel}>{t("Common:cancel")}</PopupButton>
            <PopupButton onClick={save}>{t("Common:save")}</PopupButton>
          </div>
        </Popup>
      )}
    </div>
  );
};
