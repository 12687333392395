import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../../components/button/button";
import { RatingSlider } from "../../../../components/rating-slider/rating-slider";
import { SmallTitle } from "../../../../components/small-title/small-title";
import Api from "../../../../utils/api";
import {
  setHowsYourJourneyGoingPopupOpen,
  setHideMenuItems,
  setShowLoader,
  setTitle,
} from "../../../../store/app-settings/app-settings-slice";
import { changeProfile } from "../../../../store/profile/profile-slice";
import { validateRating } from "../../../../utils/helper";
import "./define-yourself.scss";
import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

export const DefineYourself = () => {
  const { t, i18n } = useTranslation("OnBoarding");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const { state } = useLocation();
  const infoRefs = useRef([]);

  const [info, setInfo] = useState({
    HopeEvaluation: 0,
    HappinessEvaluation: 0,
    SenseOfMeaningEvaluation: 0,
    SenseOfConnectionEvaluation: 0,
    EnergyEvaluation: 0,
  });

  const [showError, setShowError] = useState([]);

  const [displayDefineYouselfCheck, setDisplayDefineYouselfCheck] =
    useState(true);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setShowLoader(true));
    if (profile) {
      setShowError(Array(Object.keys(info).length).fill(false));
      for (var key of Object.keys(info)) {
        if (profile[key]) {
          chnageInfo(key, profile[key]);
        } else if (profile.CustomFields[key]) {
          const i18Key = `OnBoarding:DefineYourself.Options.${profile.CustomFields[key]}`;
          const isKeyExist = i18n.exists(i18Key);
          chnageInfo(key, isKeyExist ? t(i18Key) : profile.CustomFields[key]);
        }
      }
      setDisplayDefineYouselfCheck(
        profile.Settings.DisplayDefineYourselfAllowed
      );
    }
    dispatch(setShowLoader(false));
  }, [profile]);

  useEffect(() => {
    if (profile) {
      dispatch(setTitle(t("DefineYourself.title")));
    }
  }, [profile, i18n]);

  const chnageInfo = (name, value) => {
    setInfo((prevState) => ({
      ...prevState,
      [name]: parseInt(value),
    }));
  };

  const handleChange = (value, name) => {
    chnageInfo(name, value);
  };

  const saveDefineYourself = async () => {
    if (!validateRating(showError, setShowError, info)) return;
    try {
      dispatch(setShowLoader(true));

      const data = {
        CustomFields: {
          HopeEvaluation: info.HopeEvaluation,
          HappinessEvaluation: info.HappinessEvaluation,
          SenseOfMeaningEvaluation: info.SenseOfMeaningEvaluation,
          SenseOfConnectionEvaluation: info.SenseOfConnectionEvaluation,
          EnergyEvaluation: info.EnergyEvaluation,
        },
      };
      const res = await Api.put("user/profile", data);

      if (res.data) {
        dispatch(changeProfile(res.data.payload));
        if (state?.openWelcomePopupInExit) {
          dispatch(setHowsYourJourneyGoingPopupOpen(true));
          dispatch(setHideMenuItems(false));
        }
        if (!profile.OnboardingFinished) {
          navigate("../onboarding/basic-info");
        } else {
          navigate("../maps");
        }
      }
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
    }
  };

  const setDisplayDefineYOurself = async () => {
    try {
      const res = await Api.put("user/profile/toggleDisplayDefineYouself");
      setDisplayDefineYouselfCheck((prevState) => !prevState);
    } catch (error) {
      dispatch(setShowLoader(false));
    }
  };

  return (
    <div className="define-yourself-container">
      <div className="text">{t("DefineYourself.rate")}</div>
      <div className="ratings-container">
        <SmallTitle>{t("DefineYourself.hopefulness")}</SmallTitle>
        <RatingSlider
          min={0}
          max={7}
          value={info.HopeEvaluation}
          changeHandler={handleChange}
          name="HopeEvaluation"
          isError={showError[0]}
          ref={(el) =>
            (infoRefs.current[Object.keys(info).indexOf("HopeEvaluation")] = el)
          }
        />

        <SmallTitle>{t("DefineYourself.happiness")}</SmallTitle>
        <RatingSlider
          min={0}
          max={7}
          value={info.HappinessEvaluation}
          changeHandler={handleChange}
          name="HappinessEvaluation"
          isError={showError[1]}
          ref={(el) =>
            (infoRefs.current[
              Object.keys(info).indexOf("HappinessEvaluation")
            ] = el)
          }
        />

        <SmallTitle>{t("DefineYourself.sense-of-meaning")}</SmallTitle>
        <RatingSlider
          min={0}
          max={7}
          value={info.SenseOfMeaningEvaluation}
          changeHandler={handleChange}
          name="SenseOfMeaningEvaluation"
          isError={showError[2]}
          ref={(el) =>
            (infoRefs.current[
              Object.keys(info).indexOf("SenseOfMeaningEvaluation")
            ] = el)
          }
        />

        <SmallTitle>{t("DefineYourself.sense-of-connection")}</SmallTitle>
        <RatingSlider
          min={0}
          max={7}
          value={info.SenseOfConnectionEvaluation}
          changeHandler={handleChange}
          name="SenseOfConnectionEvaluation"
          isError={showError[3]}
          ref={(el) =>
            (infoRefs.current[
              Object.keys(info).indexOf("SenseOfConnectionEvaluation")
            ] = el)
          }
        />

        <SmallTitle>{t("DefineYourself.energy")}</SmallTitle>
        <RatingSlider
          min={0}
          max={7}
          value={info.EnergyEvaluation}
          changeHandler={handleChange}
          name="EnergyEvaluation"
          isError={showError[4]}
          ref={(el) =>
            (infoRefs.current[Object.keys(info).indexOf("EnergyEvaluation")] =
              el)
          }
        />
      </div>

      <Button onClick={saveDefineYourself} style={{ width: "100%" }}>
        {t("Common:save")}
      </Button>

      {state?.daysPassedSinceFirstLogin &&
        state?.daysPassedSinceFirstLogin >= 60 && (
          <>
            <input
              type="checkbox"
              checked={!displayDefineYouselfCheck}
              onChange={setDisplayDefineYOurself}
            />
            <span
              className="dont-ask-me-again-text"
              onClick={setDisplayDefineYOurself}
            >
              {t("DefineYourself.not-again")}
            </span>
          </>
        )}
    </div>
  );
};
