import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../utils/api";

let initialState = {
  CreateContentData: {
    title: "",
    description: "",
    link: "",
    image: null,
    countries: [],
    tags: [],
    categories: [],
  },
  contents: [],
  countries: [],
  categories: [],
  linkFromBoosters: false,
};

export const getContents = createAsyncThunk("admin/contents", async () => {
  try {
    const res = await Api.get(`admin/contents`);
    return res.data.payload;
  } catch (error) {
    console.log(error);
  }
});

export const getContentById = createAsyncThunk(
  "admin/contents",
  async (contentId) => {
    try {
      const res = await Api.get(`admin/contents/${contentId}`);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getContentsForUser = createAsyncThunk(
  "user/profile/userContents",
  async () => {
    try {
      const res = await Api.get("user/profile/userContents");
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCountries = createAsyncThunk("admin/countries", async () => {
  try {
    const res = await Api.get(`admin/countries`);
    return res.data.payload;
  } catch (error) {
    console.log(error);
  }
});

export const getCategories = createAsyncThunk("user/categories", async () => {
  try {
    const res = await Api.get(`user/categories`);
    return res.data.payload;
  } catch (error) {
    console.log(error);
  }
});

export const getCategoryById = createAsyncThunk(
  "user/categories",
  async (categoryId) => {
    try {
      const res = await Api.get(`user/categories/${categoryId}`);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCountryById = createAsyncThunk(
  "admin/countries",
  async (countryId) => {
    try {
      const res = await Api.get(`admin/countries/${countryId}`);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const createContent = createAsyncThunk(
  "admin/contents",
  async (contentData) => {
    try {
      const res = await Api.post("admin/contents", contentData);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const editContent = createAsyncThunk(
  "admin/contents",
  async (contentData) => {
    try {
      const res = await Api.put(`admin/contents`, contentData);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const orderContents = createAsyncThunk(
  "admin/contents/order",
  async (items) => {
    try {
      await Api.post(`admin/contents/order`, items);
    } catch (error) {
      console.log(error);
    }
  }
);

export const createCountry = createAsyncThunk(
  "admin/countries",
  async (countryData) => {
    try {
      await Api.post("admin/countries", countryData);
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteContentFromCountry = createAsyncThunk(
  "admin/contents/:id/:countryId",
  async ({ contentId, countryId }) => {
    try {
      const res = await Api.delete(`admin/contents/${contentId}/${countryId}`);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const restoreContent = createAsyncThunk(
  "admin/contents/restore/:id",
  async (contentId) => {
    console.log(contentId);
    try {
      await Api.put(`admin/contents/restore/${contentId}`);
    } catch (error) {
      console.log(error);
    }
  }
);

export const duplicateContent = createAsyncThunk(
  "admin/contents/duplicate",
  async (contentData) => {
    try {
      const res = await Api.post(`admin/contents/duplicate`, contentData);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteContentEntirely = createAsyncThunk(
  "admin/contents/:id",
  async (contentId) => {
    try {
      const res = await Api.delete(`admin/contents/${contentId}`);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteCountry = createAsyncThunk(
  "admin/countries/:id",
  async (countryId) => {
    try {
      await Api.delete(`admin/countries/${countryId}`);
    } catch (error) {
      console.log(error);
    }
  }
);

export const restoreCountry = createAsyncThunk(
  "admin/countries/restore/:id",
  async (countryId) => {
    try {
      await Api.put(`admin/countries/restore/${countryId}`);
    } catch (error) {
      console.log(error);
    }
  }
);

export const setJobsForDistribution = createAsyncThunk(
  "admin/countries/distribution/:id",
  async ({ countryId, data }) => {
    try {
      await Api.post(`admin/countries/distribution/${countryId}`, data);
    } catch (error) {
      console.log(error);
    }
  }
);

export const distributeNow = createAsyncThunk(
  "admin/countries/distribution/immediately/:id",
  async (countryId) => {
    try {
      await Api.put(`admin/countries/distribution/immediately/${countryId}`);
    } catch (error) {
      console.log(error);
    }
  }
);

export const getDistributionInfo = createAsyncThunk(
  "admin/countries/distribution/:code",
  async (code) => {
    try {
      const res = await Api.get(`admin/countries/distribution/${code}`);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteContentImage = createAsyncThunk(
  "admin/contents/image/:link",
  async (link) => {
    try {
      const res = await Api.post(`admin/contents/image/${link}`);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const sendContentRequest = createAsyncThunk(
  "admin/contents/request",
  async (requestText) => {
    console.log(requestText);
    try {
      await Api.post(`admin/contents/request`, {
        requestText: requestText,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

export const mediaCenterSlice = createSlice({
  name: "media-center",
  initialState: initialState,
  reducers: {
    setLinkFromBoosters: (state, action) => {
      state.linkFromBoosters = action.payload;
    },
  },
  extraReducers: {
    [getContents.fulfilled]: (state, action) => {
      state.contents = action.payload;
    },
    [getCountries.fulfilled]: (state, action) => {
      state.countries = action.payload;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export const { setLinkFromBoosters } = mediaCenterSlice.actions;
export default mediaCenterSlice.reducer;
