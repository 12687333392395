import {
  faMap,
  faPenToSquare,
  faTrashCan,
  faFileArchive,
} from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import BlankImage from "../../assets/images/family.jpg";
import "./map-card.scss";
import { useTranslation } from "react-i18next";

export const MapCard = ({ map, openRemoveMapPopup }) => {
  const { t, i18n } = useTranslation("Maps");

  const navigate = useNavigate();
  return (
    <div className="map-card-container">
      <div className="header flex">
        <img
          className="map-image"
          src={
            map.ImagePath
              ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${map.ImagePath}`
              : BlankImage
          }
        />
        <div className="date-and-name-conatiner flex">
          <h6>{dayjs(map.CreatedDate).format("DD/MM/YYYY")}</h6>
          <h4>
            {t(`Maps:CreateMap.MainGoal.${map.Name}`) !==
            `CreateMap.MainGoal.${map.Name}`
              ? t(`Maps:CreateMap.MainGoal.${map.Name}`)
              : map.Name}
          </h4>
        </div>

        <div className="progress-container">
          {map.Completed ? (
            <FontAwesomeIcon icon={faCircleCheck} className="completed-icon" />
          ) : (
            <>
              <div
                className={
                  (map.CompletedSteps === 0 ? "progress-zero-step" : "") +
                  (map.CompletedSteps === 1 ? "progress-first-step" : "") +
                  (map.CompletedSteps === 2 ? "progress-second-step" : "")
                }
              ></div>
              <span className="progress-text">
                {map.CompletedSteps}/3 {t("MapCard.steps")}
              </span>
            </>
          )}
        </div>
      </div>
      <hr />
      <div className="footer flex">
        <div className="edit-and-remove-icons flex">
          <FontAwesomeIcon
            icon={faPenToSquare}
            className="icon"
            onClick={() => navigate(`edit/${map.Id}/goal`)}
          />
          <FontAwesomeIcon
            icon={faFileArchive}
            className="icon"
            onClick={() => openRemoveMapPopup(map.Id, map.Name)}
          />
        </div>
        <button
          className="view-map-button flex"
          onClick={() => navigate(`view/${map.Id}`)}
        >
          {t("MapCard.view-map")}
          <FontAwesomeIcon icon={faMap} size="1x" />
        </button>
      </div>
    </div>
  );
};
