import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/button/button";
import { setTitle } from "../../../store/app-settings/app-settings-slice";
import "./welcome.scss";
import { ReactComponent as WelcomeOnboardingIcon } from "../../../assets/icons/welcome-onboarding-icon.svg";
import { useTranslation } from "react-i18next";

export const Welcome = () => {
  const { t, i18n } = useTranslation("OnBoarding");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);

  useEffect(() => {
    dispatch(setTitle(t("Welcome.title")));
  }, [i18n]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (profile) {
      if (profile.OnboardingFinished) navigate("/404");
    }
  }, [profile]);

  return (
    <div className="welcome-container flex">
      <div className="description">
        {t("Welcome.description")}
        {/* Hopetimize® is here to accompany you on your journey to enhance hope in
        your life. You will be prompted to map a personalized plan that will be
        your roadmap into the future. Your unique goals will guide you toward
        wellbeing, joy and fulfillment... This powerful tool is actually quite
        simple. */}
      </div>
      <div className="moto">{t("Welcome.moto")}</div>
      <WelcomeOnboardingIcon className="welcome-icon" />

      <Button
        onClick={() => navigate("../define-yourself")}
        style={{ width: "100%" }}
      >
        {t("Welcome.ok-go")}
      </Button>
    </div>
  );
};
