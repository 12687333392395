import React from "react";
import { ReactComponent as HopetimizeIcon } from "../../assets/icons/hopetimez-by-lifesdoor-animations.svg";
import { Popup } from "../popup/popup";
import "./splash-screen.scss";

export const SplashScreen = () => {
  return (
    <Popup height={"100vh"} width={"100%"}>
      <div className="splash-screen flex">
        <HopetimizeIcon className="hopetimize-icon" />
      </div>
    </Popup>
  );
};
