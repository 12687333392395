import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./add-to-calendar-button.scss";
import { getProfile } from "../../store/profile/profile-slice";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";

export const AddToCalendarButton = ({
  step,
  addToCalendar,
  removeFromCalendar,
}) => {
  const isIOS = /iPad|iPhone|iPod|Windows/.test(navigator.userAgent);

  const { t } = useTranslation("Maps");

  return (
    <div className="flex">
      <button
        className="add-to-calendar-button-container"
        disabled={!step.StartDate}
        onClick={addToCalendar}
      >
        <div className="flex">
          {t("add-to-calendar")}
          {/* {step.CalendarEventId !== null && <VIcon />} */}
        </div>
      </button>
      {/* {step.CalendarEventId !== null && (
        <FontAwesomeIcon
          icon={faTrashCan}
          className="trash-icon"
          onClick={removeFromCalendar}
        />
      )} */}
    </div>
  );
};
