import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Select } from "../../../../components/select/select";
import { SmallTitle } from "../../../../components/small-title/small-title";
import { StepsDots } from "../../../../components/steps-dots/steps-dots";
import {
  setTitle,
  setShowLoader,
} from "../../../../store/app-settings/app-settings-slice";
import {
  changeProfile,
  getProfile,
} from "../../../../store/profile/profile-slice";
import Api from "../../../../utils/api";
import "./religion-and-spirituality.scss";
import { useTranslation } from "react-i18next";

export const ReligionAndSpirituality = () => {
  const { t, i18n } = useTranslation("OnBoarding");
  const [religionIdentificationOptions, setReligionIdentificationOptions] =
    useState([]);
  const [religionStatusOptions, setReligionStatusOptions] = useState([]);
  const [
    spiritualOrMindBodyPracticesOptions,
    setSpiritualOrMindBodyPracticesOptions,
  ] = useState([]);

  useEffect(() => {
    setReligionIdentificationOptions([
      {
        label: t("Religion.Options.select or type"),
        value: "*",
        isDisabled: true,
      },
      {
        value: "Catholicism/Christianity",
        label: t("Religion.Options.Catholicism/Christianity"),
      },
      { value: "Judaism", label: t("Religion.Options.Judaism") },
      { value: "Islam", label: t("Religion.Options.Islam") },
      { value: "Buddhism", label: t("Religion.Options.Buddhism") },
      { value: "Hinduism", label: t("Religion.Options.Hinduism") },
      {
        value: "Prefer not to say",
        label: t("Religion.Options.Prefer not to say"),
      },
    ]);
    setReligionStatusOptions([
      { value: "Not religious", label: t("Religion.Options.Not religious") },
      {
        value: "Slightly religious",
        label: t("Religion.Options.Slightly religious"),
      },
      {
        value: "Moderately religious",
        label: t("Religion.Options.Moderately religious"),
      },
      { value: "Very religious", label: t("Religion.Options.Very religious") },
      {
        value: "Prefer not to say",
        label: t("Religion.Options.Prefer not to say"),
      },
    ]);
    setSpiritualOrMindBodyPracticesOptions([
      {
        label: t("Religion.Options.select or type"),
        value: "*",
        isDisabled: true,
      },
      { value: "Meditation", label: t("Religion.Options.Meditation") },
      { value: "Yoga", label: t("Religion.Options.Yoga") },
      { value: "Prayer", label: t("Religion.Options.Prayer") },
      { value: "Mindfulness", label: t("Religion.Options.Mindfulness") },
      { value: "Guided imagery", label: t("Religion.Options.Guided imagery") },
      { value: "Creative arts", label: t("Religion.Options.Creative arts") },
      {
        value: "Prefer not to say",
        label: t("Religion.Options.Prefer not to say"),
      },
    ]);
  }, [i18n]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const religionAndSpiritualityRefs = useRef([]);
  const [showError, setShowError] = useState(false);
  const [religionAndSpirituality, setReligionAndSpirituality] = useState({
    SpiritualOrMindBodyPractices: [],
    ReligionStatus: { value: "", label: "" },
    ReligionIdentification: { value: "", label: "" },
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (profile) {
      if (profile.OnboardingFinished) navigate("/404");
      for (var key of Object.keys(religionAndSpirituality)) {
        if (profile[key]) {
          changeReligionAndSpirtuality(key, profile[key]);
        } else if (profile.CustomFields[key]) {
          if (key === "SpiritualOrMindBodyPractices") {
            var arrOfValues = profile.CustomFields[key];
            if (typeof arrOfValues === "string") {
              arrOfValues = arrOfValues.split(",");
            }
            changeReligionAndSpirtuality(
              key,
              arrOfValues.map((item) => ({
                value: item,
                label: t(`OnBoarding:Religion.Options.${item}`),
              }))
            );
          } else {
            changeReligionAndSpirtuality(key, profile.CustomFields[key]);
          }
        }
      }
    }
  }, [profile]);

  useEffect(() => {
    if (profile) {
      dispatch(setTitle(t("Religion.title")));
    }
  }, [profile, i18n]);

  const changeReligionAndSpirtuality = (name, value) => {
    if (name === "SpiritualOrMindBodyPractices") {
      setReligionAndSpirituality((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      const i18Key = `OnBoarding:Religion.Options.${value}`;
      const isKeyExist = i18n.exists(i18Key);
      setReligionAndSpirituality((prevState) => ({
        ...prevState,
        [name]: isKeyExist
          ? { value: value, label: t(i18Key) }
          : { value: value, label: value },
      }));
    }
  };

  const handleChange = (name, selectedOption) => {
    changeReligionAndSpirtuality(name, selectedOption.value);
  };

  const handleChangeMulti = (name, selectedOptions) => {
    let options = selectedOptions.map((option) => ({
      value: option.value,
      label: option.label.replace(/,/g, " "),
    }));
    if (options.some((option) => option === "Prefer not to say")) {
      options = ["Prefer not to say"];
    }
    changeReligionAndSpirtuality(
      name,
      options.filter((option) => !isEmptyOrSpaces(option.label))
    );
  };

  const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  const focusEmptySelect = (name) => {
    const index = Object.keys(religionAndSpirituality).indexOf(name);
    religionAndSpiritualityRefs.current[index].focus();
  };

  const validate = () => {
    let isValidate = true;
    if (
      !religionAndSpirituality.ReligionIdentification.label.replace(/^\s+/g, "")
    ) {
      focusEmptySelect("ReligionIdentification");
      isValidate = false;
    }
    if (!religionAndSpirituality.ReligionStatus.label.replace(/^\s+/g, "")) {
      focusEmptySelect("ReligionStatus");
      isValidate = false;
    }
    if (religionAndSpirituality.SpiritualOrMindBodyPractices.length === 0) {
      focusEmptySelect("SpiritualOrMindBodyPractices");
      isValidate = false;
    }
    if (!isValidate) setShowError(true);

    return isValidate;
  };

  const saveReligionAndSpirtuality = async () => {
    if (!validate()) return;
    dispatch(setShowLoader(true));
    try {
      const data = {
        CustomFields: {
          SpiritualOrMindBodyPractices:
            religionAndSpirituality.SpiritualOrMindBodyPractices.map(
              (item) => item.value
            ),
          ReligionStatus: religionAndSpirituality.ReligionStatus.value,
          ReligionIdentification:
            religionAndSpirituality.ReligionIdentification.value,
        },
      };
      const res = await Api.put("user/profile", data);
      dispatch(setShowLoader(false));
      if (res.data) {
        dispatch(changeProfile(res.data.payload));
        navigate("../leisure-and-community-life");
      }
    } catch (error) {
      dispatch(setShowLoader(false));
    }
  };

  const navigateBack = () => {
    navigate("../my-family-and-relationships");
  };

  return (
    <div className="religion-and-spirtuality-container flex-popup-container">
      <div>
        <p className="description">{t("Religion.description")}</p>

        <SmallTitle>{t("Religion.engage")}</SmallTitle>
        <Select
          name={"SpiritualOrMindBodyPractices"}
          value={religionAndSpirituality.SpiritualOrMindBodyPractices.map(
            (option) => option.label
          )}
          onChange={(e) => handleChangeMulti("SpiritualOrMindBodyPractices", e)}
          options={spiritualOrMindBodyPracticesOptions.filter(
            (option) =>
              !religionAndSpirituality.SpiritualOrMindBodyPractices.map(
                (item) => item.value
              ).includes(option.value)
          )}
          isMulti
          editable
          isError={
            showError &&
            religionAndSpirituality.SpiritualOrMindBodyPractices.length === 0
          }
          ref={(el) =>
            (religionAndSpiritualityRefs.current[
              Object.keys(religionAndSpirituality).indexOf(
                "SpiritualOrMindBodyPractices"
              )
            ] = el)
          }
        />

        <SmallTitle>{t("Religion.consider")}</SmallTitle>
        <Select
          name={"ReligionStatus"}
          value={religionAndSpirituality.ReligionStatus.label}
          onChange={(e) => handleChange("ReligionStatus", e)}
          options={religionStatusOptions}
          isError={
            showError &&
            !religionAndSpirituality.ReligionStatus.label.replace(/^\s+/g, "")
          }
          ref={(el) =>
            (religionAndSpiritualityRefs.current[
              Object.keys(religionAndSpirituality).indexOf("ReligionStatus")
            ] = el)
          }
        />

        <SmallTitle>{t("Religion.identify")}</SmallTitle>
        <Select
          name={"ReligionIdentification"}
          value={religionAndSpirituality.ReligionIdentification.label}
          onChange={(e) => handleChange("ReligionIdentification", e)}
          options={religionIdentificationOptions}
          editable
          isError={
            showError &&
            !religionAndSpirituality.ReligionIdentification.label.replace(
              /^\s+/g,
              ""
            )
          }
          ref={(el) =>
            (religionAndSpiritualityRefs.current[
              Object.keys(religionAndSpirituality).indexOf(
                "ReligionIdentification"
              )
            ] = el)
          }
        />
      </div>

      <StepsDots
        totalSteps={6}
        stepNumber={3}
        next={saveReligionAndSpirtuality}
        back={navigateBack}
        isLoading={showLoader}
      />
    </div>
  );
};
