import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Select } from "../../../../components/select/select";
import { SmallTitle } from "../../../../components/small-title/small-title";
import { StepsDots } from "../../../../components/steps-dots/steps-dots";
import {
  setShowLoader,
  setTitle,
} from "../../../../store/app-settings/app-settings-slice";
import {
  changeProfile,
  getProfile,
} from "../../../../store/profile/profile-slice";
import Api from "../../../../utils/api";
import { CaregiverQuotePopup } from "./caregiver-quote-popup/caregiver-quote-popup";
import { IsolationAndWellbeingQuotePopup } from "./isolation-and-wellbeing-quote-popup/isolation-and-wellbeing-quote-popup";
import "./my-family-and-relationships.scss";
import { useTranslation } from "react-i18next";

export const MyFamilyAndRelationships = () => {
  const { t, i18n } = useTranslation("OnBoarding");
  const [relationshipOptions, setRelationshipOptions] = useState([]);
  const [livingWithOptions, setLivingWithOptions] = useState([]);
  const [
    caregiverForFamilyMemberOrFriendOptions,
    setCaregiverForFamilyMemberOrFriendOptions,
  ] = useState([]);
  const [kidsCountOptions, setKidsCountOptions] = useState([]);
  const [parentsCountOptions, setParentsCountOptions] = useState([]);
  const [sibilingsCountOptions, setSibilingsCountOptions] = useState([]);

  useEffect(() => {
    setRelationshipOptions([
      {
        label: t("FamilyAndRelationship.Options.select or type"),
        value: "*",
        isDisabled: true,
      },
      { value: "Single", label: t("FamilyAndRelationship.Options.Single") },
      {
        value: "In a relationship",
        label: t("FamilyAndRelationship.Options.In a relationship"),
      },
      { value: "Married", label: t("FamilyAndRelationship.Options.Married") },
      { value: "Divorced", label: t("FamilyAndRelationship.Options.Divorced") },
      { value: "Widowed", label: t("FamilyAndRelationship.Options.Widowed") },
      {
        value: "Prefer not to say",
        label: t("FamilyAndRelationship.Options.Prefer not to say"),
      },
    ]);
    setLivingWithOptions([
      {
        label: t("FamilyAndRelationship.Options.select or type"),
        value: "*",
        isDisabled: true,
      },
      { value: "Parents", label: t("FamilyAndRelationship.Options.Parents") },
      { value: "Spouse", label: t("FamilyAndRelationship.Options.Spouse") },
      { value: "Partner", label: t("FamilyAndRelationship.Options.Partner") },
      { value: "Siblings", label: t("FamilyAndRelationship.Options.Siblings") },
      { value: "Friends", label: t("FamilyAndRelationship.Options.Friends") },
      { value: "Children", label: t("FamilyAndRelationship.Options.Children") },
      { value: "Roommate", label: t("FamilyAndRelationship.Options.Roommate") },
      { value: "Alone", label: t("FamilyAndRelationship.Options.Alone") },
      {
        value: "Prefer not to say",
        label: t("FamilyAndRelationship.Options.Prefer not to say"),
      },
    ]);
    setKidsCountOptions([
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" },
      { value: "11", label: "11" },
      { value: "12", label: "12" },
      { value: "13", label: "13" },
      { value: "14", label: "14" },
      { value: "14+", label: "14+" },
      {
        value: "Prefer not to say",
        label: t("FamilyAndRelationship.Options.Prefer not to say"),
      },
    ]);
    setSibilingsCountOptions([
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" },
      { value: "11", label: "11" },
      { value: "12", label: "12" },
      { value: "13", label: "13" },
      { value: "14", label: "14" },
      { value: "14+", label: "14+" },
      {
        value: "Prefer not to say",
        label: t("FamilyAndRelationship.Options.Prefer not to say"),
      },
    ]);
    setParentsCountOptions([
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      {
        value: "Prefer not to say",
        label: t("FamilyAndRelationship.Options.Prefer not to say"),
      },
    ]);
    setCaregiverForFamilyMemberOrFriendOptions([
      { value: "Yes", label: t("FamilyAndRelationship.Options.Yes") },
      { value: "No", label: t("FamilyAndRelationship.Options.No") },
      {
        value: "Prefer not to say",
        label: t("FamilyAndRelationship.Options.Prefer not to say"),
      },
    ]);
  }, [i18n]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const familyAndRelationshipRefs = useRef([]);
  const [showError, setShowError] = useState(false);
  const [familyAndRelationship, setFamilyAndRelationship] = useState({
    RelationshipStatus: { value: "", label: "" },
    KidsCount: { value: "", label: "" },
    SibilingsCount: { value: "", label: "" },
    ParentsCount: { value: "", label: "" },
    LivingWith: { value: "", label: "" },
    CaregiverForFamilyMemberOrFriend: { value: "", label: "" },
  });
  const [
    isolationAndWellbeingQuotePopupOpen,
    setIsolationAndWellbeingQuotePopupOpen,
  ] = useState(false);
  const [
    isolationAndWellbeingQuotePopupOpenedOnce,
    setIsolationAndWellbeingQuotePopupOpenedOnce,
  ] = useState(false);

  const [caregiverQuotePopupOpen, setCaregiverQuotePopupOpen] = useState(false);
  const [caregiverQuotePopupOpenedOnce, setCaregiverQuotePopupOpenedOnce] =
    useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (profile) {
      for (var key of Object.keys(familyAndRelationship)) {
        if (profile[key]) {
          changeFamilyAndRelationshipValue(key, profile[key]);
        } else if (profile.CustomFields[key]) {
          changeFamilyAndRelationship(key, profile.CustomFields[key]);
        }
      }
    }
  }, [profile]);

  useEffect(() => {
    if (profile) {
      dispatch(setTitle(t("FamilyAndRelationship.title")));
    }
  }, [profile, i18n]);

  const changeFamilyAndRelationshipValue = async (name, value) => {
    setFamilyAndRelationship((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const changeFamilyAndRelationship = (name, value) => {
    const i18Key = `OnBoarding:FamilyAndRelationship.Options.${value}`;
    const isKeyExist = i18n.exists(i18Key);
    setFamilyAndRelationship((prevState) => ({
      ...prevState,
      [name]: isKeyExist
        ? { value: value, label: t(i18Key) }
        : { value: value, label: value },
    }));
  };

  const handleChange = (name, selectedOption) => {
    changeFamilyAndRelationship(name, selectedOption.value);
  };

  const focusEmptySelect = (name) => {
    const index = Object.keys(familyAndRelationship).indexOf(name);
    familyAndRelationshipRefs.current[index].focus();
  };

  const validate = () => {
    let isValidate = true;
    if (!familyAndRelationship.LivingWith.label.replace(/^\s+/g, "")) {
      focusEmptySelect("LivingWith");
      isValidate = false;
    }
    if (!familyAndRelationship.ParentsCount.label.replace(/^\s+/g, "")) {
      focusEmptySelect("ParentsCount");
      isValidate = false;
    }
    if (!familyAndRelationship.SibilingsCount.label.replace(/^\s+/g, "")) {
      focusEmptySelect("SibilingsCount");
      isValidate = false;
    }
    if (!familyAndRelationship.KidsCount.label.replace(/^\s+/g, "")) {
      focusEmptySelect("KidsCount");
      isValidate = false;
    }
    if (!familyAndRelationship.RelationshipStatus.label.replace(/^\s+/g, "")) {
      focusEmptySelect("RelationshipStatus");
      isValidate = false;
    }

    if (!isValidate) setShowError(true);

    return isValidate;
  };

  const saveFamilyAndRelationships = async () => {
    if (!validate()) return;
    try {
      dispatch(setShowLoader(true));
      const data = {
        CustomFields: {
          RelationshipStatus: familyAndRelationship.RelationshipStatus.value,
          KidsCount: familyAndRelationship.KidsCount.value,
          SibilingsCount: familyAndRelationship.SibilingsCount.value,
          ParentsCount: familyAndRelationship.ParentsCount.value,
          LivingWith: familyAndRelationship.LivingWith.value,
          CaregiverForFamilyMemberOrFriend:
            familyAndRelationship.CaregiverForFamilyMemberOrFriend.value,
        },
      };
      const res = await Api.put("user/profile", data);
      dispatch(setShowLoader(false));
      if (res.data) {
        dispatch(changeProfile(res.data.payload));
        navigate("../religion-and-spirituality");
      }
    } catch (error) {
      dispatch(setShowLoader(false));
    }
  };

  const navigateBack = () => {
    navigate("../basic-info");
  };

  return (
    <div className="my-family-and-relationships-container">
      {isolationAndWellbeingQuotePopupOpen &&
        !isolationAndWellbeingQuotePopupOpenedOnce && (
          <IsolationAndWellbeingQuotePopup
            close={() => {
              setIsolationAndWellbeingQuotePopupOpen(false);
              setIsolationAndWellbeingQuotePopupOpenedOnce(true);
            }}
          />
        )}

      {caregiverQuotePopupOpen && !caregiverQuotePopupOpenedOnce && (
        <CaregiverQuotePopup
          close={() => {
            setCaregiverQuotePopupOpen(false);
            setCaregiverQuotePopupOpenedOnce(true);
          }}
        />
      )}

      <p className="description">{t("FamilyAndRelationship.description")}</p>

      <SmallTitle>{t("FamilyAndRelationship.relationship")}</SmallTitle>
      <Select
        name={"RelationshipStatus"}
        value={familyAndRelationship.RelationshipStatus.label}
        onChange={(e) => handleChange("RelationshipStatus", e)}
        options={relationshipOptions}
        editable
        isError={
          showError &&
          !familyAndRelationship.RelationshipStatus.label.replace(/^\s+/g, "")
        }
        ref={(el) =>
          (familyAndRelationshipRefs.current[
            Object.keys(familyAndRelationship).indexOf("RelationshipStatus")
          ] = el)
        }
      />

      <br />

      <SmallTitle>{t("FamilyAndRelationship.family")}</SmallTitle>
      <SmallTitle>{t("FamilyAndRelationship.kids")}</SmallTitle>
      <Select
        name={"KidsCount"}
        value={familyAndRelationship.KidsCount.label}
        onChange={(e) => handleChange("KidsCount", e)}
        options={kidsCountOptions}
        isError={
          showError &&
          !familyAndRelationship.KidsCount.label.replace(/^\s+/g, "")
        }
        ref={(el) =>
          (familyAndRelationshipRefs.current[
            Object.keys(familyAndRelationship).indexOf("KidsCount")
          ] = el)
        }
      />

      <SmallTitle>{t("FamilyAndRelationship.siblings")}</SmallTitle>
      <Select
        name={"SibilingsCount"}
        value={familyAndRelationship.SibilingsCount.label}
        onChange={(e) => handleChange("SibilingsCount", e)}
        options={sibilingsCountOptions}
        isError={
          showError &&
          !familyAndRelationship.SibilingsCount.label.replace(/^\s+/g, "")
        }
        ref={(el) =>
          (familyAndRelationshipRefs.current[
            Object.keys(familyAndRelationship).indexOf("SibilingsCount")
          ] = el)
        }
      />

      <SmallTitle>{t("FamilyAndRelationship.parents")}</SmallTitle>
      <Select
        name={"ParentsCount"}
        value={familyAndRelationship.ParentsCount.label}
        onChange={(e) => handleChange("ParentsCount", e)}
        options={parentsCountOptions}
        isError={
          showError &&
          !familyAndRelationship.ParentsCount.label.replace(/^\s+/g, "")
        }
        ref={(el) =>
          (familyAndRelationshipRefs.current[
            Object.keys(familyAndRelationship).indexOf("ParentsCount")
          ] = el)
        }
      />

      <SmallTitle>{t("FamilyAndRelationship.live-with")}</SmallTitle>
      <Select
        name={"LivingWith"}
        value={familyAndRelationship.LivingWith.label}
        onChange={(e) => {
          handleChange("LivingWith", e);
          setIsolationAndWellbeingQuotePopupOpen(true);
        }}
        options={livingWithOptions}
        isError={
          showError &&
          !familyAndRelationship.LivingWith.label.replace(/^\s+/g, "")
        }
        ref={(el) =>
          (familyAndRelationshipRefs.current[
            Object.keys(familyAndRelationship).indexOf("LivingWith")
          ] = el)
        }
      />

      <SmallTitle>{t("FamilyAndRelationship.caregiver")}</SmallTitle>
      <Select
        name={"CaregiverForFamilyMemberOrFriend"}
        value={familyAndRelationship.CaregiverForFamilyMemberOrFriend.label}
        onChange={(e) => {
          handleChange("CaregiverForFamilyMemberOrFriend", e);
          setCaregiverQuotePopupOpen(true);
        }}
        options={caregiverForFamilyMemberOrFriendOptions}
        isError={
          showError &&
          !familyAndRelationship.CaregiverForFamilyMemberOrFriend.label.replace(
            /^\s+/g,
            ""
          )
        }
        ref={(el) =>
          (familyAndRelationshipRefs.current[
            Object.keys(familyAndRelationship).indexOf(
              "CaregiverForFamilyMemberOrFriend"
            )
          ] = el)
        }
      />

      <StepsDots
        totalSteps={6}
        stepNumber={2}
        next={saveFamilyAndRelationships}
        back={navigateBack}
        isLoading={showLoader}
      />
    </div>
  );
};
