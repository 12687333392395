import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDirection } from "../../store/app-settings/app-settings-slice";
import { Select } from "../select/select";

export const SelectLanguage = ({ handleChangeLanguage, onLogin = false }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [langOptions, setLangOptions] = useState([
    { value: "en", label: "English" },
    { value: "he", label: "עברית" },
  ]);
  const [langCode, setLangCode] = useState("en");
  const [lang, setLang] = useState("English");

  useEffect(() => {
    var checkUserLocalStorageLang = localStorage.getItem("hope-lang");
    if (checkUserLocalStorageLang) {
      changeLang(checkUserLocalStorageLang);
      if (checkUserLocalStorageLang.toLocaleLowerCase() === "he") {
        setLang("עברית");
      } else {
        setLang("English");
      }
    }
  }, [i18n]);

  const changeLang = (code, language) => {
    setLangCode(code);
    setLang(language);
    if (onLogin) {
      i18n.changeLanguage(code);
      if (code.toLowerCase() === "he") {
        dispatch(setDirection("rtl"));
      } else {
        dispatch(setDirection("ltr"));
      }
      localStorage.setItem("hope-lang", code);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Select
        style={onLogin ? { width: "200%", padding: "10px" } : { width: "60%" }}
        className="lang-select"
        onChange={(e) => {
          handleChangeLanguage("LanguageCode", e.value);
          changeLang(e.value, e.label);
        }}
        value={langCode}
        label={lang}
        options={langOptions}
      />
    </div>
  );
};
