import debounce from "lodash/debounce";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../../components/button/button";
import { ContactUpload } from "../../../../../components/contacts-upload/contacts-upload";
import { DateInput } from "../../../../../components/date-input/date-input";
import { InputSwitch } from "../../../../../components/input-switch/input-switch";
import { Input } from "../../../../../components/input/input";
import { Loader } from "../../../../../components/loader/loader";
import { SmallTitle } from "../../../../../components/small-title/small-title";
import {
  setTitle,
  showToastMessage,
} from "../../../../../store/app-settings/app-settings-slice";
import { changeMap, getMapById } from "../../../../../store/map/map-slice";
import {
  updateDraftMap,
  updateUserPoints,
} from "../../../../../store/profile/profile-slice";
import { useTranslation } from "react-i18next";
import { AddToCalendarButton } from "../../../../../components/add-to-calendar-button/add-to-calendar-button";
import Api from "../../../../../utils/api";
import { NativeEvents } from "../../../../../utils/enums";
import { toast } from "react-toastify";
import "./step.scss";
import axios from "axios";

const CLIENT_ID =
  "101505278931-6ds1qli3mldqce7mplunao0a0ganpbcj.apps.googleusercontent.com";
// const CLIENT_ID = "101505278931-1en7aij2rgdge0v4j8q1v7eoa3ep5n7p.apps.googleusercontent.com"

export const Step = ({ editMode }) => {
  const { t, i18n } = useTranslation("Maps");
  const uploadRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const map = useSelector((state) => state.mapReducer.map);
  const stepRefs = useRef([]);
  const [showError, setShowError] = useState(false);
  const [baseMap, setBaseMap] = useState(null);
  const [now, setNow] = useState(new Date().toISOString().slice(0, -8));
  const [stepIndex, setStepIndex] = useState(1);
  const [step, setStep] = useState({
    Name: "",
    Obstacle: "",
    Overcome: "",
    Phone: "",
    Email: "",
    ContactName: "",
    StartDate: null,
    StartReminder: null,
    CalendarEventId: null,
  });

  const isIOS = /iPad|iPhone|iPod|Windows/.test(navigator.userAgent);

  const [showReminder, setShowReminder] = useState(false);

  const changeDraftMap = async (data) => {
    dispatch(updateDraftMap(data));
  };

  const [debouncedUpdateDaftMap] = useState(() =>
    debounce(changeDraftMap, 1000)
  );

  useEffect(() => {
    document.addEventListener("message", calendarListener);

    if (params?.stepNumber) setStepIndex(params.stepNumber - 1);

    if (editMode) {
      const mapId = params.mapId;
      if (!mapId) navigate("goal-by-categories");
      if (!map || map?._id !== mapId) dispatch(getMapById(mapId));
    }
    return () => {
      document.removeEventListener("message", calendarListener);
    };
  }, []);

  useEffect(() => {
    if (!editMode && profile?.DraftMap?.Map) initStepInfo(profile.DraftMap.Map);
  }, [profile]);

  useEffect(() => {
    if (editMode && map) initStepInfo(map);
  }, [map]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initStepInfo = async (mapToEdit) => {
    dispatch(setTitle("Mapping HOPE"));
    const stepIndexFromParams = params.stepNumber - 1;
    setBaseMap(mapToEdit);
    const steps = mapToEdit.Steps;
    if (steps[stepIndexFromParams]) {
      setStep({
        Name: steps[stepIndexFromParams].Name,
        Obstacle: steps[stepIndexFromParams].Obstacle,
        Overcome: steps[stepIndexFromParams].Overcome,
        Phone: steps[stepIndexFromParams].Phone,
        Email: steps[stepIndexFromParams].Email,
        ContactName: steps[stepIndexFromParams].ContactName,
        StartDate: steps[stepIndexFromParams].StartDate,
        StartReminder: steps[stepIndexFromParams].StartReminder,
      });
      setShowReminder(steps[stepIndexFromParams].StartReminder !== null);
    } else {
      if (editMode) navigate(`/maps/edit/${params.mapId}/goal`);
      else navigate("/maps/create/goal");
    }
  };

  useEffect(() => {
    if (profile) {
    }
  }, [profile]);

  const changeValue = (name, value) => {
    let newSteps = [...baseMap.Steps];
    newSteps[stepIndex] = { ...step, [name]: value };
    setStep((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    changeValue(e.target.name, e.target.value);
  };

  const changeContact = (name, phone, email) => {
    if (name && phone && email) {
      setStep((prevState) => ({
        ...prevState,
        ContactName: name,
        Phone: phone,
        Email: email,
      }));
    } else if (name && phone && !email) {
      setStep((prevState) => ({
        ...prevState,
        ContactName: name,
        Phone: phone,
      }));
    } else if (name && !phone && !email) {
      setStep((prevState) => ({
        ...prevState,
        ContactName: name,
      }));
    }
  };

  const handleChangeDate = (name, value) => {
    changeValue(name, value);
    // if (name === "StartDate" && value === null) {
    //   if (step.CalendarEventId) {
    //     removeFromCalendar();
    //   }
    // }
  };

  const resetValue = (name) => {
    changeValue(name, "");
  };

  const focusEmptyInput = (name) => {
    const index = Object.keys(step).indexOf(name);
    stepRefs.current[index].select();
    stepRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const calendarListener = (message) => {
    if (message.data?.calendarEventSaved) {
      const calendarEventId = message.data.calendarEventId;
      setStep((prevState) => ({
        ...prevState,
        CalendarEventId: calendarEventId,
      }));
      dispatch(
        showToastMessage({
          loading: false,
          text: t("CreateMap.Steps.reminder-added-successfully"),
          type: toast.TYPE.SUCCESS,
        })
      );
    }
    // else if (message.data?.calendarEventRemoved) {
    //   setStep((prevState) => ({
    //     ...prevState,
    //     CalendarEventId: null,
    //   }));
    //   removedFromCalendarInDB();
    // }
  };

  const addToCalendarInDB = async (calendarEventId) => {
    try {
      const data = {
        CalendarEventId: calendarEventId,
      };
      const res = await Api.put(
        `user/maps/${params.mapId}/${params.stepNumber - 1}/calendar`,
        data
      );
    } catch (err) {}
  };

  const removedFromCalendarInDB = async () => {
    try {
      const res = await Api.delete(
        `user/maps/${params.mapId}/${params.stepNumber - 1}/calendar`
      );
    } catch (err) {}
  };

  const addToCalendar = () => {
    if (window.ReactNativeWebView) {
      if (!step.CalendarEventId) {
        const stepData = {
          Name: step.Name,
          Date: step.StartDate,
        };
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            event: NativeEvents.AddToCalendar,
            stepData,
          })
        );
      } else {
        dispatch(
          showToastMessage({
            text: "Already added",
            type: toast.TYPE.ERROR,
          })
        );
      }
    } else {
      dispatch(
        showToastMessage({
          text: "Please login through the app in order to use this function",
          type: toast.TYPE.ERROR,
        })
      );
    }
  };

  const removeFromCalendar = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: NativeEvents.RemoveFromCalendar,
          calendareEvenId: step.CalendarEventId,
        })
      );
    } else {
      dispatch(
        showToastMessage({
          text: "Please login through the app in order to use this function",
          type: toast.TYPE.ERROR,
        })
      );
    }
  };

  const validate = () => {
    let isValidate = true;

    if (!step.Overcome.replace(/^\s+/g, "")) {
      focusEmptyInput("Overcome");
      isValidate = false;
    }
    if (!step.Obstacle.replace(/^\s+/g, "")) {
      focusEmptyInput("Obstacle");
      isValidate = false;
    }
    if (!step.Name.replace(/^\s+/g, "")) {
      focusEmptyInput("Name");
      isValidate = false;
    }
    if (!isValidate) setShowError(true);
    return isValidate;
  };

  const saveStep = () => {
    if (!validate()) return;
    if (editMode) {
      let newSteps = [...map.Steps];
      newSteps[stepIndex] = {
        ...step,
        StartReminder: showReminder ? step.StartReminder : null,
      };
      dispatch(
        changeMap({
          ...map,
          Steps: newSteps,
        })
      );
    } else {
      dispatch(updateUserPoints({ points: 10, createStepType: "" }));
      let newSteps = [...baseMap.Steps];
      newSteps[stepIndex] = {
        ...step,
        StartReminder: showReminder ? step.StartReminder : null,
      };
      dispatch(
        updateDraftMap({
          CategoryId: baseMap.CategoryId,
          CategoryName: baseMap.CategoryName,
          Steps: newSteps,
        })
      );
    }
    if (editMode) {
      navigate(`/maps/edit/${params.mapId}/goal`);
    } else navigate("/maps/create/goal");
  };

  return (
    <div className="step-container">
      {profile ? (
        <>
          <SmallTitle>
            {t("Common:pathway")} #{params.stepNumber}
          </SmallTitle>
          <Input
            type="text"
            name="Name"
            value={step.Name}
            onChange={handleChange}
            reset={resetValue}
            isError={showError}
            ref={(el) =>
              (stepRefs.current[Object.keys(step).indexOf("Name")] = el)
            }
          />
          <SmallTitle>{t("EditMap.EditPathway.blocker")}</SmallTitle>
          <Input
            type="text"
            name="Obstacle"
            value={step.Obstacle}
            onChange={handleChange}
            reset={resetValue}
            isError={showError}
            ref={(el) =>
              (stepRefs.current[Object.keys(step).indexOf("Obstacle")] = el)
            }
          />
          <SmallTitle>{t("EditMap.EditPathway.overcome")}</SmallTitle>
          <Input
            type="text"
            name="Overcome"
            value={step.Overcome}
            onChange={handleChange}
            reset={resetValue}
            isError={showError}
            ref={(el) =>
              (stepRefs.current[Object.keys(step).indexOf("Overcome")] = el)
            }
          />
          <br />

          <SmallTitle style={{ fontSize: "18px" }}>
            {t("EditMap.EditPathway.contacts1")}
            <br />
            {t("EditMap.EditPathway.contacts2")}
          </SmallTitle>
          <hr />
          <ContactUpload
            onChange={changeContact}
            ref={uploadRef}
            phone={profile.Phone}
          />
          <SmallTitle>{t("EditMap.EditPathway.add-contact-name")}</SmallTitle>
          <Input
            type="text"
            name="ContactName"
            value={step.ContactName || ""}
            onChange={handleChange}
          />
          <SmallTitle>{t("EditMap.EditPathway.add-phone")}</SmallTitle>
          <Input
            type="number"
            name="Phone"
            value={step.Phone || ""}
            onChange={handleChange}
            reset={resetValue}
          />
          <SmallTitle>{t("EditMap.EditPathway.add-email")}</SmallTitle>
          <Input
            type="text"
            name="Email"
            value={step.Email || ""}
            onChange={handleChange}
            reset={resetValue}
          />
          {/* <SmallTitle>
            Research shows that its helpful to decide on a date to begin
          </SmallTitle> */}
          {/* <Input
            type="datetime-local"
            name="StartDate"
            value={step.StartDate || ""}
            onChange={handleChange}
            reset={resetValue}
            min={now}
          /> */}
          <div className="flex date-calendar">
            <DateInput
              dateValue={step.StartDate}
              updateDateValue={handleChangeDate}
              title={t("EditMap.EditPathway.set-date")}
              name={"StartDate"}
              value={step.StartDate}
            />
            <div>
              {step.StartDate && (
                <AddToCalendarButton
                  step={step}
                  addToCalendar={addToCalendar}
                  removeFromCalendar={removeFromCalendar}
                />
                //    <button
                //    className="calendar-container"
                //    disabled={!step.StartDate}
                //  >
                //    <a
                //      className="add-to-calendar"
                //      target="_blank"
                //      href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${
                //        step.Name
                //      }&details=A+reminder+to+log+in+to+the+hopetimize+app&dates=
                //            ${dayjs(step.StartDate)
                //              .add(1, "day")
                //              .format()
                //              .replace(/-|:|\.\d\d\d/g, "")}/
                //            ${dayjs(step.StartDate)
                //              .format()
                //              .replace(/-|:|\.\d\d\d/g, "")}
                //          `}
                //    >
                //      <span className="flex">
                //        <div className="calander-wrapper flex">
                //          <GoogleCalendar />
                //        </div>
                //        Add to Google Calendar
                //      </span>
                //    </a>
                //  </button>
              )}
            </div>
          </div>
          <div>
            <InputSwitch
              title={t("EditMap.EditPathway.set-reminder")}
              checked={showReminder}
              style={{ margin: "10px 0" }}
              setCheked={() => setShowReminder(!showReminder)}
            />
            {showReminder && (
              <>
                <SmallTitle>
                  {t("EditMap.EditPathway.reminder")}
                  {!profile.Settings.Notifications.Email &&
                    !profile.Settings.Notifications.Allow && (
                      <span className="disabled-reminder">
                        &nbsp;({t("EditMap.EditPathway.reminders-disabled")}
                        &nbsp;
                        <a href="/profile-settings">
                          {t("EditMap.EditPathway.settings")}
                        </a>
                        )
                      </span>
                    )}
                </SmallTitle>

                <DateInput
                  dateValue={step.StartReminder}
                  updateDateValue={handleChangeDate}
                  title={t("EditMap.EditPathway.set-reminder")}
                  name={"StartReminder"}
                  value={step.StartReminder}
                />

                {/* <Input
                  className={
                    !profile.Settings.Notifications.Email &&
                      !profile.Settings.Notifications.Mobile
                      ? "disabled"
                      : ""
                  }
                  type="datetime-local"
                  name="StartReminder"
                  value={step.StartReminder || ""}
                  onChange={handleChange}
                  reset={resetValue}
                  min={now}
                /> */}
              </>
            )}
          </div>
          <br />

          <br />
          <div className="buttons-container flex">
            <Button
              onClick={() => {
                if (location.state?.fromViewMap) {
                  navigate(`/maps/view/${params.mapId}`);
                } else if (editMode)
                  navigate(`/maps/edit/${params.mapId}/goal`);
                else navigate("/maps/create/goal");
              }}
            >
              {t("Common:cancel")}
            </Button>
            <Button onClick={saveStep}>
              {editMode ? t("Common:continue") : t("Common:save")}
            </Button>
          </div>
        </>
      ) : (
        <Loader className="page" />
      )}
    </div>
  );
};
