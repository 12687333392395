import { async } from "q";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "./App.scss";
import { Loader } from "./components/loader/loader";
import LottieTry from "./components/lottie-try/lottie-try";
import ProtectedRoute from "./components/protected-route/protected-route";
import { SplashScreen } from "./components/splash-screen/splash-screen";
import { ToastMessage } from "./components/toast-message/toast-message";
import AdminLayout from "./layouts/admin-layout/admin-layout";
import MainLayout from "./layouts/main-layout/main-layout";
import { MapLayout } from "./layouts/map-layout/map-layout";
import PublicLayout from "./layouts/public-layout/public-layout";
import Page404 from "./pages/404/404";
import AdminDashboard from "./pages/admin/admin-dashboard";
import MediaCenter from "./pages/admin/media-center/media-center";
import Login from "./pages/login/login";
import { ViewSharedMap } from "./pages/public/view-shared-map/view-shared.map";
import { ArchivedMaps } from "./pages/user/archived-maps/archived-maps";
import { Community } from "./pages/user/community/community";
import { FAQs } from "./pages/user/faq/faq";
import { CreateGoal } from "./pages/user/maps/create-map/create-goal";
import { SelectCategory } from "./pages/user/maps/create-map/select-category/select-category";
import { SmartGoal } from "./pages/user/maps/create-map/smart-goal/smart-goal";
import { Goal } from "./pages/user/maps/edit-map/goal";
import { Step } from "./pages/user/maps/edit-map/step/step";
import { Maps } from "./pages/user/maps/maps";
import { ViewMap } from "./pages/user/maps/view-map/view-map";
import { UserMediaCenter } from "./pages/user/media-center/media-center";
import { Messages } from "./pages/user/messages/messages";
import { BasicInfo } from "./pages/user/onboarding/basic-info/basic-info";
import { CategoriesRoadmap } from "./pages/user/onboarding/categories-roadmap/categories-roadmap";
import { DefineYourself } from "./pages/user/onboarding/define-yourself/define-yourself";
import { LeisureAndCommunityLife } from "./pages/user/onboarding/leisure-and-community-life/leisure-and-community-life";
import { MyFamilyAndRelationships } from "./pages/user/onboarding/my-family-and-relationships/my-family-and-relationships";
import { MyHealth } from "./pages/user/onboarding/my-health/my-health.js";
import { ReligionAndSpirituality } from "./pages/user/onboarding/religion-and-spirituality/religion-and-spirituality";
import { PathwaysSchedule } from "./pages/user/pathways-schedule/pathways-schedule";
import { PrivacyPolicy } from "./pages/user/privacy-policy/privacy-policy";
import { ProfileSettings } from "./pages/user/profile-settings/profile-settings";
import { RemoveNotifications } from "./pages/user/remove-notifications/remove-notifications";
import { Welcome } from "./pages/user/welcome/welcome";
import {
  setDirection,
  setIsBoosterpopup,
  setWelcomePopupsOpen,
  showToastMessage,
} from "./store/app-settings/app-settings-slice";
import { getProfile } from "./store/profile/profile-slice";
import { NativeEvents } from "./utils/enums";
import { getErrorMessage } from "./utils/errors";
import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const {
    direction,
    publicRoutes,
    systemError,
    showLoader,
    welcomeBackPopupOpen,
  } = useSelector((state) => ({
    direction: state.appSettingsReducer.direction,
    publicRoutes: state.appSettingsReducer.publicRoutes,
    systemError: state.appSettingsReducer.systemError,
    showLoader: state.appSettingsReducer.showLoader,
    welcomeBackPopupOpen: state.appSettingsReducer.welcomeBackPopupOpen,
  }));
  const accessToken = useSelector((state) => state.authReducer.accessToken);

  const [showSplashCreen, setShowSplashCreen] = useState(
    process.env.REACT_APP_ENV === "production"
  );

  useEffect(() => {
    let splashScreenTimer = setTimeout(() => {
      setShowSplashCreen(false);
      if (!welcomeBackPopupOpen) dispatch(setWelcomePopupsOpen(true));
      if (!publicRoutes.some((route) => location.pathname.includes(route))) {
        getProfileInfo();
      }
    }, 2000);
    return () => {
      clearTimeout(splashScreenTimer);
    };
  }, []);

  useEffect(() => {
    if (systemError.active) {
      dispatch(
        showToastMessage({
          text: t(getErrorMessage(systemError.statusCode)),
          type: toast.TYPE.ERROR,
        })
      );
    }
  }, [systemError]);

  const getProfileInfo = async () => {
    const response = await dispatch(getProfile());
    if (
      response.payload &&
      response.payload.Settings &&
      response.payload.Settings.LanguageCode
    ) {
      if (response.payload.Settings.LanguageCode.toLowerCase() === "he") {
        dispatch(setDirection("rtl"));
      } else {
        dispatch(setDirection("ltr"));
      }
      i18n.changeLanguage(response.payload.Settings.LanguageCode);
    }
  };

  useEffect(() => {
    document.addEventListener("message", boosterPopup);
    return () => {
      document.removeEventListener("message", boosterPopup);
    };
  }, []);

  const boosterPopup = async (message) => {
    localStorage.setItem(
      "isBoosterPopup",
      message.data.isBoosterPopup ? "true" : "false"
    );
  };

  return (
    <div className={"app " + direction}>
      {showSplashCreen && <SplashScreen />}
      {showLoader && <Loader />}
      <ToastMessage />
      <Routes>
        <Route index element={<Navigate to="maps" />} />
        <Route path="login/*" element={<Login />} />

        <Route element={<ProtectedRoute />}>
          <Route element={<MainLayout />}>
            <Route path="welcome" element={<Welcome />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="define-yourself" element={<DefineYourself />} />
            <Route path="categories-roadmap" element={<CategoriesRoadmap />} />
            <Route path="onboarding">
              <Route path="basic-info" element={<BasicInfo />} />
              <Route
                path="my-family-and-relationships"
                element={<MyFamilyAndRelationships />}
              />
              <Route
                path="religion-and-spirituality"
                element={<ReligionAndSpirituality />}
              />
              <Route
                path="leisure-and-community-life"
                element={<LeisureAndCommunityLife />}
              />
              <Route path="my-health" element={<MyHealth />} />
            </Route>
            <Route path="maps">
              <Route index element={<Maps />} />
              <Route path="create">
                <Route path="category">
                  <Route index element={<SelectCategory />} />
                </Route>
                <Route path="smart-goal" element={<SmartGoal />} />
                <Route path="goal">
                  <Route index element={<CreateGoal />} />
                </Route>
              </Route>
              <Route path="edit/:mapId">
                <Route path="goal">
                  <Route index element={<Goal editMode />} />
                  <Route path="step/:stepNumber" element={<Step editMode />} />
                </Route>
              </Route>
            </Route>
            <Route path="pathways-schedule" element={<PathwaysSchedule />} />
            <Route path="archived-maps" element={<ArchivedMaps />} />
            <Route path="messages" element={<Messages />} />
            <Route path="profile-settings" element={<ProfileSettings />} />
            <Route path="FAQ" element={<FAQs />} />
            <Route path="community" element={<Community />} />
            <Route path="user-media-center" element={<UserMediaCenter />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route element={<MapLayout />}>
            <Route path="maps/view/:mapId" element={<ViewMap />} />

            <Route path="asd" element={<LottieTry />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute admin />}>
          <Route element={<AdminLayout />}>
            <Route path="media-center" element={<MediaCenter />} />
            <Route path="admin-dashboard" element={<AdminDashboard />} />
          </Route>
        </Route>

        <Route element={<PublicLayout />}>
          <Route
            path="remove-notifications/:userExternalId"
            element={<RemoveNotifications />}
          />
          <Route
            path="view-shared-map/:sharedMapId"
            element={<ViewSharedMap />}
          />
        </Route>

        <Route path="*" element={<Navigate to="maps" />} />
      </Routes>
    </div>
  );
}

export default App;
