import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Select } from "../../../../components/select/select";
import { SmallTitle } from "../../../../components/small-title/small-title";
import { StepsDots } from "../../../../components/steps-dots/steps-dots";
import {
  setTitle,
  setShowLoader,
} from "../../../../store/app-settings/app-settings-slice";
import {
  changeProfile,
  getProfile,
} from "../../../../store/profile/profile-slice";
import Api from "../../../../utils/api";
import "./my-health.scss";

import { useTranslation } from "react-i18next";

export const MyHealth = () => {
  const { t, i18n } = useTranslation("OnBoarding");
  const [
    havePreparedOrSharedOnstructionsForMedicalCareOptions,
    setHavePreparedOrSharedOnstructionsForMedicalCareOptions,
  ] = useState([]);
  const [healthcareProxyExistsOptions, setHealthcareProxyExistsOptions] =
    useState([]);
  const [healthcareProxyTypeOptions, setHealthcareProxyTypeOptions] = useState(
    []
  );
  const [healthChallengesOptions, setHealthChallengesOptions] = useState([]);
  const [healthStatusOptions, setHealthStatusOptions] = useState([]);

  useEffect(() => {
    setHealthcareProxyTypeOptions([
      { value: "Sibling", label: t("Health.Options.Sibling") },
      { value: "Parent", label: t("Health.Options.Parent") },
      { value: "Partner", label: t("Health.Options.Partner") },
      { value: "Offspring", label: t("Health.Options.Offspring") },
      { value: "Friend", label: t("Health.Options.Friend") },
      {
        value: "Healthcare provider",
        label: t("Health.Options.Healthcare provider"),
      },
      { value: "Clergy", label: t("Health.Options.Clergy") },
      {
        value: "Prefer not to say",
        label: t("Health.Options.Prefer not to say"),
      },
    ]);
    setHealthStatusOptions([
      { value: "Excellent", label: t("Health.Options.Excellent") },
      { value: "Very good", label: t("Health.Options.Very good") },
      { value: "Good", label: t("Health.Options.Good") },
      { value: "Fair", label: t("Health.Options.Fair") },
      { value: "Poor", label: t("Health.Options.Poor") },
      {
        value: "Prefer not to say",
        label: t("Health.Options.Prefer not to say"),
      },
    ]);
    setHealthChallengesOptions([
      {
        value: "I am healthy, I don't have any disease",
        label: t("Health.Options.I am healthy, I don't have any disease"),
      },
      { value: "Hypertension", label: t("Health.Options.Hypertension") },
      {
        value: "Elevated cholesterol",
        label: t("Health.Options.Elevated cholesterol"),
      },
      { value: "Diabetes", label: t("Health.Options.Diabetes") },
      { value: "Heart problems", label: t("Health.Options.Heart problems") },
      {
        value: "Osteomuscular pain",
        label: t("Health.Options.Osteomuscular pain"),
      },
      {
        value: "Asthma and/or COPD",
        label: t("Health.Options.Asthma and/or COPD"),
      },
      {
        value: "Gastritis or peptic ulcer disease",
        label: t("Health.Options.Gastritis or peptic ulcer disease"),
      },
      { value: "Anxiety", label: t("Health.Options.Anxiety") },
      { value: "Depression", label: t("Health.Options.Depression") },
      {
        value: "Overweight or obesity",
        label: t("Health.Options.Overweight or obesity"),
      },
      { value: "Smoker", label: t("Health.Options.Smoker") },
      { value: "Cancer", label: t("Health.Options.Cancer") },
      {
        value: "Prefer not to say",
        label: t("Health.Options.Prefer not to say"),
      },
    ]);
    setHealthcareProxyExistsOptions([
      { value: "Yes", label: t("Health.Options.Yes") },
      { value: "No", label: t("Health.Options.No") },
      {
        value: "Prefer not to say",
        label: t("Health.Options.Prefer not to say"),
      },
    ]);
    setHavePreparedOrSharedOnstructionsForMedicalCareOptions([
      { value: "Yes", label: t("Health.Options.Yes") },
      { value: "No", label: t("Health.Options.No") },
      {
        value: "Prefer not to say",
        label: t("Health.Options.Prefer not to say"),
      },
    ]);
  }, [i18n]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const myhealthRefs = useRef([]);
  const [showError, setShowError] = useState(false);
  const [myhealth, setMyHealth] = useState({
    HealthStatus: { value: "", label: "" },
    HealthChallenges: { value: "", label: "" },
    HealthcareProxyExists: { value: "", label: "" },
    HealthcareProxyType: { value: "", label: "" },
    HavePreparedOrSharedInstructionsForMedicalCare: { value: "", label: "" },
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (profile) {
      if (profile.OnboardingFinished) navigate("/404");
      for (var key of Object.keys(myhealth)) {
        if (profile[key]) {
          changeMyHealthValue(key, profile[key]);
        } else if (profile.CustomFields[key]) {
          changeMyHealth(key, profile.CustomFields[key]);
        }
      }
    }
  }, [profile]);

  useEffect(() => {
    if (profile) {
      dispatch(setTitle(t("Health.title")));
    }
  }, [profile, i18n]);

  const changeMyHealthValue = (name, value) => {
    setMyHealth((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const changeMyHealth = (name, value) => {
    const i18Key = `OnBoarding:Health.Options.${value}`;
    const isKeyExist = i18n.exists(i18Key);
    setMyHealth((prevState) => ({
      ...prevState,
      [name]: isKeyExist
        ? { value: value, label: t(i18Key) }
        : { value: value, label: value },
    }));
  };

  const handleChange = (name, selectedOption) => {
    changeMyHealth(name, selectedOption.value);
  };

  const focusEmptySelect = (name) => {
    const index = Object.keys(myhealth).indexOf(name);
    myhealthRefs.current[index].focus();
  };

  const validate = () => {
    let isValidate = true;
    if (
      !myhealth.HavePreparedOrSharedInstructionsForMedicalCare.label.replace(
        /^\s+/g,
        ""
      )
    ) {
      focusEmptySelect("HavePreparedOrSharedInstructionsForMedicalCare");
      isValidate = false;
    }
    if (
      myhealth.HealthcareProxyExists.value === "Yes" &&
      !myhealth.HealthcareProxyType.label.replace(/^\s+/g, "")
    ) {
      focusEmptySelect("HealthcareProxyType");
      isValidate = false;
    }
    if (!myhealth.HealthcareProxyExists.label.replace(/^\s+/g, "")) {
      focusEmptySelect("HealthcareProxyExists");
      isValidate = false;
    }
    if (!myhealth.HealthChallenges.label.replace(/^\s+/g, "")) {
      focusEmptySelect("HealthChallenges");
      isValidate = false;
    }
    if (!myhealth.HealthStatus.label.replace(/^\s+/g, "")) {
      focusEmptySelect("HealthStatus");
      isValidate = false;
    }

    if (!isValidate) setShowError(true);

    return isValidate;
  };

  const saveMyHealth = async () => {
    if (!validate()) return;
    try {
      dispatch(setShowLoader(true));
      const data = {
        CustomFields: {
          HealthStatus: myhealth.HealthStatus.value,
          HealthChallenges: myhealth.HealthChallenges.value,
          HealthcareProxyExists: myhealth.HealthcareProxyExists.value,
          HealthcareProxyType: myhealth.HealthcareProxyType.value,
          HavePreparedOrSharedInstructionsForMedicalCare:
            myhealth.HavePreparedOrSharedInstructionsForMedicalCare.value,
        },
      };
      const res = await Api.put("user/profile", data);
      dispatch(setShowLoader(false));
      if (res.data) {
        dispatch(changeProfile(res.data.payload));
        navigate("../../categories-roadmap");
      }
    } catch (error) {
      dispatch(setShowLoader(false));
    }
  };

  const navigateBack = () => {
    navigate("../leisure-and-community-life");
  };

  return (
    <div className="my-health-container">
      <SmallTitle>{t("Health.general")}</SmallTitle>
      <Select
        name={"HealthStatus"}
        value={myhealth.HealthStatus.label}
        onChange={(e) => handleChange("HealthStatus", e)}
        options={healthStatusOptions.map((option) => {
          return {
            label: option.label,
            value: option.value,
            isDisabled: option.isDisabled,
          };
        })}
        isError={showError && !myhealth.HealthStatus.label.replace(/^\s+/g, "")}
        ref={(el) =>
          (myhealthRefs.current[Object.keys(myhealth).indexOf("HealthStatus")] =
            el)
        }
      />

      <SmallTitle>{t("Health.challenges")}</SmallTitle>
      <Select
        name={"HealthChallenges"}
        value={myhealth.HealthChallenges.label}
        onChange={(e) => handleChange("HealthChallenges", e)}
        options={healthChallengesOptions.map((option) => {
          return {
            label: option.label,
            value: option.value,
            isDisabled: option.isDisabled,
          };
        })}
        isError={
          showError && !myhealth.HealthChallenges.label.replace(/^\s+/g, "")
        }
        ref={(el) =>
          (myhealthRefs.current[
            Object.keys(myhealth).indexOf("HealthChallenges")
          ] = el)
        }
      />

      <SmallTitle>{t("Health.proxy")}</SmallTitle>
      <Select
        name={"HealthcareProxyExists"}
        value={myhealth.HealthcareProxyExists.label}
        onChange={(e) => handleChange("HealthcareProxyExists", e)}
        options={healthcareProxyExistsOptions.map((option) => {
          return {
            label: option.label,
            value: option.value,
            isDisabled: option.isDisabled,
          };
        })}
        isError={
          showError &&
          !myhealth.HealthcareProxyExists.label.replace(/^\s+/g, "")
        }
        ref={(el) =>
          (myhealthRefs.current[
            Object.keys(myhealth).indexOf("HealthcareProxyExists")
          ] = el)
        }
      />
      {myhealth.HealthcareProxyExists.value === "Yes" && (
        <>
          <SmallTitle>{t("Health.proxy-is")}</SmallTitle>
          <Select
            name={"HealthcareProxyType"}
            value={myhealth.HealthcareProxyType.label}
            onChange={(e) => handleChange("HealthcareProxyType", e)}
            options={healthcareProxyTypeOptions.map((option) => {
              return {
                label: option.label,
                value: option.value,
                isDisabled: option.isDisabled,
              };
            })}
            editable
            isError={
              showError &&
              !myhealth.HealthcareProxyType.label.replace(/^\s+/g, "")
            }
            ref={(el) =>
              (myhealthRefs.current[
                Object.keys(myhealth).indexOf("HealthcareProxyType")
              ] = el)
            }
          />
        </>
      )}

      <SmallTitle>{t("Health.instructions")}</SmallTitle>
      <Select
        name={"HavePreparedOrSharedInstructionsForMedicalCare"}
        value={myhealth.HavePreparedOrSharedInstructionsForMedicalCare.label}
        onChange={(e) =>
          handleChange("HavePreparedOrSharedInstructionsForMedicalCare", e)
        }
        options={havePreparedOrSharedOnstructionsForMedicalCareOptions.map(
          (option) => {
            return {
              label: option.label,
              value: option.value,
              isDisabled: option.isDisabled,
            };
          }
        )}
        isError={
          showError &&
          !myhealth.HavePreparedOrSharedInstructionsForMedicalCare.label.replace(
            /^\s+/g,
            ""
          )
        }
        ref={(el) =>
          (myhealthRefs.current[
            Object.keys(myhealth).indexOf(
              "HavePreparedOrSharedInstructionsForMedicalCare"
            )
          ] = el)
        }
      />

      <StepsDots
        totalSteps={6}
        stepNumber={5}
        next={saveMyHealth}
        back={navigateBack}
        isLoading={showLoader}
      />
    </div>
  );
};
