import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "next-share";
import React, { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { SmallTitle } from "../small-title/small-title";
import { sharePlatformNames } from "./options/options";
import "./share-buttons.scss";
import { useTranslation } from "react-i18next";

export const ShareButtons = ({
  shareId,
  sendShare,
  singleMessage = "",
  subject = "",
  body = "",
  disabled,
}) => {
  const { t, i18n } = useTranslation("PopUps");

  const timerRef = useRef(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const copyToClipboard = () => {
    timerRef.current = setTimeout(
      () => {
        sendShare(shareId, sharePlatformNames.CopyToClipboard);
        setCopied(true);
        // dispatch(
        //   showToastMessage({
        //     text: "Copied to Clipboard",
        //   })
        // );
        setTimeout(() => {
          setCopied(false);
        }, 500);
      },
      (err) => {
        // console.log("failed to copy", err.mesage);
      },
      1000
    );
  };

  return (
    <div className={"share-buttons-container" + (disabled ? " disabled" : "")}>
      <SmallTitle>{t("ShareBoosters.ShareButtons.choose-platform")}</SmallTitle>
      <div className="share-buttons-container flex">
        <WhatsappShareButton
          beforeOnClick={() => sendShare(shareId, sharePlatformNames.WhatsApp)}
          title={singleMessage}
          url=""
        >
          <WhatsappIcon size={32} round className="share-button flex" />
        </WhatsappShareButton>

        <EmailShareButton
          beforeOnClick={() => sendShare(shareId, sharePlatformNames.Email)}
          subject={subject}
          body={body}
          url=""
        >
          <EmailIcon size={32} round className="share-button flex" />
        </EmailShareButton>

        <FacebookShareButton
          beforeOnClick={() => sendShare(shareId, sharePlatformNames.Facebook)}
          quote={body}
          url=""
        >
          <FacebookIcon size={32} round className="share-button flex" />
        </FacebookShareButton>

        <TelegramShareButton
          beforeOnClick={() => sendShare(shareId, sharePlatformNames.Telegram)}
          title={singleMessage}
          url=""
        >
          <TelegramIcon size={32} round className="share-button flex" />
        </TelegramShareButton>

        <TwitterShareButton
          beforeOnClick={() => sendShare(shareId, sharePlatformNames.Twitter)}
          title={singleMessage}
          url=""
        >
          <TwitterIcon size={32} round className="share-button flex" />
        </TwitterShareButton>

        <CopyToClipboard text={singleMessage} onCopy={copyToClipboard}>
          <FontAwesomeIcon
            icon={copied ? faClipboardCheck : faClipboard}
            size="2x"
          />
        </CopyToClipboard>
      </div>
    </div>
  );
};
