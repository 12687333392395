import React, { useState } from "react";
import { Popup } from "../../../../components/popup/popup";
import { Checkbox } from "../../../../components/checkbox/checkbox";
import { Button } from "../../../../components/button/button";
import { useTranslation } from "react-i18next";
import { languagesList } from "../../../../components/languages-list/languages-list";
import { Input } from "../../../../components/input/input";
import { Select } from "../../../../components/select/select";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SearchModal = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");

  return (
    <Popup
      close={() => {
        let filteredContentsTemp = props.filterBase(
          props.strFilter,
          props.filterByTitle,
          props.filterByDescription,
          props.filterByTags,
          props.filterByCategories,
          props.onlyActiveChecked,
          props.filterByArticle,
          props.filterByVideo,
          props.filterByPodcast,
          props.filterBySong,
          props.filterByTip,
          props.filterByOther,
          props.langFilter
        );
        props.setFilteredContents(filteredContentsTemp);
        props.close();
      }}
    >
      <div className="contents-filters-container">
        <div className="text-filters">
          <div>
            <Input
              type="text"
              value={props.strFilter}
              width="265px"
              maxHeight={50}
              onChange={(e) => {
                props.setStrFilter(e.target.value);
              }}
              placeholder={t("Common:search")}
            />
          </div>
          <div
            onClick={() => {
              props.setShowAdvancedFilters(!props.showAdvancedfilters);
            }}
          >
            {t("Common:advanced")}
            {props.showAdvancedfilters ? (
              <FontAwesomeIcon
                icon={faArrowAltCircleUp}
                className="icon advanced-filters-arrow"
              />
            ) : (
              <FontAwesomeIcon
                icon={faArrowAltCircleDown}
                className="icon advanced-filters-arrow"
              />
            )}
          </div>
          {props.showAdvancedfilters && (
            <div>
              <div className="filter-checkbox">
                <Checkbox
                  id={`filters-checkbox`}
                  label={t("Main.word-title")}
                  checked={props.filterByTitle}
                  onChange={(e) => {
                    props.setFilterByTitle(e.target.checked);
                  }}
                  noFlex={true}
                />
              </div>
              <div className="filter-checkbox">
                <Checkbox
                  id={`filters-checkbox`}
                  label={t("Main.subtitle")}
                  checked={props.filterByDescription}
                  onChange={(e) => {
                    props.setFilterByDescription(e.target.checked);
                  }}
                  noFlex={true}
                />
              </div>
              <div className="filter-checkbox">
                <Checkbox
                  id={`filters-checkbox`}
                  label={t("Main.tags")}
                  checked={props.filterByTags}
                  onChange={(e) => {
                    props.setFilterByTags(e.target.checked);
                  }}
                  noFlex={true}
                />
              </div>
              <div className="filter-checkbox">
                <Checkbox
                  id={`filters-checkbox`}
                  label={t("Main.categories")}
                  checked={props.filterByCategories}
                  onChange={(e) => {
                    props.setFilterByCategories(e.target.checked);
                  }}
                  noFlex={true}
                />
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="type-filters filters-checkbox">
          <table className="filters-table">
            <tbody>
              <tr>
                <td className="filter-checkbox">
                  <Checkbox
                    id={`article-filter`}
                    label={t("Main.articles")}
                    checked={props.filterByArticle}
                    onChange={(e) => {
                      props.setFilterByArticle(e.target.checked);
                    }}
                    noFlex={true}
                  />
                </td>

                <td className="filter-checkbox">
                  <Checkbox
                    id={`video-filter`}
                    label={t("Main.videos")}
                    checked={props.filterByVideo}
                    onChange={(e) => {
                      props.setFilterByVideo(e.target.checked);
                    }}
                    noFlex={true}
                  />
                </td>
              </tr>
              <tr>
                <td className="filter-checkbox">
                  <Checkbox
                    id={`podcast-filter`}
                    label={t("Main.podcasts")}
                    checked={props.filterByPodcast}
                    onChange={(e) => {
                      props.setFilterByPodcast(e.target.checked);
                    }}
                    noFlex={true}
                  />
                </td>
                <td className="filter-checkbox">
                  <Checkbox
                    id={`song-filter`}
                    label={t("Main.songs")}
                    checked={props.filterBySong}
                    onChange={(e) => {
                      props.setFilterBySong(e.target.checked);
                    }}
                    noFlex={true}
                  />
                </td>
              </tr>
              <tr>
                <td className="filter-checkbox">
                  <Checkbox
                    id={`tip-filter`}
                    label={t("Main.tips")}
                    checked={props.filterByTip}
                    onChange={(e) => {
                      props.setFilterByTip(e.target.checked);
                    }}
                    noFlex={true}
                  />
                </td>
                <td className="filter-checkbox">
                  <Checkbox
                    id={`other-filter`}
                    label={t("Main.others")}
                    checked={props.filterByOther}
                    onChange={(e) => {
                      props.setFilterByOther(e.target.checked);
                    }}
                    noFlex={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="language-filter">
          <Select
            style={{ width: "265px" }}
            name={"contentLanguages"}
            value={props.langFilter.label}
            onChange={(e) => {
              props.setLangFilter(e);
            }}
            options={[{ value: "", label: "" }, ...languagesList]}
            placeholder={t("Main.lang-filter-placeholder")}
            ref={null}
          />
        </div>
        <div className="submit-filters">
          <Button
            style={{ width: "80px", height: "30px" }}
            onClick={() => {
              let filteredContentsTemp = props.filterBase(
                props.strFilter,
                props.filterByTitle,
                props.filterByDescription,
                props.filterByTags,
                props.filterByCategories,
                props.onlyActiveChecked,
                props.filterByArticle,
                props.filterByVideo,
                props.filterByPodcast,
                props.filterBySong,
                props.filterByTip,
                props.filterByOther,
                props.langFilter
              );
              props.setFilteredContents(filteredContentsTemp);
              props.close();
            }}
          >
            {t("Common:search")}
          </Button>
        </div>
      </div>
    </Popup>
  );
};
