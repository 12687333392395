import {
  faLocationDot,
  faRoadBarrier,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { Tip } from "../../../../../../components/tip/tip";
import "./map-instructions-popup.scss";
import { useTranslation } from "react-i18next";

export const MapInstructionsPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  const [popupStep, setPopupStep] = useState(1);

  return (
    <Popup close={close}>
      <div className="map-instructions-popup-container flex-popup-container">
        <div className="finshed-creating-map-popup-title">
          <PopupTitle>{t("ViewMap.MapInstructionPopUp.title")}</PopupTitle>
        </div>

        {popupStep === 1 && (
          <div className="step-container">
            <p className="remember-title">
              {t("ViewMap.MapInstructionPopUp.remember")}:
            </p>
            <hr />
            <Tip width="100%" style={{ marginBottom: "10px" }}>
              {t("ViewMap.MapInstructionPopUp.instuction1")}{" "}
            </Tip>
            <Tip width="100%" style={{ marginBottom: "10px" }}>
              {t("ViewMap.MapInstructionPopUp.instuction2")}
            </Tip>
            <Tip width="100%" style={{ marginBottom: "10px" }}>
              {t("ViewMap.MapInstructionPopUp.instuction3")}{" "}
            </Tip>
            <Tip width="100%" style={{ marginBottom: "10px" }}>
              {t("ViewMap.MapInstructionPopUp.instuction4")}{" "}
            </Tip>
            <Tip width="100%" style={{ marginBottom: "10px" }}>
              {t("ViewMap.MapInstructionPopUp.instuction5")}
            </Tip>
          </div>
        )}
        {popupStep === 2 && (
          <div className="step-container">
            <p className="remember-title">
              {t("ViewMap.MapInstructionPopUp.remember")}:
            </p>
            <hr />
            {/* <Tip width="100%" style={{ marginBottom: "20px" }}>
              {t("ViewMap.MapInstructionPopUp.instuction6")}{" "}
              <FontAwesomeIcon icon={faRoadBarrier} />
              {t("ViewMap.MapInstructionPopUp.instuction7")}
            </Tip>
            <Tip width="100%" style={{ marginBottom: "10px" }}>
              {t("ViewMap.MapInstructionPopUp.instuction8")}
            </Tip> */}
            <Tip width="100%" style={{ marginBottom: "20px" }}>
              {t("ViewMap.MapInstructionPopUp.instuction9-part1")}{" "}
              <FontAwesomeIcon icon={faRoadBarrier} />
              {t("ViewMap.MapInstructionPopUp.instuction9-part2")}
            </Tip>
            <Tip width="100%" style={{ marginBottom: "10px" }}>
              {t("ViewMap.MapInstructionPopUp.instuction10-part1")}{" "}
              <FontAwesomeIcon icon={faLocationDot} />
              {t("ViewMap.MapInstructionPopUp.instuction10-part2")}
            </Tip>
            <Tip width="100%" style={{ marginBottom: "10px" }}>
              {t("ViewMap.MapInstructionPopUp.instuction11")}
            </Tip>
          </div>
        )}
        <div className="buttons-container flex">
          {popupStep !== 1 && (
            <PopupButton
              onClick={() => setPopupStep((prevState) => prevState - 1)}
            >
              {t("Common:back")}
            </PopupButton>
          )}
          {popupStep !== 2 ? (
            <PopupButton
              onClick={() => setPopupStep((prevState) => prevState + 1)}
            >
              {t("Common:next")}
            </PopupButton>
          ) : (
            <PopupButton onClick={close}>
              {t("ViewMap.MapInstructionPopUp.show-map")}
            </PopupButton>
          )}
        </div>
      </div>
    </Popup>
  );
};
