import "./small-title.scss";

export const SmallTitle = ({ className, error, children, ...props }) => {
  return (
    <div
      className={"input-title-container " + (className || "")}
      style={{ ...props.style, marginBottom: error ? "0" : "" }}
      {...props}
    >
      <span className={"title" + (error ? " error" : "")}>{children}</span>
    </div>
  );
};
