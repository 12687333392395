//Validate selected rating is between 1-7 (by default)
export const validateRating = (
  showError,
  setShowError,
  obj,
  min = 1,
  max = 7
) => {
  const copiedShowError = [...showError];
  let coppiedObj = { ...obj };
  if (obj.hasOwnProperty("OtherName") && !obj.OtherName) {
    delete coppiedObj.OtherName;
    delete coppiedObj.OtherEvaluation;
  }
  const elementsArr = Object.entries(coppiedObj);
  elementsArr.forEach((infoElement, index) => {
    if (infoElement[0] == "OtherName") {
      copiedShowError[index] = false;
    } else {
      if (infoElement[1] < min || infoElement[1] > max)
        copiedShowError[index] = true;
      else copiedShowError[index] = false;
    }
  });

  //   console.log("copiedShowError", copiedShowError);
  setShowError(copiedShowError);
  //check if array values has any error
  return !copiedShowError.some((c) => c);
};
