import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeCategoryNavigationMenuOpen } from "../../../../../../store/map/map-slice";
import { CategoryNavigationMenu } from "./category-navigation-menu/category-navigation-menu";
import { StepsProgress } from "./steps-progress/steps-progress";
import "./bottom-map-details.scss";

export const BottomMapDetails = ({ info, shareMode }) => {
  const dispatch = useDispatch();
  const { mapLottieViews, categoryNavigationMenuOpen } = useSelector(
    (state) => ({
      mapLottieViews: state.mapReducer.mapLottieViews,
      categoryNavigationMenuOpen: state.mapReducer.categoryNavigationMenuOpen,
    })
  );

  useEffect(() => {
    return () => {
      if (changeCategoryNavigationMenuOpen)
        dispatch(changeCategoryNavigationMenuOpen(false));
    };
  }, []);

  return (
    <div className="bottom-details-container">
      <CategoryNavigationMenu
        name={info.CategoryName}
        shareMode={shareMode}
        nextMapId={info.nextMapId}
        previousMapId={info.previousMapId}
        className={
          !shareMode && !mapLottieViews.loaded && !categoryNavigationMenuOpen
            ? "hidden"
            : ""
        }
      />
      <StepsProgress
        currentStep={
          info?.Steps ? info.Steps.filter((step) => step.Completed).length : 0
        }
        shareMode={shareMode}
        className={
          !shareMode && !mapLottieViews.loaded && !categoryNavigationMenuOpen
            ? "hidden"
            : ""
        }
        categoryName={info.CategoryName}
      />
    </div>
  );
};
