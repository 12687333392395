import { useLottie } from "lottie-react";
import { ReactComponent as HopetimizeIcon } from "../../assets/icons/hopetimize.svg";
import BackgroundLottieFile from "../../assets/lottie-files/map/background.json";
import "./map-loader.scss";

export const MapLoader = ({ className }) => {
  const BackgroundLottieOptions = {
    animationData: BackgroundLottieFile,
    loop: false,
  };
  const { View: BackgroundLottie } = useLottie(BackgroundLottieOptions, {
    position: "absolute",
    backgroundColor: "#69e19e",
  });
  return (
    <div className="map-loader-wrapper">
      <div className="map-background">
        {BackgroundLottie}
        <div className={"loader " + (className || "")}></div>
        <div className="hopetimizer-icon-container">
          <HopetimizeIcon />;
        </div>
      </div>
    </div>
  );
};
