import { useLottie } from "lottie-react";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import BirdsLottieFile from "../../../../../assets/lottie-files/map/birds.json";
import ImagePinLottieFile from "../../../../../assets/lotties/ImagePin.json";
// import ImagePinLottieFile from "../../../../../assets/lottie-files/imagePin.json";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlankImage from "../../../../../assets/images/family.jpg";
import BackgroundLottieFile from "../../../../../assets/lottie-files/map/background.json";
// import FireoworksLottieFile from "../../../../../assets/lottie-files/map/fireworks.json";
import ConfityLottieFile from "../../../../../assets/lottie-files/map/confity.json";
import LeftBottomRockLottieFile from "../../../../../assets/lottie-files/map/left-bottom-rock.json";
import BaseLayerLottieFile from "../../../../../assets/lottie-files/map/pathway.json";
import RightBottomRockLottieFile from "../../../../../assets/lottie-files/map/right-bottom-rock.json";
import RightTopMountainLottieFile from "../../../../../assets/lottie-files/map/right-top-mountain.json";
import StepLottieFile from "../../../../../assets/lottie-files/map/step.json";
import LeftTopMountainAndSun from "../../../../../assets/lottie-files/map/sun.json";
import {
  changeCategoryNavigationMenuOpen,
  changeMapLottieViews,
} from "../../../../../store/map/map-slice";
import { StepSegmentsValues } from "../enums/map-lotties-enums";
import "./animated-map.scss";
import { StepDetails } from "./step-details/step-details";
import {
  BackgroundLottieStyle,
  BaseLayerLottieStyle,
  BirdsLottieStyle,
  FireoworksLottieStyle,
  ImagePinLottieStyle,
  LeftBottomRockLottieStyle,
  LeftTopMountainAndSunLottieStyle,
  RightBottomRockLottieStyle,
  RightTopMountainLottieStyle,
  Step1LottieStyle,
  Step2LottieStyle,
  Step3LottieStyle,
} from "./styles/styles";
import { TopHeaderDetails } from "./top-header-details/top-header-details";

export const AnimatedMap = forwardRef(
  (
    {
      shareMode,
      info,
      finishStep,
      setFinishedStepPopup,
      openClickedObstaclePopup,
    },
    ref
  ) => {
    const finishStepTimer = useRef(null);
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profileReducer.profile);
    const { mapLottieViews, categoryNavigationMenuOpen } = useSelector(
      (state) => ({
        mapLottieViews: state.mapReducer.mapLottieViews,
        categoryNavigationMenuOpen: state.mapReducer.categoryNavigationMenuOpen,
      })
    );

    const [currentStep, setCurrentStep] = useState(0);
    const [mapFinished, setMapFinished] = useState(false);

    const BackgroundLottieOptions = {
      animationData: BackgroundLottieFile,
      loop: true,
      //renderer: "canvas",
    };
    const LefttBottomRockLottieOptions = {
      animationData: LeftBottomRockLottieFile,
      loop: true,
      //renderer: "canvas",
    };
    const RightBottomRockLottieOptions = {
      animationData: RightBottomRockLottieFile,
      loop: true,
      //renderer: "canvas",
    };
    const RightTopMountainLottieOptions = {
      animationData: RightTopMountainLottieFile,
      loop: true,
      //renderer: "canvas",
    };

    const FireoworksLottieOptions = {
      animationData: ConfityLottieFile,
      loop: true,
      //renderer: "canvas",
    };

    const BirdsLottieOptions = {
      animationData: BirdsLottieFile,
      loop: true,
      //renderer: "canvas",
    };

    const StepLottieOptions = {
      animationData: StepLottieFile,
      loop: true,
      //renderer: "canvas",
    };

    const ImagePinLottieOptions = {
      animationData: ImagePinLottieFile,
      loop: true,
      assetsPath: info.Image.Path
        ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${info.Image.Path} `
        : BlankImage,
      //renderer: "canvas",
    };

    const LeftTopMountainAndSunLottieFileOptions = {
      animationData: LeftTopMountainAndSun,
      loop: true,
      //renderer: "canvas",
      rendererSettings: {
        preserveAspectRatio: "none",
      },
    };

    const onLoaded = () => {
      // console.log("onLoaded");
      dispatch(changeMapLottieViews({ ...mapLottieViews, loaded: true }));
      BaseLayerLottie.goToAndStop(mapLottieViews.initialSegment.value, true);
    };

    const completeSegment = (e) => {
      // console.log("firstFrame", BaseLayerLottie.animationItem.firstFrame);
      // console.log("currentFrame", BaseLayerLottie.animationItem.currentFrame);
      // console.log(
      //   "currentRawFrame",
      //   BaseLayerLottie.animationItem.currentRawFrame
      // );
      // console.log("timeCompleted", BaseLayerLottie.animationItem.timeCompleted);
      // console.log("totalFrames", BaseLayerLottie.animationItem.totalFrames);
      // console.log(BaseLayerLottie.animationItem.firstFrame);
      // console.log(BaseLayerLottie.animationItem.firstFrame);
      const newCurrentStep = currentStep + 1;
      if (newCurrentStep !== 3) {
        finishStepTimer.current = setTimeout(() => {
          setFinishedStepPopup({ open: true, index: newCurrentStep - 1 });
          setCurrentStep(newCurrentStep);
        }, 500);
      } else {
        finishMap();
        finishStepTimer.current = setTimeout(() => {
          setFinishedStepPopup({ open: true, index: newCurrentStep - 1 });
          setCurrentStep(newCurrentStep);
        }, 1500);
      }
    };

    const BaseLayerLottieFileOptions = {
      animationData: BaseLayerLottieFile,
      loop: false,
      autoplay: false,
      onDOMLoaded: onLoaded,
      //   initialSegment: [initialSegmentValue],
      onEnterFrame: (e1, e2, e3, e4) => {
        // console.log({ e1 });
        {
          //stop and play 2nd one
        }
      },
      onComplete: completeSegment,
      //renderer: "canvas",
      rendererSettings: {
        preserveAspectRatio: "none",
      },
    };

    const BaseLayerLottie = useLottie(
      BaseLayerLottieFileOptions,
      BaseLayerLottieStyle
    );

    const { View: BackgroundLottie } = useLottie(
      BackgroundLottieOptions,
      BackgroundLottieStyle
    );

    const { View: LeftBottomRockLottie } = useLottie(
      LefttBottomRockLottieOptions,
      LeftBottomRockLottieStyle
    );

    const { View: RightBottomRockLottie } = useLottie(
      RightBottomRockLottieOptions,
      RightBottomRockLottieStyle
    );

    const { View: RightTopMountainLottie } = useLottie(
      RightTopMountainLottieOptions,
      RightTopMountainLottieStyle
    );

    const { View: BirdsLottie } = useLottie(
      BirdsLottieOptions,
      BirdsLottieStyle
    );

    const { View: Step1Lottie } = useLottie(
      StepLottieOptions,
      Step1LottieStyle
    );
    const { View: Step2Lottie } = useLottie(
      StepLottieOptions,
      Step2LottieStyle
    );
    const { View: Step3Lottie } = useLottie(
      StepLottieOptions,
      Step3LottieStyle
    );

    const { View: ImagePinLottie } = useLottie(
      ImagePinLottieOptions,
      ImagePinLottieStyle
    );

    const { View: LeftTopMountainAndSunLottie } = useLottie(
      LeftTopMountainAndSunLottieFileOptions,
      LeftTopMountainAndSunLottieStyle
    );

    const { View: FireoworksLottie } = useLottie(
      FireoworksLottieOptions,
      FireoworksLottieStyle
    );

    useEffect(() => {
      BaseLayerLottie.setSubframe(false);
      const stepNum = info.Steps.filter((step) => step.Completed).length;
      setCurrentStep(stepNum);
      // setCurrentCategoryColor();
      if (stepNum === 3) finishMap();

      // Clear the interval when the component unmounts
      return () => {
        clearTimeout(finishStepTimer.current);
        dispatch(
          changeMapLottieViews({
            ...mapLottieViews,
            initialSegment: {
              initialized: false,
              value: 0,
            },
            loaded: false,
          })
        );
      };
    }, []);

    useImperativeHandle(ref, () => ({
      moveToNextStep,
    }));

    const handleClickedObstacle = (index) => {
      // console.log("handleClickedObstacle", index);
      if (
        !shareMode &&
        !info.Archived &&
        !info.Completed &&
        !info.Steps[index].Completed &&
        index === currentStep
      ) {
        var dontShowClickedObstaclePopup = localStorage.getItem(
          "dontShowClickedObstaclePopup"
        );
        switch (index) {
          case 0:
            if (dontShowClickedObstaclePopup) finishStep(index);
            else openClickedObstaclePopup(index);
            break;
          case 1:
            if (info.Steps[0].Completed) {
              if (dontShowClickedObstaclePopup) finishStep(index);
              else openClickedObstaclePopup(index);
            }
            break;
          case 2:
            if (info.Steps[0].Completed && info.Steps[1].Completed) {
              if (dontShowClickedObstaclePopup) finishStep(index);
              else openClickedObstaclePopup(index);
            }
            break;
          default:
            break;
        }
      }
    };

    const moveToNextStep = async (index) => {
      // console.log("move to", index);
      switch (index) {
        case 0:
          BaseLayerLottie.playSegments(
            [StepSegmentsValues.Step1.Start, StepSegmentsValues.Step2.Start],
            true
          );
          break;
        case 1:
          if (info.Steps[0].Completed)
            BaseLayerLottie.playSegments(
              [StepSegmentsValues.Step2.Start, StepSegmentsValues.Step3.Start],
              true
            );
          break;
        case 2:
          if (info.Steps[0].Completed && info.Steps[1].Completed)
            BaseLayerLottie.playSegments(
              [StepSegmentsValues.Step3.Start, StepSegmentsValues.Step3.End],
              true
            );
          break;
      }
    };

    const finishMap = async () => {
      setMapFinished(true);
    };

    const closeCategoryNavigationMenu = () => {
      if (categoryNavigationMenuOpen)
        dispatch(changeCategoryNavigationMenuOpen(false));
    };

    return (
      <div
        className="animated-map-container"
        onClick={closeCategoryNavigationMenu}
      >
        {/* Lotties */}
        <div className={categoryNavigationMenuOpen ? "fade-background" : ""}>
          {BackgroundLottie}
        </div>
        <div className="left-bottom-rock">{LeftBottomRockLottie}</div>
        <div className="rigth-bottom-rock">{RightBottomRockLottie}</div>
        {RightTopMountainLottie}
        {BaseLayerLottie.View}
        {LeftTopMountainAndSunLottie}
        {BirdsLottie}
        {Step1Lottie}
        {Step2Lottie}
        {Step3Lottie}
        {ImagePinLottie}
        <div className={!mapFinished ? " hidden" : ""}>{FireoworksLottie}</div>

        <TopHeaderDetails
          firstName={!shareMode ? profile?.FirstName : info.User.FirstName}
          mainGoal={info.MainGoal}
          className={!mapLottieViews.loaded ? "hidden" : ""}
          shareMode={shareMode}
          mapFinished={mapFinished}
          mapArchived={info.Archived}
        />

        {/* STEP 1 Details */}
        <StepDetails
          index={0}
          step={info.Steps[0]}
          handleClickedObstacle={handleClickedObstacle}
          className={!mapLottieViews.loaded ? "hidden" : ""}
          shareMode={shareMode}
        />

        {/* STEP 2 Details */}
        <StepDetails
          index={1}
          step={info.Steps[1]}
          stepLottie={Step2Lottie}
          handleClickedObstacle={handleClickedObstacle}
          className={!mapLottieViews.loaded ? "hidden" : ""}
          shareMode={shareMode}
        />

        {/* STEP 3 Details */}
        <StepDetails
          index={2}
          step={info.Steps[2]}
          stepLottie={Step3Lottie}
          handleClickedObstacle={handleClickedObstacle}
          className={!mapLottieViews.loaded ? "hidden" : ""}
          shareMode={shareMode}
        />
      </div>
    );
  }
);
