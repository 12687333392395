import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Input } from "../../../../../../components/input/input";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { RatingSlider } from "../../../../../../components/rating-slider/rating-slider";
import { SmallTitle } from "../../../../../../components/small-title/small-title";
import { setShowLoader } from "../../../../../../store/app-settings/app-settings-slice";
import Api from "../../../../../../utils/api";
import "./add-custom-category-popup.scss";
import { useTranslation } from "react-i18next";

export const AddCustomCategoryPopup = ({ getCategories, close }) => {
  const { t, i18n } = useTranslation("Maps");

  const dispatch = useDispatch();
  const categorySliderRef = useRef();
  const [category, setCategory] = useState({
    Name: "",
    Evaluation: 0,
  });
  const [isCategoryNameError, setIsCategoryNameError] = useState(false);
  const [isCategoryEvaluationError, setIsCategoryEvaluationError] =
    useState(false);

  const handleChangeSlider = (value, name) => {
    setCategory({
      ...category,
      [name]: value,
    });
  };

  const validate = () => {
    let isValidate = true;
    if (!category.Name) {
      setIsCategoryNameError(true);
      isValidate = false;
    }
    if (category.Evaluation === 0) {
      setIsCategoryEvaluationError(true);
      isValidate = false;
    }
    return isValidate;
  };

  const saveNewCustomCategory = async () => {
    if (!validate()) return;
    close();
    dispatch(setShowLoader(true));
    try {
      const res = await Api.post("user/categories/custom", {
        Name: category.Name,
        Evaluation: category.Evaluation,
      });
      getCategories();
      close();
      dispatch(setShowLoader(false));
    } catch (error) {
      console.log(error);
      dispatch(setShowLoader(false));
    }
  };

  return (
    <Popup height="350px" close={close}>
      <div className="add-custom-category-popup-container flex-popup-container">
        <PopupTitle>{t("SelectCatagory.add-category-title")}</PopupTitle>
        <div className="category-input-container">
          <SmallTitle>{t("SelectCatagory.enter-category-name")}</SmallTitle>
          <Input
            type="text"
            name="categoryName"
            onChange={(e) =>
              setCategory({
                ...category,
                Name: e.target.value,
              })
            }
            value={category.Name}
            maxLength={15}
            isError={isCategoryNameError}
          />
          <SmallTitle>
            {t("SelectCatagory.enter-category-evaluation")}
          </SmallTitle>
          <RatingSlider
            min={0}
            max={8}
            value={category.Evaluation}
            changeHandler={handleChangeSlider}
            name="Evaluation"
            isError={isCategoryEvaluationError}
            ref={(el) => (categorySliderRef.current = el)}
          />
        </div>
        <PopupButton className="save-button" onClick={saveNewCustomCategory}>
          {t("Common:save")}
        </PopupButton>
      </div>
    </Popup>
  );
};
