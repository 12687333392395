import { ReactComponent as XIcon } from "../../../assets/icons/x.svg";

import "./contacts-upload-popup.scss";

export const ContactUploadPopup = ({ children, close }) => {
  return (
    <div className="contacts-modal-content">
      {close && <XIcon className="contacts-close-icon" onClick={close} />}
      {children}
    </div>
  );
};
