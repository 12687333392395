import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import {
  changeCategoryNavigationMenuOpen,
  changeIsLoadingMap,
} from "../../../../../../../store/map/map-slice";
import "./category-navigation-menu.scss";
import { useTranslation } from "react-i18next";

export const CategoryNavigationMenu = ({
  name,
  shareMode,
  nextMapId,
  previousMapId,
  ...props
}) => {
  const { t, i18n } = useTranslation("Maps");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { footerMenuOpen, categoryNavigationMenuOpen } = useSelector(
    (state) => ({
      footerMenuOpen: state.mapReducer.footerMenuOpen,
      categoryNavigationMenuOpen: state.mapReducer.categoryNavigationMenuOpen,
    })
  );

  const transitions = useTransition(categoryNavigationMenuOpen, {
    from: {
      position: "relative",
      opacity: 0,
      width: 0,
      height: 40,
    },
    enter: { opacity: 1, width: 150, height: 40 },
    leave: { opacity: 0, width: 0, height: 40 },
  });

  useEffect(() => {
    return () => {
      if (changeCategoryNavigationMenuOpen)
        dispatch(changeCategoryNavigationMenuOpen(false));
    };
  }, []);

  const navigateToPreviousMap = async (e) => {
    e.stopPropagation();
    dispatch(changeIsLoadingMap(true));
    navigate(`../maps/view/${previousMapId}`);
  };

  const navigateToNextMap = async (e) => {
    e.stopPropagation();
    dispatch(changeIsLoadingMap(true));
    navigate(`../maps/view/${nextMapId}`);
  };

  return (
    <div
      className={
        "category-navigation-menu-container" +
        (props.className ? ` ${props.className}` : "")
      }
      style={{ bottom: footerMenuOpen && !shareMode ? "60px" : "0" }}
    >
      <div className="top-ellipse"></div>
      <div className="category-name-conatiner flex">
        <div
          className="circle"
          onClick={() => {
            if (!shareMode) {
              dispatch(
                changeCategoryNavigationMenuOpen(!categoryNavigationMenuOpen)
              );
            }
          }}
        ></div>
        <div
          className="name-circle flex"
          onClick={() => {
            if (!shareMode) {
              dispatch(
                changeCategoryNavigationMenuOpen(!categoryNavigationMenuOpen)
              );
            }
          }}
        >
          <button
            className={
              "category-name-button" +
              (!categoryNavigationMenuOpen ? " menu-close" : "")
            }
          >
            <span
              className="text"
              style={{
                color: name === "Self & Wellbeing" ? "white" : "",
                fontWeight: name === "Self & Wellbeing" ? "normal" : "",
              }}
            >
              {[
                "Education",
                "Family",
                "Financial",
                "Health",
                "Legacy",
                "Leisure",
                "Professional/Career",
                "Self & Wellbeing",
                "Social & Community",
                "Spiritual",
              ].includes(name)
                ? t(`Common:Categories.${name}`)
                : name}
            </span>
          </button>
          {transitions(
            ({ width, height }, item) =>
              item && (
                <animated.div
                  style={{ width: width, height: height }}
                  className="category-nanvigation-buttons-container flex"
                >
                  <button
                    className={
                      "button flex" +
                      (!categoryNavigationMenuOpen ? " hidden" : "") +
                      (!previousMapId ? " disabled" : "")
                    }
                    onClick={navigateToPreviousMap}
                  >
                    <FontAwesomeIcon className="icon" icon={faArrowLeft} />
                    &nbsp;{t("ViewMap.AnimatedMap.prev")}
                  </button>
                  <button
                    className={
                      "button flex" +
                      (!categoryNavigationMenuOpen ? " hidden" : "") +
                      (!nextMapId ? " disabled" : "")
                    }
                    onClick={navigateToNextMap}
                  >
                    {t("ViewMap.AnimatedMap.next")}&nbsp;
                    <FontAwesomeIcon className="icon" icon={faArrowRight} />
                  </button>
                </animated.div>
              )
          )}
        </div>
      </div>
      <div className="bottom-center"></div>
      <div className="bottom-center"></div>
    </div>
  );
};
