import React from "react";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ExpertCloud from "../../../../assets/images/expert-cloud.png";
import MasterCloud from "../../../../assets/images/master-cloud.png";
import LegendCloud from "../../../../assets/images/legend-cloud.png";
import ChampionCloud from "../../../../assets/images/champion-cloud.png";
import ExpertIcon from "../../../../assets/images/expert-status-icon.png";
import LegendIcon from "../../../../assets/images/legend-status-icon.png";
import ExpertDiamond from "../../../../assets/images/expert-diamond.png";
import MasterDiamond from "../../../../assets/images/master-diamond.png";
import LegendDiamond from "../../../../assets/images/legend-diamond.png";
import ChampionDiamond from "../../../../assets/images/champion-diamond.png";

import "./status-level-up-popup.scss";
import { useTranslation } from "react-i18next";
import { setLevelUpPopupOpen } from "../../../../store/app-settings/app-settings-slice";

export const StatusLevelUpPopup = () => {
  const { t, i18n } = useTranslation("PopUps");
  const dispacth = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);

  return (
    <div className="status-level-up-popup-container">
      <Popup
        height={"400px"}
        close={() => {
          dispacth(setLevelUpPopupOpen(false));
        }}
      >
        <div className="header-container flex">
          <PopupTitle>{t("Badges.good-work")}</PopupTitle>
          <PopupTitle small={true}>{t("Badges.your-new-status")}</PopupTitle>
        </div>
        <div className="status-text">
          {profile?.Points < 1001 ? (
            <h4 style={{ color: "#609EBA" }}>{t("Badges.expert")}</h4>
          ) : profile?.Points > 1000 && profile?.Points < 2501 ? (
            <h4 style={{ color: "#C457BD" }}>{t("Badges.master")}</h4>
          ) : profile?.Points > 2500 && profile?.Points < 5001 ? (
            <h4 style={{ color: "#B5E182" }}>{t("Badges.legend")}</h4>
          ) : (
            <h4 style={{ color: "#E2A728" }}>{t("Badges.champion")}</h4>
          )}
        </div>
        {profile?.Points < 1001 ? (
          <div className="cloud-icon-container-separate">
            <div>
              <img src={ExpertCloud} className="cloud" />
              <img src={ExpertIcon} className="icon-expert" />
            </div>
          </div>
        ) : profile?.Points > 1000 && profile?.Points < 2501 ? (
          <div className="cloud-icon-container">
            <img src={MasterCloud} className="cloud" />
          </div>
        ) : profile?.Points > 2500 && profile?.Points < 5001 ? (
          <div className="cloud-icon-container-separate">
            <img src={LegendCloud} className="cloud" />
            <img src={LegendIcon} className="icon-legend" />
          </div>
        ) : (
          <div className="cloud-icon-container">
            <img src={ChampionCloud} className="cloud" />
          </div>
        )}
        <div className="points-info">
          <span>
            {profile?.Points < 1001 ? (
              <h5>{t("Badges.expert")}:</h5>
            ) : profile?.Points > 1000 && profile?.Points < 2501 ? (
              <h5>{t("Badges.master")}:</h5>
            ) : profile?.Points > 2500 && profile?.Points < 5001 ? (
              <h5>{t("Badges.legend")}:</h5>
            ) : (
              <h5>{t("Badges.champion")}:</h5>
            )}
            <img
              src={
                profile?.Points < 1001
                  ? ExpertDiamond
                  : profile?.Points > 1000 && profile?.Points < 2501
                  ? MasterDiamond
                  : profile?.Points > 2500 && profile?.Points < 5001
                  ? LegendDiamond
                  : ChampionDiamond
              }
              className="status-icon"
            />{" "}
            <h5>{profile?.Points}</h5>
          </span>
        </div>
      </Popup>
    </div>
  );
};
