import { faImages, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import Api from "../../utils/api";
import { NativeEvents } from "../../utils/enums";
import { PopupButton } from "../popup-button/popup-button";
import { Popup } from "../popup/popup";
import { PopupTitle } from "../popup/popup-title/popup-title";
import { GalleryPopup } from "./gallery-popup/gallery-popup";
import "./image-upload.scss";
import AWS from "aws-sdk";
import { setShowLoader } from "../../store/app-settings/app-settings-slice";
import { useDispatch } from "react-redux";

export const ImageUpload = ({
  mapId,
  defaultImageSrc,
  changeImage,
  saveImageUrl,
}) => {
  const { t } = useTranslation("Maps");
  const dispatch = useDispatch();

  const uploadFromDeviceImageRef = useRef();
  const processedImageRef = useRef();

  const [image, setImage] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const [isChosenFromGallery, setIsChosenFromGallery] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [scale, setScale] = useState(1);
  const [
    cameraAndMediaLibraryPermissionGranted,
    setCameraAndMediaLibraryPermissionGranted,
  ] = useState(false);

  useEffect(() => {
    document.addEventListener(
      "message",
      checkCameraAndMediaLibraryPermissionResponse
    );

    return () => {
      document.removeEventListener(
        "message",
        checkCameraAndMediaLibraryPermissionResponse
      );
    };
  }, []);

  useEffect(() => {
    if (defaultImageSrc) setImageSrc(defaultImageSrc);
  }, [defaultImageSrc]);

  const checkCameraAndMediaLibraryPermissionResponse = (message) => {
    if (message.data.cameraAndMediaLibraryPermissionGranted) {
      setCameraAndMediaLibraryPermissionGranted(true);
      setPopupIsOpen(true);
    }
  };

  const handleImageChange = (el) => {
    if (!el.target.files.length) return;
    setImage(el.target.files[0]);
    setImageSrc(URL.createObjectURL(el.target.files[0]));
    setIsChosenFromGallery(false);
  };

  const save = async () => {
    try {
      dispatch(setShowLoader(true));
      if (!imageSrc) {
        if (!defaultImageSrc) {
          setPopupIsOpen(false);
        }
        // remove existing image from map / draft map
        else if (mapId)
          await Api.delete(`user/maps/${mapId}/image`); // edit map
        else await Api.delete(`user/profile/draftMap/image`); // draft map
        changeImage("", false);
        setPopupIsOpen(false);
      } else {
        const imageData = {
          FromGallery: isChosenFromGallery,
        };
        var tempImage = null;
        if (isChosenFromGallery) {
          const response = await axios.get(imageSrc, {
            responseType: "arraybuffer",
          });
          tempImage = response.data;
        }
        let res = await Api.put(saveImageUrl, imageData);
        const data = res.data.payload;
        const formDataValues = new FormData();

        for (const key in data.fields)
          formDataValues.append(key, data.fields[key]);
        const newImageSrc = data.fields["key"];
        setImageSrc(newImageSrc);
        const imagePreview = processedImageRef.current
          .getImageScaledToCanvas()
          .toDataURL();

        const blob = await new Promise((resolve) => {
          fetch(imagePreview)
            .then((res) => res.blob())
            .then((blob) => resolve(blob));
        });

        formDataValues.append("Content-type", "image/png");
        formDataValues.append("file", blob);

        AWS.config.update({
          accessKeyId: "GIXELX3YIRN2WAEMMTIV",
          secretAccessKey: "KulhEtUp3litlrId3zuImv8cD3iWnRohael1zvkdFVk",
          endpoint: new AWS.Endpoint("https://fra1.digitaloceanspaces.com"),
        });

        const s3 = new AWS.S3();

        const bucketName = "tlechaim-static";
        const key = data.fields["key"];

        const params = {
          Bucket: bucketName,
          Key: key,
          Body: tempImage ? tempImage : image,
          ACL: "public-read",
        };

        await s3.upload(params).promise();
        changeImage(newImageSrc, isChosenFromGallery);
        dispatch(setShowLoader(false));
        setPopupIsOpen(false);
      }
    } catch (err) {
      dispatch(setShowLoader(false));
    }
  };

  const cancel = () => {
    setImageSrc(defaultImageSrc);
    setPopupIsOpen(false);
  };

  const removeImage = () => {
    setImageSrc("");
  };

  const chooseFileFromDevice = () => {
    if (window.ReactNativeWebView) {
      if (cameraAndMediaLibraryPermissionGranted) {
        uploadFromDeviceImageRef.current.click();
      } else if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            event: NativeEvents.RequestCameraAndMediaLibraryPermissions,
          })
        );
      }
    } else {
      uploadFromDeviceImageRef.current.click();
    }
  };
  return (
    <div className="image-upload-container flex-popup-container">
      {!defaultImageSrc && (
        <div className="choose-image flex" onClick={() => setPopupIsOpen(true)}>
          <PlusIcon />
          <div className="text">{t("Common:choose-an-image")}</div>
        </div>
      )}

      <input
        type="file"
        name="Image"
        accept="image/*"
        onChange={handleImageChange}
        className="hidden-file-input"
        ref={uploadFromDeviceImageRef}
        onClick={(e) => {
          e.target.value = null;
        }}
      />

      {defaultImageSrc && (
        <img
          className="selected-image"
          src={defaultImageSrc}
          onClick={() => setPopupIsOpen(true)}
        />
      )}

      {galleryOpen && (
        <GalleryPopup
          onClose={() => setGalleryOpen(false)}
          onSelect={(img) => {
            setImageSrc(img);
            setGalleryOpen(false);
            setIsChosenFromGallery(true);
          }}
          galleryUrl={"/gallery"}
        ></GalleryPopup>
      )}
      {popupIsOpen && (
        <Popup close={cancel}>
          <PopupTitle>{t("ImageUpload.choose-image")}</PopupTitle>
          <br />
          <div className="header-buttons-container flex">
            <button
              className="font-button"
              onClick={() => setGalleryOpen(true)}
            >
              <FontAwesomeIcon icon={faImages} />
              &nbsp;{t("ImageUpload.gallery")}
            </button>
            <button
              className="font-button"
              onClick={() => chooseFileFromDevice()}
            >
              <FontAwesomeIcon icon={faUpload} />
              &nbsp;{t("Common:file")}
            </button>
            {imageSrc && (
              <button className="font-button" onClick={removeImage}>
                <FontAwesomeIcon icon={faTrash} />
                &nbsp;{t("Common:remove")}
              </button>
            )}
          </div>
          <br />
          <div className="avatar-container flex">
            <AvatarEditor
              crossOrigin="anonymous"
              image={imageSrc}
              ref={processedImageRef}
              width={250}
              height={250}
              borderRadius={150}
              color={[241, 241, 241, 0.6]} // RGBA
              scale={scale}
              rotate={0}
            />
            <input
              type="range"
              step="0.001"
              min="0.5"
              max="3"
              onChange={(el) => setScale(Number(el.target.value))}
              value={scale}
            />
          </div>

          <div className="flex image-upload-buttons">
            <PopupButton onClick={cancel}>{t("Common:cancel")}</PopupButton>
            <PopupButton onClick={save}>{t("Common:save")}</PopupButton>
          </div>
        </Popup>
      )}
    </div>
  );
};
