import { useState } from "react";
import { PopupButton } from "../../../../components/popup-button/popup-button";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import { ABitStuck } from "./a-bit-stuck/a-bit-stuck";
import { MovingAlong } from "./moving-along/moving-along";
import "./hows-your-journey-going.scss";
import { useTranslation } from "react-i18next";

export const HowsYourJourneyGoingPopup = ({ close }) => {
  const { t, i18n } = useTranslation("PopUps");

  const [openMovingAlongPopup, setOpenDidGoodPopup] = useState(false);
  const [aBitStuckPopup, setABitStuckPopup] = useState(false);

  return (
    <Popup>
      {!openMovingAlongPopup && !aBitStuckPopup && (
        <div className="booster-questions-container">
          <div className="headers flex">
            <PopupTitle small={true}>
              {t("HowIsYourJourneyGoing.how-is-your")}
            </PopupTitle>
            <PopupTitle>{t("HowIsYourJourneyGoing.journey-going")}</PopupTitle>
          </div>
          <div className="footer-buttons flex">
            <PopupButton onClick={() => setOpenDidGoodPopup(true)}>
              {t("HowIsYourJourneyGoing.moving-along")}
            </PopupButton>
            <PopupButton onClick={() => setABitStuckPopup(true)}>
              {t("HowIsYourJourneyGoing.stuck")}
            </PopupButton>
          </div>
        </div>
      )}
      {openMovingAlongPopup && <MovingAlong close={close} />}
      {aBitStuckPopup && <ABitStuck close={close} />}
    </Popup>
  );
};
