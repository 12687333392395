import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  changeMapLottieViews,
  changeStepDetailsPopup,
  changeViewMainGoalPopup,
} from "../../../store/map/map-slice";
import Api from "../../../utils/api";
import {
  setCurrentCategoryColor,
  updateInitialSegment,
} from "../../../utils/mapFunc";
import { AnimatedMap } from "../../user/maps/view-map/animated-map/animated-map";
import { BottomMapDetails } from "../../user/maps/view-map/animated-map/bottom-map-details/bottom-map-details";
import { StepDetailsPopup } from "../../user/maps/view-map/popups/step-details-popup/step-details-popup";
import { ViewMainGoalPopup } from "../../user/maps/view-map/popups/view-main-goal-popup/view-main-goal-popup";
import "./view-shared.map.scss";

export const ViewSharedMap = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { mapLottieViews, stepDetailsPopup, viewMainGoalPopup } = useSelector(
    (state) => ({
      mapLottieViews: state.mapReducer.mapLottieViews,
      stepDetailsPopup: state.mapReducer.stepDetailsPopup,
      viewMainGoalPopup: state.mapReducer.viewMainGoalPopup,
    })
  );

  const [sharedMap, setSharedMap] = useState({});

  useEffect(() => {
    const getSharedMap = async () => {
      try {
        const res = await Api.get(`user/maps/share/${params.sharedMapId}`);
        if (res.data) {
          setSharedMap(res.data.payload);
        }
      } catch (error) {}
    };

    if (params.sharedMapId) {
      getSharedMap();
    }
  }, []);

  const setMapInitialLottieSegment = (data) => {
    dispatch(changeMapLottieViews({ ...mapLottieViews, initialSegment: data }));
  };

  useEffect(() => {
    if (sharedMap?.Steps && !mapLottieViews.initialSegment.updated) {
      updateInitialSegment(sharedMap.Steps, setMapInitialLottieSegment);
      setCurrentCategoryColor(sharedMap.CategoryName);
    }
  }, [sharedMap]);

  return (
    <div className="view-shared-map-container">
      {viewMainGoalPopup.open && (
        <ViewMainGoalPopup
          goal={viewMainGoalPopup.goal}
          close={() =>
            dispatch(changeViewMainGoalPopup({ open: false, goal: "" }))
          }
        />
      )}

      {stepDetailsPopup.open && (
        <StepDetailsPopup
          watchMode={true}
          index={stepDetailsPopup.index}
          info={sharedMap}
          shareMode={true}
          close={() =>
            dispatch(
              changeStepDetailsPopup({
                open: false,
                index: 0,
              })
            )
          }
        />
      )}

      {mapLottieViews.initialSegment.updated && (
        <AnimatedMap shareMode={true} info={sharedMap} />
      )}
      <BottomMapDetails info={sharedMap} shareMode={true} />
    </div>
  );
};
