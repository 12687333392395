import { PopupButton } from "../../../../../components/popup-button/popup-button";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import { SpeechBubble } from "../../../../../components/speech-bubble/speech-bubble";
import "./moving-along.scss";
import { useTranslation } from "react-i18next";

export const MovingAlong = ({ close }) => {
  const { t, i18n } = useTranslation("PopUps");

  return (
    <div className="moving-along-popup-container">
      <div className="headers flex">
        <PopupTitle small={true}>{t("MovingAlong.great")}</PopupTitle>
        <PopupTitle>{t("MovingAlong.keep-it-up")}</PopupTitle>
      </div>
      <SpeechBubble
        direction={"right"}
        top={"87px"}
        left={"25px"}
        width={"190px"}
        height={"85px"}
      >
        <p>{t("MovingAlong.research")}</p>
        <p>{t("MovingAlong.engaged")}</p>
        <p>{t("MovingAlong.build-hope")}</p>
      </SpeechBubble>
      <div className="footer-button-container flex">
        <PopupButton className="footer-button" onClick={close}>
          {t("Common:continue-journey")}
        </PopupButton>
      </div>
    </div>
  );
};
