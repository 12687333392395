import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessageBlock } from "../../../components/message-block/message-block";
import {
  setShowLoader,
  setTitle,
} from "../../../store/app-settings/app-settings-slice";
import Api from "../../../utils/api";
import "./messages.scss";
import { useTranslation } from "react-i18next";

export const Messages = () => {
  const { t, i18n } = useTranslation("Messages");

  const dispatch = useDispatch();
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    dispatch(setTitle(t("title")));
    getUserMessages();
  }, [i18n]);

  const getUserMessages = async () => {
    try {
      dispatch(setShowLoader(true));
      const res = await Api.get("user/profile/messages/all");
      if (res.data) {
        setMessages(res.data.payload);
      }
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
    }
  };

  return (
    <div className="messages-container">
      <div className="screen-container">
        <div className="chat">
          <div className="chat-container">
            <div className="conversation">
              <div className="conversation-container">
                {!showLoader && (
                  <>
                    {messages.length > 0 ? (
                      messages.map((message, index) => {
                        return (
                          <React.Fragment key={message.Id}>
                            <MessageBlock
                              index={index}
                              subject={message.Subject}
                              body={message.Body}
                              date={message.Date}
                            />
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <p className="description flex">{t("none")}</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
