import React from "react";
import "./tip.scss";
import { ReactComponent as VCircle } from "../../assets/icons/v-circle.svg";

export const Tip = ({ width, hideVCircle = false, children, ...props }) => {
  return (
    <div className="tip-container flex" style={width && { width }} {...props}>
      {!hideVCircle && <VCircle width={47} height={47} />}
      <p className={"text" + (hideVCircle ? " all-line" : "")}>{children}</p>
    </div>
  );
};
