import { forwardRef } from "react";
import { Input } from "../../../../../../components/input/input";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Tip } from "../../../../../../components/tip/tip";
import "./create-inspiration-step.scss";
import { useTranslation } from "react-i18next";

export const CreateInspirationStep = forwardRef(
  (
    { inspiration, setBooster, showError, navigateBack, navigateToNextStep },
    ref
  ) => {
    const { t, i18n } = useTranslation("PopUps");

    return (
      <div className="create-inspiration-step-container">
        <div className="background-image" />
        <div className="content">
          <div className="header">
            {t("CreateInpiration.stay-motivated")}{" "}
            <span className="examples">{t("CreateInpiration.motivators")}</span>
          </div>

          <Input
            type="text"
            name="need"
            value={inspiration}
            width="265px"
            maxHeight={50}
            onChange={(e) =>
              setBooster((prevState) => {
                return {
                  ...prevState,
                  Inspiration: e.target.value,
                };
              })
            }
            // placeholder="My motivation..."
            isError={showError}
            ref={ref}
          />

          <div className="header">Tip</div>
          <Tip width="265px">{t("CreateInpiration.tip")}</Tip>
        </div>
        <div className="footer-buttons flex">
          <PopupButton
            className="create-inspiration-button "
            onClick={navigateBack}
          >
            {t("Common:back")}
          </PopupButton>
          <PopupButton
            className="create-inspiration-button "
            onClick={navigateToNextStep}
          >
            {t("Common:OK")}
          </PopupButton>
        </div>
      </div>
    );
  }
);
