import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../../components/button/button";
import { Checkbox } from "../../../components/checkbox/checkbox";
import { Loader } from "../../../components/loader/loader";
import { showToastMessage } from "../../../store/app-settings/app-settings-slice";
import Api from "../../../utils/api";
import "./remove-notifications.scss";
import { useTranslation } from "react-i18next";

export const RemoveNotifications = () => {
  const { t, i18n } = useTranslation("ProfileSettings");

  const params = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, seErrorOccurred] = useState(false);
  const [options, setOptions] = useState({
    Email: true,
    Mobile: true,
    Newsletter: true,
  });

  useEffect(() => {
    if (params.userExternalId) {
      setIsLoading(true);
      getUserNotifications();
    } else {
      seErrorOccurred(true);
    }

    // if (params.userExternalId) {
    //   sendRemoveNotificationsRequest();
    // }
  }, []);

  const getUserNotifications = async () => {
    try {
      const res = await Api.get(
        `user/profile/notifications/${params.userExternalId}`
      );
      if (res.data) {
        setIsLoading(false);
        setOptions(res.data.payload);
      } else {
        seErrorOccurred(true);
      }
    } catch (error) {
      seErrorOccurred(true);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const sendUpdateNotificationsRequest = async () => {
    try {
      const res = await Api.put(
        `user/profile/notifications/${params.userExternalId}`,
        options
      );
      if (res.data) {
        setIsLoading(false);
        dispatch(
          showToastMessage({
            text: t("change-notification-status-confirm"),
            type: toast.TYPE.SUCCESS,
          })
        );
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onValueChange = (e) => {
    console.log(e.target.id);
    console.log(e.target.value);
    setOptions({
      ...options,
      [e.target.id]: !options[e.target.id],
    });
  };

  return (
    <div className="remove-notifications-container">
      {/* {!isLoading ? <p>{responseMessage}</p> : <Loader className="page" />} */}
      {isLoading ? (
        <Loader className="page" />
      ) : (
        <>
          {errorOccurred ? (
            <p className="flex">Error Occurred</p>
          ) : (
            <>
              <p>Choose your notifications preferences</p>
              <div className="checkboxes-container flex">
                {Object.keys(options).map((option) => {
                  return (
                    <Checkbox
                      id={option}
                      label={option}
                      value={option}
                      checked={options[option]}
                      onChange={onValueChange}
                    />
                  );
                })}
              </div>
              <Button
                className="save-button"
                onClick={sendUpdateNotificationsRequest}
              >
                Save
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};
