import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopupButton } from "../../../../components/popup-button/popup-button";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import ExpertIcon from "../../../../assets/images/expert-new.png";
import MasterIcon from "../../../../assets/images/master-new.png";
import LegendIcon from "../../../../assets/images/legend-new.png";
import ChampionIcon from "../../../../assets/images/champion-new.png";
import ExpertDiamond from "../../../../assets/images/expert-diamond.png";
import MasterDiamond from "../../../../assets/images/master-diamond.png";
import LegendDiamond from "../../../../assets/images/legend-diamond.png";
import ChampionDiamond from "../../../../assets/images/champion-diamond.png";
import "./badges-popup.scss";
import { useTranslation } from "react-i18next";
import Api from "../../../../utils/api";
import { useEffect } from "react";

export const BadgesPopup = ({ close }) => {
  const { t, i18n } = useTranslation("PopUps");

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const [popupHeight, setPopupHeight] = useState("510px");

  const [points, setPoints] = useState(0);

  const getProfileStatistics = async () => {
    const res = await Api.get("user/profile/points");
    setPoints(res.data.payload);
  };

  useEffect(() => {
    getProfileStatistics();
  }, []);

  return (
    <Popup height={popupHeight} close={close}>
      <div className="badges-popup-container">
        <div className="header-container flex">
          <PopupTitle>{t("Badges.title")}</PopupTitle>
          <PopupTitle small={true}>
            {points < 1001
              ? "Expert"
              : points > 1000 && points < 2501
              ? "Master"
              : points > 2500 && points < 5001
              ? "Legend"
              : "Champion"}{" "}
            <img
              src={
                points < 1001
                  ? ExpertDiamond
                  : points > 1000 && points < 2501
                  ? MasterDiamond
                  : points > 2500 && points < 5001
                  ? LegendDiamond
                  : ChampionDiamond
              }
              className="status-image"
            />{" "}
            {points}
          </PopupTitle>
        </div>
        <div className="upper-text">
          <h4>
            <p>{t("Badges.text1")}</p>
            <p>
              {t("Badges.text2")}{" "}
              <img src={ExpertIcon} width={"15px"} height={"15px"} />,{" "}
              {t("Badges.text3")}{" "}
              <img src={MasterIcon} width={"15px"} height={"15px"} />,{" "}
              {t("Badges.text4")}{" "}
              <img src={LegendIcon} width={"15px"} height={"15px"} />,{" "}
              {t("Badges.text5")}{" "}
              <img src={ChampionIcon} width={"15px"} height={"15px"} />.
            </p>
            <p>{t("Badges.text6")}</p>
          </h4>
        </div>
        <div className="badges-info-container">
          <PopupTitle small={true}>{t("Badges.title")}</PopupTitle>
          <div className="badges-info">
            <div className="status-icon-container">
              <div className="icon-container">
                <img src={ExpertIcon} width={"20px"} height={"20px"} />
              </div>
              <h5>{t("Badges.expert")}</h5>
              <h6>(0-1000)</h6>
            </div>
            <div className="status-icon-container">
              <div className="icon-container">
                <img src={MasterIcon} width={"25px"} height={"25px"} />
              </div>
              <h5>{t("Badges.master")}</h5>
              <h6>(1001-2500)</h6>
            </div>
            <div className="status-icon-container">
              <div className="icon-container">
                <img src={LegendIcon} width={"30px"} height={"30px"} />
              </div>
              <h5>{t("Badges.legend")}</h5>
              <h6>(2501-5000)</h6>
            </div>
            <div className="status-icon-container">
              <div className="icon-container">
                <img src={ChampionIcon} width={"35px"} height={"35px"} />
              </div>
              <h5>{t("Badges.champion")}</h5>
              <h6>(5001+)</h6>
            </div>
          </div>
        </div>
        <div className="explanation-text">
          <PopupTitle small={true}>{t("Badges.how-does-it-wotk")}</PopupTitle>
          <h4>{t("Badges.how-does-it-work-text")}</h4>
        </div>
      </div>
    </Popup>
  );
};
