export const StepSegmentsValues = {
  Step1: {
    Start: 60,
    End: 190,
  },
  Step2: {
    Start: 190,
    End: 240,
  },
  Step3: {
    Start: 240,
    End: 300,
  },
};

export const InitStepSegmentsValues = {
  Step1: {
    Start: 0,
    End: 118,
  },
  Step2: {
    Start: 118,
    End: 182,
  },
  Step3: {
    Start: 172,
    End: 247,
  },
};
