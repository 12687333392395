import { PopupButton } from "../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import { SmallTitle } from "../../../../../components/small-title/small-title";
import "./duplicate-content-popup.scss";
import { useTranslation } from "react-i18next";

//redux
import {
  duplicateContent,
  getContents,
} from "../../../../../store/media-center/media-center-slice";
import { useDispatch } from "react-redux";

export const DuplicateContentPopup = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");

  const dispatch = useDispatch();

  return (
    <div className="duplicate-content-container flex-popup-container">
      <Popup
        height="175px"
        width="400px"
        close={() => {
          props.closePopup([], false);
        }}
      >
        <div className="duplicate-content-title">
          <PopupTitle>{t("DuplicateContentPopup.title")}</PopupTitle>
        </div>
        <div className="delete-content-from-country-info">
          <SmallTitle>{t("DuplicateContentPopup.small-title")} </SmallTitle>
        </div>
        <div className="duplicate-content save-container">
          <PopupButton
            className="action-button"
            onClick={() => {
              let contentToDuplicate = { ...props.contentToDuplicate };
              delete contentToDuplicate._id;
              delete contentToDuplicate.DisplayOrder;
              delete contentToDuplicate.CreationDate;
              dispatch(duplicateContent(contentToDuplicate)).then(() => {
                dispatch(getContents()).then((res) => {
                  props.closePopup(res.payload, true);
                });
              });
            }}
          >
            {t("Common:confirm")}
          </PopupButton>
          <PopupButton
            className="action-button"
            onClick={() => {
              props.closePopup([], false);
            }}
          >
            {t("Common:cancel")}
          </PopupButton>
        </div>
      </Popup>
    </div>
  );
};
