import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./statistics-card.scss";

export const StatisticsCard = ({ title, text = null, icon, children }) => {
  return (
    <div className="statistics-card-container flex">
      <div className="flex">
        {icon && <FontAwesomeIcon icon={icon} className="fa-3x" />}

        <div className="title-wrapper">
          <div className="circle">
            <p className="text">{text !== null ? text : "0"}</p>
          </div>
          <h2 className="title">{title}</h2>
        </div>
      </div>
    </div>
  );
};
