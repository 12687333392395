import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  accessToken: "",
};

export const userSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    changeAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    resetAccessToken: (state, action) => {
      state.accessToken = "";
    },
  },

  extraReducers: {},
});

export const { changeAccessToken, resetAccessToken } = userSlice.actions;
export default userSlice.reducer;
