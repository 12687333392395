import { useEffect } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input/mobile";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { Button } from "../../../components/button/button";
import { Input } from "../../../components/input/input";
import { SelectLanguage } from "../../../components/select-language/select-language";
import { setTitle } from "../../../store/app-settings/app-settings-slice";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone.svg";
import { setDirection } from "../../../store/app-settings/app-settings-slice";
import { useTranslation } from "react-i18next";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

export default function Phone({
  phone,
  setPhone,
  sendLogin,
  moveToSignup,
  setLangCode,
}) {
  const { t, i18n } = useTranslation("Login");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle(t("Common:Login")));
  }, [i18n]);

  const handleChangeLanguage = (name, langCode) => {
    if (langCode.toLowerCase() === "he") {
      dispatch(setDirection("rtl"));
    } else {
      dispatch(setDirection("ltr"));
    }
    setLangCode(langCode);
    i18n.changeLanguage(langCode);
  };

  return (
    <div className="login-container phone">
      <div className="select-lang-container">
        <span className="select-lang-info">
          {t("ProfileSettings:select-language")}
        </span>
        <span className="lang-select">
          <SelectLanguage
            handleChangeLanguage={handleChangeLanguage}
            onLogin={true}
          />
        </span>
      </div>
      <div className="top-icon"></div>
      <div className="background-image"></div>
      <h4 className="sign-in-title">{t("sign-in")}</h4>
      <div className="content flex">
        <div className="phone-icon-and-text-container flex">
          <PhoneIcon />
          <div>
            <p className="your-mobile-phone-text">{t("user-phone")}</p>
            <p className="your-mobile-phone-text eg">
              {t("user-phone-format")}
            </p>
          </div>
        </div>
        <PhoneInput
          placeholder={t("enter-phone")}
          value={phone}
          defaultCountry="US"
          international={false}
          initialValueFormat="national"
          //labels={countryNames}
          onChange={setPhone}
          className="flex"
          addInternationalOption={true}
          // countrySelectComponent
          // containerComponent={<div style={{ background: "blue" }}></div>}
        />
        <div className="error-message">
          {phone
            ? isValidPhoneNumber(phone)
              ? undefined
              : t("inavlid-phone-number")
            : t("phone-required")}
        </div>
        {/* <div>{formatPhoneNumberIntl(phone)}</div> */}
        <div className="we-will-send-you">{t("send-otp-notice")}</div>
        <Button
          className={!phone || !isValidPhoneNumber(phone) ? " disabled" : ""}
          style={{ width: "80%" }}
          onClick={sendLogin}
        >
          {t("send-user-otp")}
        </Button>
        <br />
        <div className="not-register">
          <h4 style={{ fontWeight: "10" }}>{t("not-registered")}</h4>{" "}
          <h4 className="move-to-signup" onClick={moveToSignup}>
            {t("click-here")}
          </h4>
        </div>
      </div>
    </div>
  );
}
