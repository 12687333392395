import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { SpeechBubble } from "../../../../../../components/speech-bubble/speech-bubble";
import "./selected-category-popup.scss";
import { useTranslation } from "react-i18next";

export const SelectedCategoryPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  const navigate = useNavigate();
  const selectedCategory = useSelector(
    (state) => state.mapReducer.selectedCategory
  );

  const navigateToSmartGoalPage = () => {
    navigate("../../smart-goal");
    close();
  };

  return (
    <Popup close={navigateToSmartGoalPage}>
      <div className="selected-category-popup-container">
        <PopupTitle
          small={true}
          style={{ width: "300px", textAlign: "center" }}
        >
          {t("CreateMap.SelectCatagoryPopUp.chosen")}{" "}
          {t(`Common:Categories.${selectedCategory?.Name}`)}{" "}
          {t("CreateMap.SelectCatagoryPopUp.area")}
        </PopupTitle>
        <SpeechBubble
          direction={"right"}
          top={"175px"}
          left={"26px"}
          width={"150px"}
          height={"55px"}
        >
          <br />
          <p>{t("CreateMap.SelectCatagoryPopUp.start")}</p>
          <p>{t("CreateMap.SelectCatagoryPopUp.mapping")}</p>
        </SpeechBubble>

        <div className="popup-bottom-bottons-container flex">
          <PopupButton continue="true" onClick={navigateToSmartGoalPage}>
            {t("CreateMap.SelectCatagoryPopUp.set-goal")}
          </PopupButton>
        </div>
      </div>
    </Popup>
  );
};
