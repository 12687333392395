import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FacebookIcon } from "next-share";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowLoader,
  setTitle,
} from "../../../store/app-settings/app-settings-slice";
import Api from "../../../utils/api";
import "./community.scss";
import { useTranslation } from "react-i18next";
import { updateUserPoints } from "../../../store/profile/profile-slice";

export const Community = () => {
  const { t, i18n } = useTranslation("Community");

  const dispatch = useDispatch();
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const [communityMessages, setCommunityMessages] = useState([]);

  useEffect(() => {
    dispatch(setTitle(t("title")));
    getCommunityMessages();
  }, []);

  const getCommunityMessages = async () => {
    dispatch(setShowLoader(true));
    try {
      const res = await Api.get("user/communityMessages");
      if (res.data) {
        setCommunityMessages(res.data.payload);
      }
      dispatch(setShowLoader(false));
    } catch {
      dispatch(setShowLoader(false));
    }
  };

  const openFacebookInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const getIcon = (type) => {
    switch (type) {
      case "Facebook":
        return <FacebookIcon size={32} round />;
    }
  };

  return (
    <div className="community-container">
      {!showLoader && (
        <>
          {communityMessages.length > 0 ? (
            communityMessages.map((communityMessage) => {
              const icon = getIcon(communityMessage.Type);
              return (
                <div
                  className="card"
                  key={communityMessage._id}
                  onClick={() => {
                    openFacebookInNewTab(communityMessage.Url);
                    dispatch(
                      updateUserPoints({ points: 25, createStepType: "" })
                    );
                  }}
                >
                  <div className="flex">
                    {icon}
                    <h2 className="title">{communityMessage.Title}</h2>
                  </div>
                  <p className="body break-words">
                    {ReactHtmlParser(communityMessage.Body)}
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className="external-link-icon"
                    />
                  </p>
                </div>
              );
            })
          ) : (
            <p className="description flex">{t("none")}</p>
          )}
        </>
      )}
    </div>
  );
};
