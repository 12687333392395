import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppHeader } from "../../../components/app-header/app-header";
import { Button } from "../../../components/button/button";
import {
  setRefreshTokenRemoved,
  setShowLoader,
  setTitle,
  setWelcomePopupsOpen,
} from "../../../store/app-settings/app-settings-slice";
import { changeAccessToken } from "../../../store/auth/auth-slice";
import { getProfile } from "../../../store/profile/profile-slice";
import Api from "../../../utils/api";
import { NativeEvents } from "../../../utils/enums";
import { useTranslation } from "react-i18next";

export default function Otp({
  phone,
  email,
  otpNextDate,
  sendLogin,
  langCode,
}) {
  const { t, i18n } = useTranslation("Login");

  const profile = useSelector((state) => state.profileReducer.profile);
  const accessToken = useSelector((state) => state.authReducer.accessToken);
  const [otpValue, setOtpValue] = useState("");
  const [seconds, setSeconds] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    dispatch(setTitle(t("Common:Welcome")));
  }, []);

  useEffect(() => {
    const timeout_id = setTimeout(() => {
      if (otpNextDate) {
        const getSeconds = Math.ceil(
          DateTime.fromISO(otpNextDate).diffNow("seconds").seconds
        );
        if (getSeconds >= 0) setSeconds(getSeconds);
      }
    }, 1000);
    return () => clearTimeout(timeout_id);
  }, [seconds, otpNextDate]);

  useEffect(() => {
    if (profile) {
      if (window.ReactNativeWebView)
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            event: NativeEvents.AddPushNotificationToken,
            firstLogin: true,
          })
        );
      dispatch(setWelcomePopupsOpen(true));
      if (profile.Admin) navigate("/admin-dashboard");
      else navigate("/maps");
    }
  }, [profile]);

  const clearOtp = () => {
    setOtpValue("");
  };

  const sendValidateOtp = async (otp) => {
    setOtpValue(otp);
    if (otp.trim().length === 6) {
      const data = {
        Phone: phone,
        Otp: otp,
        LangCode: langCode,
      };
      dispatch(setShowLoader(true));
      try {
        const token = await executeRecaptcha("OtpVerify");
        const res = await Api.post("auth/otp/verify", data, {
          headers: {
            recaptcha: token,
          },
        });
        if (res.data) {
          const newAccessToken = res.data.payload;
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                event: NativeEvents.SetAccessToken,
                accessToken: newAccessToken,
              })
            );
          }
          dispatch(changeAccessToken(newAccessToken));
          dispatch(setRefreshTokenRemoved(false));
          dispatch(setShowLoader(false));
          // dispatch(setWelcomePopupsOpen(true));
          dispatch(getProfile());
        }
      } catch (error) {
        dispatch(setShowLoader(false));
        console.log(error);
      }
    }
  };

  let hideEmail = function (email) {
    return email.replace(/(.{1})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  };

  return (
    <>
      <AppHeader />
      <div className="login-container otp flex">
        <div className="verification-code">{t("OTP.verification-code")}</div>
        <div className="please-type">
          {t("OTP.type-otp")}{" "}
          {phone.startsWith("+972") ? phone : hideEmail(email)}
        </div>
        <div className="otp-input-container flex">
          <OtpInput
            shouldAutoFocus={true}
            value={otpValue}
            onChange={sendValidateOtp}
            numInputs={6}
            isInputNum={true}
            placeholder="XXXXXX"
            separator={<span className="separator flex">-</span>}
            inputStyle={{
              background: "none",
              border: "none",
              direction: "ltr",
            }}
          />
        </div>
        {/* {otpValue.trim().length > 0 && (
          // <div className="flex">
          //   <Button onClick={clearOtp} className="">
          //     Clear
          //   </Button>
          //   <Button onClick={() => sendValidateOtp(otpValue)} className="">
          //     Send
          //   </Button>
          // </div>
          <Button
            onClick={() => sendValidateOtp(otpValue)}
            style={{ width: "80%" }}
            className=""
          >
            Next
          </Button>
        )} */}
        <div>
          {/* {seconds >= 1 && <div>{seconds}</div>} */}
          {/* {seconds < 1 && ( */}
          <button onClick={sendLogin} className="send-again-button">
            {t("OTP.send-otp-again")}
          </button>
          {/* )} */}
        </div>
        {/* {otpValue.trim().length === 6 && (
          <Button style={{ width: "80%" }} onClick={sendValidateOtp}>
            Next
          </Button>
        )} */}
      </div>
    </>
  );
}
