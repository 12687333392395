import { ReactComponent as XIcon } from "../../assets/icons/x.svg";

import "./popup.scss";

export const Popup = ({ style, height, width, close, children }) => {
  return (
    <div className="popup-container" style={style}>
      <div className="popup-background" />
      <div
        className="popup"
        style={{ minHeight: height ? height : "", width: width ? width : "" }}
      >
        <div
          className="popup-children"
          style={{
            minHeight: height ? height : "260px",
            height: height ? height : "",
            width: width ? width : "",
          }}
        >
          {
            close && <XIcon className="close-icon" onClick={close} />
            // <FontAwesomeIcon icon={faClose} size="2x" className="close-icon" onClick={close} />
          }

          {children}
        </div>
        {/* <div className="popup-bottom-bottons-container flex">{buttons}</div> */}
      </div>
    </div>
  );
};
