import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./welcome-back-stats-card.scss";

export const WelcomeBackStatsCard = ({
  icon,
  label,
  value,
  imageIcon = false,
}) => {
  return (
    <div className="welcome-back-stats-card-container flex description">
      <div className="header-text-and-icon flex">
        {imageIcon ? (
          <img src={icon} className="image-icon" />
        ) : (
          <FontAwesomeIcon icon={icon} />
        )}
        <h3 className="text">{label}</h3>
      </div>
      <span className="value-text">{value}</span>
    </div>
  );
};
