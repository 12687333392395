import Axios from "axios";
import { changeAccessToken, resetAccessToken } from "../store/auth/auth-slice";
import Cookies from "js-cookie";
import {
  setRefreshTokenRemoved,
  setSystemError,
} from "../store/app-settings/app-settings-slice";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { NativeEvents } from "./enums";

let store;

export const injectStore = (_store) => {
  store = _store;
};

// Function that will be called to refresh authorization
const refreshAuthLogic = async (failedRequest) => {
  const { dispatch } = store; // direct access to redux store.

  try {
    const response = await Api.post("/auth/refresh");
    if (response.data.payload) {
      const accessToken = response.data.payload.accessToken;
      dispatch(changeAccessToken(accessToken));
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ event: NativeEvents.SetAccessToken, accessToken })
        );
        if (response.data.payload.areNotificationsAllowed)
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ event: NativeEvents.AddPushNotificationToken })
          );
      }
      return Promise.resolve();
    } else {
      // remove accesToken if exists
      if (store.getState().authReducer.accessToken) {
        dispatch(resetAccessToken());
      }
      Cookies.remove("refreshToken");
      dispatch(setRefreshTokenRemoved(true));
      window.location.href = "/login";
      return Promise.reject();
    }
  } catch (_error) {
    // remove accesToken if exists
    if (store.getState().authReducer.accessToken) {
      dispatch(resetAccessToken());
    }
    Cookies.remove("refreshToken");
    dispatch(setRefreshTokenRemoved(true));
    window.location.href = "/login";
    return Promise.reject(_error);
  }
};

const AccessPoint = "";
const Api = Axios.create({
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    "Content-Type": "application/json",
  },
  baseURL: AccessPoint + "/api/",
  withCredentials: true,
});

// Instantiate the interceptor
createAuthRefreshInterceptor(Api, refreshAuthLogic);

Api.interceptors.request.use(
  (config) => {
    const token = store.getState().authReducer.accessToken;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    console.log({ error });
    return Promise.reject(error);
  }
);
Api.interceptors.response.use(
  (res) => {
    return res;
  },

  async (err) => {
    const originalConfig = err.config;
    const { dispatch } = store; // direct access to redux store.
    if (
      !["/auth/login", "/auth/refresh"].includes(originalConfig?.url) &&
      err.response
    ) {
      // if (err.response.status === 401 && !originalConfig.sent) {
      //   // Access Token has expired
      //   originalConfig._retry = true;
      //   try {
      //     const response = await Api.post("/auth/refresh");
      //     if (response.data.payload) {
      //       const accessToken = response.data.payload;
      //       dispatch(changeAccessToken(accessToken));
      //       return Api(originalConfig);
      //     } else {
      //       // remove accesToken if exists
      //       if (store.getState().authReducer.accessToken) {
      //         dispatch(resetAccessToken());
      //       }
      //       Cookies.remove("refreshToken");
      //       window.location.href = "/login";
      //       return Promise.reject(err);
      //     }
      //   } catch (_error) {
      //     // remove accesToken if exists
      //     if (store.getState().authReducer.accessToken) {
      //       dispatch(resetAccessToken());
      //     }
      //     Cookies.remove("refreshToken");
      //     window.location.href = "/login";
      //     return Promise.reject(_error);
      //   }
      // } else {
      //   const { statusCode, error } = err.response.data;
      //   dispatch(setSystemError({ statusCode, error }));
      // }
      if (!(err.response.status === 401 && !originalConfig.sent)) {
        const { statusCode, error } = err.response.data;
        dispatch(setSystemError({ statusCode, error }));
      }
    }

    return Promise.reject(err);
  }
);

export default Api;
