import {
  faAnglesDown,
  faAnglesUp,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import {
  setBoosterQuestionsPopup,
  setViewBoosterQuestionsPopupOpen,
} from "../../store/app-settings/app-settings-slice";
import {
  changeFooterMenuOpen,
  changeShareMapPopupOpen,
} from "../../store/map/map-slice";
import { FooterMenuButton } from "./footer-menu-button/footer-menu-button";
import "./footer-menu.scss";
import { ReactComponent as BoosterIcon } from "./icons/boosters_1.svg";
// import { ReactComponent as BagOfBoostersIcon } from "./icons/bag-of-boosters.svg";
import { ReactComponent as CommunityIcon } from "./icons/community.svg";
import { ReactComponent as MessagesIcon } from "./icons/messages.svg";
import { ReactComponent as MyMapsIcon } from "./icons/my-maps.svg";
import { ReactComponent as PlusIcon } from "./icons/plus.svg";
import { useTranslation } from "react-i18next";

export const FooterMenu = ({}) => {
  const { t, i18n } = useTranslation("Menu");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    boosterQuestionsPopup,
    viewBoosterQuestionsPopupOpen,
    badgesPopupOpen,
  } = useSelector((state) => ({
    boosterQuestionsPopup: state.appSettingsReducer.boosterQuestionsPopup,
    viewBoosterQuestionsPopupOpen:
      state.appSettingsReducer.viewBoosterQuestionsPopupOpen,
    badgesPopupOpen: state.appSettingsReducer.badgesPopupOpen,
  }));

  const {
    footerMenuOpen,
    shareMapPopupOpen,
    mapLottieViews,
    categoryNavigationMenuOpen,
  } = useSelector((state) => ({
    footerMenuOpen: state.mapReducer.footerMenuOpen,
    shareMapPopupOpen: state.mapReducer.shareMapPopupOpen,
    mapLottieViews: state.mapReducer.mapLottieViews,
    categoryNavigationMenuOpen: state.mapReducer.categoryNavigationMenuOpen,
  }));

  const [showFooterMenuOpenButton, setShowFooterMenuOpenButton] = useState(
    !footerMenuOpen
  );
  const [showFooterMenuCloseButton, setShowFooterMenuCloseButton] =
    useState(false);

  const transitions = useTransition(footerMenuOpen, {
    from: { position: "absolute", opacity: 0, height: "0", bottom: "0" },
    enter: { opacity: 1, height: "60px", bottom: "0" },
    leave: { opacity: 0, height: "0", bottom: "0" },

    onChange: () => {
      // console.log("change");
    },
    onRest: () => {
      // console.log("reset");
    },
  });

  const navigateToPage = (text) => {
    if (viewBoosterQuestionsPopupOpen) {
      dispatch(setViewBoosterQuestionsPopupOpen(false));
    }
    if (boosterQuestionsPopup.open) {
      dispatch(setBoosterQuestionsPopup({ open: false, editMode: false }));
    }
    if (shareMapPopupOpen) dispatch(shareMapPopupOpen(false));

    switch (text) {
      case "Messeges":
        navigate("../messages");
        break;
      case "My Maps":
        navigate("../maps");
        break;
      case "Community":
        navigate("../community");
        break;
      case "Add Map":
        navigate("../maps/create/category"); // to do - check if draft map has deleted
        break;
      default:
        break;
    }
  };

  const openShareMapPopup = () => {
    dispatch(changeShareMapPopupOpen(true));
  };
  useEffect(() => {
    setTimeout(() => {
      setShowFooterMenuCloseButton(true);
    }, 700);
  }, []);

  const openFooterMenu = () => {
    setShowFooterMenuOpenButton(false);
    dispatch(changeFooterMenuOpen(true));
    setTimeout(() => {
      setShowFooterMenuCloseButton(true);
    }, 1200);
  };

  const closeFooterMenu = () => {
    dispatch(changeFooterMenuOpen(false));
    setTimeout(() => {
      setShowFooterMenuOpenButton(true);
      setShowFooterMenuCloseButton(false);
    }, 700);
  };

  const openViewBoosterQuestionPopup = () => {
    dispatch(setViewBoosterQuestionsPopupOpen(true));
  };

  return (
    <div className="footer-menu-container flex">
      <div
        className={
          "menu-drawer-button flex open" +
          (!showFooterMenuOpenButton ? " hidden" : "")
        }
        onClick={openFooterMenu}
      >
        <FontAwesomeIcon icon={faAnglesUp} color="#82da90" />
      </div>

      {transitions(
        ({ height }, item) =>
          item &&
          (mapLottieViews.loaded || categoryNavigationMenuOpen) && (
            <animated.div
              style={{ height: height }}
              className="animated-menu-container flex"
            >
              <div
                className={
                  "menu-drawer-button close flex" +
                  (!showFooterMenuCloseButton ? " hidden" : "")
                }
                onClick={closeFooterMenu}
              >
                <FontAwesomeIcon icon={faAnglesDown} color="#82da90" />
                {/* <XIcon className="icon" /> */}
              </div>
              <div
                className={
                  "buttons-container flex" + (!footerMenuOpen ? " hidden" : "")
                }
              >
                <FooterMenuButton
                  text={t("messages")}
                  icon={<MessagesIcon className="icon" />}
                  onClick={() => navigateToPage("Messeges")}
                  index={0}
                />
                <FooterMenuButton
                  text={t("my-boosters")}
                  icon={<BoosterIcon className="icon" />}
                  onClick={openViewBoosterQuestionPopup}
                  index={1}
                />
                <FooterMenuButton
                  text={t("maps")}
                  icon={<MyMapsIcon className="icon" />}
                  onClick={() => navigateToPage("My Maps")}
                  index={2}
                />
                <FooterMenuButton
                  text={t("hope-for-all")}
                  icon={<CommunityIcon className="icon" />}
                  onClick={() => navigateToPage("Community")}
                  index={3}
                />
                <FooterMenuButton
                  text={t("add-map")}
                  icon={<PlusIcon className="icon" />}
                  onClick={() => navigateToPage("Add Map")}
                  index={4}
                />
                <FooterMenuButton
                  text={t("share")}
                  icon={
                    <FontAwesomeIcon
                      icon={faShareNodes}
                      size="2x"
                      className="icon fa"
                    />
                  }
                  onClick={openShareMapPopup}
                  index={5}
                />
              </div>
            </animated.div>
          )
      )}
    </div>
  );
};
