import React, { useState } from "react";
import "./ask-for-more.scss";
import lookingImage from "../../../../assets/images/ask-for-more.png";
import thanksImage from "../../../../assets/images/ask-for-more-thanks.png";
import { Button } from "../../../../components/button/button";
import { sendContentRequest } from "../../../../store/media-center/media-center-slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export const AskForMore = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");

  const [sent, setSent] = useState(false);
  const [text, setText] = useState("");

  const dispatch = useDispatch();

  return (
    <div className="ask-for-more-container">
      {sent ? (
        <div className="after-send">
          <div className="thanks-image-container">
            <img src={thanksImage} className="thanks-image" />
          </div>
          <div className="thanks-text-first">{t("AskForMore.thanks")}</div>
          <div className="thanks-text-second">
            <h4>{t("AskForMore.received")}</h4>
          </div>
          <div className="send-container">
            <Button
              small={true}
              onClick={() => {
                props.returnToMedia();
              }}
            >
              <h4>{t("AskForMore.back")}</h4>
            </Button>
          </div>
        </div>
      ) : (
        <div className="before-send">
          <div className="description-text">
            <h4>{t("AskForMore.description1")}</h4>
            <h4>{t("AskForMore.description2")}</h4>
          </div>
          <div className="looking-image-container">
            <img src={lookingImage} className="looking-image" />
          </div>
          <div className="looking-text">
            <h4>{t("AskForMore.looking")}</h4>
          </div>
          <div className="textarea-container">
            <textarea
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
              className="looking-textarea"
              placeholder={t("AskForMore.tell")}
            />
          </div>
          <div className="send-container">
            <Button
              small={true}
              onClick={() => {
                dispatch(sendContentRequest(text));
                setSent(true);
              }}
            >
              <h4>{t("Common:send")}</h4>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
