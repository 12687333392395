import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MapLoader } from "../../../../components/map-loader/map-loader";
import {
  setBadgesPopupOpen,
  setBoosterQuestionsPopup,
  setLevelUpPopupOpen,
  setTitle,
  setViewBoosterQuestionsPopupOpen,
} from "../../../../store/app-settings/app-settings-slice";
import {
  changeIsLoadingMap,
  changeMap,
  changeMapLottieViews,
  changeShareMapPopupOpen,
  changeMapInstructionsPopupOpen,
  changeMapInstructionsPopupOpenedOnce,
  changeStepDetailsPopup,
  changeViewMainGoalPopup,
  getMapById,
} from "../../../../store/map/map-slice";
import Api from "../../../../utils/api";
import {
  setCurrentCategoryColor,
  updateInitialSegment,
} from "../../../../utils/mapFunc";
import { BoosterQuestionsPopup } from "../../app-popups/booster-questions/booster-questions-popup";
import { ViewBoosters } from "../../app-popups/view-boosters/view-boosters-popup";
import { AnimatedMap } from "./animated-map/animated-map";
import { BottomMapDetails } from "./animated-map/bottom-map-details/bottom-map-details";
import { ClickedObstaclePopup } from "./popups/clicked-obstacle-popup/clicked-obstacle-popup";
import { FinshedFirstStepPopup } from "./popups/finshed-first-step-popup/finshed-first-step-popup";
import { FinshedSecondPopup } from "./popups/finshed-second-step-popup/finshed-second-step-popup";
import { FinshedThirdPopup } from "./popups/finshed-third-step-popup/finshed-third-step-popup";
import { ShareMapPopup } from "./popups/share-map-popup/share-map-popup";
import { MapInstructionsPopup } from "./popups/map-instructions-popup/map-instructions-popup";
import { StepDetailsPopup } from "./popups/step-details-popup/step-details-popup";
import { ThatsOkPopup } from "./popups/thats-ok-popup/thats-ok-popup";
import { ViewMainGoalPopup } from "./popups/view-main-goal-popup/view-main-goal-popup";
import "./view-map.scss";
import { BadgesPopup } from "../../app-popups/badges-popup/badges-popup";
import { StatusLevelUpPopup } from "../../app-popups/status-level-up-popup/status-level-up-popup";
import {
  getUserPoints,
  updateUserPoints,
} from "../../../../store/profile/profile-slice";

export const ViewMap = () => {
  const mapLottiesRef = useRef();
  const dispatch = useDispatch();
  const params = useParams();
  const {
    boosterQuestionsPopup,
    viewBoosterQuestionsPopupOpen,
    badgesPopupOpen,
    levelUpPopupOpen,
  } = useSelector((state) => ({
    boosterQuestionsPopup: state.appSettingsReducer.boosterQuestionsPopup,
    viewBoosterQuestionsPopupOpen:
      state.appSettingsReducer.viewBoosterQuestionsPopupOpen,
    badgesPopupOpen: state.appSettingsReducer.badgesPopupOpen,
    levelUpPopupOpen: state.appSettingsReducer.levelUpPopupOpen,
  }));
  const {
    map,
    isLoadingMap,
    mapLottieViews,
    stepDetailsPopup,
    shareMapPopupOpen,
    viewMainGoalPopup,
    mapInstructionsPopupOpen,
    mapInstructionsPopupOpenedOnce,
  } = useSelector((state) => ({
    map: state.mapReducer.map,
    mapLottieViews: state.mapReducer.mapLottieViews,
    isLoadingMap: state.mapReducer.isLoadingMap,
    stepDetailsPopup: state.mapReducer.stepDetailsPopup,
    shareMapPopupOpen: state.mapReducer.shareMapPopupOpen,
    viewMainGoalPopup: state.mapReducer.viewMainGoalPopup,
    mapInstructionsPopupOpen: state.mapReducer.mapInstructionsPopupOpen,
    mapInstructionsPopupOpenedOnce:
      state.mapReducer.mapInstructionsPopupOpenedOnce,
  }));

  const profile = useSelector((state) => state.profileReducer.profile);

  const [info, setInfo] = useState({});
  const [clickedObstaclePopup, setClickedObstaclePopup] = useState({
    open: false,
    index: 0,
  });
  const [thatsOkPopup, setThatsOkPopup] = useState({
    open: false,
    index: 0,
  });
  const [finishedStepPopup, setFinishedStepPopup] = useState({
    open: false,
    index: 0,
  });

  const getProfilePoints = async () => {
    dispatch(getUserPoints()).then((res) => {
      if (
        profile?.Points > 0 &&
        ((profile?.Points < 1001 && res.payload > 1000) ||
          (profile?.Points < 2501 && res.payload > 2500) ||
          (profile?.Points < 5001 && res.payload > 5000))
      ) {
        dispatch(setLevelUpPopupOpen(true));
      }
    });
  };

  useEffect(() => {
    // if (
    //   (profile && profile?.Phone === "+972547997155") ||
    //   profile?.Phone === "+972547898398" ||
    //   profile?.Phone === "+972525813751" ||
    //   profile?.Phone === "+972537536392" ||
    //   profile?.Phone === "+972525291333" ||
    //   profile?.Phone === "+972545603120" ||
    //   profile?.Phone === "+972587897623" ||
    //   profile?.Phone === "+972528506862"
    // ) {
    //}

    setTimeout(() => {
      getProfilePoints();
    }, 2250);
  }, [profile, map]);

  useEffect(() => {
    // if (!map || map._id !== params.mapId) {
    //   const mapId = params.mapId;
    //   getUserMap(mapId);
    // }
    if (params.mapId) {
      const mapId = params.mapId;
      getUserMap(mapId);
    }
  }, [params.mapId]);

  const getUserMap = async (mapId) => {
    if (!isLoadingMap) dispatch(changeIsLoadingMap(true));
    if (mapLottieViews.initialSegment.updated) {
      dispatch(
        changeMapLottieViews({
          loaded: false,
          initialSegment: {
            updated: false,
            value: 0,
          },
        })
      );
    }
    dispatch(getMapById(mapId));
  };

  useEffect(() => {
    if (map?._id === params.mapId) {
      dispatch(setTitle(map.MainGoal));
      setInfo(map);
      if (!map.Steps[0].Completed) {
        if (!mapInstructionsPopupOpenedOnce)
          dispatch(changeMapInstructionsPopupOpen(true));
      }
    }
  }, [map]);

  const setMapInitialLottieSegment = (data) => {
    dispatch(changeMapLottieViews({ ...mapLottieViews, initialSegment: data }));
  };

  useEffect(() => {
    if (info?.Steps && !mapLottieViews.initialSegment.updated) {
      updateInitialSegment(info.Steps, setMapInitialLottieSegment);
      setCurrentCategoryColor(info.CategoryName);
    }
  }, [info]);

  useEffect(() => {
    if (mapLottieViews.initialSegment.updated)
      dispatch(changeIsLoadingMap(false));
  }, [mapLottieViews.initialSegment.updated]);

  const updateStep = async (data) => {
    const res = await Api.put(`user/maps/${params.mapId}/step`, data);
    if (res.data.payload) {
      if (data.step == 1) {
        dispatch(updateUserPoints({ points: 150, createStepType: "" }));
      }
      if (data.step == 2) {
        dispatch(updateUserPoints({ points: 200, createStepType: "" }));
      }
      dispatch(changeMap(res.data.payload));
    }
  };

  const finishStep = async (index) => {
    const data = {
      StepIndex: index,
      Completed: true,
      NotYet: false,
    };
    await updateStep(data);
    mapLottiesRef.current.moveToNextStep(index);
  };

  const openClickedObstaclePopup = (index) => {
    setClickedObstaclePopup({
      open: true,
      index,
    });
  };

  return (
    <div className="view-map-container">
      {levelUpPopupOpen && (
        <StatusLevelUpPopup
          close={() => {
            dispatch(setLevelUpPopupOpen(false));
          }}
        />
      )}
      {badgesPopupOpen && (
        <BadgesPopup
          close={() => {
            dispatch(setBadgesPopupOpen(false));
          }}
        />
      )}
      {shareMapPopupOpen && (
        <ShareMapPopup
          mapId={params.mapId}
          close={() => {
            dispatch(changeShareMapPopupOpen(false));
          }}
        />
      )}
      {viewBoosterQuestionsPopupOpen && (
        <ViewBoosters
          close={() => {
            dispatch(setViewBoosterQuestionsPopupOpen(false));
          }}
        />
      )}
      {boosterQuestionsPopup.open && (
        <BoosterQuestionsPopup
          editMode={boosterQuestionsPopup.editMode}
          close={() => {
            dispatch(
              setBoosterQuestionsPopup({ open: false, editMode: false })
            );
          }}
        />
      )}

      {viewMainGoalPopup.open && (
        <ViewMainGoalPopup
          goal={viewMainGoalPopup.goal}
          close={() =>
            dispatch(changeViewMainGoalPopup({ open: false, goal: "" }))
          }
        />
      )}

      {stepDetailsPopup.open && (
        <StepDetailsPopup
          index={stepDetailsPopup.index}
          info={info}
          updateStep={updateStep}
          finishStep={finishStep}
          setThatsOkPopup={setThatsOkPopup}
          shareMode={false}
          close={() =>
            dispatch(
              changeStepDetailsPopup({
                open: false,
                index: 0,
              })
            )
          }
        />
      )}

      {clickedObstaclePopup.open && (
        <ClickedObstaclePopup
          index={clickedObstaclePopup.index}
          info={info}
          finishStep={finishStep}
          close={() =>
            setClickedObstaclePopup({
              open: false,
              index: 0,
            })
          }
        />
      )}

      {thatsOkPopup.open && (
        <ThatsOkPopup
          index={thatsOkPopup.index}
          info={info}
          close={() => setThatsOkPopup({ open: false, index: 0 })}
        />
      )}

      {finishedStepPopup.open && finishedStepPopup.index === 0 && (
        <FinshedFirstStepPopup
          close={() => setFinishedStepPopup({ open: false, index: 0 })}
        />
      )}

      {finishedStepPopup.open && finishedStepPopup.index === 1 && (
        <FinshedSecondPopup
          close={() => setFinishedStepPopup({ open: false, index: 0 })}
        />
      )}

      {finishedStepPopup.open && finishedStepPopup.index === 2 && (
        <FinshedThirdPopup
          close={() => setFinishedStepPopup({ open: false, index: 0 })}
        />
      )}

      {!isLoadingMap && mapLottieViews.initialSegment.updated ? (
        <AnimatedMap
          key={params.mapId}
          shareMode={false}
          info={info}
          finishStep={finishStep}
          setFinishedStepPopup={setFinishedStepPopup}
          openClickedObstaclePopup={openClickedObstaclePopup}
          ref={mapLottiesRef}
        />
      ) : (
        <MapLoader className="page" />
      )}

      {!isLoadingMap &&
        mapLottieViews.initialSegment.updated &&
        mapInstructionsPopupOpen && (
          <MapInstructionsPopup
            close={() => {
              dispatch(changeMapInstructionsPopupOpen(false));
              if (!mapInstructionsPopupOpenedOnce)
                dispatch(changeMapInstructionsPopupOpenedOnce(true));
            }}
          />
        )}

      <BottomMapDetails info={info} shareMode={false} />
    </div>
  );
};
