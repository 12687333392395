import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../../components/button/button";
import { SmallTitle } from "../../../../../components/small-title/small-title";
import { DefineStepPopup } from "./define-step-popup/define-step-popup";
import "./steps-buttons.scss";
import { useTranslation } from "react-i18next";

export const StepsButtons = forwardRef(
  (
    {
      info,
      setInfo,
      editMode,
      debouncedUpdateDaftMap,
      setFinishedSettingThreeStepsPopupOpen,
      editedSteps,
      setEditedSteps,
    },
    ref
  ) => {
    const { t, i18n } = useTranslation("Maps");

    const navigate = useNavigate();
    const [definedStepsCount, setDefinedStepsCount] = useState(0);
    const [openEditSteps, setOpenEditSteps] = useState(false);
    const [defineStepPopup, setDefineStepPopup] = useState({
      open: false,
      index: 0,
      info: {
        Name: "",
        StartDate: "",
      },
    });

    useEffect(() => {
      if (definedStepsCount === 3) {
        setOpenEditSteps(true);
      }
    }, [definedStepsCount]);

    useImperativeHandle(ref, () => ({
      initStepsButtons(steps) {
        let newDefinedStepsCount = 0;
        let editStepsEnabled = true;
        steps.forEach((step) => {
          if (!step.Name) {
            editStepsEnabled = false;
          }
          if (step.Name) newDefinedStepsCount++;
        });
        setOpenEditSteps(editStepsEnabled);
        if (editStepsEnabled) setDefinedStepsCount(3);

        setDefinedStepsCount(newDefinedStepsCount);
      },
    }));

    const openStep = (index) => {
      navigate(`step/${index + 1}`, {
        state: { editMode, editedSteps, setEditedSteps },
      });
    };

    const saveStep = () => {
      const index = defineStepPopup.index;
      let newSteps = [...info.Steps];
      newSteps[index] = {
        ...info.Steps[index],
        Name: defineStepPopup.info.Name,
        StartDate: defineStepPopup.info.StartDate,
      };

      setInfo((prevState) => ({
        ...prevState,
        Steps: newSteps,
      }));

      debouncedUpdateDaftMap({
        ...info,
        Steps: newSteps,
      });
      const newDefinedStepsCount = newSteps.filter((step) => step.Name).length;
      if (newDefinedStepsCount === 3)
        setFinishedSettingThreeStepsPopupOpen(true);
      setDefinedStepsCount(newDefinedStepsCount);

      setDefineStepPopup({
        ...defineStepPopup,
        open: false,
        index: 0,
        info: { Name: "", StartDate: null },
      });
    };

    return (
      <div className="steps-buttons-container">
        {defineStepPopup.open && (
          <DefineStepPopup
            defineStepPopup={defineStepPopup}
            setDefineStepPopup={setDefineStepPopup}
            saveStep={saveStep}
            close={() =>
              setDefineStepPopup({ ...defineStepPopup, open: false })
            }
          />
        )}

        {info.Steps.map((step, i) => {
          return (
            <div key={i} className="step-in-goal">
              <div className="break-words">
                <SmallTitle>{`${t("Common:pathway")} #${i + 1}`}</SmallTitle>
                {step?.Name && <h4>{step?.Name}</h4>}
              </div>
              {!editMode && definedStepsCount !== 3 && (
                <Button
                  className="step-button create"
                  onClick={() =>
                    setDefineStepPopup({
                      open: true,
                      index: i,
                      info: {
                        Name: step.Name,
                        StartDate: step.StartDate,
                      },
                    })
                  }
                  icon={step.Name ? faCircleCheck : null}
                >
                  <span className="start-setting-text">START SETTING </span>
                  {step.Name && (
                    <FontAwesomeIcon icon={faCircleCheck} size="1x" />
                  )}
                </Button>
              )}
              {(editMode || openEditSteps) && (
                <Button
                  className="step-button edit"
                  onClick={() => openStep(i)}
                  style={{
                    background:
                      step.Name && step.Obstacle && step.Overcome
                        ? ""
                        : "#f5f5f5",
                  }}
                >
                  <span>{t("Common:edit")}</span>
                  <FontAwesomeIcon icon={faPenToSquare} />
                </Button>
              )}
            </div>
          );
        })}
      </div>
    );
  }
);
