import React, { Component } from "react";
import "../../pages/admin/media-center/content-box/content-box.scss";
import Vimeo from "@u-wave/react-vimeo";
class VimeoVideo extends Component {
  state = {
    debug: true,
    data: { status: true, statusCode: "completed", score: null, allData: {} },
    isP: false,
  };

  timeChange = (e) => {
    const p = e.percent * 100;
    if (p > 90 && !this.state.isP) {
      this.setState({
        isP: true,
      });
      this.props.onReport(this.state.data);
    }
  };
  componentDidMount() {
    //this.props.onReport(this.state.data);
  }
  render() {
    const regEx =
      /(?:<iframe [^>]*src=")?(https?:\/\/(?:[\w]+\.)*vimeo\.com(?:[\/\w:]*(?:\/videos)?)?\/([0-9]+)[^\s"]*)"?(?:[^>]*><\/iframe>)?(?:<p>.*<\/p>)?/g;
    const match = regEx.exec(this.props.url);
    const vimUrl = match ? match[1] : "";
    return (
      <Vimeo
        onTimeUpdate={(e) => {
          this.timeChange(e);
        }}
        className={
          "vimeo-frame " +
          (this.props.fullScreen ? "contentPage-full-screen" : "contentPage")
        }
        video={vimUrl}
        autoplay
      />
    );
  }
}

export default VimeoVideo;
