import {
  faCircleCheck,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setShowLoader,
  setTitle,
} from "../../../store/app-settings/app-settings-slice";
import Api from "../../../utils/api";
import "./pathways-schedule.scss";
import { useTranslation } from "react-i18next";

export const PathwaysSchedule = () => {
  const { t, i18n } = useTranslation("Reminders");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const [remindersByDates, setRemindersByDates] = useState({});
  useEffect(() => {
    dispatch(setTitle(t("title")));
    getUserMapsSchedule();
  }, [i18n]);

  const retrieveDate = (date) => {
    let tempDateArr = date.split(",");
    let tempMonth = tempDateArr[1]
      .substring(1, tempDateArr[1].length)
      .split(" ");
    return (
      t(`Common:Days.${tempDateArr[0]}`) +
      ", " +
      t(`Common:Months.${tempMonth[0]}`) +
      " " +
      tempMonth[1] +
      "," +
      tempDateArr[2]
    );
  };

  const getUserMapsSchedule = async () => {
    dispatch(setShowLoader(true));
    try {
      const res = await Api.get("user/maps/schedule/all");
      if (res.data.payload) {
        const data = _.groupBy(res.data.payload, (reminder) =>
          new Date(reminder.Date).toLocaleDateString("en-US", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        );
        setRemindersByDates(data);
        dispatch(setShowLoader(false));
      }
    } catch (error) {
      console.log(error);
      dispatch(setShowLoader(false));
    }
  };
  return (
    <div className="pathways-schedule-container ">
      {!showLoader && (
        <>
          {Object.keys(remindersByDates).length > 0 ? (
            Object.keys(remindersByDates).map(function (date, i) {
              return (
                <React.Fragment key={i}>
                  <h3>{retrieveDate(date)}</h3>
                  {remindersByDates[date].map((reminder, j) => {
                    return (
                      <div key={j} className="reminder-container">
                        <div className="info">
                          <div className="break-words">
                            <h4 className="description">
                              {reminder.CategoryName} - {reminder.MainGoal}
                            </h4>
                            <p className="description">
                              {t("name")}:{" "}
                              <span className="italic">
                                {reminder.StepName}
                              </span>
                            </p>
                            <p className="description">
                              {t("start")}:{" "}
                              <span className="italic">
                                {dayjs(new Date(reminder.Date))
                                  .format("HH:mm")
                                  .toString()}
                              </span>
                            </p>
                          </div>
                          <div className="break-words">
                            {reminder.Completed ? (
                              <FontAwesomeIcon
                                className="icon completed"
                                icon={faCircleCheck}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="icon"
                                onClick={() => {
                                  navigate(
                                    `/maps/edit/${reminder.MapId}/goal/step/${
                                      reminder.StepIndex + 1
                                    }`
                                  );
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <br />
                </React.Fragment>
              );
            })
          ) : (
            <p className="description flex">{t("none")}</p>
          )}
        </>
      )}
    </div>
  );
};
