export const BaseLayerLottieStyle = {
  height: "100%",
  width: "100%",
  position: "absolute",
};

export const ImagePinLottieStyle = {
  height: "100%",
  width: "100%",
  position: "absolute",
  top: "-5%",
  rendererSettings: {
    preserveAspectRatio: "none",
  },
};

export const BackgroundLottieStyle = {
  // height: "100vh",
  // width: "100vw",
  position: "absolute",
  // scale: "1.1",
};

export const LeftTopMountainAndSunLottieStyle = {
  height: "100%",
  width: "100%",
  position: "absolute",
};

export const LeftBottomRockLottieStyle = {
  height: "100%",
  width: "100%",
  position: "absolute",
  zIndex: "1000",
  marginLeft: "-5px",
  rendererSettings: {
    preserveAspectRatio: "none",
  },
};
export const RightBottomRockLottieStyle = {
  height: "100%",
  width: "100%",
  position: "absolute",
  zIndex: "1000",
  marginLeft: "5px",
  rendererSettings: {
    preserveAspectRatio: "none",
  },
};

export const RightTopMountainLottieStyle = {
  height: "100%",
  width: "100%",
  position: "absolute",
  right: "-3%", // to do - not in iphone
  rendererSettings: {
    preserveAspectRatio: "none",
  },
  // zIndex: "1000",
};

export const BirdsLottieStyle = {
  height: "100%",
  width: "100%",
  position: "absolute",
};

export const FireoworksLottieStyle = {
  height: "80%",
  width: "100%",
  position: "absolute",
};

export const Step1LottieStyle = {
  height: "25%",
  width: "25%",
  position: "absolute",
  top: "47%",
  left: "23%",
};

export const Step2LottieStyle = {
  height: "20%",
  width: "20%",
  position: "absolute",
  top: "39%",
  left: "69%",
};

export const Step3LottieStyle = {
  height: "18%",
  width: "18%",
  position: "absolute",
  top: "25%",
  left: "50%",
  cursor: "pointer",
};
