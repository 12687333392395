import { forwardRef, useEffect, useState } from "react";
import { ReactComponent as VIcon } from "../../assets/icons/v.svg";
import { SmallTitle } from "../small-title/small-title";
import { useTranslation } from "react-i18next";
import "./input.scss";

export const Input = forwardRef(
  (
    { disabled, reset, isError, endIcon, width, maxHeight = 200, ...props },
    ref
  ) => {
    const { t, i18n } = useTranslation("Errors");

    const [classOfInput, setClassOfInput] = useState("");

    useEffect(() => {
      if (props.value && props.name) {
        document
          .getElementsByName(props.name)
          .forEach(
            (element) =>
              (element.style.height = `${calcDefaultHeight(element)}px`)
          );
      }
    }, [props.value, props.name]);

    const calcDefaultHeight = (element) => {
      element.style.height = "5px";
      if (maxHeight > Number(element.style.height.replace("px", "")))
        return (element.style.height = element.scrollHeight + "px");
      else return element.style.height;
    };

    const updateStatus = () =>
      setClassOfInput(classOfInput === "" ? " active" : "");

    return (
      <div className="input-container">
        {isError && !props.value.replace(/^\s+/g, "") && (
          <SmallTitle error={true}>{t("required-field")}</SmallTitle>
        )}
        <div
          className={
            "input flex" + (disabled ? " disabled" : "") + classOfInput
          }
          style={{
            width: width ? width : "",
            height: props.value ? "" : "46px",
            maxHeight: `${maxHeight}px`,
            overflowY: props.value?.length > 100 ? "auto" : "hidden",
          }}
        >
          <VIcon
            className={`icon flex start ${
              !(!disabled && props.value && props.value.replace(/^\s+/g, "")) &&
              "hidden"
            }`}
          />
          <textarea
            className="input-textarea"
            ref={ref}
            {...props}
            onFocus={updateStatus}
            onBlur={updateStatus}
            rows={1}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
);
