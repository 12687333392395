import React from "react";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { SpeechBubble } from "../../../../../../components/speech-bubble/speech-bubble";

import "./finshed-first-step-popup.scss";

import { useTranslation } from "react-i18next";

export const FinshedFirstStepPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  return (
    <Popup close={close} height="350px">
      <div className="finished-first-step-popup-container">
        <PopupTitle>{t("ViewMap.FinshedFirstStepPopUp.fantastic")}</PopupTitle>

        <SpeechBubble
          direction={"right"}
          top={"67px"}
          left={"25px"}
          width={"195px"}
          height={"100px"}
        >
          <p>{t("ViewMap.FinshedFirstStepPopUp.info1")}</p>
          <p>{t("ViewMap.FinshedFirstStepPopUp.info2")}</p>
          <p>{t("ViewMap.FinshedFirstStepPopUp.info3")}</p>
          <p>{t("ViewMap.FinshedFirstStepPopUp.info4")}</p>
        </SpeechBubble>

        <div className="popup-bottom-bottons-container flex">
          <PopupButton continue="true" onClick={close}>
            {t("Common:next")}
          </PopupButton>
        </div>
      </div>
    </Popup>
  );
};
