import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../../../../../components/input/input";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Tip } from "../../../../../../components/tip/tip";
import "./edit-inspiration-step.scss";
import { useTranslation } from "react-i18next";

export const EditInspirationStep = forwardRef(
  ({ inspiration, setBooster, showError, close, navigateToNextStep }, ref) => {
    const { t, i18n } = useTranslation("PopUps");

    const profile = useSelector((state) => state.profileReducer.profile);

    const handleChange = (name, selectedOption) => {
      setBooster((prevState) => {
        return {
          ...prevState,
          Inspiration: selectedOption.label,
        };
      });
    };

    const changeInspiration = (e) => {
      setBooster((prevState) => {
        return {
          ...prevState,
          Inspiration: e.target.value,
        };
      });
    };

    return (
      <>
        <div className="edit-inspiration-step-container">
          <div className="background-image"></div>
          <div className="content">
            <div className="header">
              {t("EditInspiration.my-hope-boosters")}{" "}
              <span className="examples">
                {t("EditInspiration.motivators")}
              </span>
            </div>

            <Input
              type="text"
              name="need"
              value={inspiration}
              width="265px"
              onChange={changeInspiration}
              // placeholder="My HOPE boosters..."
              isError={showError}
              ref={ref}
            />

            {/* <div className="header">Tip</div> */}
            <Tip width="265px">{t("EditInspiration.tip")}</Tip>
            <p className="do-you-want-to-add">
              {t("EditInspiration.add-booster")}
            </p>
            <div className="footer-buttons flex">
              <PopupButton
                className="edit-inspiration-button"
                onClick={navigateToNextStep}
              >
                {t("Common:sure")}
              </PopupButton>
              <PopupButton className="edit-inspiration-button" onClick={close}>
                {t("Common:not-now")}
              </PopupButton>
            </div>
          </div>
        </div>
      </>
    );
  }
);
