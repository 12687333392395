import dayjs from "dayjs";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateInput } from "../../../../../../components/date-input/date-input";
import { InputSwitch } from "../../../../../../components/input-switch/input-switch";
import { Input } from "../../../../../../components/input/input";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { SmallTitle } from "../../../../../../components/small-title/small-title";
import {
  changeProfile,
  updateDraftMap,
} from "../../../../../../store/profile/profile-slice";
import { ReactComponent as GoogleCalendar } from "../../../../../../assets/icons/google-calendar.svg";
import { ContactUpload } from "../../../../../../components/contacts-upload/contacts-upload";
import "./create-step-card.scss";
import { useTranslation } from "react-i18next";
import { AddToCalendarButton } from "../../../../../../components/add-to-calendar-button/add-to-calendar-button";
import { showToastMessage } from "../../../../../../store/app-settings/app-settings-slice";
import { NativeEvents } from "../../../../../../utils/enums";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import axios from "axios";

const CLIENT_ID =
  "101505278931-6ds1qli3mldqce7mplunao0a0ganpbcj.apps.googleusercontent.com";
// const CLIENT_ID = "101505278931-1en7aij2rgdge0v4j8q1v7eoa3ep5n7p.apps.googleusercontent.com"

export const CreateStepCard = forwardRef(
  (
    {
      stepIndex,
      finishedDefineStepsNames,
      setUserPointsTarget,
      TimeToComplete,
      setTimeToComplete,
    },
    ref
  ) => {
    const { t, i18n } = useTranslation("Maps");

    const isIOS = /iPad|iPhone|iPod|Windows/.test(navigator.userAgent);

    const uploadRef = useRef();
    const stepRefs = useRef([]);
    const profile = useSelector((state) => state.profileReducer.profile);
    const dispatch = useDispatch();
    const [step, setStep] = useState({
      Name: "",
      StartDate: null,
      Obstacle: "",
      Overcome: "",
      Phone: "",
      Email: "",
      ContactName: "",
      StartReminder: null,
      CalendarEventId: null,
    });
    const [showReminder, setShowReminder] = useState(false);
    const [showError, setShowError] = useState(false);
    const [wrote, setWrote] = useState(
      (!finishedDefineStepsNames &&
        profile.DraftMap?.Map.Steps[stepIndex].Name) ||
        (finishedDefineStepsNames &&
          profile.DraftMap?.Map.Steps[stepIndex].Obstacle)
        ? true
        : false
    );

    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      document.addEventListener("message", calendarListener);

      return () => {
        document.removeEventListener("message", calendarListener);
      };
    }, []);

    useEffect(() => {
      if (profile && profile.DraftMap?.Map) {
        const draftMap = profile.DraftMap?.Map;
        let step = { ...draftMap.Steps[stepIndex] };
        setStep({
          Name: step.Name,
          StartDate: step.StartDate,
          Obstacle: step.Obstacle,
          Overcome: step.Overcome,
          Phone: step.Phone,
          Email: step.Email,
          ContactName: step.ContactName,
          StartReminder: step.StartReminder,
        });
        setShowReminder(step.StartReminder !== null);
      }
    }, [profile]);

    useImperativeHandle(ref, () => ({
      validateStepName() {
        if (!step.Name.replace(/^\s+/g, "")) {
          setShowError(true);
          focusEmptyInput("Name");
          return false;
        }
        return true;
      },
      validateStep() {
        if (!step.Obstacle.replace(/^\s+/g, "")) {
          setShowError(true);
          focusEmptyInput("Obstacle");
          return false;
        }
        if (!step.Overcome.replace(/^\s+/g, "")) {
          setShowError(true);
          focusEmptyInput("Overcome");
          return false;
        }
        return true;
      },
    }));

    const changeDraftMap = async (data) => {
      dispatch(updateDraftMap(data));
    };

    const [debouncedUpdateDaftMap] = useState(() =>
      debounce(changeDraftMap, 1000)
    );

    const calendarListener = (message) => {
      if (message.data?.calendarEventSaved) {
        const calendarEventId = message.data.calendarEventId;
        setStep((prevState) => ({
          ...prevState,
          CalendarEventId: calendarEventId,
        }));
        dispatch(
          showToastMessage({
            loading: false,
            text: t("CreateMap.Steps.reminder-added-successfully"),
            type: toast.TYPE.SUCCESS,
          })
        );
      }
      // else if (message.data?.calendarEventRemoved) {
      //   setStep((prevState) => ({
      //     ...prevState,
      //     CalendarEventId: null,
      //   }));
      //   removedFromCalendarInDB();
      // }
    };

    const focusEmptyInput = (name) => {
      const index = Object.keys(step).indexOf(name);
      stepRefs.current[index].select();
      stepRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    };

    const saveStep = (name, value) => {
      let draftMap = { ...profile.DraftMap.Map };
      let newSteps = [...draftMap.Steps];
      newSteps[stepIndex] = {
        ...draftMap.Steps[stepIndex],
        [name]: draftMap.Steps[stepIndex].Name,
      };

      dispatch(
        changeProfile({
          ...profile,
          DraftMap: {
            ...profile.DraftMap,
            Map: {
              ...profile.DraftMap.Map,
              Steps: newSteps,
            },
          },
        })
      );
    };

    const handleChange = (e) => {
      setStep({
        ...step,
        [e.target.name]: e.target.value,
      });

      let draftMap = { ...profile.DraftMap.Map };
      let newSteps = [...draftMap.Steps];
      newSteps[stepIndex] = {
        ...draftMap.Steps[stepIndex],
        [e.target.name]: e.target.value,
      };

      const newDraftMap = {
        ...profile.DraftMap.Map,
        Steps: newSteps,
      };
      dispatch(
        changeProfile({
          ...profile,
          DraftMap: { ...profile?.DraftMap, Map: newDraftMap },
        })
      );
      setUserPointsTarget({ target: e.target.name, stepIndex: stepIndex });

      // debouncedUpdateDaftMap(newDraftMap);
      // saveStep(e.target.name, e.target.value);
    };

    const changeDate = (name, value) => {
      setStep({
        ...step,
        name: value,
      });

      let draftMap = { ...profile.DraftMap.Map };
      let newSteps = [...draftMap.Steps];
      newSteps[stepIndex] = {
        ...draftMap.Steps[stepIndex],
        [name]: value,
      };

      const newDraftMap = {
        ...profile.DraftMap.Map,
        Steps: newSteps,
      };

      debouncedUpdateDaftMap({ ...newDraftMap, TimeToComplete });
      setTimeToComplete(0);
      // saveStep(name, value);
    };

    const changeContact = (name, phone, email) => {
      if (name && phone && email) {
        setStep((prevState) => ({
          ...prevState,
          ContactName: name,
          Phone: phone,
          Email: email,
        }));
        let draftMap = { ...profile.DraftMap.Map };
        let newSteps = [...draftMap.Steps];
        newSteps[stepIndex] = {
          ...draftMap.Steps[stepIndex],
          ContactName: name,
          Phone: phone,
          Email: email,
        };
        dispatch(
          changeProfile({
            ...profile,
            DraftMap: {
              ...profile.DraftMap,
              Map: {
                ...profile.DraftMap.Map,
                Steps: newSteps,
              },
            },
          })
        );
      } else if (name && phone && !email) {
        setStep((prevState) => ({
          ...prevState,
          ContactName: name,
          Phone: phone,
        }));
        let draftMap = { ...profile.DraftMap.Map };
        let newSteps = [...draftMap.Steps];
        newSteps[stepIndex] = {
          ...draftMap.Steps[stepIndex],
          ContactName: name,
          Phone: phone,
        };
        dispatch(
          changeProfile({
            ...profile,
            DraftMap: {
              ...profile.DraftMap,
              Map: {
                ...profile.DraftMap.Map,
                Steps: newSteps,
              },
            },
          })
        );
      } else if (name && !phone && !email) {
        setStep((prevState) => ({
          ...prevState,
          ContactName: name,
        }));
        saveStep("ContactName", name);
      }
    };

    const addToCalendar = () => {
      if (window.ReactNativeWebView) {
        if (!step.CalendarEventId) {
          const stepData = {
            Name: step.Name,
            Date: step.StartDate,
          };
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              event: NativeEvents.AddToCalendar,
              stepData,
            })
          );
        } else {
          dispatch(
            showToastMessage({
              text: "Already added",
              type: toast.TYPE.ERROR,
            })
          );
        }
      } else {
        dispatch(
          showToastMessage({
            text: "Please login through the app in order to use this function",
            type: toast.TYPE.ERROR,
          })
        );
      }
    };

    const removeFromCalendar = () => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            event: NativeEvents.RemoveFromCalendar,
            calendareEvenId: step.CalendarEventId,
          })
        );
      } else {
        dispatch(
          showToastMessage({
            text: "Please login through the app in order to use this function",
            type: toast.TYPE.ERROR,
          })
        );
      }
    };

    return (
      <div className="create-step-card-container" ref={ref}>
        {/* <div className="popup-titles-wrapper">
        <PopupTitle>Pathway #{stepIndex + 1}</PopupTitle>
      </div> */}
        {!finishedDefineStepsNames && (
          <>
            <div className="description">
              <div>
                {t("CreateMap.Steps.describe1")}
                <span className="pathway-number-name">
                  {stepIndex === 0
                    ? t("Common:first")
                    : stepIndex === 1
                    ? t("Common:second")
                    : t("Common:third")}
                </span>{" "}
                {t("CreateMap.Steps.describe2")}
              </div>
              <div>{t("CreateMap.Steps.describe3")}</div>
              <div>'{t("Common:next")}'.</div>
            </div>
            <div className="inputs-container flex">
              <Input
                width="330px"
                type="text"
                name="Name"
                value={step.Name}
                onChange={handleChange}
                isError={showError}
                placeholder={t("CreateMap.Steps.I-will")}
                ref={(el) =>
                  (stepRefs.current[Object.keys(step).indexOf("Name")] = el)
                }
              />

              <br />
              <div className="create-date-container step1">
                <DateInput
                  dateValue={step.StartDate}
                  updateDateValue={changeDate}
                  title={t("CreateMap.Steps.set-date")}
                  style={{ marginBottom: "15px" }}
                  name={"StartDate"}
                  value={step.StartDate}
                />
              </div>
            </div>
          </>
        )}
        {profile && finishedDefineStepsNames && (
          <>
            <SmallTitle
              style={{
                fontSize: "24px",
                textAlign: "center",
                textDecoration: "underline",
                wordWrap: "break-word",
                marginBottom: "20px",
              }}
            >
              {step.Name}
            </SmallTitle>
            <SmallTitle>{t("CreateMap.Steps.obstacle")}</SmallTitle>
            <Input
              type="text"
              name="Obstacle"
              value={step.Obstacle}
              onChange={handleChange}
              isError={showError}
              ref={(el) =>
                (stepRefs.current[Object.keys(step).indexOf("Obstacle")] = el)
              }
            />
            <SmallTitle>{t("CreateMap.Steps.overcome")}</SmallTitle>
            <Input
              type="text"
              name="Overcome"
              value={step.Overcome}
              onChange={handleChange}
              isError={showError}
              ref={(el) =>
                (stepRefs.current[Object.keys(step).indexOf("Overcome")] = el)
              }
            />
            <br />
            <SmallTitle style={{ fontSize: "18px" }}>
              {t("CreateMap.Steps.helpers-text1")}
              <br />
              {t("CreateMap.Steps.helpers-text2")}
            </SmallTitle>
            <hr />
            <ContactUpload
              onChange={changeContact}
              ref={uploadRef}
              phone={profile.Phone}
            />
            <SmallTitle>{t("CreateMap.Steps.add-contact-name")}</SmallTitle>
            <Input
              type="text"
              name="ContactName"
              value={step.ContactName || ""}
              onChange={handleChange}
            />
            <SmallTitle>{t("CreateMap.Steps.add-phone")}</SmallTitle>
            <Input
              type="number"
              name="Phone"
              value={step.Phone || ""}
              onChange={handleChange}
            />
            <SmallTitle>{t("CreateMap.Steps.add-email")}</SmallTitle>
            <Input
              type="text"
              name="Email"
              value={step.Email || ""}
              onChange={handleChange}
            />

            {/* <SmallTitle>
            Research shows that its helpful to decide on a date to begin
          </SmallTitle> */}
            {/* <Input
            type="datetime-local"
            name="StartDate"
            value={step.StartDate || ""}
            onChange={handleChange}
            reset={resetValue}
            min={now}
          /> */}
            <SmallTitle>{t("CreateMap.Steps.set-date")}</SmallTitle>
            <div className="dateinput-and-calendar flex">
              <div className="create-date-container step2">
                <DateInput
                  dateValue={step.StartDate}
                  updateDateValue={changeDate}
                  title={t("CreateMap.Steps.set-date")}
                  name={"StartDate"}
                  value={step.StartDate}
                />
              </div>
              {step.StartDate && (
                <AddToCalendarButton
                  step={step}
                  addToCalendar={addToCalendar}
                  removeFromCalendar={removeFromCalendar}
                />
                // <button className="calendar-container" disabled={!step.StartDate}>
                //   <a
                //     className="add-to-calendar"
                //     target="_blank"
                //     href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${
                //       step.Name
                //     }&details=A+reminder+to+log+in+to+the+hopetimize+app&dates=
                //             ${dayjs(step.StartDate)
                //               .add(1, "day")
                //               .format()
                //               .replace(/-|:|\.\d\d\d/g, "")}/
                //             ${dayjs(step.StartDate)
                //               .format()
                //               .replace(/-|:|\.\d\d\d/g, "")}
                //           `}
                //   >
                //     <span className="flex">
                //       <div className="calander-wrapper flex">
                //         <GoogleCalendar />
                //       </div>
                //       {t("CreateMap.Steps.add-to-google-calendar")}
                //     </span>
                //   </a>
                // </button>
              )}
            </div>

            <div>
              <InputSwitch
                title={t("CreateMap.Steps.set-reminder")}
                checked={showReminder}
                style={{
                  margin: "10px 0",
                  fontWeight: "700",
                  color: "#015568",
                }}
                setCheked={() => {
                  if (showReminder)
                    setStep({
                      ...step,
                      StartReminder: false,
                    });
                  setShowReminder((prevState) => !prevState);
                }}
              />
              {showReminder && (
                <>
                  <SmallTitle>
                    Reminder
                    {!profile.Settings.Notifications.Email &&
                      !profile.Settings.Notifications.Allow && (
                        <span className="disabled-reminder">
                          &nbsp;({t("CreateMap.Steps.reminders-disabled")}&nbsp;
                          <a href="/profile-settings">
                            {t("CreateMap.Steps.settings")}
                          </a>
                          )
                        </span>
                      )}
                  </SmallTitle>

                  <DateInput
                    dateValue={step.StartReminder}
                    updateDateValue={changeDate}
                    title={t("CreateMap.Steps.set-reminder")}
                    name={"StartReminder"}
                    value={step.StartReminder}
                  />

                  {/* <Input
                  className={
                    !profile.Settings.Notifications.Email &&
                      !profile.Settings.Notifications.Mobile
                      ? "disabled"
                      : ""
                  }
                  type="datetime-local"
                  name="StartReminder"
                  value={step.StartReminder || ""}
                  onChange={handleChange}
                  reset={resetValue}
                  min={now}
                /> */}
                </>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
);
