import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { SpeechBubble } from "../../../../../../components/speech-bubble/speech-bubble";
import "./finished-setting-three-steps-popup.scss";
import { useTranslation } from "react-i18next";

export const FinishedSettingThreeStepsPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  return (
    <Popup width={"335px"} height={"482px"} close={close}>
      <div className="finished-setting-three-steps-popup">
        <div className="headers flex">
          <PopupTitle small={true}>
            {t("CreateMap.FinishedSettingThreeStepsPopUp.you-have")}
          </PopupTitle>
          <PopupTitle>
            {t("CreateMap.FinishedSettingThreeStepsPopUp.set-pathways")}
          </PopupTitle>
        </div>

        <SpeechBubble
          direction={"right"}
          top={"85px"}
          left={"15px"}
          width={"238px"}
          height={"145px"}
        >
          <div className="speeach-bubble-text-container flex">
            <p>{t("CreateMap.FinishedSettingThreeStepsPopUp.unfortunately")}</p>
            <p>{t("CreateMap.FinishedSettingThreeStepsPopUp.encounter")}</p>
            <p>{t("CreateMap.FinishedSettingThreeStepsPopUp.but")}</p>
            <p>
              {t(
                "CreateMap.FinishedSettingThreeStepsPopUp.descrine-challenges"
              )}
            </p>
            <p>{t("CreateMap.FinishedSettingThreeStepsPopUp.or-obstacles")}</p>
          </div>
        </SpeechBubble>

        <div className="background"></div>

        <div className="popup-bottom-bottons-container flex">
          <PopupButton onClick={close}>{t("Common:next")}</PopupButton>
        </div>
      </div>
    </Popup>
  );
};
