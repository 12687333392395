import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/button/button";
import { setTitle } from "../../../store/app-settings/app-settings-slice";
import { changeProfile } from "../../../store/profile/profile-slice";
import Api from "../../../utils/api";
import "./privacy-policy.scss";
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation("OnBoarding");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);

  useEffect(() => {
    if (profile && profile.PrivacyPolicyAccepted) {
      navigate("../maps");
    }
  }, [profile]);

  useEffect(() => {
    dispatch(setTitle(t("PrivacyPolicy.title")));
  }, [profile, i18n]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const acceptPrivacyPolicy = async () => {
    try {
      const res = await Api.put("user/profile/privacyPolicy");
      if (res.data.payload) {
        await dispatch(
          changeProfile({ ...profile, PrivacyPolicyAccepted: true })
        );
        navigate("/welcome");
      } else {
        navigate("../maps");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="privacy-policy-container">
      {/* {t("PrivacyPolicy.section3")} */}
      <p
        dangerouslySetInnerHTML={{ __html: t("PrivacyPolicy.company-info") }}
      ></p>
      <p
        dangerouslySetInnerHTML={{ __html: t("PrivacyPolicy.collection-info") }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: t("PrivacyPolicy.protection-data-info"),
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: t("PrivacyPolicy.data-use-info"),
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: t("PrivacyPolicy.transfer-data-and-refferal"),
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: t("PrivacyPolicy.cookies-info"),
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: t("PrivacyPolicy.other-info"),
        }}
      ></p>
      <a target="_blank" href={t("PrivacyPolicy.url")}>
        {t("PrivacyPolicy.url title")}
      </a>
      <p>{t("PrivacyPolicy.url other title")}</p>
      <a target="_blank" href={t("PrivacyPolicy.url")}>
        {t("PrivacyPolicy.url")}
      </a>
      <Button className="accept-button" onClick={acceptPrivacyPolicy}>
        {t("PrivacyPolicy.accept")}
      </Button>
    </div>
  );
};
