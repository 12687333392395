import React from "react";
import { animated, config, useSpring } from "react-spring";
import "./footer-menu-button.scss";

export const FooterMenuButton = ({ text, icon, index, ...props }) => {
  var animation = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(100px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0px)",
    },
    config: config.wobbly, // make it nice and wobbly,
    delay: 50 * (index + 1),
  });

  return (
    <animated.div
      className="footer-menu-button-container"
      style={animation}
      {...props}
    >
      <div className="circle-icon flex">{icon}</div>
      <div className="text flex">{text}</div>
    </animated.div>
  );
};
