import React, { forwardRef, useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./rating-slider.scss";
import { SmallTitle } from "../small-title/small-title";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const RatingSlider = forwardRef(
  ({ min, max, changeHandler, name, value, isError, ...props }, ref) => {
    const { t, i18n } = useTranslation("Common");

    const [step, setStep] = useState(0);
    const [marks, setmarks] = useState(null);
    const { direction } = useSelector((state) => ({
      direction: state.appSettingsReducer.direction,
    }));

    //when user sent default rating
    useEffect(() => {
      if (max && value) inititalMarks(max, value);
    }, [max, value]);

    //when user changed rating
    useEffect(() => {
      if (max && step != 0) inititalMarks(max, step);
    }, [max, step]);

    const inititalMarks = (maxNumber, num) => {
      let createMarks = {};
      for (let i = 0; i <= maxNumber; i++) {
        if (num === i)
          createMarks[i] = (
            <p style={i < 10 ? { left: "-5px" } : { left: "-10px" }}>{i}</p>
          );
      }
      setmarks(createMarks);
    };

    return (
      <>
        <div className="rating-bar">
          {isError &&
            Number.isInteger(value) &&
            Number.isInteger(min) &&
            value <= min && (
              <SmallTitle error={true}>Required field</SmallTitle>
            )}

          <div className="slider-wrapper" ref={ref}>
            <Slider
              {...props}
              // count={2}
              // disabled={disabled}
              // reverse={reverse}
              // vertical={vertical}
              // range={range}
              // defaultValue={[30, 50]}
              // dots
              // step={5}
              // pushable={5}

              reverse={direction === "rtl"}
              draggableTrack
              allowCross={true}
              onChange={(nextValues) => {
                setStep(nextValues);
                changeHandler(nextValues, name);
                if (nextValues === 0) setmarks(null);
              }}
              min={min}
              max={max}
              value={value}
              step={1}
              marks={marks}
              name={name}
            />
            <div className="headlines">
              <p>
                {min + 1}-{t("lowest")}
              </p>
              <p>
                {max}-{t("highest")}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
);
