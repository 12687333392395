import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArchivedMapCard } from "../../../components/archived-map-card/archived-map-card";
import {
  setShowLoader,
  setTitle,
} from "../../../store/app-settings/app-settings-slice";
import Api from "../../../utils/api";
import { ReactComponent as EducationCategoryIcon } from "../../../assets/icons/education-category.svg";
import { ReactComponent as FamilyCategoryIcon } from "../../../assets/icons/family-category.svg";
import { ReactComponent as FinancialCategoryIcon } from "../../../assets/icons/financial-category.svg";
import { ReactComponent as HealthCategoryIcon } from "../../../assets/icons/health-category.svg";
import { ReactComponent as LegacyCategoryIcon } from "../../../assets/icons/legacy-category.svg";
import { ReactComponent as LeisureCategoryIcon } from "../../../assets/icons/leisure-category.svg";
import { ReactComponent as OtherCategoryIcon } from "../../../assets/icons/other-category.svg";
import { ReactComponent as ProfessionalOrCareerCategoryIcon } from "../../../assets/icons/professional-or-career-category.svg";
import { ReactComponent as SelfAndWellbeingCategoryCategoryIcon } from "../../../assets/icons/self-and-wellbeing-category.svg";
import { ReactComponent as SocialAndCommunityCategoryIcon } from "../../../assets/icons/social-and-community.svg";
import { ReactComponent as SpiritualCategoryIcon } from "../../../assets/icons/spiritual-category.svg";
import "./archived-maps.scss";
import { getCategoryColor } from "../../../utils/mapFunc";
import { useTranslation } from "react-i18next";

export const ArchivedMaps = () => {
  const { t, i18n } = useTranslation("ArchivedMaps");

  const dispatch = useDispatch();
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const [categories, setCategories] = useState([]);
  const [mapsCount, setMapsCount] = useState(0);

  useEffect(() => {
    dispatch(setTitle(t("title")));
    getUserArchivedMaps();
  }, [i18n]);

  const getUserArchivedMaps = async () => {
    dispatch(setShowLoader(true));
    try {
      const res = await Api.get("user/maps/list/archived");
      if (res.data) {
        const newCategories = res.data.payload;
        setCategories(newCategories);
        const newMapsCount = [].concat.apply(
          [],
          newCategories.map((category) => category.Maps.map((m) => m))
        );
        setMapsCount(newMapsCount.length);
      }
      dispatch(setShowLoader(false));
    } catch {
      dispatch(setShowLoader(false));
    }
  };

  const getCategoryIcon = (name) => {
    switch (name) {
      case "Health":
        return <HealthCategoryIcon className="icon" />;
      case "Self & Wellbeing":
        return <SelfAndWellbeingCategoryCategoryIcon className="icon" />;
      case "Family":
        return <FamilyCategoryIcon className="icon" />;
      case "Leisure":
        return <LeisureCategoryIcon className="icon" />;
      case "Spiritual":
        return <SpiritualCategoryIcon className="icon" />;
      case "Social & Community":
        return <SocialAndCommunityCategoryIcon className="icon" />;
      case "Professional/Career":
        return <ProfessionalOrCareerCategoryIcon className="icon" />;
      case "Financial":
        return <FinancialCategoryIcon className="icon" />;
      case "Education":
        return <EducationCategoryIcon className="icon" />;
      case "Legacy":
        return <LegacyCategoryIcon className="icon" />;
      default:
        return <OtherCategoryIcon className="icon" />;
    }
  };

  return (
    <div className="archived-maps-container">
      {!showLoader && (
        <>
          {mapsCount > 0 && categories.length > 0 ? (
            categories.map((category, i) => {
              if (category.Maps.length > 0) {
                return (
                  <div key={category.Id} className="category-container">
                    <div className="category-header flex">
                      <div className="category-icon">
                        {getCategoryIcon(category.Name)}
                      </div>
                      <span className="category-name">{category.Name}</span>
                    </div>
                    <hr
                      style={{ background: getCategoryColor(category.Name) }}
                    />
                    {category.Maps.map((m) => {
                      return <ArchivedMapCard key={m.Id} map={m} />;
                    })}
                    <br />
                  </div>
                );
              }
              return <React.Fragment key={category.Id}></React.Fragment>;
            })
          ) : (
            <>
              <p className="description flex">{t("none")}</p>
            </>
          )}
        </>
      )}
    </div>
  );
};
