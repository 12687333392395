import {
  faCircleCheck,
  faListCheck,
  faListUl,
  faMapLocationDot,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import StatusIcon from "../../../../assets/images/welcome-back-status-icon.png";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopupButton } from "../../../../components/popup-button/popup-button";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import {
  setGoodToHaveYouBackPopupOpen,
  setHowsYourJourneyGoingPopupOpen,
} from "../../../../store/app-settings/app-settings-slice";
import Api from "../../../../utils/api";
import { WelcomeBackStatsCard } from "./welcome-back-stats-card/welcome-back-stats-card";
import "./welcome-back-popup.scss";
import { useTranslation } from "react-i18next";

export const WelcomeBackPopup = ({ setWelcomeBackPopupOpen }) => {
  const { t, i18n } = useTranslation("PopUps");

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const [stats, setStats] = useState({
    ActiveMaps: 0,
    StepsToTake: 0,
    DoneMaps: 0,
    StepsYouDid: 0,
    Points: 0,
  });

  useEffect(() => {
    getProfileStatistics();
  }, []);

  const getProfileStatistics = async () => {
    const res = await Api.get("user/profile/statistics");
    if (res.data.payload) {
      setStats(res.data.payload);
    }
  };

  return (
    <Popup>
      <div className="welcome-back-container flex">
        <div className="headers flex">
          <PopupTitle>
            {t("Common:welcome-back")} {profile.FirstName}
          </PopupTitle>
          <div className="journey-preview">
            <PopupTitle small={true}>
              {t("WelcomeBack.journey-preview")}
            </PopupTitle>
          </div>
        </div>
        <WelcomeBackStatsCard
          icon={faMapLocationDot}
          label={t("WelcomeBack.active-maps")}
          value={stats.ActiveMaps}
        />
        <WelcomeBackStatsCard
          icon={faListUl}
          label={t("WelcomeBack.pathways-to-take")}
          value={stats.StepsToTake}
        />
        <WelcomeBackStatsCard
          icon={faCircleCheck}
          label={t("WelcomeBack.completed-maps")}
          value={stats.DoneMaps}
        />
        <WelcomeBackStatsCard
          icon={faListCheck}
          label={t("WelcomeBack.pathways-you-achieved")}
          value={stats.StepsYouDid}
        />
        <WelcomeBackStatsCard
          icon={StatusIcon}
          label={"Current status"}
          value={
            stats.Points < 1001
              ? "Expert"
              : stats.Points > 1000 && stats.Points < 2501
              ? "Master"
              : stats.Points > 2500 && stats.Points < 5001
              ? "Legend"
              : "Champion"
          }
          imageIcon={true}
        />
        <WelcomeBackStatsCard
          icon={faStar}
          label={t("WelcomeBack.points")}
          value={stats.Points}
        />

        <PopupButton
          className="continue-button"
          onClick={() => {
            setWelcomeBackPopupOpen(false);
            if (profile.FirstMapCreatedDate) {
              if (profile.Boosters.Answers.length === 0) {
                const daysPassedSinceFirstLogin = dayjs().diff(
                  dayjs(profile.FirstLoginDate),
                  "days"
                );
                if (
                  !(
                    profile.FirstMapCreatedDate &&
                    profile.Boosters.Answers.length === 0 &&
                    daysPassedSinceFirstLogin !== 0 &&
                    daysPassedSinceFirstLogin % 30 === 0
                  )
                ) {
                  // console.log("setHowsYourJourneyGoingPopupOpen");
                  dispatch(setHowsYourJourneyGoingPopupOpen(true));
                }
                // setBoosterQuestionsInitPopupOpen(true);
              } else {
                // console.log("setGoodToHaveYouBackPopupOpen");
                dispatch(setGoodToHaveYouBackPopupOpen(true));
              }
            }
          }}
        >
          {t("WelcomeBack.continue")}
        </PopupButton>
      </div>
    </Popup>
  );
};
