import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../utils/api";

let initialState = {
  map: null,
  selectedCategory: null,
  footerMenuOpen: false,
  shareMapPopupOpen: false,
  viewMainGoalPopup: {
    open: false,
    goal: "",
  },
  mapLottieViews: {
    loaded: false,
    initialSegment: {
      updated: false,
      value: 0,
    },
  },
  categoryNavigationMenuOpen: false,
  isLoadingMap: true,
  stepDetailsPopup: {
    open: false,
    index: 0,
  },
  startMapPopupOpen: false,
  startMapPopupOpenedOnce: false,
  // map: {
  //   isLoading: false,
  //   isError: false,
  //   _id: "",
  //   User: "",
  //   UserExternalId: "",
  //   Category: {
  //     _id: "",
  //     Name: "",
  //     RatingSum: 0,
  //     UsersCoun: 0,
  //     Deleted: false,
  //   },
  //   CategoryName: "",
  //   CreateTime: 0,
  //   CreateTime: "",
  //   CreateDate: "",
  //   CompletedDate: null,
  //   Completed: false,
  //   Archived: false,
  //   MainGoal: "",
  //   OldMainGoals: [],
  //   StrengthWord: "",
  //   NumberOfMapShares: 0,
  //   Steps: [
  //     {
  //       Name: "",
  //       Obstacle: "",
  //       Overcome: "",
  //       Phone: "",
  //       Email: "",
  //       Location: "",
  //       ChangedByUser: false,
  //       StartDate: null,
  //       StartReminder: null,
  //       Completed: false,
  //       CompletedDate: null,
  //     },
  //   ],
  //   Image: "",
  //   Sharings: [
  //     {
  //       Platform: "",
  //       ReceiverType: "",
  //     },
  //   ],
  // },
};

export const getMapById = createAsyncThunk("user/maps", async (mapId) => {
  try {
    const res = await Api.get(`user/maps/${mapId}`);
    return res.data.payload;
  } catch (error) {
    console.log(error);
  }
});

export const mapSlice = createSlice({
  name: "map",
  initialState: initialState,
  reducers: {
    changeMap: (state, action) => {
      state.map = action.payload;
    },
    changeSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    changeFooterMenuOpen: (state, action) => {
      state.footerMenuOpen = action.payload;
    },
    changeShareMapPopupOpen: (state, action) => {
      state.shareMapPopupOpen = action.payload;
    },
    changeCategoryNavigationMenuOpen: (state, action) => {
      state.categoryNavigationMenuOpen = action.payload;
    },
    changeIsLoadingMap: (state, action) => {
      state.isLoadingMap = action.payload;
    },
    changeStepDetailsPopup: (state, action) => {
      state.stepDetailsPopup = action.payload;
    },
    changeMapLottieViews: (state, action) => {
      state.mapLottieViews = action.payload;
    },
    changeViewMainGoalPopup: (state, action) => {
      state.viewMainGoalPopup = action.payload;
    },
    changeMapInstructionsPopupOpen: (state, action) => {
      state.mapInstructionsPopupOpen = action.payload;
    },
    changeMapInstructionsPopupOpenedOnce: (state, action) => {
      state.mapInstructionsPopupOpenedOnce = action.payload;
    },
  },
  extraReducers: {
    [getMapById.pending]: (state) => {
      // state.map.isLoading = true;
    },
    [getMapById.fulfilled]: (state, action) => {
      state.map = action.payload;
    },
    [getMapById.rejected]: (state, action) => {
      state.map = initialState.map;
    },
  },
});

export const {
  changeMap,
  changeSelectedCategory,
  changeFooterMenuOpen,
  changeShareMapPopupOpen,
  changeMapLottieViews,
  changeCategoryNavigationMenuOpen,
  changeIsLoadingMap,
  changeStepDetailsPopup,
  changeViewMainGoalPopup,
  changeMapInstructionsPopupOpen,
  changeMapInstructionsPopupOpenedOnce,
} = mapSlice.actions;
export default mapSlice.reducer;
