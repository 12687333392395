import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../utils/api";

let initialState = {
  profile: null,
  appInitRequired: false,
  points: 0,
  overwirteGetPoints: false,
};

export const getProfile = createAsyncThunk("user/profile", async () => {
  try {
    const res = await Api.get(`user/profile`);
    return res.data.payload;
  } catch (error) {
    console.log(error);
  }
});

export const updateDraftMap = createAsyncThunk(
  "user/profile/draftMap",
  async (data) => {
    try {
      const res = await Api.put("user/profile/draftMap", {
        CreateDate: new Date(),
        ...data,
      });
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserCountry = createAsyncThunk(
  "user/profile/country",
  async () => {
    try {
      const res = await Api.get("user/profile/country");
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserFavoriteCategories = createAsyncThunk(
  "user/profile/favoriteCategories",
  async () => {
    try {
      const res = await Api.get("user/profile/favoriteCategories");
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserTags = createAsyncThunk("user/profile/tags", async () => {
  try {
    const res = await Api.get("user/profile/tags");
    return res.data.payload;
  } catch (error) {
    console.log(error);
  }
});

export const bookmarkUserContent = createAsyncThunk(
  "user/profile/userContents/bookmark",
  async (data) => {
    try {
      await Api.post("user/profile/userContents/bookmark", data);
    } catch (error) {
      console.log(error);
    }
  }
);

export const viewUserContent = createAsyncThunk(
  "user/profile/userContents/view",
  async (data) => {
    try {
      const res = await Api.post("user/profile/userContents/view", data);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateUserPoints = createAsyncThunk(
  "user/profile/points",
  async ({ points, createStepType = "" }) => {
    console.log("updateUserPoints");
    try {
      const res = await Api.put(`user/profile/points`, {
        points: points,
        createStepType: createStepType,
      });
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserPoints = createAsyncThunk(
  "user/profile/points",
  async () => {
    try {
      const res = await Api.get("user/profile/points");
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  }
);

export const assignContentsToUser = createAsyncThunk(
  "user/newUser/contents",
  async () => {
    try {
      await Api.put("user/profile/newUser/contents");
    } catch (error) {
      console.log(error);
    }
  }
);

export const profileSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    changeProfile: (state, action) => {
      state.profile = action.payload;
    },
    resetDraftMap: (state, action) => {
      state.profile.DraftMap = null;
    },
  },
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => {
      state.profile = action.payload;
      state.appInitRequired = true;
    },
    [viewUserContent.fulfilled]: (state, action) => {
      state.profile.Points = action.payload;
      state.appInitRequired = true;
    },
    [updateDraftMap.fulfilled]: (state, action) => {
      state.profile.DraftMap = action.payload;
    },
    [updateUserPoints.fulfilled]: (state, action) => {
      state.profile.Points = action.payload;
    },
    [getUserPoints.fulfilled]: (state, action) => {
      state.profile.Points = action.payload;
    },
  },
});

export const { changeProfile, resetDraftMap } = profileSlice.actions;
export default profileSlice.reducer;
