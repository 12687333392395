import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EducationCategoryIcon } from "../../../assets/icons/education-category.svg";
import { ReactComponent as FamilyCategoryIcon } from "../../../assets/icons/family-category.svg";
import { ReactComponent as FinancialCategoryIcon } from "../../../assets/icons/financial-category.svg";
import { ReactComponent as HealthCategoryIcon } from "../../../assets/icons/health-category.svg";
import { ReactComponent as LegacyCategoryIcon } from "../../../assets/icons/legacy-category.svg";
import { ReactComponent as LeisureCategoryIcon } from "../../../assets/icons/leisure-category.svg";
import { ReactComponent as OtherCategoryIcon } from "../../../assets/icons/other-category.svg";
import { ReactComponent as ProfessionalOrCareerCategoryIcon } from "../../../assets/icons/professional-or-career-category.svg";
import { ReactComponent as SelfAndWellbeingCategoryCategoryIcon } from "../../../assets/icons/self-and-wellbeing-category.svg";
import { ReactComponent as SocialAndCommunityCategoryIcon } from "../../../assets/icons/social-and-community.svg";
import { ReactComponent as SpiritualCategoryIcon } from "../../../assets/icons/spiritual-category.svg";
import { Button } from "../../../components/button/button";
import { Loader } from "../../../components/loader/loader";
import { MapCard } from "../../../components/map-card/map-card";
import {
  setShowLoader,
  setTitle,
  showToastMessage,
} from "../../../store/app-settings/app-settings-slice";
import Api from "../../../utils/api";
import { getCategoryColor } from "../../../utils/mapFunc";
import { DraftMapPopup } from "../app-popups/draft-map/draft-map-popup";
import "./maps.scss";
import { RemoveMapPopup } from "./remove-map-popup/remove-map-popup";
import { useTranslation } from "react-i18next";

export const Maps = () => {
  const { t, i18n } = useTranslation("Maps");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const [categories, setCategories] = useState([]);
  const [mapsCount, setMapsCount] = useState(0);
  const [existingDraftPopupOpen, setExistingDraftPopupOpen] = useState(false);
  const [removeMapPopup, setRemoveMapPopup] = useState({
    open: false,
    mapId: "",
    mapGoal: "",
  });

  useEffect(() => {
    dispatch(setTitle(t("title")));
    getUserCategories();
  }, [i18n]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getUserCategories = async () => {
    try {
      dispatch(setShowLoader(true));
      const res = await Api.get("user/maps/list/active");
      if (res.data) {
        const newCategories = res.data.payload.Categories;
        setCategories(newCategories);
        const newMapsCount = [].concat.apply(
          [],
          newCategories.map((category) => category.Maps.map((m) => m))
        );
        setMapsCount(newMapsCount.length);
      }
      dispatch(setShowLoader(false));
    } catch {
      dispatch(setShowLoader(false));
    }
  };

  const getCategoryIcon = (name) => {
    switch (name) {
      case "Health":
        return <HealthCategoryIcon className="icon" />;
      case "Self & Wellbeing":
        return <SelfAndWellbeingCategoryCategoryIcon className="icon" />;
      case "Family":
        return <FamilyCategoryIcon className="icon" />;
      case "Leisure":
        return <LeisureCategoryIcon className="icon" />;
      case "Spiritual":
        return <SpiritualCategoryIcon className="icon" />;
      case "Social & Community":
        return <SocialAndCommunityCategoryIcon className="icon" />;
      case "Professional/Career":
        return <ProfessionalOrCareerCategoryIcon className="icon" />;
      case "Financial":
        return <FinancialCategoryIcon className="icon" />;
      case "Education":
        return <EducationCategoryIcon className="icon" />;
      case "Legacy":
        return <LegacyCategoryIcon className="icon" />;
      default:
        return <OtherCategoryIcon className="icon" />;
    }
  };

  const openRemoveMapPopup = (mapId, mapGoal) => {
    setRemoveMapPopup({
      open: true,
      mapId,
      mapGoal,
    });
  };

  const removeMap = async () => {
    try {
      const res = await Api.delete(`user/maps/${removeMapPopup.mapId}`);
      if (res.data.payload) {
        dispatch(
          showToastMessage({
            text: t("map-deleted"),
          })
        );
        getUserCategories();
      }
    } catch {}
  };

  return (
    <div className="maps-container">
      {
        <>
          {existingDraftPopupOpen && (
            <DraftMapPopup close={() => setExistingDraftPopupOpen(false)} />
          )}

          {removeMapPopup.open && (
            <RemoveMapPopup
              mapGoal={removeMapPopup.mapGoal}
              removeMap={removeMap}
              close={() =>
                setRemoveMapPopup({
                  open: false,
                  mapId: "",
                  mapGoal: "",
                })
              }
            />
          )}

          <div className="flex">
            <Button
              className="add-new-button"
              onClick={() => {
                if (profile?.DraftMap) setExistingDraftPopupOpen(true);
                else navigate("./create/category");
              }}
            >
              {t("add-map")}
              <FontAwesomeIcon icon={faPlus} className="add-new-button-icon" />
            </Button>
          </div>

          {categories.length > 0 ? (
            categories.map((category, i) => {
              if (category.Maps.length > 0) {
                return (
                  <div key={category.Id} className="category-container">
                    <div className="category-header flex">
                      <div className="category-icon">
                        {getCategoryIcon(category.Name)}
                      </div>
                      <span className="category-name">
                        {t(`Common:Categories.${category.Name}`)}
                      </span>
                    </div>
                    <hr
                      style={{ background: getCategoryColor(category.Name) }}
                    />
                    {category.Maps.map((m) => {
                      return (
                        <MapCard
                          key={m.Id}
                          map={m}
                          openRemoveMapPopup={openRemoveMapPopup}
                        />
                      );
                    })}
                    <br />
                  </div>
                );
              }
              return <React.Fragment key={category.Id}></React.Fragment>;
            })
          ) : (
            <>
              {!showLoader && (
                <>
                  <p>{t("feels-empty")}</p>
                  <p>{t("add-new-map-empty")}</p>
                </>
              )}
            </>
          )}
        </>
      }
    </div>
  );
};
