import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  smallTitle: "",
  title: "",
  titleIcon: false,
  direction: "ltr",
  systemError: {
    active: false,
    statusCode: 0,
    error: "",
  },
  welcomePopupsOpen: false,
  newsletterPopupOpen: false,
  refreshTokenRemoved: false,
  howsYourJourneyGoingPopupOpen: false,
  boosterQuestionsPopup: {
    open: false,
    editMode: false,
  },
  viewBoosterQuestionsPopupOpen: false,
  goodToHaveYouBackPopupOpen: false,
  hideMenuItems: false,
  toastMessage: {
    loading: false,
    open: false,
    text: "",
    type: "",
  },
  publicRoutes: ["login", "remove-notifications", "view-shared-map"],
  showLoader: false,
  isBoosterPopup: false,
  badgesPopupOpen: false,
  levelUpPopupOpen: false,
};

export const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState: initialState,
  reducers: {
    setTitle: (state, action) => {
      if (typeof action.payload === "object") {
        state.smallTitle = action.payload.smallTitle;
        state.title = action.payload.title;
      } else {
        state.title = action.payload;
        if (state.smallTitle) state.smallTitle = "";
      }
    },
    setTitleIcon: (state, action) => {
      state.titleIcon = action.payload;
    },
    setDirection: (state, action) => {
      state.direction = action.payload;
    },
    setSystemError: (state, action) => {
      state.systemError = {
        active: true,
        statusCode: action.payload.statusCode,
        error: action.payload.error,
      };
    },
    setRefreshTokenRemoved: (state, action) => {
      state.refreshTokenRemoved = action.payload;
    },
    initSystemError: (state, action) => {
      state.systemError = initialState.systemError;
    },
    setWelcomePopupsOpen: (state, action) => {
      state.welcomePopupsOpen = action.payload;
    },
    openNewsletterPopup: (state, action) => {
      state.newsletterPopupOpen = true;
    },
    closeNewsletterPopup: (state, action) => {
      state.newsletterPopupOpen = false;
    },
    setHowsYourJourneyGoingPopupOpen: (state, action) => {
      state.howsYourJourneyGoingPopupOpen = action.payload;
    },
    showToastMessage: (state, action) => {
      state.toastMessage = {
        loading: action.payload?.loading,
        open: true,
        text: action.payload?.text || "",
        type: action.payload?.type || "",
      };
    },
    closeToastMessage: (state, action) => {
      state.toastMessage = initialState.toastMessage;
    },
    setHideMenuItems: (state, action) => {
      state.hideMenuItems = action.payload;
    },
    setBoosterQuestionsPopup: (state, action) => {
      state.boosterQuestionsPopup = action.payload;
    },
    setViewBoosterQuestionsPopupOpen: (state, action) => {
      state.viewBoosterQuestionsPopupOpen = action.payload;
    },
    setGoodToHaveYouBackPopupOpen: (state, action) => {
      state.goodToHaveYouBackPopupOpen = action.payload;
    },
    setShowLoader: (state, action) => {
      state.showLoader = action.payload;
    },
    setIsBoosterpopup: (state, action) => {
      state.isBoosterPopup = action.payload;
    },
    setBadgesPopupOpen: (state, action) => {
      state.badgesPopupOpen = action.payload;
    },
    setLevelUpPopupOpen: (state, action) => {
      state.levelUpPopupOpen = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setTitle,
  setTitleIcon,
  setDirection,
  setSystemError,
  initSystemError,
  setWelcomePopupsOpen,
  openNewsletterPopup,
  closeNewsletterPopup,
  setRefreshTokenRemoved,
  setHowsYourJourneyGoingPopupOpen,
  closeToastMessage,
  showToastMessage,
  setHideMenuItems,
  setBoosterQuestionsPopup,
  setViewBoosterQuestionsPopupOpen,
  setGoodToHaveYouBackPopupOpen,
  setShowLoader,
  setIsBoosterpopup,
  setBadgesPopupOpen,
  setLevelUpPopupOpen,
} = appSettingsSlice.actions;
export default appSettingsSlice.reducer;
