import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./content-box.scss";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faWindowRestore } from "@fortawesome/free-regular-svg-icons";
import { EditContentPopup } from "../contents-action-popups/edit-content-popup/edit-content-popup";
import { useState } from "react";
import { DeleteContentFromCountryPopup } from "../contents-action-popups/delete-content-from-country-popup/delete-content-from-country-popup";
import { DeleteContentEntirely } from "../contents-action-popups/delete-content-entirely-popup/delete-content-entirely-popup";
import { DuplicateContentPopup } from "../contents-action-popups/duplicate-content-popup/duplicate-content-popup";
import { RestoreContentPopup } from "../contents-action-popups/restore-content-popup/restore-content-popup";
import { ViewVideoModal } from "../view-video-modal/view-video-modal";
import { useTranslation } from "react-i18next";
import { languagesList } from "../../../../components/languages-list/languages-list";

export const ContentBox = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const [showRestorePopup, setShowRestorePopup] = useState(false);
  const [showVideoPopup, setShowVideoPopup] = useState(false);

  return (
    <div className="content-box-container" id={props.id}>
      {showEditPopup && (
        <EditContentPopup
          showPopup={showEditPopup}
          closePopup={(contents, doSet = false) => {
            if (doSet) {
              props.closePopup(contents);
            }
            setShowEditPopup(false);
          }}
          countries={props.countries}
          categories={props.categories}
          tags={props.tags}
          id={props.id}
          contentsType={props.contentsType}
        />
      )}

      {showDuplicatePopup && (
        <DuplicateContentPopup
          showPopup={showDuplicatePopup}
          closePopup={(contents, doSet = false) => {
            if (doSet) {
              props.closePopup(contents);
            }
            setShowDuplicatePopup(false);
          }}
          contentToDuplicate={props.item}
          contentsType={props.contentsType}
        />
      )}

      {showRestorePopup && (
        <RestoreContentPopup
          showPopup={showRestorePopup}
          closePopup={(contents, doSet = false) => {
            if (doSet) {
              props.closePopup(contents);
            }
            setShowRestorePopup(false);
          }}
          contentId={props.id}
          contentsType={props.contentsType}
        />
      )}

      {showDeletePopup && props.currentCountryId && (
        <DeleteContentFromCountryPopup
          showPopup={showDeletePopup}
          closePopup={(contents, doSet = false) => {
            if (doSet) {
              props.closePopup(contents);
            }
            setShowDeletePopup(false);
          }}
          countryId={props.currentCountryId}
          id={props.id}
          contentsType={props.contentsType}
        />
      )}

      {showDeletePopup && props.currentCountryId === "" && (
        <DeleteContentEntirely
          showPopup={showDeletePopup}
          closePopup={(contents, doSet = false) => {
            if (doSet) {
              props.closePopup(contents);
            }
            setShowDeletePopup(false);
          }}
          id={props.id}
          contentsType={props.contentsType}
        />
      )}

      {showVideoPopup && (
        <ViewVideoModal
          showPopup={showVideoPopup}
          closePopup={() => {
            setShowVideoPopup(false);
          }}
          item={props.item}
        />
      )}

      <div className="info-container">
        <div className="content-type">
          {t("ContentBox.type")}:{" "}
          {props.item ? t(`ContentBox.${props.item.Type}`) : ""}
        </div>
        <div className="content-title">
          {t("ContentBox.title")}: {props.item ? props.item.Title : ""}
        </div>
        <div className="content-desription">
          {t("ContentBox.description")}:{" "}
          {props.item
            ? props.item.Description.length < 60
              ? props.item.Description
              : props.item.Description.substring(0, 57) + "..."
            : ""}
        </div>
        <div className="content-languages">
          {t("ContentBox.languages")}:{" "}
          {props.item
            ? props.item.Languages?.Languages.map((lang, index) => {
                if (index < 2) {
                  if (index < props.item.Languages.Languages.length) {
                    return (
                      languagesList.filter(
                        (tempLang) => tempLang.value === lang
                      )[0].label + ", "
                    );
                  } else {
                    return languagesList.filter(
                      (tempLang) => tempLang.value === lang
                    )[0].label;
                  }
                } else if (index === 3) {
                  return languagesList.filter(
                    (tempLang) => tempLang.value === lang
                  )[0].label;
                } else if (index === 4) {
                  return "...";
                } else {
                  return "";
                }
              })
            : ""}
        </div>
        <div
          className="content-link"
          onClick={() => {
            if (props.item) {
              if (!props.item.VideoType || props.item.VideoType === "") {
                window.open(props.item.Link, "_blank");
              } else {
                setShowVideoPopup(true);
              }
            }
          }}
        >
          {t("ContentBox.link")}:{" "}
          {props.item
            ? props.item.Link.length < 60
              ? props.item.Link
              : props.item.Link.substring(0, 57) + "..."
            : ""}
        </div>
        <div className="content-date">
          {t("ContentBox.creation-date")}:{" "}
          {props.item ? props.item.CreationDate : ""}
        </div>
      </div>
      <div className="image-container">
        {props.item.Image ? (
          <img
            className={"selected-image"}
            src={
              props.item
                ? props.item.Image ===
                  "https://tlechaim-static.fra1.digitaloceanspaces.com/gallery-images/MediaCenter/content-default.png"
                  ? props.item.Image
                  : process.env.REACT_APP_BASE_IMAGE_URL +
                    "/" +
                    props.item.Image
                : ""
            }
          ></img>
        ) : (
          <div className={"selected-image no-image"}></div>
        )}
      </div>
      <div className="actions-container">
        {props.item.Active && props.currentCountryId === "" && (
          <div>
            <div className="edit-container">
              <FontAwesomeIcon
                className="edit"
                icon={faPenToSquare}
                onClick={() => setShowEditPopup(true)}
              />
            </div>
            <div className="duplicate-container">
              <FontAwesomeIcon
                className="duplicate"
                icon={faCopy}
                onClick={() => setShowDuplicatePopup(true)}
              />
            </div>
          </div>
        )}
        {props.item.Active && (
          <div className="delete-container">
            <FontAwesomeIcon
              className="delete"
              icon={faTrashCan}
              onClick={() => setShowDeletePopup(true)}
            />
          </div>
        )}
        {!props.item.Active && (
          <div className="delete-container">
            <FontAwesomeIcon
              className="delete"
              icon={faWindowRestore}
              onClick={() => setShowRestorePopup(true)}
            />
          </div>
        )}
      </div>
      {props.canDrag && <div className="drag-handle">{props.children}</div>}
    </div>
  );
};
