import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button } from "../../../../../components/button/button";
import {
  setShowLoader,
  setTitle,
} from "../../../../../store/app-settings/app-settings-slice";
import { changeSelectedCategory } from "../../../../../store/map/map-slice";
import Api from "../../../../../utils/api";
import { SelectedCategoryPopup } from "../popups/selected-category/selected-category-popup";
import { AddCustomCategoryPopup } from "./add-custom-category-popup/add-custom-category-popup";
import { CategoryButton } from "./category-button/category-button";
import "./select-category.scss";
import { useTranslation } from "react-i18next";
import { changeProfile } from "../../../../../store/profile/profile-slice";
import { useSelector } from "react-redux";

export const SelectCategory = () => {
  const { t, i18n } = useTranslation("Maps");

  const dispatch = useDispatch();
  const { state } = useLocation();
  const [categories, setCategories] = useState([]);
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const [isCustomCategoryExist, setIsCustomCategoryExist] = useState(true);
  const [undisplayedCategoriesAdded, setUndisplayedCategoriesAdded] =
    useState(false);
  const [selectedCategoryPopupOpen, setSelectedCategoryPopupOpen] =
    useState(false);
  const [addCustomCategoryPopup, setAddCustomCategoryPopup] = useState(false);

  useEffect(() => {
    dispatch(
      setTitle({
        smallTitle: t("SelectCatagory.small-head-title"),
        title: t("SelectCatagory.head-title"),
      })
    );
    getCategories();
  }, [i18n]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      setIsCustomCategoryExist(
        categories.some((category) => category.IsCustom)
      );
      if (state?.categoriesWithMaxRatings) {
        const favoriteCategories = state.categoriesWithMaxRatings.map(
          (categoryName) => ({
            Id: categories.filter(
              (category) => category.Name == categoryName
            )[0].Id,
            Name: categoryName,
            IsCustom: categories.filter(
              (category) => category.Name == categoryName
            )[0].IsCustom,
          })
        );
        if (favoriteCategories) {
          saveFavoriteCategories(favoriteCategories);
        }

        setDisplayedCategories(favoriteCategories);
      } else {
        setDisplayedCategories(
          categories.map((category) => ({
            Id: category.Id,
            Name: category.Name,
            IsCustom: category.IsCustom,
          }))
        );
      }
    }
  }, [categories]);

  const saveFavoriteCategories = async (favoriteCategories) => {
    const data = {
      Categories: favoriteCategories
        .filter((c) => !c.IsCustom)
        .map((c) => c.Id),
    };
    const res = await Api.put("user/profile", data);
    if (res.data) {
      await dispatch(changeProfile(res.data.payload));
    }
  };

  const getCategories = async () => {
    dispatch(setShowLoader(true));
    try {
      const res = await Api.get("user/categories");
      if (res.data) {
        setCategories(res.data.payload);
        if (undisplayedCategoriesAdded) setUndisplayedCategoriesAdded(false);
      }
      dispatch(setShowLoader(false));
    } catch (error) {
      console.log(error);
      dispatch(setShowLoader(false));
    }
  };

  const selectCategory = (categoryId) => {
    const newSelectedCategory = displayedCategories.find(
      (category) => category.Id === categoryId
    );
    setSelectedCategoryPopupOpen(true);
    dispatch(changeSelectedCategory(newSelectedCategory));
  };

  const addUndisplayedCategories = () => {
    const categoriesToAdd = categories.filter(
      (category) => !displayedCategories.some((dCat) => dCat.Id === category.Id)
    );
    const newDisplayedCategories = displayedCategories.concat(categoriesToAdd);
    setUndisplayedCategoriesAdded(true);
    setDisplayedCategories(newDisplayedCategories);
  };

  const saveNewCustomCategory = async (id, name) => {
    dispatch(setShowLoader(true));
    try {
      const res = await Api.put(`user/categories/custom/${id}`, { Name: name });
      if (res.data) {
        getCategories();
      }
      dispatch(setShowLoader(false));
    } catch (error) {
      console.log(error);
      dispatch(setShowLoader(false));
    }
  };

  const removeCustomCategory = async (id) => {
    dispatch(setShowLoader(true));
    try {
      const res = await Api.delete(`user/categories/custom/${id}`);
      if (res.data) {
        getCategories();
      }
      dispatch(setShowLoader(false));
    } catch (error) {
      console.log(error);
      dispatch(setShowLoader(false));
    }
  };

  return (
    <div className="select-category-conatiner">
      {addCustomCategoryPopup && (
        <AddCustomCategoryPopup
          getCategories={getCategories}
          close={() => setAddCustomCategoryPopup(false)}
        />
      )}
      {selectedCategoryPopupOpen && (
        <SelectedCategoryPopup
          close={() => setSelectedCategoryPopupOpen(false)}
        />
      )}
      <div className="description">{t("SelectCatagory.info")}</div>
      <div className="displayed-categories-container">
        {displayedCategories.length > 0 &&
          displayedCategories.map((category) => {
            return (
              <CategoryButton
                key={category.Id}
                name={category.Name}
                category={category}
                onClick={() => selectCategory(category.Id)}
                saveNewCustomCategory={saveNewCustomCategory}
                removeCustomCategory={removeCustomCategory}
              />
            );
          })}
        {!isCustomCategoryExist && !state?.categoriesWithMaxRatings && (
          <Button
            className="add-category-button flex"
            onClick={() => setAddCustomCategoryPopup(true)}
          >
            {t("SelectCatagory.add-category")}{" "}
            <FontAwesomeIcon className="plus-icon" icon={faCirclePlus} />
          </Button>
        )}
        {state?.categoriesWithMaxRatings && !undisplayedCategoriesAdded && (
          <Button
            className="show-all-categories"
            onClick={addUndisplayedCategories}
          >
            +
          </Button>
        )}
      </div>
    </div>
  );
};
