import React, { useEffect, useState } from "react";
import { useLottie } from "lottie-react";
import BirdsLottieFile from "../../assets/lotties/birds.json";
import Layer2LottieFile from "../../assets/lotties/layer2.json";
import StepLottieFile from "../../assets/lotties/step.json";
import BaseLayerLottieFile from "../../assets/lotties/baseLayer.json";
import ImagePinLottieFile from "../../assets/lotties/ImagePin.json";
import "./grad.scss";
const segments = {
  Step1: {
    Start: 0,
    End: 150,
  },
  Step2: {
    Start: 150,
    End: 384,
  },
  Step3: {
    Start: 384,
    End: 540,
  },
};

const LottieTry = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const BirdsLottieOptions = {
    animationData: BirdsLottieFile,
    loop: true,
    //renderer: "canvas",
  };
  const StepLottieOptions = {
    animationData: StepLottieFile,
    loop: true,
    //renderer: "canvas",
  };
  const ImagePinLottieOptions = {
    animationData: ImagePinLottieFile,
    loop: true,
    assetsPath: "https://thispersondoesnotexist.com/image",
    //renderer: "canvas",
  };
  const Layer2LottieFileOptions = {
    animationData: Layer2LottieFile,
    loop: true,
    //renderer: "canvas",
    rendererSettings: {
      preserveAspectRatio: "none",
    },
  };
  const completeSegment = (e) => {
    console.log("firstFrame", BaseLayerLottie.animationItem.firstFrame);
    console.log("currentFrame", BaseLayerLottie.animationItem.currentFrame);
    console.log(
      "currentRawFrame",
      BaseLayerLottie.animationItem.currentRawFrame
    );
    console.log("timeCompleted", BaseLayerLottie.animationItem.timeCompleted);
    console.log("totalFrames", BaseLayerLottie.animationItem.totalFrames);
    console.log(BaseLayerLottie.animationItem.firstFrame);
    console.log(BaseLayerLottie.animationItem.firstFrame);
    switch (BaseLayerLottie.animationItem.firstFrame) {
      case segments.Step1.Start:
        console.log("STEP1!");
        setCurrentStep(1);
        break;
      case segments.Step2.Start:
        setCurrentStep(2);
        console.log("STEP2!");
        break;
      case segments.Step3.Start:
        setCurrentStep(3);
        console.log("STEP3!");
        break;
    }
  };
  const BaseLayerLottieFileOptions = {
    animationData: BaseLayerLottieFile,
    loop: false,
    autoplay: false,
    onEnterFrame: (e1, e2, e3, e4) => {
      //console.log(e1);
    },
    onComplete: completeSegment,
    //renderer: "canvas",
    rendererSettings: {
      preserveAspectRatio: "none",
    },
  };

  const BaseLayerLottie = useLottie(BaseLayerLottieFileOptions, {
    height: "100%",
    width: "100%",

    position: "absolute",
  });
  const { View: BirdsLottie } = useLottie(BirdsLottieOptions, {
    height: "100%",
    width: "100%",
    position: "absolute",
  });
  const { View: Step1Lottie } = useLottie(StepLottieOptions, {
    height: "25%",
    width: "25%",
    position: "absolute",
    top: "50%",
    left: "18%",
  });
  const { View: Step2Lottie } = useLottie(StepLottieOptions, {
    height: "20%",
    width: "20%",
    position: "absolute",
    top: "39%",
    left: "72%",
  });
  const { View: Step3Lottie } = useLottie(StepLottieOptions, {
    height: "18%",
    width: "18%",
    position: "absolute",
    top: "27%",
    left: "50%",
    cursor: "pointer",
  });
  const { View: ImagePinLottie } = useLottie(ImagePinLottieOptions, {
    height: "100%",
    width: "100%",
    position: "absolute",
    rendererSettings: {
      preserveAspectRatio: "none",
    },
  });
  const { View: Layer2Lottie } = useLottie(Layer2LottieFileOptions, {
    height: "100%",
    width: "100%",
    position: "absolute",
  });

  useEffect(() => {
    BaseLayerLottie.setSubframe(false);
  }, []);
  const transparent1 = {
    width: "40%",
    height: "7%",
    position: "absolute",
    top: "71%",
    left: "17%",
    //backgroundColor: "red",
    //border: "1px solid black",
    cursor: "pointer",
    zIndex: 4000,
  };
  const transparent2 = {
    width: "40%",
    height: "7%",
    position: "absolute",
    top: "53%",
    left: "35%",
    //backgroundColor: "red",
    zIndex: 4001,
  };
  const transparent3 = {
    width: "40%",
    height: "6%",
    position: "absolute",
    top: "41%",
    left: "49%",
    //backgroundColor: "red",
    zIndex: 4002,
  };
  const obs1 = () => {
    if (currentStep == 0) BaseLayerLottie.playSegments([0, 150], true);
  };
  const obs2 = () => {
    if (currentStep == 1) BaseLayerLottie.playSegments([150, 384], true);
  };
  const obs3 = () => {
    if (currentStep == 2) BaseLayerLottie.playSegments([384, 540], true);
  };
  return (
    <div
      className="animate-map-container"
      style={{
        height: "100vh",
        //border: "1px solid red",
        width: "100vw",
        position: "fixed",
        // zIndex: 500000,
        zIndex: 100,
        top: 0,
        left: 0,
        backgroundColor: "#000000",
      }}
    >
      {BaseLayerLottie.View}
      {Layer2Lottie}
      {BirdsLottie}
      <div className="" onClick={() => obs1()}></div>
      {Step1Lottie}
      {Step2Lottie}
      {Step3Lottie}
      <div style={transparent1} onClick={() => obs1()}></div>
      <div style={transparent2} onClick={() => obs2()}></div>
      <div style={transparent3} onClick={() => obs3()}></div>
      {ImagePinLottie}
      <div className="map-grad-layer"></div>
    </div>
  );
};

export default LottieTry;
