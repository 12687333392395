import React from "react";
import { PopupButton } from "../../../../components/popup-button/popup-button";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import "./remove-map-popup.scss";
import { useTranslation } from "react-i18next";

export const RemoveMapPopup = ({ mapGoal, removeMap, close }) => {
  const { t, i18n } = useTranslation("Maps");

  const closeAndRemoveMap = () => {
    close();
    removeMap();
  };

  return (
    <Popup close={close}>
      <div className="remove-map-popup-container">
        <PopupTitle>{t("RemoveMapPopUp.remove")}</PopupTitle>
        <p>{t("RemoveMapPopUp.are-you-sure")}</p>
        <p className="goal">{mapGoal}</p>
        <div className="note-container">
          <p className="header">{t("Common:note")}:</p>
          <p className="description">
            {t("RemoveMapPopUp.cannot-be-restored")}
          </p>
        </div>
        <div className="flex">
          <PopupButton onClick={close}>{t("Common:cancel")}</PopupButton>
          <PopupButton onClick={closeAndRemoveMap}>
            {t("RemoveMapPopUp.move-to-archive")}{" "}
          </PopupButton>
        </div>
      </div>
    </Popup>
  );
};
