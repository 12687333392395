import { forwardRef } from "react";
import ReactSelect, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow.svg";
import { SmallTitle } from "../small-title/small-title";
import { Control, Option } from "./custom-components/custom-components";
import "./select.scss";
import { useTranslation } from "react-i18next";

export const Select = forwardRef(
  (
    {
      singleMenuStyle,
      name,
      value,
      editable,
      isError,
      style,
      height = "200px",
      className,
      label,
      ...props
    },
    ref
  ) => {
    const { t, i18n } = useTranslation("Common");

    const customStyle = {
      menuList: (base) => ({
        ...base,
        maxHeight: height,
        "::-webkit-scrollbar": {
          width: "10px",
        },
        "::-webkit-scrollbar-track": {
          background: "#F8F1F1",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#cecece",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "2px 0px",
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          fontSize: "16px",
        };
      },
      multiValue: (base) => ({
        ...base,
        borderRadius: "30px",
        fontSize: "14px",
      }),
      menu: (base, state) => {
        return {
          ...base,
          marginTop: "14px",
          color: "#014352",
          background: "#E7E7E7",
          borderRadius: "20px",
          boxShadow: "none",
          overflow: "hidden",
          zIndex: "1000",
        };
      },
      container: (base, state) => {
        return {
          ...base,
          height: "inherit",
          marginTop: "5px",
        };
      },
      control: (base, state) => {
        if (singleMenuStyle) {
          return {
            ...base,
            transition: "",
            border: "2px solid #1b2c7f",
            boxShadow: "none",
            borderRadius: !state.menuIsOpen ? "20px" : "20px 20px 0px 0px",
            borderBottom: !state.menuIsOpen ? "2px solid #1b2c7f" : "0px",
            marginBottom: !state.menuIsOpen ? "0px" : "2px",
            "&:hover": {
              border: "2px solid #1b2c7f",
              borderRadius: !state.menuIsOpen ? "20px" : "20px 20px 0px 0px",
              borderBottom: !state.menuIsOpen ? "2px solid #1b2c7f" : "0px",
              boxShadow: "none",
            },
          };
        }
        return {
          ...base,
          flex: 1,
          background: " #E7E7E7",
          border: "none",
          boxShadow: "none",
          minHeight: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        };
      },
    };

    const Placeholder = (props) => {
      return <components.Placeholder {...props} />;
    };

    const CaretDownIcon = () => {
      return <DownArrow />;
    };

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <CaretDownIcon />
        </components.DropdownIndicator>
      );
    };

    return (
      <>
        {isError && (
          <SmallTitle error={true}>{t("Select.required-field")}</SmallTitle>
        )}
        <div
          className={
            "select-container" +
            (isError ? " focus" : "") +
            (` ${className}` || "")
          }
          style={style}
        >
          <div className="select">
            {editable ? (
              <CreatableSelect
                styles={customStyle}
                components={{
                  IndicatorSeparator: () => null,
                  Placeholder,
                  DropdownIndicator,
                  Control,
                  Option,
                }}
                placeholder={props.placeholder || t("Select.select-or-type")}
                value={
                  value
                    ? Array.isArray(value)
                      ? value.map((option) => ({
                          value: option,
                          label: option,
                        }))
                      : { value: value, label: value }
                    : ""
                }
                onInputChange={(inputValue) =>
                  props.maxLength
                    ? inputValue.length <= props.maxLength
                      ? inputValue
                      : inputValue.substring(0, props.maxLength)
                    : inputValue
                }
                closeMenuOnSelect={props.closeMenuOnSelect ? true : false}
                ref={ref}
                {...props}
              />
            ) : (
              <ReactSelect
                styles={customStyle}
                components={{
                  IndicatorSeparator: () => null,
                  Placeholder,
                  DropdownIndicator,
                  Control,
                  Option,
                }}
                placeholder={props.placeholder || t("Select.select")}
                value={
                  value
                    ? Array.isArray(value)
                      ? value.map((option) => ({
                          value: option,
                          label: option,
                        }))
                      : { value: value, label: label ? label : value }
                    : ""
                }
                isSearchable={props.searchable ? true : false}
                closeMenuOnSelect={props.closeMenuOnSelect ? true : false}
                // isSearchable={false}
                ref={ref}
                {...props}
              ></ReactSelect>
            )}
          </div>
        </div>
      </>
    );
  }
);
