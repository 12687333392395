import React from "react";
import "./checkbox.scss";

export const Checkbox = ({ id, label, checked, ...props }) => {
  return (
    <div
      className={
        props.noFlex
          ? "checkbox-container" +
            (props.className ? ` ${props.className}` : "")
          : "checkbox-container flex" +
            (props.className ? ` ${props.className}` : "")
      }
    >
      <input
        id={id}
        type="checkbox"
        className={"checkbox" + (checked ? " checked" : "")}
        {...props}
      />
      <label className={"label" + (checked ? " checked" : "")} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
