import { ObjectID } from "bson";
import { useLottie } from "lottie-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { PopupButton } from "../../../../components/popup-button/popup-button";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import { Select } from "../../../../components/select/select";
import { ShareButtons } from "../../../../components/share-buttons/share-buttons";
import { SmallTitle } from "../../../../components/small-title/small-title";
import Api from "../../../../utils/api";
import { useTranslation } from "react-i18next";

import "../../app-popups/booster-questions/steps/share-step/share-booster-popup/share-booster-popup.scss";
import { useDispatch } from "react-redux";
import { updateUserPoints } from "../../../../store/profile/profile-slice";

export const ShareMedia = ({ item, close }) => {
  const { t, i18n } = useTranslation("PopUps");
  const dispatch = useDispatch();

  const [shareReceiverTypeOptions, setShareReceiverTypeOptions] = useState([]);

  useEffect(() => {
    setShareReceiverTypeOptions([
      { value: "Friend", label: t("Share:Friend") },
      { value: "Partner", label: t("Share:Partner") },
      { value: "Wife", label: t("Share:Wife") },
      { value: "Husband", label: t("Share:Husband") },
      { value: "Mentor", label: t("Share:Mentor") },
      { value: "Brother", label: t("Share:Brother") },
      { value: "Son", label: t("Share:Son") },
      { value: "Daughter", label: t("Share:Daughter") },
      { value: "Sister", label: t("Share:Sister") },
      { value: "Mother", label: t("Share:Mother") },
      { value: "Father", label: t("Share:Father") },
      { value: "Co-Worker", label: t("Share:Co-Worker") },
      {
        value: "Family-Member-(Other)",
        label: t("Share:Family-Member-(Other)"),
      },
      { value: "Clergy", label: t("Share:Clergy") },
      { value: "Health-Care-Provider", label: t("Share:Health-Care-Provider") },
      { value: "Neighbor", label: t("Share:Neighbor") },
      { value: "Boss", label: t("Share:Clergy") },
      { value: "Coach", label: t("Share:Coach") },
      { value: "Myself", label: t("Share:Myself") },
      { value: "Other", label: t("Share:Other") },
    ]);
  }, [i18n]);

  const [share, setShare] = useState({
    ReceiverType: { value: "", label: "" },
    Platform: "",
  });
  const [message, setMessage] = useState({
    subject: "",
    body: "",
  });
  const [singleMessage, setSingleMessage] = useState("");
  const [shareId, setShareId] = useState(new ObjectID());

  // const ShareLottieOptions = {
  //   animationData: ShareLottieFile,
  //   loop: true,
  //   //renderer: "canvas",
  // };

  // const { View: ShareLottie } = useLottie(ShareLottieOptions, {
  //   height: "150px",
  //   marginTop: "-40px",
  //   marginBottom: "-30px",
  // });

  useEffect(() => {
    var singleMessage = "";
    var message = "";
    if (item.Type === "Tip") {
      singleMessage = `I'm using an app that has content that helps building hope.
    I want to share with you a content from the app:
${item.Title}: ${item.Description}.

${t("Share:Boosters.single-message-part2")}
https://play.google.com/store/apps/details?id=com.hrplus.hopetimize
    `;

      message = {
        subject: t("Share:Boosters.message-title"),
        body: `I'm using an app that has content that helps building hope.
      I want to share with you a content from the app:
  ${item.Title}: ${item.Link}.

${t("Share:Boosters.message-body-part2")}
https://play.google.com/store/apps/details?id=com.hrplus.hopetimize
`,
      };
    } else {
      singleMessage = `I'm using an app that has content that helps building hope.
    I want to share with you a content from the app:
${item.Title}: ${item.Link}.

${t("Share:Boosters.single-message-part2")}
https://play.google.com/store/apps/details?id=com.hrplus.hopetimize
    `;

      message = {
        subject: t("Share:Boosters.message-title"),
        body: `I'm using an app that has content that helps building hope.
      I want to share with you a content from the app:
  ${item.Title}: ${item.Link}.

${t("Share:Boosters.message-body-part2")}
https://play.google.com/store/apps/details?id=com.hrplus.hopetimize
`,
      };
    }

    setSingleMessage(singleMessage);
    setMessage(message);
  }, [shareId]);

  const changeShare = (name, value) => {
    setShare((prevState) => ({
      ...prevState,
      [name]: { value: value.value, label: value.label },
    }));
  };

  const handleChange = (name, selectedOption) => {
    changeShare(name, selectedOption);
  };

  const sendShare = async (shareid, platform) => {
    dispatch(updateUserPoints({ points: 50, createStepType: "" }));
  }; // Don't delete the parameters. It's important for ShareButtons.

  return (
    <Popup close={close} height="520px">
      <div className="share-booster-popup-container flex">
        <div className="header">
          <PopupTitle style={{ width: "300px" }}>
            {t("MediaCenter:ShareMediaPopup.title")}
          </PopupTitle>
          <PopupTitle style={{ width: "300px" }} small={true}>
            {t("MediaCenter:ShareMediaPopup.small-title")}
          </PopupTitle>
        </div>

        {/* {ShareLottie} */}
        <div className="share-with-container" style={{ width: "250px" }}>
          <SmallTitle>{t("MediaCenter:ShareMediaPopup.share-with")}</SmallTitle>
          <Select
            name={"ReceiverType"}
            value={share.ReceiverType.label}
            onChange={(e) => handleChange("ReceiverType", e)}
            options={shareReceiverTypeOptions}
            height="170px"
          />
        </div>

        <ShareButtons
          shareId={"1"}
          sendShare={sendShare}
          disabled={!share.ReceiverType}
          singleMessage={singleMessage}
          subject={message.subject}
          body={message.body}
        />
        <PopupButton className="close-button" onClick={close}>
          {t("Common:close")}
        </PopupButton>
      </div>
    </Popup>
  );
};
