import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { closeToastMessage } from "../../store/app-settings/app-settings-slice";
import { useTranslation } from "react-i18next";

import "./toast-message.scss";

export const ToastMessage = () => {
  const { t, i18n } = useTranslation("Common");

  const toastMessage = useSelector(
    (state) => state.appSettingsReducer.toastMessage
  );
  const dispatch = useDispatch();
  const [toastId, setToastId] = useState(null);

  const closeToast = () => {
    if (toastId) setToastId(null);
    dispatch(closeToastMessage());
  };

  useEffect(() => {
    if (toastMessage.loading) {
      const id = toast.loading(t("please-wait"), {
        containerId: "main",
        onClose: () => closeToast(),
      });
      setToastId(id);
    } else if (toastMessage.open) {
      if (toastId) {
        toast.update(toastId, {
          containerId: "main",
          render: toastMessage.text,
          type: toastMessage.type,
          isLoading: false,
          autoClose: 2000,
          onClose: () => closeToast(),
        });
      } else if (toastMessage.type) {
        toast[toastMessage.type](toastMessage.text, {
          containerId: "main",
          autoClose: 2000,
          onClose: () => closeToast(),
        });
      } else {
        toast.success(toastMessage.text, {
          containerId: "main",
          autoClose: 2000,
          onClose: () => closeToast(),
        });
      }
    }
  }, [toastMessage]);

  return (
    <div>
      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId={"main"}
        limit={1}
      />
    </div>
  );
};
