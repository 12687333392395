import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { setShowLoader } from "../../store/app-settings/app-settings-slice";
import Api from "../../utils/api";
import Page404 from "../404/404";
import "./login.scss";
import Otp from "./pages/otp";
//import Phone from "./pages/phone";
import Phone from "./pages/Phone";
import Signup from "./pages/signup";

export default function Login() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [phone, setPhone] = useState("");
  const [otpNextDate, setOtpNextDate] = useState(null);
  const [email, setEmail] = useState("");
  const [langCode, setLangCode] = useState("en");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sendLogin = async () => {
    dispatch(setShowLoader(true));
    try {
      const data = {
        Phone: phone,
      };
      const token = await executeRecaptcha("OtpLogin");
      const res = await Api.post("auth/otp/login", data, {
        headers: {
          recaptcha: token,
        },
      });
      setEmail(res.data.payload);
      dispatch(setShowLoader(false));
      if (res.data) {
        setOtpNextDate(res.data.payload);
        navigate("otp");
      }
    } catch (error) {
      console.log(error);
      dispatch(setShowLoader(false));
    }
  };

  const signupFinish = async (res) => {
    if (res.data) {
      setEmail(res.data.payload);
      setOtpNextDate(res.data.payload);
      navigate("otp");
    }
  };

  const moveToSignup = () => {
    navigate("signup");
  };

  const goBack = () => {
    navigate("../login");
  };

  return (
    <Routes>
      <Route
        // path="phone"
        index
        element={
          <Phone
            phone={phone}
            setPhone={setPhone}
            sendLogin={sendLogin}
            moveToSignup={moveToSignup}
            setLangCode={setLangCode}
          />
        }
      />
      <Route
        path="otp"
        element={
          <Otp
            phone={phone}
            email={email}
            otpNextDate={otpNextDate}
            sendLogin={sendLogin}
            langCode={langCode}
          />
        }
      />
      <Route
        path="signup"
        element={
          <Signup
            phone={phone}
            setPhone={setPhone}
            signupFinish={signupFinish}
            goBack={goBack}
          />
        }
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
