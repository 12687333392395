import { components } from "react-select";
import { ReactComponent as VIcon } from "../../../assets/icons/v.svg";

export const Control = ({ children, ...props }) => {
  return (
    components.Control && (
      <components.Control {...props}>
        <VIcon className={"v-icon" + (!props.hasValue ? " hidden" : "")} />
        {children}
      </components.Control>
    )
  );
};

export const Option = ({ isSelected, label, value, ...props }) => {
  return (
    <div>
      <components.Option {...props}>
        <div>
          <div className="option-conatiner">
            {value !== "*" && (
              <input
                type="checkbox"
                className={"option-checkbox" + (isSelected ? " checked" : "")}
              />
            )}{" "}
            <label className="option-label">{label}</label>
          </div>
        </div>
      </components.Option>
    </div>
  );
};
