import React from "react";
import "./popup-title.scss";

export const PopupTitle = ({ small, children, ...props }) => {
  return (
    <div className="popup-title-container">
      <h2
        className={
          "popup-title" +
          (small ? " small" : " big") +
          (props.className ? ` ${props.className}` : "")
        }
        {...props}
      >
        {children}
      </h2>
    </div>
  );
};
