import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { SpeechBubble } from "../../../../../../components/speech-bubble/speech-bubble";
import { setBoosterQuestionsPopup } from "../../../../../../store/app-settings/app-settings-slice";
import "./thats-ok-popup.scss";
import { useTranslation } from "react-i18next";

export const ThatsOkPopup = ({ index, info, close }) => {
  const { t, i18n } = useTranslation("Maps");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openBoostersPopup = () => {
    dispatch(setBoosterQuestionsPopup({ open: true, editMode: false }));
    close();
  };

  return (
    <Popup close={close}>
      <div className="thats-ok-popup-container">
        <PopupTitle>{t("ViewMap.ThatsOkPopUp.title")}</PopupTitle>

        <SpeechBubble
          direction={"right"}
          top={"85px"}
          left={"16px"}
          width={"145px"}
          height={"120px"}
        >
          <p className="first-info">{t("ViewMap.ThatsOkPopUp.info1-1")}</p>
          <p>{t("ViewMap.ThatsOkPopUp.info1-2")}</p>
          <p>{t("ViewMap.ThatsOkPopUp.info1-3")}</p>
          <p>{t("ViewMap.ThatsOkPopUp.info1-4")}</p>
        </SpeechBubble>

        <SpeechBubble
          direction={"left"}
          top={"85px"}
          left={"180px"}
          width={"135px"}
          height={"110px"}
        >
          <p className="first-info">{t("ViewMap.ThatsOkPopUp.info2-1")}</p>
          <p>{t("ViewMap.ThatsOkPopUp.info2-2")}</p>
          <p>{t("ViewMap.ThatsOkPopUp.info2-3")}</p>
          <p>{t("ViewMap.ThatsOkPopUp.info2-4")}</p>
        </SpeechBubble>

        <div className="popup-bottom-bottons-container flex">
          <PopupButton continue="true" onClick={openBoostersPopup}>
            {t("Common:next")}
          </PopupButton>
        </div>
      </div>
    </Popup>
  );
};
