import "./title.scss";
import BoosterIcon from "../../assets/images/save-as-booster.png";

export const Title = ({
  children,
  style,
  title,
  smallTitle,
  titleIcon,
  ...props
}) => {
  return (
    <div className="title-container flex" style={style}>
      {titleIcon && <img src={BoosterIcon} className="booster-img" />}
      <span className="title flex">
        <div className="title-text flex">
          {smallTitle && <div className="small">{smallTitle}</div>}
          <div className="big">{title}</div>
        </div>
      </span>
    </div>
  );
};
