import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { ReactComponent as SmartGoalIcon } from "../../../../../assets/icons/smart-goal-icon.svg";
import { Button } from "../../../../../components/button/button";
import { setTitle } from "../../../../../store/app-settings/app-settings-slice";
import {
  resetDraftMap,
  updateDraftMap,
} from "../../../../../store/profile/profile-slice";
import Api from "../../../../../utils/api";
import { ReadMorePopup } from "./read-more-popup/read-more-popup";
import "./smart-goal.scss";
import { useTranslation } from "react-i18next";

export const SmartGoal = () => {
  const { t, i18n } = useTranslation("Maps");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readMorePopupOpen, setReadMorePopupOpen] = useState(false);
  const [timeToComplete, setTimeToComplete] = useState(0);
  const profile = useSelector((state) => state.profileReducer.profile);
  const selectedCategory = useSelector(
    (state) => state.mapReducer.selectedCategory
  );

  useEffect(() => {
    const interval = setInterval(
      () => setTimeToComplete((prev) => prev + 1),
      1000
    );
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    dispatch(setTitle(t("CreateMap.SmartGoal.title")));
    if (!selectedCategory) {
      navigate("../category");
    }
  }, [i18n]);

  const openReadMorePopup = () => {
    setReadMorePopupOpen(true);
  };

  const navigateToSetGoal = async () => {
    try {
      const data = {
        CategoryId: selectedCategory.Id,
        CategoryName: selectedCategory.Name,
        TimeToComplete: timeToComplete,
      };
      if (profile?.DraftMap) {
        // draft map reset needed in client & server
        dispatch(resetDraftMap());
        await Api.delete("user/profile/draftMap");
      }
      const res = await dispatch(updateDraftMap(data));
      if (res.payload) {
        navigate("../goal");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="smart-goal-container flex">
      {readMorePopupOpen && (
        <ReadMorePopup close={() => setReadMorePopupOpen(false)} />
      )}
      <div>
        <p className="title">{t("Common:tip")}:</p>
        <p className="content">
          {t("CreateMap.SmartGoal.suggest")}{" "}
          <b>{t("CreateMap.SmartGoal.SMART")}</b>- <br />
          <b>{t("CreateMap.SmartGoal.S")} - </b>
          {t("CreateMap.SmartGoal.pecific")}
          <br />
          <b>{t("CreateMap.SmartGoal.M")} - </b>
          {t("CreateMap.SmartGoal.easurable")}
          <br />
          <b>{t("CreateMap.SmartGoal.A")} - </b>
          {t("CreateMap.SmartGoal.ttainable")}
          <br />
          <b>{t("CreateMap.SmartGoal.R")} - </b>
          {t("CreateMap.SmartGoal.elevant")}
          <br />
          <b>{t("CreateMap.SmartGoal.T")} - </b>
          {t("CreateMap.SmartGoal.imely")}
        </p>
      </div>
      <SmartGoalIcon />
      <Button className="read-more-button flex" onClick={openReadMorePopup}>
        <PlusIcon />
        {t("Common:read-more")}
      </Button>
      <Button className="ok-button" onClick={navigateToSetGoal}>
        {t("Common:OK")}
      </Button>
    </div>
  );
};
