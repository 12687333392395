import {
  faCircleCheck,
  faMapLocationDot,
  faShareNodes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Card } from "../../components/card/card";
import { ReportCard } from "../../components/report-card/report-card";
import { StatisticsCard } from "../../components/statistics-card/statistics-card";
import Api from "../../utils/api";
import "./admin-dashboard.scss";
import {
  setShowLoader,
  setTitle,
} from "../../store/app-settings/app-settings-slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function AdminDashboard(props) {
  const { t, i18n } = useTranslation("AdminDashboard");
  const profile = useSelector((state) => state.profileReducer.profile);
  console.log(profile);

  const [statistics, setStatistics] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle(t("title")));
    getStatistics();
  }, [i18n]);

  const getStatistics = async () => {
    dispatch(setShowLoader(true));
    try {
      const res = await Api.get("admin/statistics");
      if (res.data) {
        dispatch(setShowLoader(false));
        const payload = res.data.payload;
        setStatistics({
          users: payload.Users,
          maps: payload.Maps,
          mapsCompleted: payload.MapsCompleted,
          mapsShares: payload.MapsShares,
          categories: payload.Categories,
        });
      }
    } catch (error) {
      dispatch(setShowLoader(false));
      console.log(error);
    }
  };
  return (
    <div className="admin-dashboard-container">
      <h2>{t("statistics")}</h2>
      <Card>
        <div className="statistics-container ">
          <StatisticsCard
            title={t("users-num")}
            text={statistics.users}
            icon={faUser}
          />
          <StatisticsCard
            title={t("maps-num")}
            text={statistics.maps}
            icon={faMapLocationDot}
          />
          <StatisticsCard
            title={t("maps-completed")}
            text={statistics.mapsCompleted}
            icon={faCircleCheck}
          />
          <StatisticsCard
            title={t("shared-maps")}
            text={statistics.mapsShares}
            icon={faShareNodes}
          />
          <div className="common-maps-container">
            <h2 className="title common-maps-title">{t("common-maps")}</h2>
            {statistics.categories &&
              statistics.categories.map((category, i) => (
                <p
                  className="category"
                  key={i}
                  style={{ fontSize: `${18 - i * 2}px` }}
                >
                  {[
                    "Education",
                    "Family",
                    "Financial",
                    "Health",
                    "Legacy",
                    "Leisure",
                    "Professional/Career",
                    "Self & Wellbeing",
                    "Social & Community",
                    "Spiritual",
                  ].includes(category.Name)
                    ? t(`Common:Categories.${category.Name}`)
                    : category.Name}
                </p>
              ))}
          </div>
        </div>
      </Card>
      <hr />

      <h2>{t("reports")}</h2>
      <Card>
        <div className="statistics-container">
          <ReportCard title={t("maps-report")} type="maps" />
          <ReportCard title={t("goal-change")} type="goalChange" />
          <ReportCard title={t("shared-maps-report")} type="sharedMaps" />
          <ReportCard
            smallTitle={true}
            title={t("avg-category-maps")}
            type="categories"
          />
          <ReportCard title={t("draft-maps")} type="draftMaps" />
          <ReportCard title={t("boosters")} type="boosters" />
          <ReportCard
            smallTitle={true}
            title={t("boosters-views")}
            type="boostersViews"
          />
          <ReportCard title={"users"} type={"users"} />
        </div>
      </Card>
    </div>
  );
}
