import { useLottie } from "lottie-react";
import React, { useEffect, useState } from "react";
import ShareLottieFile from "../../../../../../assets/lottie-files/share.json";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { Select } from "../../../../../../components/select/select";
import { ShareButtons } from "../../../../../../components/share-buttons/share-buttons";
import { SmallTitle } from "../../../../../../components/small-title/small-title";
import Api from "../../../../../../utils/api";
import { ObjectID } from "bson";
import "./share-map-popup.scss";
import { useTranslation } from "react-i18next";

export const ShareMapPopup = ({ mapId, close }) => {
  const { t, i18n } = useTranslation("Maps");
  const [shareReceiverTypeOptions, setShareReceiverTypeOptions] = useState([]);
  useEffect(() => {
    setShareReceiverTypeOptions([
      { value: "Partner", label: t("Share:Partner") },
      { value: "Wife", label: t("Share:Wife") },
      { value: "Husband", label: t("Share:Husband") },
      { value: "Mentor", label: t("Share:Mentor") },
      { value: "Brother", label: t("Share:Brother") },
      { value: "Son", label: t("Share:Son") },
      { value: "Daughter", label: t("Share:Daughter") },
      { value: "Sister", label: t("Share:Sister") },
      { value: "Mother", label: t("Share:Mother") },
      { value: "Father", label: t("Share:Father") },
      { value: "Co-Worker", label: t("Share:Co-Worker") },
      {
        value: "Family-Member-(Other)",
        label: t("Share:Family-Member-(Other)"),
      },
      { value: "Clergy", label: t("Share:Clergy") },
      { value: "Health Care Provider", label: t("Share:Health-Care-Provider") },
      { value: "Neighbor", label: t("Share:Neighbor") },
      { value: "Boss", label: t("Share:Clergy") },
      { value: "Coach", label: t("Share:Coach") },
      { value: "Myself", label: t("Share:Myself") },
      { value: "Other", label: t("Share:Other") },
    ]);
  }, [i18n]);

  const [share, setShare] = useState({
    ReceiverType: "",
    Platform: "",
  });
  const [message, setMessage] = useState({
    subject: "",
    body: "",
  });
  const [singleMessage, setSingleMessage] = useState("");
  const [shareId, setShareId] = useState(new ObjectID());

  // const ShareLottieOptions = {
  //   animationData: ShareLottieFile,
  //   loop: true,
  //   //renderer: "canvas",
  // };

  // const { View: ShareLottie } = useLottie(ShareLottieOptions, {
  //   height: "150px",
  //   marginTop: "-40px",
  //   marginBottom: "-30px",
  // });

  useEffect(() => {
    const singleMessage = `${t("Share:Maps.single-message-part1")}
${process.env.REACT_APP_BASE_URL}/view-shared-map/${shareId}

${t("Share:Maps.single-message-part2")}
https://play.google.com/store/apps/details?id=com.hrplus.hopetimize
    `;

    const message = {
      subject: t("Share:Maps.message-title"),
      body: `${t("Share:Maps.message-body-part1")}
${process.env.REACT_APP_BASE_URL}/view-shared-map/${shareId}


${t("Share:Maps.message-body-part2")}
https://play.google.com/store/apps/details?id=com.hrplus.hopetimize
`,
    };

    setSingleMessage(singleMessage);
    setMessage(message);
  }, [shareId]);

  const changeShare = (name, value) => {
    setShare((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (name, selectedOption) => {
    changeShare(name, selectedOption.label);
  };

  const sendShare = async (shareId, platform) => {
    try {
      const res = await Api.put(`user/maps/${mapId}/share`, {
        Id: shareId,
        ReceiverType: share.ReceiverType,
        Platform: platform,
      });
      setShareId(new ObjectID());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Popup close={close} height="400px">
      <div className="share-map-popup-container flex">
        <div className="header">
          <PopupTitle>{t("ViewMap.ShareMapPopUp.share")}</PopupTitle>
        </div>
        {/* {ShareLottie} */}
        <div className="share-with-container">
          <SmallTitle>{t("ViewMap.ShareMapPopUp.with")}</SmallTitle>
          <Select
            name={"ReceiverType"}
            value={share.ReceiverType}
            onChange={(e) => handleChange("ReceiverType", e)}
            options={shareReceiverTypeOptions}
            height="130px"
          />
        </div>
        <ShareButtons
          shareId={shareId}
          sendShare={sendShare}
          disabled={!share.ReceiverType}
          singleMessage={singleMessage}
          subject={message.subject}
          body={message.body}
        />

        <PopupButton className="close-button" onClick={close}>
          {t("Common:close")}
        </PopupButton>
      </div>
    </Popup>
  );
};
