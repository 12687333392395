import React, { Component } from "react";
import "../../pages/admin/media-center/content-box/content-box.scss";
import YouTube from "react-youtube";

class YouTubeVideo extends Component {
  state = {
    debug: true,
    data: { status: true, statusCode: "completed", score: null, allData: {} },
    isP: false,
  };

  componentDidMount() {}

  render() {
    const regEx =
      /(?:https?:\/\/)?(?:(?:(?:www\.?)?youtube\.com(?:\/(?:(?:watch\?.*?(v=([^&\s]+)).*)|(?:v(\/.*))|(channel\/.+)|(?:user\/(.+))|(?:results\?(search_query=.+))))?)|(?:youtu\.be\/(.*)?))/g;
    const match = regEx.exec(this.props.url);
    const ytId = match ? (match[2] ? match[2] : match[7]) : "";
    return (
      <YouTube
        style={this.props.style ? this.props.style : {}}
        fullScreen={true}
        // style={this.props.style}
        videoId={ytId}
        className={
          "vimeo-frame " +
          (this.props.fullScreen ? "contentPage-full-screen" : "contentPage")
        }
      />
    );
  }
}
export default YouTubeVideo;
