import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/phone.svg";
import { Input } from "../../../../components/input/input";
import { Select } from "../../../../components/select/select";
import { SmallTitle } from "../../../../components/small-title/small-title";
import { StepsDots } from "../../../../components/steps-dots/steps-dots";
import {
  setTitle,
  setShowLoader,
} from "../../../../store/app-settings/app-settings-slice";
import {
  changeProfile,
  getProfile,
} from "../../../../store/profile/profile-slice";
import Api from "../../../../utils/api";
import "./basic-info.scss";
import countryList from "country-list";
import { useTranslation } from "react-i18next";

export const BasicInfo = () => {
  const { t, i18n } = useTranslation("OnBoarding");

  const [genderOption, setGenderOption] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [primaryHomeLocationOptions, setPrimaryHomeLocationOptions] = useState(
    []
  );
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [placeOfBirthOptions, setPlaceOfBirthOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [workStatusOptions, setWorkStatusOptions] = useState([]);
  const [countryOptions, setcountriesOptions] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );

  const basicInfoRefs = useRef([]);
  const [showError, setShowError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    label: "",
    value: "",
  });
  const [basicInfo, setBasicInfo] = useState({
    FirstName: "",
    LastName: "",
    Phone: "",
    Email: "",
    CountryCode: "",
    Gender: { value: "", label: "" },
    Age: { value: "", label: "" },
    PrimaryHomeLocation: { value: "", label: "" },
    Ethnicity: { value: "", label: "" },
    PlaceOfBirth: { value: "", label: "" },
    Education: { value: "", label: "" },
    WorkStatus: { value: "", label: "" },
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const countriesObj = countryList.getNameList();
    const arr = [];
    for (let key in countriesObj) {
      arr.push({
        value: countriesObj[key],
        label: key.charAt(0).toUpperCase() + key.slice(1),
      });
    }
    setcountriesOptions(arr);
    setGenderOption([
      { value: "Male", label: t("BasicInfo.Options.Male") },
      { value: "Female", label: t("BasicInfo.Options.Female") },
      { value: "Non-binary", label: t("BasicInfo.Options.Non-binary") },
      { value: "Other", label: t("BasicInfo.Options.Other") },
    ]);
    setAgeOptions([
      { value: "0-15", label: "0-15" },
      { value: "15-20", label: "15-20" },
      { value: "20-30", label: "20-30" },
      { value: "30-45", label: "30-45" },
      { value: "45-60", label: "45-60" },
      { value: "60-75", label: "60-75" },
      { value: "75-90", label: "75-90" },
      { value: "90+", label: "90+" },
      {
        value: "Prefer not to say",
        label: t("BasicInfo.Options.Prefer not to say"),
      },
    ]);
    setLocationOptions([
      {
        label: t("BasicInfo.Options.select or type"),
        value: "*",
        isDisabled: true,
      },
      { value: "North America", label: t("BasicInfo.Options.North America") },
      {
        value: "Central America",
        label: t("BasicInfo.Options.Central America"),
      },
      { value: "South America", label: t("BasicInfo.Options.South America") },
      { value: "UK", label: t("BasicInfo.Options.UK") },
      { value: "Europe", label: t("BasicInfo.Options.Europe") },
      { value: "Africa", label: t("BasicInfo.Options.Africa") },
      { value: "Asia", label: t("BasicInfo.Options.Asia") },
      { value: "Australia", label: t("BasicInfo.Options.Australia") },
      { value: "Pacific Islander", label: "Pacific Islander" },
      {
        value: "Caribbean Islands",
        label: t("BasicInfo.Options.Caribbean Islands"),
      },
      { value: "Middle East", label: t("BasicInfo.Options.Middle East") },
      {
        value: "Prefer not to say",
        label: t("BasicInfo.Options.Prefer not to say"),
      },
    ]);
    setPrimaryHomeLocationOptions([
      {
        label: t("BasicInfo.Options.select or type"),
        value: "*",
        isDisabled: true,
      },
      { value: "North America", label: t("BasicInfo.Options.North America") },
      {
        value: "Central America",
        label: t("BasicInfo.Options.Central America"),
      },
      { value: "South America", label: t("BasicInfo.Options.South America") },
      { value: "UK", label: t("BasicInfo.Options.UK") },
      { value: "Europe", label: t("BasicInfo.Options.Europe") },
      { value: "Africa", label: t("BasicInfo.Options.Africa") },
      { value: "Asia", label: t("BasicInfo.Options.Asia") },
      { value: "Australia", label: t("BasicInfo.Options.Australia") },
      { value: "Pacific Islander", label: "Pacific Islander" },
      {
        value: "Caribbean Islands",
        label: t("BasicInfo.Options.Caribbean Islands"),
      },
      { value: "Middle East", label: t("BasicInfo.Options.Middle East") },
      {
        value: "Prefer not to say",
        label: t("BasicInfo.Options.Prefer not to say"),
      },
    ]);
    setPlaceOfBirthOptions([
      {
        label: t("BasicInfo.Options.select or type"),
        value: "*",
        isDisabled: true,
      },
      { value: "North America", label: t("BasicInfo.Options.North America") },
      {
        value: "Central America",
        label: t("BasicInfo.Options.Central America"),
      },
      { value: "South America", label: t("BasicInfo.Options.South America") },
      { value: "UK", label: t("BasicInfo.Options.UK") },
      { value: "Europe", label: t("BasicInfo.Options.Europe") },
      { value: "Africa", label: t("BasicInfo.Options.Africa") },
      { value: "Asia", label: t("BasicInfo.Options.Asia") },
      { value: "Australia", label: t("BasicInfo.Options.Australia") },
      { value: "Pacific Islander", label: "Pacific Islander" },
      {
        value: "Caribbean Islands",
        label: t("BasicInfo.Options.Caribbean Islands"),
      },
      { value: "Middle East", label: t("BasicInfo.Options.Middle East") },
      {
        value: "Prefer not to say",
        label: t("BasicInfo.Options.Prefer not to say"),
      },
    ]);
    setEthnicityOptions([
      {
        label: t("BasicInfo.Options.select or type"),
        value: "*",
        isDisabled: true,
      },
      { value: "Caucasian", label: t("BasicInfo.Options.Caucasian") },
      {
        value: "Black or African-American",
        label: t("BasicInfo.Options.Black or African-American"),
      },
      {
        value: "Latino or Hispanic",
        label: t("BasicInfo.Options.Latino or Hispanic"),
      },
      { value: "Asian", label: t("BasicInfo.Options.Asian") },
      {
        value: "Native American",
        label: t("BasicInfo.Options.Native American"),
      },
      { value: "Aboriginal", label: t("BasicInfo.Options.Aboriginal") },
      { value: "European", label: t("BasicInfo.Options.European") },
      { value: "Arab", label: t("BasicInfo.Options.Arab") },
      { value: "Jewish", label: t("BasicInfo.Options.Jewish") },
      { value: "Indian", label: t("BasicInfo.Options.Indian") },
      { value: "Chinese", label: t("BasicInfo.Options.Chinese") },
      { value: "Japanese", label: t("BasicInfo.Options.Japanese") },
      { value: "Irish", label: t("BasicInfo.Options.Irish") },
      { value: "South American", label: t("BasicInfo.Options.South American") },
      {
        value: "Native Hawaiian or Pacific Islander",
        label: t("BasicInfo.Options.Native Hawaiian or Pacific Islander"),
      },
      { value: "Unknown", label: t("BasicInfo.Options.Unknown") },
      {
        value: "Prefer not to say",
        label: t("BasicInfo.Options.Prefer not to say"),
      },
    ]);
    setEducationOptions([
      {
        label: t("BasicInfo.Options.select or type"),
        value: "*",
        isDisabled: true,
      },
      {
        value: "Some High School",
        label: t("BasicInfo.Options.Some High School"),
      },
      { value: "High School", label: t("BasicInfo.Options.High School") },
      {
        value: "Bachelor's Degree",
        label: t("BasicInfo.Options.Bachelor's Degree"),
      },
      {
        value: "Master's Degree",
        label: t("BasicInfo.Options.Master's Degree"),
      },
      {
        value: "Ph.D. or higher",
        label: t("BasicInfo.Options.Ph-D or higher"),
      },
      { value: "Trade School", label: t("BasicInfo.Options.Trade School") },
      {
        value: "Prefer not to say",
        label: t("BasicInfo.Options.Prefer not to say"),
      },
    ]);
    setWorkStatusOptions([
      {
        label: t("BasicInfo.Options.select or type"),
        value: "*",
        isDisabled: true,
      },
      {
        value: "Employed Full-Time",
        label: t("BasicInfo.Options.Employed Full-Time"),
      },
      {
        value: "Employed Part-Time",
        label: t("BasicInfo.Options.Employed Part-Time"),
      },
      {
        value: "Seeking opportunities",
        label: t("BasicInfo.Options.Seeking opportunities"),
      },
      { value: "Self-employed", label: t("BasicInfo.Options.Self-employed") },
      { value: "Retired", label: t("BasicInfo.Options.Retired") },
      {
        value: "Prefer not to say",
        label: t("BasicInfo.Options.Prefer not to say"),
      },
    ]);
  }, [i18n]);

  useEffect(() => {
    if (profile) {
      if (profile.OnboardingFinished) navigate("/404");
      for (var key of Object.keys(basicInfo)) {
        if (profile[key]) {
          chnageBasicInfoVAlue(key, profile[key]);
        } else if (profile.CustomFields[key] && key !== "CountryCode") {
          chnageBasicInfo(key, profile.CustomFields[key]);
        }
      }
    }
  }, [profile]);

  useEffect(() => {
    if (profile) {
      if (profile.OnboardingFinished) navigate("/404");
      dispatch(
        setTitle({
          smallTitle: t("BasicInfo.small-title"),
          title: t("BasicInfo.title"),
        })
      );
    }
  }, [profile, i18n]);

  useEffect(() => {
    if (profile && countryOptions.length > 0 && !basicInfo.CountryCode) {
      const country = countryOptions.find(
        (c) => c.value === profile.Settings.CountryCode
      );
      if (country) {
        setSelectedCountry(country);
        setBasicInfo((prevState) => ({
          ...prevState,
          CountryCode: country.value,
        }));
      }
    }
  }, [profile && countryOptions]);

  const chnageBasicInfoVAlue = (name, value) => {
    setBasicInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const chnageBasicInfo = (name, value) => {
    const i18Key = `OnBoarding:BasicInfo.Options.${value}`;
    const isKeyExist = i18n.exists(i18Key);
    setBasicInfo((prevState) => ({
      ...prevState,
      [name]: isKeyExist
        ? { value: value, label: t(i18Key) }
        : { value: value, label: value },
    }));
  };

  const handleChange = (name, selectedOption) => {
    chnageBasicInfo(name, selectedOption.value);
  };
  const focusEmptySelect = (name) => {
    const index = Object.keys(basicInfo).indexOf(name);
    basicInfoRefs.current[index].focus();
  };
  const validate = () => {
    let isValidate = true;

    if (!basicInfo.WorkStatus.label.replace(/^\s+/g, "")) {
      focusEmptySelect("WorkStatus");
      isValidate = false;
    }
    if (!basicInfo.Education.label.replace(/^\s+/g, "")) {
      focusEmptySelect("Education");
      isValidate = false;
    }
    if (!basicInfo.PlaceOfBirth.label.replace(/^\s+/g, "")) {
      focusEmptySelect("PlaceOfBirth");
      isValidate = false;
    }
    if (!basicInfo.Ethnicity.label.replace(/^\s+/g, "")) {
      focusEmptySelect("Ethnicity");
      isValidate = false;
    }
    if (!basicInfo.PrimaryHomeLocation.label.replace(/^\s+/g, "")) {
      focusEmptySelect("PrimaryHomeLocation");
      isValidate = false;
    }
    if (!basicInfo.Age.label.replace(/^\s+/g, "")) {
      focusEmptySelect("Age");
      isValidate = false;
    }
    if (!basicInfo.Gender.label.replace(/^\s+/g, "")) {
      focusEmptySelect("Gender");
      isValidate = false;
    }
    if (!basicInfo.CountryCode.replace(/^\s+/g, "")) {
      focusEmptySelect("CountryCode");
      isValidate = false;
    }

    if (!isValidate) setShowError(true);

    return isValidate;
  };

  const saveBasicInfo = async () => {
    if (!validate()) return;
    try {
      dispatch(setShowLoader(true));
      const data = {
        FirstName: basicInfo.FirstName,
        LastName: basicInfo.LastName,
        Phone: basicInfo.Phone,
        Email: basicInfo.Email,
        CustomFields: {
          CountryCode: basicInfo.CountryCode,
          Gender: basicInfo.Gender.value,
          Age: basicInfo.Age.value,
          PrimaryHomeLocation: basicInfo.PrimaryHomeLocation.value,
          Ethnicity: basicInfo.Ethnicity.value,
          PlaceOfBirth: basicInfo.PlaceOfBirth.value,
          Education: basicInfo.Education.value,
          WorkStatus: basicInfo.WorkStatus.value,
        },
      };
      const res = await Api.put("user/profile", data);
      dispatch(setShowLoader(false));
      if (res.data) {
        dispatch(changeProfile(res.data.payload));
        navigate("../my-family-and-relationships");
      }
    } catch (error) {
      dispatch(setShowLoader(false));
    }
  };
  return (
    <div className="basic-info-container">
      <SmallTitle style={{ fontSize: "24px" }}>
        {t("BasicInfo.intro-title")}
      </SmallTitle>
      <p className="intro-text">{t("BasicInfo.intro")}</p>
      <p className="intro-text">{t("BasicInfo.time-intro")}</p>
      <hr />
      <SmallTitle>{t("BasicInfo.full-name")}</SmallTitle>
      <Input
        disabled
        type="text"
        name="FirstName"
        value={`${basicInfo.FirstName} ${basicInfo.LastName}`}
      />

      <SmallTitle>{t("Common:phone")}</SmallTitle>
      <Input
        disabled
        type="text"
        name="Phone"
        value={basicInfo.Phone}
        endIcon={<PhoneIcon />}
      />

      <SmallTitle>{t("Common:email")}</SmallTitle>
      <Input
        disabled
        type="text"
        name="Email"
        value={basicInfo.Email}
        endIcon={
          <FontAwesomeIcon icon={faEnvelope} size="1x" className="email-icon" />
        }
      />

      <SmallTitle>{t("BasicInfo.country")}</SmallTitle>
      <Select
        name="CountryCode"
        value={
          countryOptions.filter(
            (option) => option.value === basicInfo.CountryCode
          ).length > 0
            ? countryOptions.filter(
                (option) => option.value === basicInfo.CountryCode
              )[0].label
            : basicInfo.CountryCode
            ? basicInfo.CountryCode
            : ""
        }
        onChange={(e) => {
          setSelectedCountry(e);
          chnageBasicInfoVAlue("CountryCode", e.value);
        }}
        options={countryOptions}
        isError={showError && !basicInfo.CountryCode.replace(/^\s+/g, "")}
        ref={(el) =>
          (basicInfoRefs.current[
            Object.keys(basicInfo).indexOf("CountryCode")
          ] = el)
        }
        height="300px"
        editable
      />

      <SmallTitle>{t("BasicInfo.gender")}</SmallTitle>
      <Select
        name="Gender"
        value={basicInfo.Gender.label}
        onChange={(e) => handleChange("Gender", e)}
        options={genderOption}
        isError={showError && !basicInfo.Gender.label.replace(/^\s+/g, "")}
        ref={(el) =>
          (basicInfoRefs.current[Object.keys(basicInfo).indexOf("Gender")] = el)
        }
      />

      <SmallTitle>{t("BasicInfo.age")}</SmallTitle>
      <Select
        name="Age"
        value={basicInfo.Age.label}
        onChange={(e) => handleChange("Age", e)}
        options={ageOptions}
        isError={showError && !basicInfo.Age.label.replace(/^\s+/g, "")}
        ref={(el) =>
          (basicInfoRefs.current[Object.keys(basicInfo).indexOf("Age")] = el)
        }
      />

      <SmallTitle>{t("BasicInfo.home-place")}</SmallTitle>
      <Select
        name={"PrimaryHomeLocation"}
        value={basicInfo.PrimaryHomeLocation.label}
        onChange={(e) => handleChange("PrimaryHomeLocation", e)}
        options={primaryHomeLocationOptions}
        editable
        isError={
          showError && !basicInfo.PrimaryHomeLocation.label.replace(/^\s+/g, "")
        }
        ref={(el) =>
          (basicInfoRefs.current[
            Object.keys(basicInfo).indexOf("PrimaryHomeLocation")
          ] = el)
        }
      />

      <SmallTitle>{t("BasicInfo.ethnicity")}</SmallTitle>
      <Select
        name={"Ethnicity"}
        value={basicInfo.Ethnicity.label}
        onChange={(e) => handleChange("Ethnicity", e)}
        options={ethnicityOptions}
        editable
        isError={showError && !basicInfo.Ethnicity.label.replace(/^\s+/g, "")}
        ref={(el) =>
          (basicInfoRefs.current[Object.keys(basicInfo).indexOf("Ethnicity")] =
            el)
        }
      />

      <SmallTitle>{t("BasicInfo.birth-place")}</SmallTitle>
      <Select
        name={"PlaceOfBirth"}
        value={basicInfo.PlaceOfBirth.label}
        onChange={(e) => handleChange("PlaceOfBirth", e)}
        options={placeOfBirthOptions}
        editable
        isError={
          showError && !basicInfo.PlaceOfBirth.label.replace(/^\s+/g, "")
        }
        ref={(el) =>
          (basicInfoRefs.current[
            Object.keys(basicInfo).indexOf("PlaceOfBirth")
          ] = el)
        }
      />

      <SmallTitle>{t("BasicInfo.education")}</SmallTitle>
      <Select
        name={"Education"}
        value={basicInfo.Education.label}
        onChange={(e) => handleChange("Education", e)}
        options={educationOptions}
        editable
        isError={showError && !basicInfo.Education.label.replace(/^\s+/g, "")}
        ref={(el) =>
          (basicInfoRefs.current[Object.keys(basicInfo).indexOf("Education")] =
            el)
        }
      />

      <SmallTitle>{t("BasicInfo.work")}</SmallTitle>
      <Select
        title="My work"
        name={"WorkStatus"}
        value={basicInfo.WorkStatus.label}
        onChange={(e) => handleChange("WorkStatus", e)}
        options={workStatusOptions}
        editable
        isError={showError && !basicInfo.WorkStatus.label.replace(/^\s+/g, "")}
        ref={(el) =>
          (basicInfoRefs.current[Object.keys(basicInfo).indexOf("WorkStatus")] =
            el)
        }
      />

      <StepsDots
        totalSteps={6}
        stepNumber={1}
        next={saveBasicInfo}
        isLoading={showLoader}
        className="basic-info-step-dots"
      />
    </div>
  );
};
