import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { SpeechBubble } from "../../../../../../components/speech-bubble/speech-bubble";
import "./finshed-third-step-popup.scss";
import { useTranslation } from "react-i18next";

export const FinshedThirdPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  return (
    <Popup close={close} height="450px" width="95%">
      <div className="finished-third-step-popup-container">
        <PopupTitle>WOW!</PopupTitle>

        {step === 1 && (
          <SpeechBubble
            direction={"right"}
            top={"70px"}
            left={"30px"}
            width={"325px"}
            height={"125px"}
            style={
              i18n.dir() === "rtl"
                ? { transform: "translateX(-25px)" }
                : { transform: "translateX(25px)" }
            }
          >
            <p>{t("ViewMap.FinshedThirdPopup.info1-1")}</p>
            <p>{t("ViewMap.FinshedThirdPopup.info1-2")}</p>
            <p>{t("ViewMap.FinshedThirdPopup.info1-3")}</p>
            <p>{t("ViewMap.FinshedThirdPopup.info1-4")}</p>
            <p>{t("ViewMap.FinshedThirdPopup.info1-5")}</p>
          </SpeechBubble>
        )}

        {step === 2 && (
          <SpeechBubble
            direction={"right"}
            top={"70px"}
            left={"30px"}
            width={"250px"}
            height={"125px"}
          >
            <p>{t("ViewMap.FinshedThirdPopup.info2-1")}</p>
            <p>{t("ViewMap.FinshedThirdPopup.info2-2")}</p>
            <p>{t("ViewMap.FinshedThirdPopup.info2-3")}</p>
            <p>{t("ViewMap.FinshedThirdPopup.info2-4")}</p>
          </SpeechBubble>
        )}

        {step === 3 && (
          <>
            <SpeechBubble
              direction={"right"}
              top={"70px"}
              left={"30px"}
              width={"250px"}
              height={"125px"}
            >
              <p>{t("ViewMap.FinshedThirdPopup.info3-1")}</p>
              <p>{t("ViewMap.FinshedThirdPopup.info3-2")}</p>
              <p>{t("ViewMap.FinshedThirdPopup.info3-3")}</p>
              <p>{t("ViewMap.FinshedThirdPopup.info3-4")}</p>
              <p>{t("ViewMap.FinshedThirdPopup.info3-5")}</p>
            </SpeechBubble>
          </>
        )}

        <div className="popup-bottom-bottons-container flex">
          {step === 3 ? (
            <>
              <PopupButton
                continue="true"
                onClick={() => setStep((prevState) => prevState - 1)}
              >
                {t("Common:previous")}
              </PopupButton>
              <PopupButton continue="true" onClick={close}>
                {t("Common:close")}
              </PopupButton>
              <PopupButton
                continue="true"
                onClick={() => navigate("../maps/create/category")}
              >
                {t("ViewMap.FinshedThirdPopup.create-new-map")}{" "}
              </PopupButton>
            </>
          ) : (
            <>
              {step !== 1 && (
                <PopupButton
                  continue="true"
                  onClick={() => setStep((prevState) => prevState - 1)}
                >
                  {t("Common:previous")}
                </PopupButton>
              )}

              <PopupButton
                continue="true"
                onClick={() => setStep((prevState) => prevState + 1)}
              >
                {t("Common:next")}
              </PopupButton>
            </>
          )}
        </div>
      </div>
    </Popup>
  );
};
