import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setBadgesPopupOpen,
  setBoosterQuestionsPopup,
  setGoodToHaveYouBackPopupOpen,
  setHideMenuItems,
  setHowsYourJourneyGoingPopupOpen,
  setLevelUpPopupOpen,
  setViewBoosterQuestionsPopupOpen,
  setWelcomePopupsOpen,
} from "../../../store/app-settings/app-settings-slice";
import { changeProfile } from "../../../store/profile/profile-slice";
import { BoosterQuestionsPopup } from "./booster-questions/booster-questions-popup";
import { GoodToHaveYouBackPopup } from "./good-to-have-you-back/good-to-have-you-back-popup";
import { HowsYourJourneyGoingPopup } from "./hows-your-journey-going/hows-your-journey-going";
import { ViewBoosters } from "./view-boosters/view-boosters-popup";
import { WelcomeBackPopup } from "./welcome-back/welcome-back-popup";
import { BadgesPopup } from "./badges-popup/badges-popup";
import { StatusLevelUpPopup } from "./status-level-up-popup/status-level-up-popup";

export const AppPopups = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.authReducer.accessToken);
  const { profile, appInitRequired } = useSelector((state) => ({
    profile: state.profileReducer.profile,
    appInitRequired: state.profileReducer.appInitRequired,
  }));
  const {
    welcomePopupsOpen,
    howsYourJourneyGoingPopupOpen,
    boosterQuestionsPopup,
    goodToHaveYouBackPopupOpen,
    viewBoosterQuestionsPopupOpen,
    badgesPopupOpen,
    levelUpPopupOpen,
  } = useSelector((state) => ({
    welcomePopupsOpen: state.appSettingsReducer.welcomePopupsOpen,
    howsYourJourneyGoingPopupOpen:
      state.appSettingsReducer.howsYourJourneyGoingPopupOpen,
    boosterQuestionsPopup: state.appSettingsReducer.boosterQuestionsPopup,
    goodToHaveYouBackPopupOpen:
      state.appSettingsReducer.goodToHaveYouBackPopupOpen,
    welcomeBackPopupOpen: state.appSettingsReducer.welcomeBackPopupOpen,
    viewBoosterQuestionsPopupOpen:
      state.appSettingsReducer.viewBoosterQuestionsPopupOpen,
    badgesPopupOpen: state.appSettingsReducer.badgesPopupOpen,
    levelUpPopupOpen: state.appSettingsReducer.levelUpPopupOpen,
  }));
  const [welcomeBackPopupOpen, setWelcomeBackPopupOpen] = useState(false);

  const [isBoosterPopup, setIsBoosterPopup] = useState(
    localStorage.getItem("isBoosterPopup") === "true"
  );
  useEffect(() => {
    localStorage.setItem("isBoosterPopup", "false");
  }, []);

  useEffect(() => {
    if (accessToken && appInitRequired && profile) {
      if (!profile.PrivacyPolicyAccepted) {
        dispatch(setHideMenuItems(true));
        navigate("/privacy-policy");
      } else if (!profile.OnboardingFinished) {
        dispatch(setHideMenuItems(true));
        navigate("/welcome");
      } else if (welcomePopupsOpen) {
        dispatch(setWelcomePopupsOpen(false));
        setWelcomeBackPopupOpen(true);
        const daysPassedSinceFirstLogin = dayjs().diff(
          dayjs(profile.FirstLoginDate),
          "days"
        );
        if (
          profile.Settings.DisplayDefineYourselfAllowed &&
          profile.FirstMapCreatedDate &&
          profile.Boosters.Answers.length === 0 &&
          daysPassedSinceFirstLogin !== 0 &&
          daysPassedSinceFirstLogin % 30 === 0
        ) {
          navigate("/define-yourself", {
            state: { openWelcomePopupInExit: true, daysPassedSinceFirstLogin },
          });
          dispatch(setHideMenuItems(true));
        }
      }
    } else if (!accessToken) {
      // remove open popups in logout
      if (welcomeBackPopupOpen) setWelcomeBackPopupOpen(false);
      else if (goodToHaveYouBackPopupOpen)
        dispatch(setGoodToHaveYouBackPopupOpen(false));
      else if (howsYourJourneyGoingPopupOpen)
        dispatch(setHowsYourJourneyGoingPopupOpen(false));
      if (profile) dispatch(changeProfile(null));
    }
  }, [accessToken, appInitRequired]);

  return (
    <>
      {isBoosterPopup && (
        <ViewBoosters
          close={() => {
            setIsBoosterPopup(false);
            localStorage.setItem("isBoosterPopup", "false");
          }}
        />
      )}
      {welcomeBackPopupOpen && (
        <WelcomeBackPopup setWelcomeBackPopupOpen={setWelcomeBackPopupOpen} />
      )}
      {howsYourJourneyGoingPopupOpen && (
        <HowsYourJourneyGoingPopup
          close={() => dispatch(setHowsYourJourneyGoingPopupOpen(false))}
        />
      )}
      {goodToHaveYouBackPopupOpen && (
        <GoodToHaveYouBackPopup
          close={() => dispatch(setGoodToHaveYouBackPopupOpen(false))}
        />
      )}
      {boosterQuestionsPopup.open && (
        <BoosterQuestionsPopup
          editMode={boosterQuestionsPopup.editMode}
          close={() =>
            dispatch(setBoosterQuestionsPopup({ open: false, editMode: false }))
          }
        />
      )}
      {viewBoosterQuestionsPopupOpen && (
        <ViewBoosters
          close={() => dispatch(setViewBoosterQuestionsPopupOpen(false))}
        />
      )}
      {badgesPopupOpen && (
        <BadgesPopup
          close={() => {
            dispatch(setBadgesPopupOpen(false));
          }}
        />
      )}
      {levelUpPopupOpen &&
        /*(profile?.Phone === "+972547997155" ||
          profile?.Phone === "+972547898398" ||
          profile?.Phone === "+972525813751" ||
          profile?.Phone === "+972537536392" ||
          profile?.Phone === "+972525291333" ||
          profile?.Phone === "+972545603120" ||
          profile?.Phone === "+972587897623" ||
          profile?.Phone === "+972528506862")*/ true && (
          <StatusLevelUpPopup
            close={() => {
              dispatch(setLevelUpPopupOpen(false));
            }}
          />
        )}
    </>
  );
};
