import { Button } from "../button/button";
import "./steps-dots.scss";
import { useTranslation } from "react-i18next";

export const StepsDots = ({
  totalSteps,
  stepNumber,
  back,
  next,
  isLoading,
  className,
  lastNextButtonText,
}) => {
  const { t, i18n } = useTranslation("Common");

  return (
    <div className={"step-dots-container " + (className || "")}>
      {back && (
        <Button small onClick={back}>
          {stepNumber > 0 ? "< " + t("back") : "Back"}
        </Button>
      )}
      {Array.from(Array(totalSteps).keys()).map((step) => {
        return (
          <span
            key={step}
            className={"step-dot" + (step === stepNumber - 1 ? " full" : "")}
          />
        );
      })}
      {next && (
        <Button small onClick={next} disabled={isLoading} isLoading={isLoading}>
          {stepNumber === Array.from(Array(totalSteps).keys()).length
            ? lastNextButtonText || t("continue")
            : t("next") + " >"}
        </Button>
      )}
    </div>
  );
};
