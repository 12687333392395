import React from "react";
import { PopupButton } from "../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../components/popup/popup";
import Quote from "../../../../../components/quote/quote";
import "./isolation-and-wellbeing-quote-popup.scss";
import { useTranslation } from "react-i18next";

export const IsolationAndWellbeingQuotePopup = ({ close }) => {
  const { t, i18n } = useTranslation("OnBoarding");

  return (
    <Popup close={close}>
      <div className="isolation-and-wellbeing-quote-popup-container flex">
        <Quote
          quote={t("FamilyAndRelationship.isolationPoUpQuote")}
          source={t("FamilyAndRelationship.isolationPoUpSource")}
        />
        <PopupButton className="continue-button" onClick={close}>
          {t("Common:continue")}
        </PopupButton>
      </div>
    </Popup>
  );
};
