import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function ProtectedRoute({ admin }) {
  const profile = useSelector((state) => state.profileReducer.profile);
  const accessToken = useSelector((state) => state.authReducer.accessToken);
  const refreshTokenRemoved = useSelector(
    (state) => state.appSettingsReducer.refreshTokenRemoved
  );

  const isLoggedIn = accessToken.length > 0 || !refreshTokenRemoved;
  if (!isLoggedIn) {
    return <Navigate to="/login" />; // Redirect to login page
  }

  if (admin && profile && !profile.Admin) {
    return <Navigate to="maps" />; // Redirect to user's home page
  }

  return <Outlet />;
}
