export const languagesList = [
  { value: "af", label: "Afrikaans" },
  { value: "sq", label: "Shqip" },
  { value: "am", label: "አማርኛ" },
  { value: "ar", label: "العربية" },
  { value: "hy", label: "Հայերեն" },
  { value: "az", label: "Azərbaycan dili" },
  { value: "eu", label: "Euskara" },
  { value: "be", label: "Беларуская мова" },
  { value: "bn", label: "বাংলা" },
  { value: "bs", label: "Bosanski jezik" },
  { value: "bg", label: "Български език" },
  { value: "ca", label: "Català" },
  { value: "zh", label: "中文 (Zhōngwén), 汉语, 漢語" },
  { value: "hr", label: "Hrvatski" },
  { value: "cs", label: "Čeština" },
  { value: "da", label: "Dansk" },
  { value: "nl", label: "Nederlands" },
  { value: "en", label: "English" },
  { value: "eo", label: "Esperanto" },
  { value: "et", label: "Eesti keel" },
  { value: "tl", label: "Wikang Tagalog" },
  { value: "fi", label: "Suomen kieli" },
  { value: "fr", label: "Français" },
  { value: "gl", label: "Galego" },
  { value: "ka", label: "ქართული" },
  { value: "de", label: "Deutsch" },
  { value: "el", label: "Ελληνικά" },
  { value: "gu", label: "ગુજરાતી" },
  { value: "ht", label: "Kreyòl ayisyen" },
  { value: "ha", label: "Hausa, هَوُسَ" },
  { value: "he", label: "עברית" },
  { value: "hi", label: "हिन्दी" },
  { value: "hmn", label: "Hmoob" },
  { value: "hu", label: "Magyar" },
  { value: "is", label: "Íslenska" },
  { value: "ig", label: "Asụsụ Igbo" },
  { value: "id", label: "Bahasa Indonesia" },
  { value: "ga", label: "Gaeilge" },
  { value: "it", label: "Italiano" },
  { value: "ja", label: "日本語 (にほんご／にっぽんご)" },
  { value: "jw", label: "ꦧꦱꦗꦮ (Javanese)" },
  { value: "kn", label: "ಕನ್ನಡ" },
  { value: "kk", label: "Қазақ тілі" },
  { value: "km", label: "ភាសាខ្មែរ (Phasa Khmer)" },
  { value: "rw", label: "Kinyarwanda" },
  { value: "ko", label: "한국어 (韓國語), 조선말 (朝鮮語)" },
  { value: "ku", label: "Kurdî, کوردی‎" },
  { value: "ky", label: "Кыргызча" },
  { value: "lo", label: "ພາສາລາວ (Phaasaa Lao)" },
  { value: "la", label: "Latine" },
  { value: "lv", label: "Latviešu valoda" },
  { value: "lt", label: "Lietuvių kalba" },
  { value: "lb", label: "Lëtzebuergesch" },
  { value: "mk", label: "Македонски јазик" },
  { value: "mg", label: "Malagasy fiteny" },
  { value: "ms", label: "Bahasa Melayu, بهاس ملايو‎" },
  { value: "ml", label: "മലയാളം" },
  { value: "mt", label: "Malti" },
  { value: "ne", label: "नेपाली" },
  { value: "nd", label: "isiNdebele" },
  { value: "se", label: "Davvisámegiella" },
  { value: "no", label: "Norsk" },
  { value: "nb", label: "Norsk bokmål" },
  { value: "nn", label: "Norsk nynorsk" },
  { value: "ny", label: "chiCheŵa, chinyanja" },
  { value: "or", label: "ଓଡ଼ିଆ" },
  { value: "om", label: "Afaan Oromoo" },
  { value: "os", label: "Ирон æвзаг" },
  { value: "ps", label: "پښتو" },
  { value: "fa", label: "فارسی" },
  { value: "pl", label: "Polski" },
  { value: "pt", label: "Português" },
  { value: "pa", label: "ਪੰਜਾਬੀ" },
  { value: "qu", label: "Runa Simi, Kichwa" },
  { value: "ro", label: "Română" },
  { value: "rm", label: "Rumantsch Grischun" },
  { value: "ru", label: "Русский" },
  { value: "sm", label: "Gagana fa'a Samoa" },
  { value: "sg", label: "yângâ tî sängö" },
  { value: "sa", label: "संस्कृतम्" },
  { value: "sr", label: "српски језик" },
  { value: "gd", label: "Gàidhlig" },
  { value: "sn", label: "chiShona" },
  { value: "sd", label: "سنڌي" },
  { value: "si", label: "සිංහල" },
  { value: "sk", label: "Slovenčina" },
  { value: "sl", label: "Slovenščina" },
  { value: "so", label: "Soomaaliga, af Soomaali" },
  { value: "st", label: "Sesotho" },
  { value: "es", label: "Español" },
  { value: "su", label: "Basa Sunda" },
  { value: "sw", label: "Kiswahili" },
  { value: "sv", label: "Svenska" },
  { value: "tg", label: "тоҷикӣ, toçikī, تاجیکی‎" },
  { value: "ta", label: "தமிழ்" },
  { value: "tt", label: "татар теле, tatar tele" },
  { value: "te", label: "తెలుగు" },
  { value: "th", label: "ไทย" },
  { value: "bo", label: "བོད་ཡིག" },
  { value: "ti", label: "ትግርኛ" },
  { value: "to", label: "faka Tonga" },
  { value: "tr", label: "Türkçe" },
  { value: "tk", label: "Türkmençe, Түркмен, تۆرکمن‎" },
  { value: "tw", label: "Twi" },
  { value: "uk", label: "Українська" },
  { value: "ur", label: "اردو" },
  { value: "ug", label: "ئۇيغۇرچە‎, Uyghurche" },
  { value: "uz", label: "Oʻzbek, Ўзбек, أۇزبېك‎" },
  { value: "vi", label: "Tiếng Việt" },
  { value: "vo", label: "Volapük" },
  { value: "cy", label: "Cymraeg" },
  { value: "wa", label: "Walon" },
  { value: "wo", label: "Wollof" },
  { value: "xh", label: "isiXhosa" },
  { value: "yi", label: "ייִדיש" },
  { value: "yo", label: "Yorùbá" },
  { value: "zu", label: "isiZulu" },
];
