export const CategoriesNames = {
  HealthEvaluation: "Health",
  SelfAndWellbeingEvaluation: "Self & Wellbeing",
  FamilyEvaluation: "Family",
  LeisureEvaluation: "Leisure",
  SpiritualEvaluation: "Spiritual",
  SocialAndCommunityEvaluation: "Social & Community",
  ProfessionalEvaluation: "Professional/Career",
  FinancialEvaluation: "Financial",
  EducationEvaluation: "Education",
  LegacyEvaluation: "Legacy",
  OtherEvaluation: "Other",
};

export const NativeEvents = {
  AddPushNotificationToken: "AddPushNotificationToken",
  SetAccessToken: "SetAccessToken",
  RequestCameraAndMediaLibraryPermissions:
    "RequestCameraAndMediaLibraryPermissions",
  RequestContactPermissions: "RequestContactPermissions",
  AddToCalendar: "AddToCalendar",
  RemoveFromCalendar: "RemoveFromCalendar",
  isBoosterPopup: "isBoosterPopup",
};
