import React from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../../../../../components/input/input";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { SmallTitle } from "../../../../../../components/small-title/small-title";
import BlankImage from "../../../../../../assets/images/family.jpg";
import "./step-details-popup.scss";
import { useTranslation } from "react-i18next";

export const StepDetailsPopup = ({
  index,
  info,
  updateStep,
  finishStep,
  setThatsOkPopup,
  shareMode,
  close,
}) => {
  const { t } = useTranslation("Maps");

  const navigate = useNavigate();

  const navigateToModifyStep = () => {
    navigate(`../../../maps/edit/${info._id}/goal/step/${index + 1}`, {
      state: { fromViewMap: true },
    });
    close();
  };

  const updateStepAsNotDoneYet = async () => {
    const data = {
      StepIndex: index,
      Completed: false,
      NotYet: true,
    };
    await updateStep(data);
    setThatsOkPopup({ open: true, index });
    close();
  };

  const finisheStepAndClose = async () => {
    finishStep(index);
    close();
  };

  return (
    <Popup height={"650px"} close={close}>
      <div className="step-details-popup-container flex">
        <PopupTitle>
          {t("Common:pathway")} #{index + 1}
        </PopupTitle>
        <div className="fields">
          <SmallTitle>{t("ViewMap.StepDetailsPopUp.goal")}</SmallTitle>
          <img
            className="goal-image"
            src={
              info.Image.Path
                ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${info.Image.Path} `
                : BlankImage
            }
          />
          <div className="step-info">
            <span>{info.MainGoal}</span>
          </div>

          <SmallTitle>
            {t("ViewMap.StepDetailsPopUp.strengthening-word")}
          </SmallTitle>
          <div className="step-info">
            <span>{info.StrengthWord}</span>
          </div>

          <SmallTitle>{t("Common:pathway")}</SmallTitle>
          <div className="step-info">
            <span>{info.Steps[index].Name}</span>
          </div>

          <SmallTitle>{t("ViewMap.StepDetailsPopUp.obstacle")}</SmallTitle>
          <div className="step-info">
            <span>{info.Steps[index].Obstacle}</span>
          </div>

          <SmallTitle>{t("ViewMap.StepDetailsPopUp.overcome")}</SmallTitle>
          <div className="step-info">
            <span>{info.Steps[index].Overcome}</span>
          </div>

          {info.Steps[index].ContactName && (
            <>
              <SmallTitle>
                {t("ViewMap.StepDetailsPopUp.contact-name")}
              </SmallTitle>
              <div className="step-info">
                <span>{info.Steps[index].ContactName}</span>
              </div>
            </>
          )}

          {info.Steps[index].Phone && (
            <>
              <SmallTitle>{t("Common:phone")}</SmallTitle>
              <div className="step-info">
                <span>{info.Steps[index].Phone}</span>
              </div>
            </>
          )}

          {info.Steps[index].Email && (
            <>
              <SmallTitle>{t("Common:email")}</SmallTitle>
              <div className="step-info">
                <span>{info.Steps[index].Email}</span>
              </div>
            </>
          )}
        </div>
        {!info.Steps[index].Completed && (
          <SmallTitle>
            {t("ViewMap.StepDetailsPopUp.did-you-complete")}
          </SmallTitle>
        )}
        {info.Steps[index].Completed || shareMode || info.Archived ? (
          <div className="buttons flex">
            <PopupButton onClick={close}>{t("Common:close")}</PopupButton>
          </div>
        ) : (
          <div className="buttons flex">
            {info.Steps.filter((step) => step.Completed === true).length ===
              index && (
              <PopupButton
                onClick={finisheStepAndClose}
                style={{ width: "60px", margin: "3px", fontSize: "15px" }}
              >
                {t("ViewMap.StepDetailsPopUp.did")}
              </PopupButton>
            )}
            {!info.Steps[index].Completed && (
              <PopupButton onClick={updateStepAsNotDoneYet}>
                {t("ViewMap.StepDetailsPopUp.did-not")}
              </PopupButton>
            )}
            <PopupButton
              onClick={navigateToModifyStep}
              style={{ width: "140px", margin: "3px", fontSize: "15px" }}
            >
              {t("ViewMap.StepDetailsPopUp.modify")}
            </PopupButton>
          </div>
        )}
      </div>
    </Popup>
  );
};
