import React from "react";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import { SpeechBubble } from "../../../../../../components/speech-bubble/speech-bubble";
import "./finshed-second-step-popup.scss";
import { useTranslation } from "react-i18next";

export const FinshedSecondPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  return (
    <Popup close={close} height="350px">
      <div className="finished-second-step-popup-container">
        <PopupTitle>{t("ViewMap.FinshedSecondPopUp.amazing")}</PopupTitle>

        <SpeechBubble
          direction={"right"}
          top={"80px"}
          left={"25px"}
          width={"180px"}
          height={"75px"}
        >
          <p>{t("ViewMap.FinshedSecondPopUp.info1")}</p>
          <p>{t("ViewMap.FinshedSecondPopUp.info2")}</p>
        </SpeechBubble>

        <div className="popup-bottom-bottons-container flex">
          <PopupButton continue="true" onClick={close}>
            {t("Common:next")}
          </PopupButton>
        </div>
      </div>
    </Popup>
  );
};
