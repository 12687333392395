import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PopupButton } from "../../../../components/popup-button/popup-button";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import { resetDraftMap } from "../../../../store/profile/profile-slice";
import Api from "../../../../utils/api";
import "./draft-map-popup.scss";
import { useTranslation } from "react-i18next";

export const DraftMapPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetDraftMapInClientAndServer = async () => {
    // draft map reset needed in client & server
    dispatch(resetDraftMap());
    try {
      await Api.delete("user/profile/draftMap");
    } catch (error) {
      console.log(error);
    }
  };

  const openNewMap = async () => {
    await resetDraftMapInClientAndServer();
    close();
    navigate("/maps/create/category");
  };

  const openDraftMap = async () => {
    close();
    navigate("/maps/create/goal");
  };

  const resetDraftMapAndClose = async () => {
    close();
  };

  return (
    <Popup height="300px" close={close}>
      <div className="draft-map-popup-container flex-popup-container">
        <PopupTitle>{t("CreateMap.continueExist.title")}</PopupTitle>
        <div>
          <p>{t("CreateMap.continueExist.info")}</p>
          <p>{t("CreateMap.continueExist.info2")}</p>
          <br />
          <p>{t("CreateMap.continueExist.question")}</p>
          <p>{t("CreateMap.continueExist.question2")}</p>
        </div>
        <div className="footer-buttons-container flex">
          <PopupButton className="footer-buttons" onClick={openNewMap}>
            {t("CreateMap.continueExist.no")}
          </PopupButton>
          <PopupButton className="footer-buttons" onClick={openDraftMap}>
            {t("CreateMap.continueExist.yes")}
          </PopupButton>
        </div>
      </div>
    </Popup>
  );
};
