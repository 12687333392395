import React from "react";
import YouTube from "react-youtube";

export const VideoStep = () => {
  const opts = {
    height: "300", //"390",
    width: "400", //"640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <div className="video-container">
      <YouTube videoId="tz0fDABt67g" opts={opts} onReady={_onReady} />
      <p>Dan Arieli - Changing Habits</p>
    </div>
  );
};
