import { useState, useEffect } from "react";
import { PopupButton } from "../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import { Select } from "../../../../../components/select/select";
import { SmallTitle } from "../../../../../components/small-title/small-title";
import "./new-content-alert-popup.scss";
import { useTranslation } from "react-i18next";

//redux
import {
  setJobsForDistribution,
  distributeNow,
} from "../../../../../store/media-center/media-center-slice";
import { useDispatch } from "react-redux";

export const NewContentAlertPopup = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");

  const [filteredDaysOptions, setFilteredDaysOptions] = useState([]);
  const [days, setDays] = useState([]);
  const [daysNumbers, setDaysNumbers] = useState([]);
  const [hour, setHour] = useState({ value: "", label: "" });
  const [errorOccurred, setErrorOccurred] = useState(false);

  const daysOptions = [
    { value: 0, label: t("Common:Days.Sunday") },
    { value: 1, label: t("Common:Days.Monday") },
    { value: 2, label: t("Common:Days.Tuesday") },
    { value: 3, label: t("Common:Days.Wednesday") },
    { value: 4, label: t("Common:Days.Thursday") },
    { value: 5, label: t("Common:Days.Friday") },
    { value: 6, label: t("Common:Days.Saturday") },
  ];

  const hourOptions = [
    { value: "0 0 0 * * ", label: "00:00" },
    { value: "0 0 1 * * ", label: "01:00" },
    { value: "0 0 2 * * ", label: "02:00" },
    { value: "0 0 3 * * ", label: "03:00" },
    { value: "0 0 4 * * ", label: "04:00" },
    { value: "0 0 5 * * ", label: "05:00" },
    { value: "0 0 6 * * ", label: "06:00" },
    { value: "0 0 7 * * ", label: "07:00" },
    { value: "0 0 8 * * ", label: "08:00" },
    { value: "0 0 9 * * ", label: "09:00" },
    { value: "0 0 10 * * ", label: "10:00" },
    { value: "0 0 11 * * ", label: "11:00" },
    { value: "0 0 12 * * ", label: "12:00" },
    { value: "0 0 13 * * ", label: "13:00" },
    { value: "0 0 14 * * ", label: "14:00" },
    { value: "0 0 15 * * ", label: "15:00" },
    { value: "0 0 16 * * ", label: "16:00" },
    { value: "0 0 17 * * ", label: "17:00" },
    { value: "0 0 18 * * ", label: "18:00" },
    { value: "0 0 19 * * ", label: "19:00" },
    { value: "0 0 20 * * ", label: "20:00" },
    { value: "0 0 21 * * ", label: "21:00" },
    { value: "0 0 22 * * ", label: "22:00" },
    { value: "0 0 23 * * ", label: "23:00" },
  ];

  const dispatch = useDispatch();

  const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  const getHourFromCronTime = () => {
    const cronSplitted = props.cronJobTime.split("*");
    const cronHour = cronSplitted[0] + "* * ";
    return cronHour;
  };

  const getDaysFromCronTime = () => {
    const cronSplitted = props.cronJobTime.split("*");
    var cronDaysString = cronSplitted[cronSplitted.length - 1];
    cronDaysString = cronDaysString.substring(1, cronDaysString.length);
    const cronDaysArr = cronDaysString.split(",");
    const cronDays = daysOptions.filter((day) =>
      cronDaysArr.includes(day.value.toString())
    );
    return cronDays;
  };

  useEffect(() => {
    setFilteredDaysOptions(daysOptions);
    if (props.cronJobTime && props.cronJobTime !== "") {
      let cronDays = getDaysFromCronTime();
      let options = cronDays.map((option) => option.label.replace(/,/g, " "));
      setDaysNumbers(daysOptions.filter((x) => options.includes(x.label)));
      setDays(options.filter((option) => !isEmptyOrSpaces(option)));
      let difference = daysOptions.filter((x) => !options.includes(x.label));
      setFilteredDaysOptions(difference);
      setHour(
        hourOptions.filter(
          (tempHour) => tempHour.value === getHourFromCronTime()
        )[0]
      );
    }
  }, []);

  return (
    <div className="new-content-alert-container flex-popup-container">
      <Popup
        height="500px"
        width="375px"
        close={() => {
          props.closePopup();
          setDays([]);
          setDaysNumbers([]);
        }}
      >
        <div className="new-content-alert-title">
          <PopupTitle>{t("NewContentAlertPopup.title")}</PopupTitle>
        </div>
        <div className="new-content-alert-info">
          <SmallTitle>{t("NewContentAlertPopup.small-title")}</SmallTitle>
        </div>
        <div className="select-days-container">
          <Select
            name="days"
            value={days}
            isMulti
            onChange={(e) => {
              let options = e.map((option) => option.label.replace(/,/g, " "));
              setDaysNumbers(
                daysOptions.filter((x) => options.includes(x.label))
              );
              setDays(options.filter((option) => !isEmptyOrSpaces(option)));
              let difference = daysOptions.filter(
                (x) => !options.includes(x.label)
              );
              setFilteredDaysOptions(difference);
            }}
            options={filteredDaysOptions}
            placeholder={t("Common:Days.select-days")}
            height="160px"
            style={{
              width: "300px",
            }}
          />
        </div>

        <div className="select-hour-container">
          <Select
            name="hour"
            value={hour.label}
            onChange={(e) => {
              // let options = e.map((option) => option.label.replace(/,/g, " "));
              setHour(e);
            }}
            options={hourOptions}
            height="160px"
            style={{
              width: "300px",
            }}
            placeholder={t("NewContentAlertPopup.select-hour")}
          />
        </div>
        {errorOccurred && (
          <div className="error-container">
            {t("NewContentAlertPopup.error")}
          </div>
        )}
        <div className="create-input save-container">
          <PopupButton
            className={"save-job"}
            onClick={() => {
              if (!days || days.length === 0 || hour.value === "") {
                setErrorOccurred(true);
                return;
              }
              const data = {
                Days: daysNumbers.map((day) => day.value),
                Hour: hour.value,
              };
              dispatch(
                setJobsForDistribution({
                  countryId: props.countryId,
                  data: data,
                })
              );
              setDays([]);
              setDaysNumbers([]);
              setHour({ value: "", label: "" });
              props.closePopup();
            }}
          >
            {t("Common:save")}
          </PopupButton>
          <PopupButton
            className={"send-now"}
            onClick={() => {
              dispatch(distributeNow(props.countryId));
              setDays([]);
              setDaysNumbers([]);
              setHour({ value: "", label: "" });
              props.closePopup();
            }}
          >
            {t("NewContentAlertPopup.distribute-now")}
          </PopupButton>
        </div>
      </Popup>
    </div>
  );
};
