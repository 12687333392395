import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "../../../../../components/button/button";
import { Tip } from "../../../../../components/tip/tip";
import {
  closeNewsletterPopup,
  openNewsletterPopup,
  setTitle,
} from "../../../../../store/app-settings/app-settings-slice";
import {
  changeProfile,
  resetDraftMap,
  updateDraftMap,
  updateUserPoints,
} from "../../../../../store/profile/profile-slice";
import Api from "../../../../../utils/api";
import { CreateStepCard } from "./create-step-card/create-step-card";
import { FinishedSettingThreeStepsPopup } from "../popups/finished-setting-three-steps/finished-setting-three-steps-popup";
import { FinishedCreatingMapPopup } from "../popups/finshed-creating-map/finished-creating-map-popup";
import { NewsletterPopup } from "../popups/newsletter/newsletter-popup";
import Slider from "rc-slider";
import "./create-steps.scss";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

export const CreateSteps = ({
  createMapStage,
  setCreateMapStage,
  ...props
}) => {
  const { t, i18n } = useTranslation("Maps");

  const createStepCardsRef = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profileReducer.profile);
  const { newsletterPopupOpen } = useSelector((state) => ({
    newsletterPopupOpen: state.appSettingsReducer.newsletterPopupOpen,
  }));
  const [selectedStepIndex, setSelectedStepIndex] = useState(0);
  const [finishedDefineStepsNames, setFinishedDefineStepsNames] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newMapId, setNewMapId] = useState("");
  const [finishedCreatingMapPopupOpen, setFinishedCreatingMapPopupOpen] =
    useState(false);
  const [
    finishedSettingThreeStepsPopupOpen,
    setFinishedSettingThreeStepsPopupOpen,
  ] = useState(false);

  const [draftMap, setDraftMap] = useState(null);
  const [userPointsTarget, setUserPointsTarget] = useState({
    target: "",
    stepIndex: 0,
  });

  const [finishedSteps, setFinishedSteps] = useState([]);

  const [step, setStep] = useState(1);
  const [timeToComplete, setTimeToComplete] = useState(0);

  const changeDraftMap = async (data) => {
    dispatch(updateDraftMap(data));
  };

  useEffect(() => {
    const interval = setInterval(
      () => setTimeToComplete((prev) => prev + 1),
      1000
    );
    return () => clearInterval(interval);
  }, []);

  const [debouncedUpdateDaftMap] = useState(() =>
    debounce(changeDraftMap, 1000)
  );

  useEffect(() => {
    dispatch(setTitle(t("CreateMap.Steps.title") + '"' + props.goal + '"'));
  }, [i18n]);

  const navigateNext = () => {
    let isValidate = false;
    if (finishedDefineStepsNames) {
      isValidate = createStepCardsRef.current[selectedStepIndex].validateStep();
    } else
      isValidate =
        createStepCardsRef.current[selectedStepIndex].validateStepName();

    if (!isValidate) return;
    if (selectedStepIndex === 2) {
      if (finishedDefineStepsNames) {
        saveMap();
        dispatch(resetDraftMap());
        dispatch(updateUserPoints({ points: 500, createStepType: "" }));
      } else {
        setFinishedSteps([
          ...finishedSteps,
          userPointsTarget.target + "" + userPointsTarget.stepIndex,
        ]);
        debouncedUpdateDaftMap({
          ...profile?.DraftMap?.Map,
          TimeToComplete: timeToComplete,
        });
        setTimeToComplete(0);
        setFinishedSettingThreeStepsPopupOpen(true);
      }
    } else {
      setFinishedSteps([
        ...finishedSteps,
        userPointsTarget.target + "" + userPointsTarget.stepIndex,
      ]);
      debouncedUpdateDaftMap({
        ...profile?.DraftMap?.Map,
        TimeToComplete: timeToComplete,
      });
      setTimeToComplete(0);
      setSelectedStepIndex((prevState) => prevState + 1);
    }
    if (
      !finishedDefineStepsNames &&
      !finishedSteps.includes(
        userPointsTarget.target + "" + userPointsTarget.stepIndex
      )
    ) {
      dispatch(
        updateUserPoints({
          points: 50,
          createStepType:
            userPointsTarget.target !== "Obstacle" &&
            userPointsTarget.target !== "Name"
              ? "don't add"
              : finishedDefineStepsNames
              ? "obstacle step " + userPointsTarget.stepIndex
              : "name step " + userPointsTarget.stepIndex,
        })
      );
    }
  };

  const navigatePrevious = () => {
    if (selectedStepIndex === 0) {
      if (finishedDefineStepsNames) {
        setFinishedDefineStepsNames(false);
        setSelectedStepIndex(2);
      } else {
        // navigate to createGoal
        setCreateMapStage({
          ...createMapStage,
          createGoal: true,
          createSteps: false,
        });
      }
    } else setSelectedStepIndex((prevState) => prevState - 1);
  };

  const saveMap = async () => {
    setIsLoading(true);
    try {
      // create map
      var currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() + 1440);
      var year = currentDate.getUTCFullYear();
      var month = String(currentDate.getUTCMonth() + 1).padStart(2, "0");
      var day = String(currentDate.getUTCDate()).padStart(2, "0");
      var hours = String(currentDate.getUTCHours()).padStart(2, "0");
      var minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
      var seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");
      var milliseconds = String(currentDate.getUTCMilliseconds()).padStart(
        3,
        "0"
      );
      var dateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

      const data = {
        ...profile.DraftMap.Map,
        _id: profile.DraftMap.Map.Id,
        Image: profile.DraftMap.Map.Image,
        BoosterQuestionDate: dateString,
        CreateDate: new Date(profile.DraftMap.CreateDate),
        TimeToComplete: timeToComplete,
      };
      const res = await Api.post("/user/maps", data);
      if (res.data.payload) {
        setNewMapId(res.data.payload);
        dispatch(resetDraftMap());
        if (!profile.FirstMapCreatedDate) {
          dispatch(
            changeProfile({ ...profile, FirstMapCreatedDate: new Date() })
          );
          dispatch(openNewsletterPopup());
        }
        setFinishedCreatingMapPopupOpen(true);
      } else {
        // console.log("error");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const navigateToViewMap = () => {
    setFinishedCreatingMapPopupOpen(false);
    navigate(`/maps/view/${newMapId}`);
  };

  return (
    <div className="create-steps-container">
      {finishedCreatingMapPopupOpen && !newsletterPopupOpen && (
        <FinishedCreatingMapPopup close={navigateToViewMap} />
      )}
      {finishedSettingThreeStepsPopupOpen && (
        <FinishedSettingThreeStepsPopup
          close={() => {
            setFinishedSettingThreeStepsPopupOpen(false);
            setFinishedDefineStepsNames(true);
            setSelectedStepIndex(0);
          }}
        />
      )}
      {newsletterPopupOpen && (
        <NewsletterPopup close={() => dispatch(closeNewsletterPopup())} />
      )}
      <div className="description">
        <p>{t("CreateMap.Steps.tip")}</p>
      </div>
      <Tabs className="tabs-container" selectedIndex={selectedStepIndex}>
        <TabList>
          <Tab>
            <span>{t("Common:pathway")} #1</span>
          </Tab>
          <Tab>
            <span>{t("Common:pathway")} #2</span>
          </Tab>
          <Tab>
            <span>{t("Common:pathway")} #3</span>
          </Tab>
        </TabList>

        <TabPanel>
          <CreateStepCard
            stepIndex={0}
            finishedDefineStepsNames={finishedDefineStepsNames}
            setUserPointsTarget={setUserPointsTarget}
            TimeToComplete={timeToComplete}
            setTimeToComplete={setTimeToComplete}
            ref={(el) => (createStepCardsRef.current[0] = el)}
          />
        </TabPanel>
        <TabPanel>
          <CreateStepCard
            stepIndex={1}
            finishedDefineStepsNames={finishedDefineStepsNames}
            setUserPointsTarget={setUserPointsTarget}
            TimeToComplete={timeToComplete}
            setTimeToComplete={setTimeToComplete}
            ref={(el) => (createStepCardsRef.current[1] = el)}
          />
        </TabPanel>
        <TabPanel>
          <CreateStepCard
            stepIndex={2}
            finishedDefineStepsNames={finishedDefineStepsNames}
            setUserPointsTarget={setUserPointsTarget}
            TimeToComplete={timeToComplete}
            setTimeToComplete={setTimeToComplete}
            ref={(el) => (createStepCardsRef.current[2] = el)}
          />
        </TabPanel>
      </Tabs>
      <div className="goal-buttons flex">
        <Button className="goal-button" onClick={navigatePrevious}>
          {t("Common:previous")}
        </Button>
        <Button
          className="goal-button"
          onClick={navigateNext}
          isLoading={isLoading}
        >
          {t("Common:save")}
        </Button>
      </div>
    </div>
  );
};
