import React, { useEffect, useRef, useState } from "react";
import { Input } from "../../../../../../../components/input/input";
import { PopupButton } from "../../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../../components/popup/popup-title/popup-title";
import "./edit-custom-category-popup.scss";
import { useTranslation } from "react-i18next";

export const EditCustomCategoryPopup = ({
  saveNewCustomCategory,
  removeCustomCategory,
  category,
  close,
}) => {
  const { t, i18n } = useTranslation("Maps");

  const ref = useRef();
  const [customCategoryName, setCustomCategoryName] = useState("");
  const [error, setError] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  useEffect(() => {
    setCustomCategoryName(category.Name);
    // ref.current.focus();
  }, []);

  const handleChangeInput = (e) => {
    setCustomCategoryName(e.target.value);
  };

  const resetCustomCategoryName = () => {
    setCustomCategoryName("");
  };

  const changeCustomCategoryName = () => {
    if (!customCategoryName.replace(/^\s+/g, "")) {
      ref.current.focus();
      setError(true);
      return;
    }
    saveNewCustomCategory(category.Id, customCategoryName);
    close();
  };

  return (
    <Popup height={deleteMode ? "180px" : "320px"} close={close}>
      <div className="edit-custom-category-popup-container flex-popup-container ">
        {deleteMode ? (
          <>
            <PopupTitle small={true}>
              {t("SelectCatagory.confirm-remove")} "{category.Name}"?
            </PopupTitle>

            <div className="flex delete-buttons">
              <PopupButton onClick={() => setDeleteMode(false)}>
                {t("Common:back")}
              </PopupButton>
              <PopupButton
                continue="true"
                onClick={() => {
                  close();
                  removeCustomCategory(category.Id);
                }}
                className={customCategoryName.length === 0 && "disabled"}
              >
                {t("Common:delete")}
              </PopupButton>
            </div>
          </>
        ) : (
          <>
            <PopupTitle>{t("SelectCatagory.edit-category-title")}</PopupTitle>

            <Input
              ref={ref}
              type="text"
              name="OtherName"
              onChange={handleChangeInput}
              value={customCategoryName}
              maxLength={15}
              reset={resetCustomCategoryName}
              isError={error}
            />

            <div className="flex save-buttons">
              <PopupButton onClick={() => setDeleteMode(true)}>
                {t("Common:delete")}
              </PopupButton>
              <PopupButton
                continue="true"
                onClick={() => {
                  close();
                  changeCustomCategoryName();
                }}
                className={customCategoryName.length === 0 && "disabled"}
              >
                {t("Common:save")}
              </PopupButton>
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};
