import React from "react";
import { Outlet } from "react-router-dom";
import { FooterMenu } from "../../components/footer-menu/footer-menu";
import Menu from "../../components/menu/menu";
import "./map-layout.scss";

export const MapLayout = () => {
  return (
    <div className="map-layout-conatiner">
      <Menu />
      <FooterMenu />
      <div>
        <Outlet />
      </div>
    </div>
  );
};
