import React from "react";
import { useDispatch } from "react-redux";
import { changeStepDetailsPopup } from "../../../../../../store/map/map-slice";
import "./step-details.scss";

export const StepDetails = ({
  index,
  step,
  handleClickedObstacle,
  shareMode,
  ...props
}) => {
  const dispatch = useDispatch();

  const handleClickedStep = (index) => {
    dispatch(
      changeStepDetailsPopup({
        open: true,
        index,
      })
    );
  };

  return (
    <div
      className={
        "step-details-container" +
        (props.className ? ` ${props.className}` : "")
      }
    >
      <div
        className={
          "step-pin-position" +
          (index === 0 ? " one" : "") +
          (index === 1 ? " two" : "") +
          (index === 2 ? " three" : "")
        }
        onClick={() => handleClickedStep(index)}
      ></div>
      <div
        className={
          "step-obstacle-position" +
          (index === 0 ? " one" : "") +
          (index === 1 ? " two" : "") +
          (index === 2 ? " three" : "")
        }
        onClick={() => handleClickedObstacle(index)}
      ></div>
      <div
        className={
          "step-details flex" +
          (index === 0 ? " one" : "") +
          (index === 1 ? " two" : "") +
          (index === 2 ? " three" : "")
        }
        onClick={() => handleClickedStep(index)}
      >
        <p className="text">{step.Name}</p>
      </div>
    </div>
  );
};
