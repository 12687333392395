import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import "./i18n";

import { BrowserRouter } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import { injectStore } from "./utils/api";
import { GoogleOAuthProvider } from "@react-oauth/google";

// const CLIENT_ID =
// "101505278931-1en7aij2rgdge0v4j8q1v7eoa3ep5n7p.apps.googleusercontent.com";
const CLIENT_ID =
  "101505278931-6ds1qli3mldqce7mplunao0a0ganpbcj.apps.googleusercontent.com";
const scopes = ["https://www.googleapis.com/auth/calendar.events"];

injectStore(store);

const element = document.getElementById("root");
if (element) {
  const root = ReactDOM.createRoot(element);
  root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
      >
        <GoogleOAuthProvider
          clientId={CLIENT_ID}
          params={{
            scope: scopes.join(" "),
          }}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </GoogleReCaptchaProvider>
    </Provider>
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
