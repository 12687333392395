import React from "react";
import "./input-switch.scss";
export const InputSwitch = ({ checked, setCheked, title, className, name, id, ...props }) => {
  return (
    <div className={`flex switch-wrapper ${className}`} {...props}>
      {title && <label htmlFor="switch" className="switch-label">{title}</label>}
      <label className="switch">
        <input type="checkbox"
          checked={checked}
          name={name}
          id={id}
          onChange={setCheked}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};
InputSwitch.defaultProps = {
  name: "switch",
  id: "switch",
  className: ""
}
