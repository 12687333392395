import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import { changeProfile } from "../../../../store/profile/profile-slice";
import Api from "../../../../utils/api";
import "./booster-questions-popup.scss";
import { CreateInspirationStep } from "./steps/create-inspiration-step/create-inspiration-step";
import { CreateNeedStep } from "./steps/create-need-step/create-need-step";
import { EditInspirationStep } from "./steps/edit-inspiration-step/edit-inspiration-step";
import { EditNeedStep } from "./steps/edit-need-step/edit-need-step";
import { ShareBooster } from "./steps/share-step/share-booster-popup/share-booster-popup";
import { ShareStep } from "./steps/share-step/share-step";
import { VideoStep } from "./steps/video-step/video-step";
import { useTranslation } from "react-i18next";

export const BoosterQuestionsPopup = ({ editMode, close }) => {
  const { t, i18n } = useTranslation("PopUps");

  const START_STEP_NUMBER = 2;
  const { navigator } = useContext(NavigationContext);
  const needRef = useRef();
  const inspirationRef = useRef();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const [stepNumber, setStepNumber] = useState(START_STEP_NUMBER);
  const [openedSteps, setOpenedSteps] = useState({
    video: true,
    createNeed: false,
    editNeed: false,
    createInspiration: false,
    editInspiration: false,
    share: false,
  });
  const [needError, setNeedError] = useState(false);
  const [inspirationError, setInspirationError] = useState(false);
  const [canEditNeed, setCanEditNeed] = useState(false);
  const [canEditInspiration, setCanEditInspiration] = useState(false);
  const [boosters, setBooster] = useState({
    Need: "",
    Inspiration: "",
  });
  const [shareBoosterPopupOpen, setShareBoosterPopupOpen] = useState(false);

  // useEffect(() => {
  //   const listener = ({ location, action }) => {
  //     if (action === NavigationType.Pop) {
  //       window.history.forward();
  //       close();
  //     }
  //   };
  //   const unlisten = navigator.listen(listener);
  //   return unlisten;
  // }, [navigator]);

  useEffect(() => {
    // console.log({ editMode });
    if (editMode) {
      switch (stepNumber) {
        case 2:
          openStep("editNeed");
          break;
        case 3:
          openStep("editInspiration");
          break;
        case 4:
          saveBoosters();
          openStep("share");
          break;
        case 5:
          close();
          break;
        default:
          openStep("editNeed");
          break;
      }
    } else {
      switch (stepNumber) {
        case 1:
          openStep("video");
          break;
        case 2:
          openStep("createNeed");
          break;
        case 3:
          openStep("createInspiration");
          break;
        case 4:
          saveBoosters();
          openStep("share");
          break;
        case 5:
          close();
          break;
        default:
          openStep("video");
          break;
      }
    }
  }, [stepNumber]);

  const openStep = (stepName) => {
    let newOpenedSteps = { ...openedSteps };
    for (var key of Object.keys(openedSteps)) {
      newOpenedSteps[key] = false;
    }
    if (editMode) {
      if (stepName === "editInspiration") {
        // if (canEditNeed) newOpenedSteps.editNeed = true;
      } else if (stepName === "share") {
        // if (canEditNeed) newOpenedSteps.editNeed = true;
        // if (canEditInspiration) newOpenedSteps.editInspiration = true;
      }
    } else {
      if (stepName === "createInspiration") {
        // newOpenedSteps.createNeed = true;
      } else if (stepName === "share") {
        // newOpenedSteps.createNeed = true;
        // newOpenedSteps.createInspiration = true;
      }
    }
    newOpenedSteps[stepName] = true;
    setOpenedSteps(newOpenedSteps);
  };

  const saveBoosters = async () => {
    if (
      !editMode ||
      profile.Boosters.Answers[profile.Boosters.Answers.length - 1].Need !==
        boosters.Need ||
      profile.Boosters.Answers[profile.Boosters.Answers.length - 1]
        .Inspiration !== boosters.Inspiration
    ) {
      try {
        const res = await Api.put("user/profile/boosters", boosters);
        dispatch(
          changeProfile({
            ...profile,
            Boosters: {
              ...profile.Boosters,
              Answer: {
                Need: boosters.Need,
                Inspiration: boosters.Inspiration,
              },
            },
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const navigateToNextStep = () => {
    switch (stepNumber) {
      case START_STEP_NUMBER:
        if (!boosters.Need.replace(/^\s+/g, "")) {
          focusEmptySelect("Need");
          return;
        }
        break;
      case START_STEP_NUMBER + 1:
        if (!boosters.Need.replace(/^\s+/g, "")) {
          if (!boosters.Inspiration.replace(/^\s+/g, "")) {
            focusEmptySelect("Inspiration");
          }
          focusEmptySelect("Need");
          return;
        }
        if (!boosters.Inspiration.replace(/^\s+/g, "")) {
          focusEmptySelect("Inspiration");
          return;
        }

        break;
      default:
        break;
    }
    setStepNumber((prevStep) => prevStep + 1);
  };

  const navigateBack = () => {
    if (editMode) {
      if (stepNumber === 1 && canEditNeed) {
        setCanEditNeed(false);
      } else if (stepNumber === 2 && canEditInspiration) {
        setCanEditInspiration(false);
      } else {
        setStepNumber((prevStep) => prevStep - 1);
      }
    } else {
      setStepNumber((prevStep) => prevStep - 1);
    }
  };

  const focusEmptySelect = (name) => {
    // const index = Object.keys(basicInfo).indexOf(name);
    // basicInfoRefs.current[index].focus();
    if (name === "Need") {
      setNeedError(true);
      needRef.current.focus();
    } else {
      setInspirationError(true);
      inspirationRef.current.focus();
    }
  };

  return (
    <Popup
      height={stepNumber === 3 || stepNumber === 4 ? "590px" : "520px"}
      close={close}
    >
      <div className="booster-questions-popup-container flex">
        <div className="header-container flex">
          <PopupTitle small={true}>{t("Boosters.jumpsatrt")}</PopupTitle>
          <PopupTitle>{t("Boosters.on-your-journey")}</PopupTitle>
        </div>
        {!editMode && (
          <>
            {openedSteps.video && <VideoStep />}
            {openedSteps.createNeed && (
              <CreateNeedStep
                ref={needRef}
                showError={needError}
                need={boosters.Need}
                setBooster={setBooster}
                navigateToNextStep={navigateToNextStep}
              />
            )}
            {openedSteps.createInspiration && (
              <CreateInspirationStep
                ref={inspirationRef}
                showError={inspirationError}
                inspiration={boosters.Inspiration}
                setBooster={setBooster}
                navigateBack={navigateBack}
                navigateToNextStep={navigateToNextStep}
              />
            )}
            {openedSteps.share && (
              <ShareStep
                setShareBoosterPopupOpen={setShareBoosterPopupOpen}
                navigateToNextStep={navigateToNextStep}
              />
            )}
          </>
        )}

        {editMode && (
          <>
            {(openedSteps.editNeed || openedSteps.editInspiration) && (
              <EditNeedStep
                ref={needRef}
                need={boosters.Need}
                openedSteps={openedSteps}
                setBooster={setBooster}
                navigateToNextStep={navigateToNextStep}
                showError={needError}
              />
            )}
            {openedSteps.editInspiration && (
              <EditInspirationStep
                ref={inspirationRef}
                inspiration={boosters.Inspiration}
                setBooster={setBooster}
                showError={inspirationError}
                close={close}
                navigateToNextStep={navigateToNextStep}
              />
            )}
            {openedSteps.share && (
              <ShareStep
                setShareBoosterPopupOpen={setShareBoosterPopupOpen}
                navigateToNextStep={navigateToNextStep}
              />
            )}
          </>
        )}
        {/* <StepsDots
          totalSteps={editMode ? 3 : 4}
          stepNumber={stepNumber}
          back={stepNumber !== 1 ? navigateBack : null}
          next={
            editMode
              ? (stepNumber === 1 && canEditNeed) ||
                (stepNumber === 2 && canEditInspiration)
                ? navigateToNextStep
                : null
              : navigateToNextStep
          }
        /> */}
        {/* <div className="footer-buttons flex">
          {stepNumber === 3 > 2 && (
            <PopupButton onClick={navigateBack}>Back</PopupButton>
          )}
          {(stepNumber === 2 || stepNumber === 3) && (
            <PopupButton
              onClick={
                editMode
                  ? (stepNumber === 1 && canEditNeed) ||
                    (stepNumber === 2 && canEditInspiration)
                    ? navigateToNextStep
                    : null
                  : navigateToNextStep
              }
            >
              OK
            </PopupButton>
          )}
        </div> */}
      </div>
      {shareBoosterPopupOpen && (
        <ShareBooster
          index={profile.Boosters.Answers.length}
          boosters={boosters}
          close={() => setShareBoosterPopupOpen(false)}
        />
      )}
    </Popup>
  );
};
