import { configureStore } from "@reduxjs/toolkit";
import appSettingsReducer from "./app-settings/app-settings-slice";
import authReducer from "./auth/auth-slice";
import profileReducer from "./profile/profile-slice";
import mapReducer from "./map/map-slice";
import mediaCenterReducer from "./media-center/media-center-slice";

export const store = configureStore({
  reducer: {
    appSettingsReducer,
    authReducer,
    profileReducer,
    mapReducer,
    mediaCenterReducer,
  },
});
