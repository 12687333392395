import React from "react";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import "./view-main-goal-popup.scss";
import { useTranslation } from "react-i18next";

export const ViewMainGoalPopup = ({ goal, close }) => {
  const { t, i18n } = useTranslation("Maps");

  return (
    <Popup close={close} height="200px">
      <div className="view-main-goal-popup-container flex">
        <PopupTitle>{t("ViewMap.ViewMainGoalPopUp.goal")}</PopupTitle>
        <p className="goal">{goal}</p>
        <PopupButton onClick={close}>{t("Common:OK")}!</PopupButton>
      </div>
    </Popup>
  );
};
