import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowLoader,
  setTitle,
} from "../../../store/app-settings/app-settings-slice";
import Api from "../../../utils/api";
import "./faq.scss";
import { useTranslation } from "react-i18next";

export const FAQs = () => {
  const { t, i18n } = useTranslation("FAQ");

  const dispatch = useDispatch();
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const [FAQs, setFAQs] = useState([]);

  useEffect(() => {
    dispatch(setTitle(t("title")));
    getFAQs();
  }, [i18n]);

  const getFAQs = async () => {
    dispatch(setShowLoader(true));
    try {
      const res = await Api.get("user/FAQs");
      if (res.data) {
        setFAQs(res.data.payload);
      }
      dispatch(setShowLoader(false));
    } catch {
      dispatch(setShowLoader(false));
    }
  };

  return (
    <div className="faq-container">
      {!showLoader && (
        <>
          {FAQs.length > 0 ? (
            FAQs.map((FAQ) => {
              return (
                <div key={FAQ._id}>
                  <details>
                    <summary>{FAQ.Question}</summary>
                    <div className="break-words">{FAQ.Answer}</div>
                  </details>
                </div>
              );
            })
          ) : (
            <p className="description flex">{t("none")}</p>
          )}
        </>
      )}
    </div>
  );
};
