import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input/mobile";
import "react-phone-number-input/style.css";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { SelectLanguage } from "../../../components/select-language/select-language";
import {
  setDirection,
  setShowLoader,
} from "../../../store/app-settings/app-settings-slice";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/button/button";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Api from "../../../utils/api";

export default function Signup({ phone, setPhone, signupFinish, goBack }) {
  const { t, i18n } = useTranslation("Login");

  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);
  useEffect(() => {}, []);

  const handleChangeLanguage = (name, langCode) => {
    if (langCode.toLowerCase() === "he") {
      dispatch(setDirection("rtl"));
    } else {
      dispatch(setDirection("ltr"));
    }
    i18n.changeLanguage(langCode);
  };

  const validate = () => {
    let tempErrors = [];
    if (!firstName) {
      tempErrors = [...tempErrors, "firstNameErr"];
    }
    if (!lastName) {
      tempErrors = [...tempErrors, "lastNameErr"];
    }
    if (!email) {
      tempErrors = [...tempErrors, "emailErr"];
    }
    if (!phone || !isValidPhoneNumber(phone)) {
      tempErrors = [...tempErrors, "phoneErr"];
    }
    setErrors(tempErrors);
    if (!tempErrors || tempErrors.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const doSignup = async (firstName, lastName, email) => {
    if (!validate()) {
      return;
    }
    dispatch(setShowLoader(true));
    try {
      const data = {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phone,
      };
      const token = await executeRecaptcha("OtpSignup");
      const res = await Api.post("auth/otp/signup", data, {
        headers: {
          recaptcha: token,
        },
      });
      dispatch(setShowLoader(false));
      signupFinish(res);
    } catch (error) {
      console.log(error);
      dispatch(setShowLoader(false));
    }
  };

  return (
    <div className="login-container signup">
      <div className="select-lang-container">
        <span className="select-lang-info">
          {t("ProfileSettings:select-language")}
        </span>
        <span className="lang-select">
          <SelectLanguage
            handleChangeLanguage={handleChangeLanguage}
            onLogin={true}
          />
        </span>
      </div>
      <div className="top-icon"></div>
      <h4 className="sign-in-title">{t("registration")}</h4>
      <div className="content flex">
        <div className="info-container">
          <h4>
            {t("welcome-to")}{" "}
            <span className="purple-hopetimize">Hopetimize</span> ®,
          </h4>
          <h4>
            {t("sign-up-messy")}.{" "}
            <span className="purple-hopetimize">Hopetimize</span> ®,{" "}
            {t("sign-up-guide")} {t("sign-up-meaningful-life-goals")} -{" "}
            {t("sign-up-and-map")}
          </h4>
          <h4>{t("sign-up-over-thirty-years")}.</h4>
          <h4>
            {t("sign-up-logging")}{" "}
            <span className="purple-hopetimize">Hopetimize</span>
            <span className="purple-hopetimize-unbold">rs</span>{" "}
            {t("sign-up-around-the-globe")}
          </h4>
        </div>
        <div className="input-line">
          <div className="input-container">
            <Input
              type="text"
              value={firstName}
              width="155px"
              maxHeight={50}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              placeholder={t("first-name")}
            />
          </div>

          <div className="input-container">
            <Input
              type="text"
              value={lastName}
              width="155px"
              maxHeight={50}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              placeholder={t("last-name")}
            />
          </div>
        </div>
        <div className="input-line">
          {errors.includes("firstNameErr") && (
            <div className="error">Please enter first name</div>
          )}
          {errors.includes("lastNameErr") && (
            <div className="error">Please enter last name</div>
          )}
        </div>
        <div className="input-line">
          <PhoneInput
            placeholder={t("enter-phone")}
            value={phone}
            defaultCountry="IL"
            international={false}
            initialValueFormat="national"
            //labels={countryNames}
            onChange={setPhone}
            className="flex"
            addInternationalOption={true}
            // countrySelectComponent
            // containerComponent={<div style={{ background: "blue" }}></div>}
          />
        </div>
        {errors.includes("phoneErr") && (
          <div className="error">Please enter valid phone</div>
        )}
        <div className="input-line">
          <div className="input-container">
            <Input
              type="text"
              value={email}
              width="320px"
              maxHeight={50}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={t("email")}
            />
          </div>
        </div>
        {errors.includes("emailErr") && (
          <div className="error">Please enter email</div>
        )}
      </div>
      <div className="flex">
        <Button
          className={
            !phone || !isValidPhoneNumber(phone) || !email
              ? " sign-up disabled"
              : "sign-up"
          }
          onClick={() => doSignup(firstName, lastName, email)}
        >
          {t("sign-up")}
        </Button>
      </div>
      <br />
      <div className="flex">
        <div className="not-register">
          <h4 style={{ fontWeight: "10" }}>{t("registered")}</h4>{" "}
          <h4 className="move-to-signup" onClick={goBack}>
            {t("sign-in")}
          </h4>
        </div>
      </div>
    </div>
  );
}
