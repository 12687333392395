import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ABitStuckIcon } from "../../../../../assets/icons/a-bit-stuck.svg";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import Api from "../../../../../utils/api";
import { PopupButton } from "../../../../../components/popup-button/popup-button";
import { setBoosterQuestionsPopup } from "../../../../../store/app-settings/app-settings-slice";
import "./a-bit-stuck.scss";
import { useTranslation } from "react-i18next";

export const ABitStuck = ({ close }) => {
  const { t, i18n } = useTranslation("PopUps");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openBoosterQuestionsPopup = () => {
    dispatch(setBoosterQuestionsPopup({ open: true, editMode: false }));
    close();
  };

  const navigateToLastCreatedMap = async () => {
    try {
      const res = await Api.get("user/maps/last/mapId");
      if (res?.data?.payload) {
        const mapId = res.data.payload;
        navigate(`../maps/edit/${mapId}/goal`);
        close();
      } else {
        navigate("../maps");
        close();
      }
    } catch (error) {
      navigate("../maps");
      close();
    }
  };

  return (
    <div className="a-bit-stuck-container flex">
      <div className="headers flex">
        <PopupTitle small={true}>{t("Stuck.use")}</PopupTitle>
        <PopupTitle>{t("Stuck.help")}</PopupTitle>
      </div>
      <p className="thats-ok">{t("Stuck.thats-ok")}</p>
      <ABitStuckIcon />
      <div className="footer-button-container flex">
        <PopupButton
          className="footer-button"
          onClick={openBoosterQuestionsPopup}
        >
          {t("Stuck.use-help")}
        </PopupButton>
        <PopupButton
          className="footer-button"
          onClick={navigateToLastCreatedMap}
        >
          {t("Stuck.rethink-pathway")}
        </PopupButton>
      </div>
      {/* <Button onClick={openBoosterQuestionsPopup}>
        I Need some guidance to move forward
      </Button>
      <Button onClick={navigateToLastCreatedMap}>
        I think I need to update the goals and check out the steps
      </Button> */}
    </div>
  );
};
