import { useRef, useState } from "react";
import { DateInput } from "../../../../../../components/date-input/date-input";
import { Input } from "../../../../../../components/input/input";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import "./define-step-popup.scss";
import { useTranslation } from "react-i18next";

export const DefineStepPopup = ({
  defineStepPopup,
  setDefineStepPopup,
  saveStep,
  close,
}) => {
  const { t, i18n } = useTranslation("Maps");

  const defineStepRefs = useRef([]);
  const [showError, setShowError] = useState(false);

  const changeDefineStepPopup = (name, value) => {
    setDefineStepPopup({
      ...defineStepPopup,
      info: {
        ...defineStepPopup.info,
        [name]: value,
      },
    });
  };

  const handleChange = (e) => {
    changeDefineStepPopup(e.target.name, e.target.value);
  };

  const handleChangeDate = (name, value) => {
    changeDefineStepPopup("StartDate", value);
  };

  const resetChange = (name) => {
    changeDefineStepPopup(name, "");
  };

  const focusEmptyInput = (name) => {
    const index = Object.keys(defineStepPopup.info).indexOf(name);
    defineStepRefs.current[index].select();
    defineStepRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const validate = () => {
    let isValidate = true;
    if (!defineStepPopup.info.Name.replace(/^\s+/g, "")) {
      focusEmptyInput("Name");
      isValidate = false;
    }
    // if (!defineStepPopup.info.StartDate || !defineStepPopup.info.StartDate.replace(/^\s+/g, "")) {
    //   // focusEmptyInput("date-input");
    //   isValidate = false;
    // }

    if (!isValidate) setShowError(true);
    return isValidate;
  };

  return (
    <Popup height="400px" close={close}>
      <div className="define-step-popup-container flex">
        <div className="popup-titles-wrapper">
          <PopupTitle>Pathway #{defineStepPopup.index + 1}</PopupTitle>
        </div>
        <div className="description1">
          <p>{t("EditMap.DefineStepPopUp.info")}</p>
        </div>

        <div className="description2">
          <div>
            {t("EditMap.DefineStepPopUp.describe")}{" "}
            {defineStepPopup.index === 0
              ? t("Common:first")
              : defineStepPopup.index === 1
              ? t("Common:second")
              : t("Common:third")}
            {t("EditMap.DefineStepPopUp.pathway")}
          </div>
          <div>{t("EditMap.DefineStepPopUp.need")}</div>
          <div>'{t("Common:next")}'.</div>
        </div>

        <Input
          type="text"
          name="Name"
          value={defineStepPopup.info.Name}
          onChange={handleChange}
          reset={resetChange}
          isError={showError}
          placeholder={t("EditMap.DefineStepPopUp.I-will")}
          ref={(el) =>
            (defineStepRefs.current[
              Object.keys(defineStepPopup.info).indexOf("Name")
            ] = el)
          }
        />

        <div className="date">
          <DateInput
            dateValue={defineStepPopup.info.StartDate}
            updateDateValue={handleChangeDate}
            title={t("EditMap.DefineStepPopUp.set-date")}
            style={{ marginBottom: "15px" }}
            name={"date-input"}
            value={defineStepPopup.info.StartDate}
          />
        </div>

        <div className="popup-bottom-bottons-container flex">
          <PopupButton
            continue="true"
            onClick={() => {
              validate() && saveStep();
            }}
            style={{ width: "230px" }}
          >
            {t("Common:next")}
          </PopupButton>
        </div>
      </div>
    </Popup>
  );
};
