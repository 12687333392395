import { faShareNodes, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { ReactComponent as BoosterIcon } from "../../../../assets/icons/booster_2.svg";
import BoosterIcon from "../../../../assets/images/save-as-booster.png";
import MediaCenterIcon from "../../../../assets/images/play-icon.png";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../../components/button/button";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import {
  setBoosterQuestionsPopup,
  setViewBoosterQuestionsPopupOpen,
} from "../../../../store/app-settings/app-settings-slice";
import Api from "../../../../utils/api";
import { ShareBooster } from "../booster-questions/steps/share-step/share-booster-popup/share-booster-popup";
import { RemoveBoosterPopup } from "./remove-booster-popup/remove-booster-popup";
import "./view-boosters-popup.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { setLinkFromBoosters } from "../../../../store/media-center/media-center-slice";
import { useSelector } from "react-redux";

export const ViewBoosters = ({ close }) => {
  const { t, i18n } = useTranslation("PopUps");

  const profile = useSelector((state) => state.profileReducer.profile);

  const dispatch = useDispatch();
  const [boosters, setBoosters] = useState([]);
  const [shareBoosterPopup, setShareBoosterPopup] = useState({
    open: false,
    index: 0,
    boosters: {
      Need: "",
      Inspiration: "",
    },
  });
  const [removeBoosterPopup, setRemoveBoosterPopup] = useState({
    open: false,
    index: 0,
    need: "",
    inspiration: "",
  });

  useEffect(() => {
    getBoosters();
    sendViewBoosters();
  }, []);

  const sendViewBoosters = async () => {
    try {
      const res = await Api.put("user/profile/boosters/view");
    } catch (error) {}
  };

  const getBoosters = async () => {
    try {
      const res = await Api.get("user/profile/boosters");
      if (res.data) setBoosters(res.data.payload);
    } catch (error) {
      console.log(error);
    }
  };

  const openRemoveBoosterPopup = (index, boosterToRemove) => {
    console.log("new index", boosters.length - 1 - index);
    setRemoveBoosterPopup({
      open: true,
      index: boosters.length - 1 - index,
      need: boosterToRemove.Need,
      inspiration: boosterToRemove.Inspiration,
    });
  };

  const removeBoosters = async () => {
    try {
      const res = await Api.delete(
        `user/profile/boosters/${removeBoosterPopup.index}`
      );
      if (res.data) getBoosters();
    } catch (error) {
      console.log(error);
    }
  };

  const openCreateNewBoostersPopup = () => {
    dispatch(setBoosterQuestionsPopup({ open: true, editMode: false }));
    close();
  };

  return (
    <Popup
      height={boosters.length > 4 ? "520px" : `${350 + boosters.length * 70}px`}
      close={close}
    >
      {removeBoosterPopup.open && (
        <RemoveBoosterPopup
          index={removeBoosterPopup.index}
          need={removeBoosterPopup.need}
          inspiration={removeBoosterPopup.inspiration}
          removeBoosters={removeBoosters}
          close={() => {
            setRemoveBoosterPopup({
              open: false,
              index: 0,
              need: "",
              inspiration: "",
            });
          }}
        />
      )}
      <div className="view-boosters-popup-conatiner flex">
        <div className="item-button-container">
          <img src={BoosterIcon} />
          {/* <BoosterIcon className="item-icon fa-item-icon" /> */}
        </div>
        <div className="headers flex">
          <PopupTitle small={true}>{t("Boosters.hope")}</PopupTitle>
          <PopupTitle>{t("Boosters.boosters")}</PopupTitle>
        </div>
        <br />
        <Button onClick={openCreateNewBoostersPopup} className="add-button">
          +
        </Button>
        {
          <div className="booster-card-container flex-popup-container">
            {boosters.reverse().map((boostersAnswer, index) => {
              return (
                <div key={index} className="booster-card">
                  <div className="flex">
                    <div className="remove-booster-content">
                      {/* <span>{t("Boosters.Motivation")}</span> */}
                      <span>
                        <b>{boostersAnswer.Inspiration}</b>
                      </span>
                    </div>
                    <FontAwesomeIcon
                      icon={faShareNodes}
                      onClick={() =>
                        setShareBoosterPopup({
                          open: true,
                          index: boosters.length - 1 - index,
                          boosters: boostersAnswer,
                        })
                      }
                    />
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className="trash-icon"
                      onClick={() =>
                        openRemoveBoosterPopup(index, boostersAnswer)
                      }
                    />
                  </div>
                </div>
              );
            })}

            {
              /*(profile?.Phone === "+972547997155" ||
              profile?.Phone === "+972547898398" ||
              profile?.Phone === "+972525813751" ||
              profile?.Phone === "+972537536392" ||
              profile?.Phone === "+972525291333" ||
              profile?.Phone === "+972545603120" ||
              profile?.Phone === "+972587897623" ||
              profile?.Phone === "+972528506862") */ true && (
                <div className="media-center-icon-container">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="../user-media-center"
                    onClick={() => {
                      dispatch(setViewBoosterQuestionsPopupOpen(false));
                      dispatch(setLinkFromBoosters(true));
                    }}
                  >
                    <img src={MediaCenterIcon} className="media-center-icon" />{" "}
                    <h4 className="media-center-link-text">
                      {t("Boosters.visit-media-center")}
                    </h4>
                  </Link>
                </div>
              )
            }
            {/* <div className="bag-boosters-icon"></div> */}
            <Button className="continue-button" onClick={close}>
              {t("Common:continue-journey")}
            </Button>
          </div>
        }
      </div>
      {shareBoosterPopup.open && (
        <ShareBooster
          index={shareBoosterPopup.index}
          boosters={shareBoosterPopup.boosters}
          close={() =>
            setShareBoosterPopup({
              open: false,
              index: 0,
              boosters: {
                Need: "",
                Inspiration: "",
              },
            })
          }
        />
      )}
    </Popup>
  );
};
