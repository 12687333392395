import React from "react";
import { PopupButton } from "../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import "./remove-booster-popup.scss";

export const RemoveBoosterPopup = ({
  need,
  inspiration,
  removeBoosters,
  close,
}) => {
  const removeBoostersAndClose = () => {
    removeBoosters();
    close();
  };

  return (
    <Popup close={close} height="400px">
      <div className="remove-booster-popup-container flex-popup-container">
        <div>
          <PopupTitle>Remove Boosters</PopupTitle>
          <p className="question">
            Are you sure you want to remove your boosters:
          </p>
          <div className="remove-booster-content">
            <table>
              <tbody>
                <tr>
                  <td>
                    <h4>Need: </h4>
                    <span>{need}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Inspiration: </h4>
                    <span>{inspiration}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex">
          <PopupButton onClick={close}>Cancel</PopupButton>
          <PopupButton onClick={removeBoostersAndClose}>Remove</PopupButton>
        </div>
      </div>
    </Popup>
  );
};
