import { useLottie } from "lottie-react";
import { useSelector } from "react-redux";
import NewslettereLottieFile from "../../../../../../assets/lottie-files/newsletter.json";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import Api from "../../../../../../utils/api";
import "./newsletter-popup.scss";
import { useTranslation } from "react-i18next";

export const NewsletterPopup = ({ close }) => {
  const { t, i18n } = useTranslation("Maps");

  const profile = useSelector((state) => state.profileReducer.profile);

  const NewsletterLottieOptions = {
    animationData: NewslettereLottieFile,
    loop: true,
  };

  const { View: NewsletterLottie } = useLottie(NewsletterLottieOptions, {
    height: "150px",
  });

  const registerToNewsletter = async () => {
    try {
      const data = {
        Notifications: {
          Allow: true,
          Email: profile.Settings.Notifications.Email,
          Newsletter: true,
        },
        LanguageCode: profile.Settings.LanguageCode,
      };
      const res = await Api.put("user/profile/settings", data);
      close();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Popup close={close}>
      <div className="newsletter-popup-container">
        <PopupTitle>
          {t("CreateMap.NewsletterPopUp.guiding-resources")}
        </PopupTitle>
        {NewsletterLottie}
        <p>{t("CreateMap.NewsletterPopUp.info")}</p>
        <div className="footer-buttons-container flex">
          <PopupButton
            className="newsletter-button"
            onClick={registerToNewsletter}
          >
            {t("CreateMap.NewsletterPopUp.yes")}
          </PopupButton>
          <PopupButton className="newsletter-button" onClick={close}>
            {t("CreateMap.NewsletterPopUp.no")}
          </PopupButton>
        </div>
      </div>
    </Popup>
  );
};
