import React from "react";
import { useSelector } from "react-redux";
import "./steps-progress.scss";

export const StepsProgress = ({
  currentStep,
  shareMode,
  categoryName,
  ...props
}) => {
  const { footerMenuOpen, mapLottieViews } = useSelector((state) => ({
    footerMenuOpen: state.mapReducer.footerMenuOpen,
    mapLottieViews: state.mapReducer.mapLottieViews,
  }));

  return (
    <div
      className={
        "steps-progress-container flex" +
        (props.className ? ` ${props.className}` : "")
      }
      style={{ bottom: footerMenuOpen && !shareMode ? "90px" : "25px" }}
    >
      <div className="progress-circle flex">
        {mapLottieViews.loaded && (
          <div
            className="text"
            style={{
              color: categoryName === "Self & Wellbeing" ? "white" : "",
              fontWeight: categoryName === "Self & Wellbeing" ? "normal" : "",
            }}
          >
            {currentStep}/3
          </div>
        )}
      </div>
      <div className="progress-ellipse-container">
        <div
          className={
            (currentStep >= 1 ? " progress-first-step" : "") +
            (currentStep >= 2 ? " progress-second-step" : "") +
            (currentStep === 3 ? " progress-third-step" : "")
          }
        ></div>
      </div>
    </div>
  );
};
