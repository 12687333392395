import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Select } from "../../../../components/select/select";
import { SmallTitle } from "../../../../components/small-title/small-title";
import { StepsDots } from "../../../../components/steps-dots/steps-dots";
import {
  setTitle,
  setShowLoader,
} from "../../../../store/app-settings/app-settings-slice";
import {
  changeProfile,
  getProfile,
} from "../../../../store/profile/profile-slice";
import Api from "../../../../utils/api";
import "./leisure-and-community-life.scss";
import { useTranslation } from "react-i18next";

export const LeisureAndCommunityLife = () => {
  const { t, i18n } = useTranslation("OnBoarding");
  const [communitiesOptions, setCommunitiesOptions] = useState([]);
  const [filteredCommunitiesOptions, setFilteredCommunitiesOptions] = useState(
    []
  );

  const [hobbiesOptions, setHobbiesOptions] = useState([]);
  const [
    regularPhysicalActivitiesOptions,
    setRegularPhysicalActivitiesOptions,
  ] = useState([]);

  useEffect(() => {
    setCommunitiesOptions([
      { value: "Professional", label: t("Community.Options.Professional") },
      { value: "Social", label: t("Community.Options.Social") },
      { value: "Literature", label: t("Community.Options.Literature") },
      { value: "Arts", label: t("Community.Options.Arts") },
      { value: "Gardening", label: t("Community.Options.Gardening") },
      { value: "Academic", label: t("Community.Options.Academic") },
      { value: "Sports", label: t("Community.Options.Sports") },
      { value: "Games", label: t("Community.Options.Games") },
      { value: "Travel", label: t("Community.Options.Travel") },
      { value: "Volunteering", label: t("Community.Options.Volunteering") },
      { value: "Religious", label: t("Community.Options.Religious") },
      {
        value: "Prefer not to say",
        label: t("Community.Options.Prefer not to say"),
      },
    ]);
    setHobbiesOptions([
      { value: "Sewing", label: t("Community.Options.Sewing") },
      { value: "Gardening", label: t("Community.Options.Gardening") },
      { value: "Cooking/baking", label: t("Community.Options.Cooking/baking") },
      { value: "Writing", label: t("Community.Options.Writing") },
      { value: "Travel", label: t("Community.Options.Travel") },
      { value: "Creative arts", label: t("Community.Options.Creative arts") },
      { value: "Animals", label: t("Community.Options.Animals") },
      { value: "Sports", label: t("Community.Options.Sports") },
      {
        value: "Meditation/yoga",
        label: t("Community.Options.Meditation/yoga"),
      },
      { value: "Nature", label: t("Community.Options.Nature") },
      { value: "Theater/movies", label: t("Community.Options.Theater/movies") },
      { value: "Crafting", label: t("Community.Options.Crafting") },
      { value: "Volunteering", label: t("Community.Options.Volunteering") },
      { value: "Music", label: t("Community.Options.Music") },
      { value: "Water sports", label: t("Community.Options.Water sports") },
      { value: "Science", label: t("Community.Options.Science") },
      { value: "Camping", label: t("Community.Options.Camping") },
      { value: "Dancing", label: t("Community.Options.Dancing") },
      {
        value: "Prefer not to say",
        label: t("Community.Options.Prefer not to say"),
      },
    ]);
    setRegularPhysicalActivitiesOptions([
      { value: "Walking", label: t("Community.Options.Walking") },
      { value: "Running", label: t("Community.Options.Running") },
      { value: "Biking", label: t("Community.Options.Biking") },
      { value: "Swimming", label: t("Community.Options.Swimming") },
      { value: "Basketball", label: t("Community.Options.Basketball") },
      { value: "Tennis", label: t("Community.Options.Tennis") },
      { value: "Golf", label: t("Community.Options.Golf") },
      {
        value: "Prefer not to say",
        label: t("Community.Options.Prefer not to say"),
      },
    ]);
  }, [i18n]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.profile);
  const showLoader = useSelector(
    (state) => state.appSettingsReducer.showLoader
  );
  const leisureAndCommunityLifeyRefs = useRef([]);
  const [showError, setShowError] = useState(false);
  const [leisureAndCommunityLife, setLeisureAndCommunityLife] = useState({
    Hobbies: [],
    AssociatedCommunities: [],
    RegularPhysicalActivities: [],
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (profile) {
      if (profile.OnboardingFinished) navigate("/404");
      for (var key of Object.keys(leisureAndCommunityLife)) {
        if (profile[key]) {
          changeReligionAndSpirtuality(key, profile[key]);
        } else if (profile.CustomFields[key]) {
          var arrOfValues = profile.CustomFields[key];
          if (typeof arrOfValues === "string") {
            arrOfValues = arrOfValues.split(",");
          }
          changeReligionAndSpirtuality(
            key,
            arrOfValues.map((item) => ({
              value: item,
              label: t(`OnBoarding:Community.Options.${item}`),
            }))
          );
        }
      }
    }
  }, [profile]);

  useEffect(() => {
    if (profile) {
      dispatch(setTitle(t("Community.title")));
    }
  }, [profile, i18n]);

  const changeReligionAndSpirtuality = (name, value) => {
    setLeisureAndCommunityLife((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeMulti = (name, selectedOptions) => {
    let options = selectedOptions.map((option) => ({
      value: option.value,
      label: option.label.replace(/,/g, " "),
    }));
    if (options.some((option) => option === "Prefer not to say")) {
      options = ["Prefer not to say"];
    }
    changeReligionAndSpirtuality(
      name,
      options.filter((option) => !isEmptyOrSpaces(option.label))
    );
  };

  const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  const focusEmptySelect = (name) => {
    const index = Object.keys(leisureAndCommunityLife).indexOf(name);
    leisureAndCommunityLifeyRefs.current[index].focus();
  };

  const validate = () => {
    let isValidate = true;

    if (leisureAndCommunityLife.RegularPhysicalActivities.length === 0) {
      focusEmptySelect("RegularPhysicalActivities");
      isValidate = false;
    }
    if (leisureAndCommunityLife.AssociatedCommunities.length === 0) {
      focusEmptySelect("AssociatedCommunities");
      isValidate = false;
    }
    if (leisureAndCommunityLife.Hobbies.length === 0) {
      focusEmptySelect("Hobbies");
      isValidate = false;
    }
    if (!isValidate) setShowError(true);

    return isValidate;
  };

  const saveLeisureAndCommunityLife = async () => {
    try {
      if (!validate()) return;
      dispatch(setShowLoader(true));
      const hobbieTags = hobbiesOptions
        .filter(
          (option) =>
            leisureAndCommunityLife.Hobbies.map((item) => item.value).includes(
              option.value
            ) && option.value !== "Prefer not to say"
        )
        .map((option) => option.value);

      const activitiesTags = regularPhysicalActivitiesOptions
        .filter(
          (option) =>
            leisureAndCommunityLife.RegularPhysicalActivities.map(
              (item) => item.value
            ).includes(option.value) && option.value !== "Prefer not to say"
        )
        .map((option) => option.value);

      const data = {
        Tags: hobbieTags.concat(activitiesTags),
        CustomFields: {
          Hobbies: leisureAndCommunityLife.Hobbies.map((item) => item.value),
          AssociatedCommunities:
            leisureAndCommunityLife.AssociatedCommunities.map(
              (item) => item.value
            ),
          RegularPhysicalActivities:
            leisureAndCommunityLife.RegularPhysicalActivities.map(
              (item) => item.value
            ),
        },
      };
      const res = await Api.put("user/profile", data);
      dispatch(setShowLoader(false));
      if (res.data) {
        dispatch(changeProfile(res.data.payload));
        navigate("../my-health");
      }
    } catch (error) {
      dispatch(setShowLoader(false));
    }
  };

  const navigateBack = () => {
    navigate("../religion-and-spirituality");
  };

  return (
    <div className="leisure-and-community-life-container flex-popup-container">
      <div>
        <p className="description">{t("Community.description")}</p>

        <SmallTitle>{t("Community.hobbies")}</SmallTitle>
        <Select
          name={"Hobbies"}
          value={leisureAndCommunityLife.Hobbies.map((option) => option.label)}
          onChange={(e) => handleChangeMulti("Hobbies", e)}
          options={hobbiesOptions.filter(
            (option) =>
              !leisureAndCommunityLife.Hobbies.map(
                (item) => item.value
              ).includes(option.value)
          )}
          isMulti
          editable
          isError={showError && leisureAndCommunityLife.Hobbies.length === 0}
          ref={(el) =>
            (leisureAndCommunityLifeyRefs.current[
              Object.keys(leisureAndCommunityLife).indexOf("Hobbies")
            ] = el)
          }
        />

        <SmallTitle>{t("Community.engage-community")}</SmallTitle>
        <Select
          name={"AssociatedCommunities"}
          value={leisureAndCommunityLife.AssociatedCommunities.map(
            (option) => option.label
          )}
          onChange={(e) => handleChangeMulti("AssociatedCommunities", e)}
          options={communitiesOptions.filter(
            (option) =>
              !leisureAndCommunityLife.AssociatedCommunities.map(
                (item) => item.value
              ).includes(option.value)
          )}
          isMulti
          editable
          isError={
            showError &&
            leisureAndCommunityLife.AssociatedCommunities.length === 0
          }
          ref={(el) =>
            (leisureAndCommunityLifeyRefs.current[
              Object.keys(leisureAndCommunityLife).indexOf(
                "AssociatedCommunities"
              )
            ] = el)
          }
        />

        <SmallTitle>{t("Community.engage-activity")}</SmallTitle>
        <Select
          name={"RegularPhysicalActivities"}
          value={leisureAndCommunityLife.RegularPhysicalActivities.map(
            (option) => option.label
          )}
          onChange={(e) => handleChangeMulti("RegularPhysicalActivities", e)}
          options={regularPhysicalActivitiesOptions.filter(
            (option) =>
              !leisureAndCommunityLife.RegularPhysicalActivities.map(
                (item) => item.value
              ).includes(option.value)
          )}
          isMulti
          editable
          isError={
            showError &&
            leisureAndCommunityLife.RegularPhysicalActivities.length === 0
          }
          ref={(el) =>
            (leisureAndCommunityLifeyRefs.current[
              Object.keys(leisureAndCommunityLife).indexOf(
                "RegularPhysicalActivities"
              )
            ] = el)
          }
        />
      </div>

      <StepsDots
        totalSteps={6}
        stepNumber={4}
        next={saveLeisureAndCommunityLife}
        back={navigateBack}
        isLoading={showLoader}
      />
    </div>
  );
};
