export const ErrorStatusCodes = {
  0: "Errors:0",

  // -- Login errors --
  // 10 <= error < 1000
  10: "Errors:Login.10",
  20: "Errors:Login.20",
  30: "Errors:Login.30",
  40: "Errors:Login.40",
  50: "Errors:Login.50",
  60: "Errors:Login.60",
  70: "Errors:Login.70",

  // -- User errors --
  // 1000 <= error < 2000
  1000: "Errors:User.1000",
  1010: "Errors:User.1010",
  1020: "Errors:User.1020",
  1030: "Errors:User.1030",
  1040: "Errors:User.1040",
  1050: "Errors:User.1050",
  1060: "Errors:User.1060",

  // -- Map errors --
  // 2000 <= error < 3000
  2000: "Errors:Maps.2000",
  2010: "Errors:Maps.2010",
  2020: "Errors:Maps.2020",
  2030: "Errors:Maps.2030",
  2040: "Errors:Maps.2040",
  2050: "Errors:Maps.2050",
  2060: "Errors:Maps.2060",
  2070: "Errors:Maps.2070",
  2080: "Errors:Maps.2080",
  2110: "Errors:Maps.2110",

  // -- External API errors --
  // 3000 <= error < 4000
  3000: "Errors:ExternalAPI.3000",
  3010: "Errors:ExternalAPI.3010",
};

export const getErrorMessage = (statusCode) => {
  if (ErrorStatusCodes[statusCode]) return ErrorStatusCodes[statusCode];
  return "Errors:error-occurred";
};
