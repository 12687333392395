import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ContentBox } from "../../pages/admin/media-center/content-box/content-box";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import "./draggable-list.scss";

import { sortableContainer, sortableElement } from "react-sortable-hoc";

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <div className="draggables-container" distance={1}>
      {children}
    </div>
  );
});

const SortableItem = sortableElement(({ item, ...props }) => (
  <div className="draggable-item-container" id={`${item._id}`}>
    <ContentBox item={item} {...props} id={`${item._id}`}></ContentBox>
  </div>
));

const DraggableList = (props) => {
  const { t } = useTranslation();

  const shouldCancelStart = (event) => {
    // Check if the event target is the drag handle element
    return !event.target.classList.contains("drag-handle") || !props.canDrag;
  };

  return (
    <SortableContainer
      onSortEnd={props.onSortEnd}
      axis="xy"
      shouldCancelStart={shouldCancelStart}
    >
      {props.items.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          item={item}
          {...props}
        />
      ))}
    </SortableContainer>
    // <div>
    //   {props.items && props.items.length > 0 && (
    //     <DragDropContext onDragEnd={props.handleOnDragEnd}>
    //       <Droppable droppableId="myDroppableId" direction="both">
    //         {(provided) => (
    //           <div
    //             {...provided.droppableProps}
    //             ref={provided.innerRef}
    //             className="draggables-container"
    //           >
    //             {props.items.map((item, index) => (
    //               <Draggable
    //                 key={item._id}
    //                 draggableId={item._id}
    //                 index={index}
    //                 isDragDisabled={props.currentCountryId ? true : false}
    //                 id={item._id}
    //               >
    //                 {(provided, snapshot) => (
    //                   <div {...provided.draggableProps} ref={provided.innerRef}>
    //                     {item && item.Title && (
    //                       <ContentBox
    //                         key={item._id}
    //                         isOnDrag={snapshot.isDragging}
    //                         item={item}
    //                         id={item._id}
    //                         active={item.Active}
    //                         {...props}
    //                       >
    //                         <span>
    //                           <span {...provided.dragHandleProps}>
    //                             <FontAwesomeIcon icon={faDotCircle} />
    //                           </span>
    //                         </span>
    //                       </ContentBox>
    //                     )}
    //                   </div>
    //                 )}
    //               </Draggable>
    //             ))}
    //             {provided.placeholder}
    //           </div>
    //         )}
    //       </Droppable>
    //     </DragDropContext>
    //   )}
    // </div>
  );
};

export default DraggableList;
