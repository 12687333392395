import { forwardRef, useEffect } from "react";
import { Input } from "../../../../../../components/input/input";
import { PopupButton } from "../../../../../../components/popup-button/popup-button";
import "./edit-need-step.scss";
import { useTranslation } from "react-i18next";

export const EditNeedStep = forwardRef(
  ({ need, openedSteps, setBooster, showError, navigateToNextStep }, ref) => {
    const { t, i18n } = useTranslation("PopUps");

    useEffect(() => {
      setBooster((prevState) => {
        return {
          ...prevState,
          Need: "",
        };
      });
    }, []);

    const changeNeed = (e) => {
      setBooster((prevState) => {
        return {
          ...prevState,
          Need: e.target.value,
        };
      });
    };

    return (
      <div className="edit-need-step-container">
        {!openedSteps.editInspiration && (
          <div className="background-image"></div>
        )}
        <div className="content">
          <p className="question">
            {t("EditNeedStep.what-else-will-move-you-along")}
          </p>
          <Input
            type="text"
            name="need"
            value={need}
            width="265px"
            onChange={changeNeed}
            // placeholder="My small action..."
            isError={showError}
            ref={ref}
          />
          {!openedSteps.editInspiration && (
            <div className="footer-buttons flex">
              <PopupButton
                className="edit-need-button"
                onClick={navigateToNextStep}
              >
                {t("Common:OK")}
              </PopupButton>
            </div>
          )}
        </div>
      </div>
    );
  }
);
