import dayjs from "dayjs";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { ReactComponent as VIcon } from "../../assets/icons/v.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { SmallTitle } from "../small-title/small-title";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import "./date-input.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

//create your dateValue as null - useState(new Date(null))
// and send it in update mode as - const [dateValue, setdateValue] = useState(new Date("10/16/2022 10:00"))

export const DateInput = forwardRef(
  ({ title, dateValue, updateDateValue, isError, ...props }, ref) => {
    const { t, i18n } = useTranslation("Errors");

    const [date, setDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(false);
    const [classOfInput, setClassOfInput] = useState("");

    useEffect(() => {
      if (dateValue) {
        setSelectedDate(true);
        setDate(
          typeof dateValue === "string" ? new Date(dateValue) : dateValue
        );
      }
    }, [dateValue]);

    // dayjs(step.StartReminder).format("HH:mm DD/MM/YYYY")
    const handleDateChange = (value) => {
      setDate(value);
      const name = props.name ? props.name : "date-input";
      updateDateValue && updateDateValue(name, value);
      setSelectedDate(true);
    };

    const restDate = () => {
      updateDateValue(props.name ? props.name : "date-input", null);
      setSelectedDate(false);
    };

    return (
      <div className="date-input flex">
        <div style={{ width: "100%" }}>
          {isError && !selectedDate && (
            <SmallTitle error={true}>{t("required-field")}</SmallTitle>
          )}
          <div
            className={"date-time-container" + classOfInput}
            {...props}
            ref={ref}
          >
            <div className="flex">
              <label
                htmlFor={props.name ? props.name : "date-time"}
                className="flex"
              >
                <CalendarIcon className="calendar-icon" />
                {!selectedDate ? title : dayjs(date).format("HH:mm DD/MM/YYYY")}
              </label>
              <DatePicker
                onSelect={() => setClassOfInput(" active")}
                className={dateValue ? "active" : ""}
                name={props.name ? props.name : "date-input"}
                id={props.name ? props.name : "date-time"}
                selected={date}
                onChange={handleDateChange}
                showTimeSelect
                dateFormat="HH:mm dd/MM/yyyy"
                timeFormat="HH:mm"
                timeIntervals={15}
              />
              {selectedDate && <VIcon className="icon flex start v-icon" />}
            </div>
          </div>
        </div>
        {selectedDate && (
          <FontAwesomeIcon
            icon={faTrashCan}
            className="trash-icon"
            onClick={restDate}
          />
        )}
      </div>
    );
  }
);
