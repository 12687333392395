import { useState, useEffect } from "react";
import { PopupButton } from "../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import { Select } from "../../../../../components/select/select";
import countryList from "country-list";
import "./create-country-popup.scss";
import { useTranslation } from "react-i18next";

//redux
import {
  createCountry,
  getCountries,
} from "../../../../../store/media-center/media-center-slice";
import { useDispatch } from "react-redux";

export const CreateCountryPopup = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");

  const [selectedCountry, setSelectedCountry] = useState({
    value: "",
    label: "",
  });
  const [filteredCountriesOptions, setFilteredCountriesOptions] = useState([]);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const countriesObj = countryList.getNameList();
    const arr = [];
    for (let key in countriesObj) {
      arr.push({
        value: countriesObj[key],
        label: key.charAt(0).toUpperCase() + key.slice(1),
      });
    }
    setFilteredCountriesOptions(
      arr.filter((item) => !props.countries.includes(item.label))
    );
  }, []);
  return (
    <div className="create-country-container flex-popup-container">
      <Popup
        height="400px"
        width="375px"
        close={() => {
          props.closePopup([], false);
          setSelectedCountry("");
          setErrorOccurred(false);
        }}
      >
        <div className="country-info">
          <div className="create-country-title">
            <PopupTitle>{t("CreateCountryPopup.title")}</PopupTitle>
          </div>
          <div className="create-input content-country-container">
            <div className="content-country-name-container">
              <Select
                name="CountryCode"
                value={selectedCountry.label}
                onChange={(e) => {
                  setSelectedCountry(e);
                }}
                options={filteredCountriesOptions}
                height="200px"
                style={{
                  width: "300px",
                }}
                searchable={true}
              />
            </div>
          </div>
          {errorOccurred && (
            <div className="error-container">
              {t("CreateCountryPopup.error")}
            </div>
          )}
        </div>
        <div className="create-input save-container">
          <PopupButton
            onClick={() => {
              if (!selectedCountry.value || selectedCountry.value === "") {
                setErrorOccurred(true);
                return;
              }
              const data = {
                Name: selectedCountry.label,
                Code: selectedCountry.value,
              };
              dispatch(createCountry(data)).then(() => {
                dispatch(getCountries()).then((res) => {
                  props.closePopup(res.payload, true);
                });
                setSelectedCountry("");
                setErrorOccurred(false);
              });
            }}
          >
            {t("Common:save")}
          </PopupButton>
        </div>
      </Popup>
    </div>
  );
};
