import { PopupButton } from "../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import { SmallTitle } from "../../../../../components/small-title/small-title";
import "./restore-country-popup.scss";
import { useTranslation } from "react-i18next";

//redux
import {
  getCountries,
  restoreCountry,
} from "../../../../../store/media-center/media-center-slice";
import { useDispatch } from "react-redux";

export const RestoreCountryPopup = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");

  const dispatch = useDispatch();

  return (
    <div className="restore-country-container flex-popup-container">
      <Popup
        height="175px"
        width="375px"
        close={() => {
          props.closePopup([], false);
        }}
      >
        <div className="restore-country-title">
          <PopupTitle>{t("RestoreCountryPopup.title")}</PopupTitle>
        </div>
        <div className="restore-country-info">
          <SmallTitle>{t("RestoreCountryPopup.small-title")}</SmallTitle>
        </div>
        <div className="restore-country save-container">
          <PopupButton
            className="action-button"
            onClick={() => {
              dispatch(restoreCountry(props.countryId)).then(() => {
                dispatch(getCountries()).then((res) => {
                  props.closePopup(res.payload, true);
                });
              });
            }}
          >
            {t("Common:confirm")}
          </PopupButton>
          <PopupButton
            className="action-button"
            onClick={() => {
              props.closePopup([], false);
            }}
          >
            {t("Common:cancel")}
          </PopupButton>
        </div>
      </Popup>
    </div>
  );
};
