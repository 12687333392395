import { ReactComponent as FantasticIcon } from "../../../../../assets/icons/fantastic-popup.svg";
import { PopupButton } from "../../../../../components/popup-button/popup-button";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import "./went-well-popup.scss";
import { useTranslation } from "react-i18next";

export const WentWellPopup = ({ close }) => {
  const { t, i18n } = useTranslation("PopUps");

  return (
    <div className="went-well-conatiner flex">
      <PopupTitle>{t("WentWell.fantastic")}!</PopupTitle>
      <FantasticIcon className="icon" />
      <PopupButton className="continue-button" onClick={close}>
        {t("WentWell.continue-my-journey")}
      </PopupButton>
    </div>
  );
};
