import dayjs from "dayjs";
import React from "react";
import ReactHtmlParser from "react-html-parser";

export const MessageBlock = ({ subject, body, date, index }) => {
  return (
    <div className={`message ${index % 2 === 0 ? "sent" : "received"}`}>
      <h3 className="message-title">{subject}</h3>
      <div className="message-body">{ReactHtmlParser(body)}</div>
      <span className="metadata">
        <span className="time">{dayjs(date).format("DD/MM/YYYY HH:mm")}</span>
      </span>
    </div>
  );
};
