import { faRoadBarrier } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "../../../../../../components/button/button";
import { Checkbox } from "../../../../../../components/checkbox/checkbox";
import { Popup } from "../../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../../components/popup/popup-title/popup-title";
import "./clicked-obstacle-popup.scss";
import { useTranslation } from "react-i18next";

export const ClickedObstaclePopup = ({ index, info, finishStep, close }) => {
  const { t, i18n } = useTranslation("Maps");

  const [dontShowMeAgain, setDontShowMeAgain] = useState(false);

  const finishStepAndClose = () => {
    if (dontShowMeAgain)
      localStorage.setItem("dontShowClickedObstaclePopup", true);
    finishStep(index);
    close();
  };

  return (
    <Popup close={close}>
      <div className="clicked-obstacle-popup-container flex">
        <div className="headers flex">
          <PopupTitle style={{ textAlign: "center" }}>
            {t("ViewMap.ClickedObstaclePopUp.clicked")}{" "}
            <FontAwesomeIcon icon={faRoadBarrier} className="obstacle-icon" />
          </PopupTitle>
          <PopupTitle small={true} style={{ textAlign: "center" }}>
            {t("ViewMap.ClickedObstaclePopUp.purpose")}{" "}
          </PopupTitle>
        </div>

        <br />
        <Button onClick={finishStepAndClose} className="got-it-button">
          {t("ViewMap.ClickedObstaclePopUp.got-it")}
        </Button>
        <div>
          <Checkbox
            id={`DontShowMeAgain`}
            label={t("ViewMap.ClickedObstaclePopUp.not-again")}
            checked={dontShowMeAgain}
            onChange={() => setDontShowMeAgain((prevState) => !prevState)}
          />
        </div>
      </div>
    </Popup>
  );
};
